<template>
    <div>
        <ToolBar
            :menu="'Tra cứu học sinh'"
            :content="getContentToolBar()"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    label="Quay lại"
                    :btnToolBar="true"
                    @click="navigateLockUpStudent"
                />
                <CustomButton
                    v-if="!viewVisible"
                    label="Chỉnh sửa"
                    :btnToolBar="true"
                    @click="handleUpdate"
                />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <CustomTab
                    :initialTab="active_tab_info"
                    :tabs="list_tab_info"
                    :hideHr="false"
                />
                <div class="form-update mt-3">
                    <div class="row">
                        <div class="col-md-2">
                            <Avatar :avatar="avatar" :code="student_code" />
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Khối học
                                        </label>
                                        <div class="n-index error">[1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_school_block"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="school_block"
                                        @change="onChangeSchoolBlock"
                                        @input="
                                            onChangeHandler(
                                                'school_block',
                                                $event
                                            )
                                        "
                                        :class="{
                                            'error-select':
                                                err_school_block.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_school_block.length > 0"
                                        class="error"
                                    >
                                        {{ err_school_block }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Lớp học
                                        </label>
                                        <div class="n-index error">[2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_class_name"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="class_name"
                                        @input="
                                            onChangeHandler('class_name', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_class_name.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_class_name.length > 0"
                                        class="error"
                                    >
                                        {{ err_class_name }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Họ tên
                                        </label>
                                        <div class="n-index error">[3]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="full_name"
                                        :disabled="viewVisible"
                                        @input="
                                            onChangeHandler('full_name', $event)
                                        "
                                        :textError="err_full_name"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày sinh
                                        </label>
                                        <div class="n-index error">[4]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="date_of_birth"
                                        :disabled="viewVisible"
                                        @input="
                                            onChangeHandler(
                                                'date_of_birth',
                                                $event
                                            )
                                        "
                                        :show-error="err_date_of_birth.length > 0"
                                    />
                                    <span
                                        v-if="err_date_of_birth.length > 0"
                                        class="error"
                                    >
                                        {{ err_date_of_birth }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Giới tính
                                        </label>
                                        <div class="n-index error">[5]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_gender"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="gender"
                                        @input="onChangeHandler('gender', $event)"
                                        :class="{
                                            'error-select': err_gender.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_gender.length > 0"
                                        class="error"
                                    >
                                        {{ err_gender }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Trạng thái học sinh
                                        </label>
                                        <div class="n-index error">[6]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_student_status"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="student_status"
                                        @input="
                                            onChangeHandler(
                                                'student_status',
                                                $event
                                            )
                                        "
                                        @change="onChangeStudentStatus"
                                        :class="{
                                            'error-select':
                                                err_student_status.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_student_status.length > 0"
                                        class="error"
                                    >
                                        {{ err_student_status }}
                                    </span>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-show="[1, 3].includes(student_status)"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi chuyển đi
                                        </label>
                                        <div
                                            :class="{
                                                'n-index': true,
                                                error: student_status !== 1
                                            }"
                                        >
                                            [6.1]
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_departure"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="departure"
                                        @input="
                                            onChangeHandler('departure', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_departure.length > 0
                                        }"
                                        @change="onChangeDeparture"
                                    />
                                    <span
                                        v-if="err_departure.length > 0"
                                        class="error"
                                    >
                                        {{ err_departure }}
                                    </span>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-show="
                                    [1].includes(student_status) &&
                                    departure != '03'
                                "
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Chuyển đến từ tỉnh
                                        </label>
                                        <div class="n-index">[6.2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_province_filter"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="from_province"
                                        @change="onChangeFromProvince"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-show="
                                    [1].includes(student_status) &&
                                    departure != '03'
                                "
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Chuyển đến từ huyện
                                        </label>
                                        <div class="n-index">[6.3]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_from_district"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="from_district"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-show="
                                    [3].includes(student_status) &&
                                    departure != '03'
                                "
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Chuyển đi đến tỉnh
                                        </label>
                                        <div class="n-index">[6.4]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_province_filter"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="to_province"
                                        @change="onChangeToProvince"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-show="
                                    [3].includes(student_status) &&
                                    departure != '03'
                                "
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Chuyển đi đến huyện
                                        </label>
                                        <div class="n-index">[6.5]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_to_district"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="to_district"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày trạng thái
                                        </label>
                                        <div class="n-index error">[7]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="date_status"
                                        :disabled="viewVisible"
                                        @input="
                                            onChangeHandler('date_status', $event)
                                        "
                                        :show-error="err_date_status.length > 0"
                                    />
                                    <span
                                        v-if="err_date_status.length > 0"
                                        class="error"
                                    >
                                        {{ err_date_status }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Dân tộc
                                        </label>
                                        <div class="n-index error">[8]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_nation"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="nation"
                                        @input="onChangeHandler('nation', $event)"
                                        :class="{
                                            'error-select': err_nation.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_nation.length > 0"
                                        class="error"
                                    >
                                        {{ err_nation }}
                                    </span>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Dân tộc trên giấy khai sinh
                                        </label>
                                        <div class="n-index">[8.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="nation_on_birth_certificate"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Quốc tịch
                                        </label>
                                        <div class="n-index">[8.2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_nationality"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="nationality"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Tôn giáo
                                        </label>
                                        <div class="n-index">[9]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_religion"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="religion"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Mã định danh công dân
                                        </label>
                                        <div class="n-index">[10]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="citizen_ID"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Mã định danh bộ GD&ĐT
                                        </label>
                                        <div class="n-index">[11]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="ministry_of_education_ID"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày vào trường
                                        </label>
                                        <div class="n-index">[12]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="entry_date"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div> -->
                            <!-- <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày trạng thái
                                        </label>
                                        <div class="n-index">[13]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="status_date"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div> -->
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Thời điểm chuyển trường/bỏ học của học
                                            sinh
                                        </label>
                                        <div class="n-index">[14]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="transfer_date"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label blue">
                                            Thông tin hộ khẩu, quê quán
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-8"></div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Hộ khẩu thường trú
                                        </label>
                                        <div class="n-index">[15]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_province"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="province_permanent_address"
                                        @change="onChangeProvincePermanentAddress"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4"></div>
                                <div class="col-md-8">
                                    <div style="display: flex">
                                        <div class="col-sm-6">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_district_permanent_address
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    district_permanent_address
                                                "
                                                @change="
                                                    onChangeDistrictPermanentAddress
                                                "
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_wards_permanent_address
                                                "
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="wards_permanent_address"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4"></div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="village_permanent_address"
                                        :disabled="viewVisible"
                                        :placeholder="'Nhập Tổ/Thôn/Xóm thường trú'"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Quê quán
                                        </label>
                                        <div class="n-index">[16]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_province"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="province_hometown"
                                        @change="onChangeProvinceHometown"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4"></div>
                                <div class="col-md-8">
                                    <div style="display: flex">
                                        <div class="col-sm-6">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_district_hometown"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="district_hometown"
                                                @change="onChangeDistrictHometown"
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <ESelect
                                                style="width: 100%"
                                                :clearable="true"
                                                :disabled="viewVisible"
                                                :data="list_wards_hometown"
                                                :placeholder="'Chọn '"
                                                :filterable="true"
                                                :multiple="false"
                                                :collapse-tags="true"
                                                :fields="['label', 'value']"
                                                v-model="wards_hometown"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4"></div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="village_hometown"
                                        :disabled="viewVisible"
                                        :placeholder="'Nhập Tổ/Thôn/Xóm quê quán'"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label blue">
                                            Thông tin hộ khẩu, quê quán
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-8"></div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi sinh
                                        </label>
                                        <div class="n-index">[17]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="place_of_birth"
                                        :type="'textarea'"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Chỗ ở hiện nay
                                        </label>
                                        <div class="n-index">[18]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="current_residence"
                                        :type="'textarea'"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nhóm máu
                                        </label>
                                        <div class="n-index">[19]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_blood_type"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="blood_type"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Thành phần gia đình
                                        </label>
                                        <div class="n-index">[20]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_family_background"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="family_background"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Số điện thoại liên hệ
                                        </label>
                                        <div class="n-index">[21]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="contact_phone"
                                        :trimAll="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Email
                                        </label>
                                        <div class="n-index">[22]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="email"
                                        :trimAll="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Sổ đăng bộ
                                        </label>
                                        <div class="n-index">[23]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="family_book"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Thứ tự
                                        </label>
                                        <div class="n-index">[24]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="order"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-3"></div>
                <CustomTab
                    :initialTab="active_tab_private"
                    :tabs="list_tab_private"
                    :hideHr="false"
                    @tabChanged="handleTabPrivateChanged"
                />

                <div class="form-update mt-3" v-if="active_tab_private == 1">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Khu vực
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_area"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="area"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Khuyết tật
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_disability"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Loại khuyết tật
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_disability_type"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="disability_type"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Khuyết tật không đánh giá
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_unassessed_disability"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Đối tượng chính sách
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_policy_object"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="policy_object"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Hướng nghiệp, dạy nghề
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_career_orientation"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="career_orientation"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Đối tượng ưu tiên
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_priority_object"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="priority_object"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Hỗ trợ chi phí học tập
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="
                                            is_financial_support_for_education
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Cấp tiền hàng tháng
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_monthly_stipend"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Hỗ trợ nhà ở
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_housing_assistance"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Bệnh về mắt
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_eye_related_illness_support"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh biết bơi kỳ I
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_swimming_ability_semester_I"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh biết bơi kỳ II
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_swimming_ability_semester_II"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            CMTND/TCC
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="CMTND_TCC"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày cấp
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <DatePicker
                                        v-model="date_of_issue"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi cấp
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="place_of_issue"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Loại nhập học
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_type_of_admission"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="type_of_admission"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Có mẹ dân tộc
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_ethnic_mother"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Có cha dân tộc
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_ethnic_father"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Phụ huynh có Smartphone
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_parent_has_smartphone"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Phụ huynh có Máy tính Internet
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_parent_has_computer_internet"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Số buổi học trên tuần
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="
                                            number_of_study_sessions_per_week
                                        "
                                        :disabled="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh lớp bán trú
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_boarding_class_student"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học bán trú
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :clearable="true"
                                        :disabled="viewVisible"
                                        :data="list_boarding_school_student"
                                        :placeholder="'Chọn '"
                                        :filterable="true"
                                        :multiple="false"
                                        :collapse-tags="true"
                                        :fields="['label', 'value']"
                                        v-model="boarding_school_student"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Đội viên
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_party_member"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Đoàn viên
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_youth_union_member"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học tiếng dân tộc
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_ethnic_language_study"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học song ngữ
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_bilingual_study"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([4, 5, 45])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học 2 buổi
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_two_sessions_per_day"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([4, 5, 45])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            HS tuyển mới
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_accepting_new_students"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([4, 5, 45])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Trẻ lớp bán trú
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_boarding_school_student"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh bán trú dân nuôi
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_boarding_student_foster"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh nội trú dân nuôi
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_boarding_student_resident"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh bán trú
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_boarding_student"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh nội trú
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_resident_student"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh phổ thông dân tộc bán trú
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_boarding_ethnic_student"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group" v-if="isGradeLevelValid([1])">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh nhu cầu hỗ trợ ngoại ngữ
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="
                                            is_students_need_language_support
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group" v-if="isGradeLevelValid([1])">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học sinh dân tộc trợ giảng
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="
                                            is_ethnic_minority_teaching_assistants
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group" v-if="isGradeLevelValid([1])">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Tăng cường tiếng Việt
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="
                                            is_enhance_vietnamese_language_skills
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group" v-if="isGradeLevelValid([1])">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Đã học lớp mẫu giáo 5 tuổi
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="
                                            is_have_attended_kindergarte_at_age_5
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Học chương trình giáo dục của Bộ
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_ministry_education_program"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-update mt-3" v-if="active_tab_private == 2">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Họ tên bố
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="father_name"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nghề nghiệp bố
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="father_occupation"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Năm sinh bố
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <DatePicker
                                        v-model="father_birth_year"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi làm việc bố
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="father_workplace"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Họ tên mẹ
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="mother_name"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nghề nghiệp mẹ
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="mother_occupation"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Năm sinh mẹ
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <DatePicker
                                        v-model="mother_birth_year"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi làm việc mẹ
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="mother_workplace"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Họ tên người giám hộ
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="guardian_name"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nghề nghiệp người giám hộ
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="guardian_occupation"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Năm sinh người giám hộ
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <DatePicker
                                        v-model="guardian_birth_year"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi làm việc người giám hộ
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="guardian_workplace"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </FormLockUp>
    </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomTab from '@/components/CustomTab.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { getFromSessionStorage } from '@/utils/session'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import Avatar from '@/components/Avatar.vue'
import FormLockUp from '@/components/FormLockUp.vue'
import { list_gender, list_student_status } from '@/mock_data/tra-cuu-hoc-sinh'
import { list_school_block } from '@/mock_data'
import {
    convertListData,
    filterListSchoolBlock,
    formatDateToYYYYMMDD,
    formatDateToDDMMYYYY,
    convertStringToNumber,
    convertNumberToString,
    convertToBoolean,
    convertToBinary,
    parseJsonArray
} from '@/utils'
import { handlerError, validateFields } from '@/utils/validator'
import { hideLoading, showLoading } from '@/utils/loading'
import ESelect from '@/components/ESelect.vue'
import { path } from '@/router/path'
import { showNotication } from '@/utils/notification'
export default {
    name: 'UpdateLockUpStudent',
    components: {
        ToolBar,
        CustomButton,
        CustomTab,
        FormLockUp,
        ESelect,
        CustomInput,
        DatePicker,
        Avatar
    },
    data() {
        return {
            avatar: null,
            student_code: '',
            list_school_block: [],
            school_block: '', // khối học
            age_group: '', // nhóm tuổi
            list_class_name: [],
            class_name: '', // lớp học
            full_name: '', // họ tên
            date_of_birth: '', // ngày sinh
            list_gender: list_gender,
            gender: '', // giới tính
            list_student_status: list_student_status,
            student_status: '', // trạng thái học sinh
            departure: '', // nơi chuyển đi
            from_province: '', // chuyển đến từ tỉnh
            to_province: '', // chuyển đi đến tỉnh
            list_from_district: [],
            from_district: '', // chuyển đến từ huyện
            list_to_district: [],
            to_district: '', // chuyển đi đến huyện

            date_status: '', // Ngày trạng thái
            nation: '', // dân tộc
            nation_on_birth_certificate: '', // dân tộc trên giấy khai sinh
            nationality: '', // quốc tịch
            religion: '', // tôn giáo
            citizen_ID: '', // mã định danh công dân
            ministry_of_education_ID: '', // mã định danh bộ GD&ĐT
            entry_date: '', // ngày vào trường
            status_date: '', // ngày trạng thái
            transfer_date: '', // thời điểm chuyển trường/bỏ học của học sinh

            // Hộ khẩu thường trú
            province_permanent_address: '', // tỉnh thường trú
            list_district_permanent_address: [],
            district_permanent_address: '', // huyện thường trú
            list_wards_permanent_address: [],
            wards_permanent_address: '', // phường thường trú
            village_permanent_address: '', // xã thường trú
            // Quê quán
            province_hometown: '', // tỉnh
            list_district_hometown: [],
            district_hometown: '', // huyện
            list_wards_hometown: [],
            wards_hometown: '', // phường
            village_hometown: '', //xã
            place_of_birth: '', // nơi sinh
            current_residence: '', // chỗ ở hiện nay
            blood_type: '', // nhóm máu
            family_background: '', // thành phần gia đình
            contact_phone: '', // số điện thoại liên hệ
            email: '', // email
            family_book: '', // sổ đăng bộ
            order: '', // thứ tự

            area: '', // Khu vực
            is_disability: false, // Khuyết tật
            disability_type: '', // Loại khuyết tật
            is_unassessed_disability: false, // Khuyết tật không đánh giá
            policy_object: '', // Đối tượng chính sách
            priority_object: '', // Đối tượng ưu tiên
            is_financial_support_for_education: false, // Hỗ trợ chi phí học tập
            is_monthly_stipend: false, //  cấp tiền hàng tháng
            is_housing_assistance: false, //  hỗ trợ nhà ở
            is_eye_related_illness_support: false, //  hỗ trợ bệnh về mắt
            career_orientation: '', // Hướng nghiệp, dạy nghề
            is_swimming_ability_semester_I: false, // Học sinh biết bơi kỳ I
            is_swimming_ability_semester_II: false, // Học sinh biết bơi kỳ II

            CMTND_TCC: '', // CMTND/TCC
            date_of_issue: '', // Ngày cấp
            place_of_issue: '', // Nơi cấp

            type_of_admission: '', // Loại nhập học
            is_ethnic_mother: false, // Có mẹ dân tộc
            is_ethnic_father: false, // Có cha dân tộc
            is_parent_has_smartphone: false, // Phụ huynh có Smartphone
            is_parent_has_computer_internet: false, // Phụ huynh có Máy tính Internet
            number_of_study_sessions_per_week: '', // Số buổi học trên tuần
            is_boarding_class_student: false, // Học sinh lớp bán trú
            boarding_school_student: '', // Học bán trú

            is_party_member: false, // Đội viên
            is_youth_union_member: false, // Đoàn viên
            is_ethnic_language_study: false, // Học tiếng dân tộc
            is_bilingual_study: false, // Học song ngữ
            is_two_sessions_per_day: false, // Học 2 buổi
            is_accepting_new_students: false, // Học sinh mới được tuyển
            is_boarding_school_student: false, // Trẻ lớp bán trú
            is_boarding_student_foster: false, // Học sinh bán trú dân nuôi
            is_boarding_student_resident: false, // Học sinh nội trú dân nuôi
            is_boarding_student: false, // Học sinh bán trú
            is_resident_student: false, // Học sinh nội trú
            is_boarding_ethnic_student: false, // Học sinh phổ thông dân tộc bán trú
            is_students_need_language_support: false, // Học sinh nhu cầu hỗ trợ ngoại ngữ
            is_ethnic_minority_teaching_assistants: false, // Học sinh dân tộc trợ giảng
            is_enhance_vietnamese_language_skills: false, // Tăng cường tiếng Việt
            is_have_attended_kindergarte_at_age_5: false, // Đã học lớp mẫu giáo 5 tuổi
            is_ministry_education_program: false, // Học chương trình giáo dục của Bộ

            father_name: '', // Họ tên bố
            mother_name: '', // Họ tên mẹ
            guardian_name: '', // Họ tên người giám hộ
            father_occupation: '', // Nghề nghiệp bố
            mother_occupation: '', // Nghề nghiệp mẹ
            guardian_occupation: '', // Nghề nghiệp người giám hộ
            father_birth_year: '', // Năm sinh bố
            mother_birth_year: '', // Năm sinh mẹ
            guardian_birth_year: '', // Năm sinh người giám hộ
            father_workplace: '', // Nơi làm việc bố
            mother_workplace: '', // Nơi làm việc mẹ
            guardian_workplace: '', // Nơi làm việc người giám hộ

            err_school_block: '',
            err_class_name: '',
            err_full_name: '',
            err_date_of_birth: '',
            err_gender: '',
            err_student_status: '',
            err_departure: '',
            err_date_status: '',
            err_nation: '',

            errModal: false,
            info_student: {},
            viewVisible: false,

            active_tab_info: 1,
            list_tab_info: [{ number: 1, label: 'Thông tin chung' }],

            active_tab_private: 1,
            list_tab_private: [
                { number: 1, label: 'I. Thông tin cá nhân' },
                { number: 2, label: 'II. Thông tin liên hệ gia đình' }
            ]
        }
    },

    methods: {
        isGradeLevelValid(allowedValues) {
            return allowedValues.includes(this.info_student.capHoc)
        },
        getContentToolBar() {
            return this.viewVisible
                ? 'Thông tin học sinh'
                : 'Chỉnh sửa thông tin học sinh'
        },
        navigateLockUpStudent() {
            this.$router.push({ path: path.lock_up.student.home })
        },
        onChangeStudentStatus() {
            this.departure = ''
            this.err_departure = ''
            this.onChangeDeparture()
        },
        onChangeDeparture() {
            this.from_province = ''
            this.from_district = ''
            this.list_to_district = []
            this.list_from_district = []
            this.to_province = ''
            this.to_district = ''
        },
        async get_list_district(province, type) {
            try {
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: province,
                    check: false
                }
                const response = await sendRequest(
                    Api.app.quan_huyen,
                    null,
                    this.request_headers,
                    request_params
                )

                const list = convertListData(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
                const listMap = {
                    from: 'list_from_district',
                    to: 'list_to_district',
                    permanent_address: 'list_district_permanent_address',
                    hometown: 'list_district_hometown'
                }

                province
                    ? (this[listMap[type]] = list)
                    : (this[listMap[type]] = [])
            } catch (error) {
                console.log(error)
            }
        },
        onChangeFromProvince() {
            this.from_district = ''
            this.get_list_district(this.from_province, 'from')
        },
        onChangeToProvince() {
            this.to_district = ''
            this.get_list_district(this.to_province, 'to')
        },
        onChangeProvincePermanentAddress() {
            this.district_permanent_address = ''
            this.list_wards_permanent_address = []
            this.wards_permanent_address = ''
            this.get_list_district(
                this.province_permanent_address,
                'permanent_address'
            )
        },
        onChangeDistrictPermanentAddress() {
            this.wards_permanent_address = ''
            this.get_list_wards(
                this.district_permanent_address,
                'permanent_address'
            )
        },
        onChangeProvinceHometown() {
            this.district_hometown = ''
            this.list_wards_hometown = []
            this.wards_hometown = ''
            this.get_list_district(this.province_hometown, 'hometown')
        },
        onChangeDistrictHometown() {
            this.wards_hometown = ''
            this.get_list_wards(this.district_hometown, 'hometown')
        },
        async get_list_wards(district, type) {
            try {
                const request_params = {
                    start: 0,
                    limit: 9999,
                    maTinhThanh:
                        type === 'hometown'
                            ? this.province_hometown
                            : this.province_permanent_address,
                    maQuanHuyen: district,
                    check: false
                }
                const response = await sendRequest(
                    Api.app.phuong_xa,
                    null,
                    this.request_headers,
                    request_params
                )

                const list = convertListData(
                    response.rows,
                    'tenPhuongXa',
                    'maPhuongXa'
                )
                const listMap = {
                    permanent_address: 'list_wards_permanent_address',
                    hometown: 'list_wards_hometown'
                }
                district
                    ? (this[listMap[type]] = list)
                    : (this[listMap[type]] = [])
            } catch (error) {
                console.log(error)
            }
        },
        async get_info_student() {
            try {
                showLoading()
                const request_params = {
                    idHsTheoNam: this.$route.params.id
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_hoc_sinh.chi_tiet_hoc_sinh,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response) {
                    this.info_student = response
                    this.avatar = response.avatar
                    this.student_code = response.maHocSinh
                    this.school_block = response.khoiHoc
                    this.list_school_block = filterListSchoolBlock(
                        list_school_block,
                        response.capHoc
                    )
                    this.class_name = response.maLopHocCn
                    this.full_name = response.hoTen
                    this.date_of_birth = formatDateToYYYYMMDD(response.ngaySinh)
                    this.gender = convertNumberToString(response.gioiTinh)
                    this.student_status = response.trangThai
                    this.departure = response.noiChuyenDi

                    this.from_province = convertStringToNumber(
                        response.chuyenDenTuTinh
                    )
                    if (this.from_province) {
                        this.get_list_district(this.to_province, 'from')
                    }

                    this.from_district = response.chuyenDenTuQuanHuyen

                    this.to_province = convertStringToNumber(
                        response.chuyenDiDenTinh
                    )
                    if (this.to_province) {
                        this.get_list_district(this.to_province, 'to')
                    }
                    this.to_district = response.chuyenDiDenQuanHuyen

                    this.date_status = formatDateToYYYYMMDD(
                        response.ngayTrangThai
                    )
                    this.nation = response.maDanToc
                    this.nation_on_birth_certificate =
                        response.danTocTheoGiayKhaiSinh
                    this.nationality = response.maQuocTich
                    this.religion = response.maTonGiao
                    this.citizen_ID = response.citizenId
                    this.ministry_of_education_ID = response.moetCode
                    // this.entry_date = formatDateToYYYYMMDD(response.ngayTrangThai)
                    // this.status_date = formatDateToYYYYMMDD(
                    //     response.ngayTrangThai
                    // )
                    this.transfer_date = response.thoiDiemRoiTruong

                    // Hộ khẩu thường trú
                    this.province_permanent_address = response.maTthanhThtru

                    if (this.province_permanent_address) {
                        this.onChangeProvincePermanentAddress()
                    }

                    this.district_permanent_address = response.maQhuyenThtru
                    if (this.district_permanent_address) {
                        this.onChangeDistrictPermanentAddress()
                    }
                    this.wards_permanent_address = response.maPxaThtru
                    this.village_permanent_address = response.maTthonThtru

                    // Quê quán
                    this.province_hometown = response.maTthanhQueQuan

                    if (this.province_hometown) {
                        this.onChangeProvinceHometown()
                    }
                    this.district_hometown = response.maQhuyenQueQuan
                    if (this.district_hometown) {
                        this.onChangeDistrictHometown()
                    }
                    this.wards_hometown = response.maPxaQueQuan
                    this.village_hometown = response.maTthonQueQuan

                    this.place_of_birth = response.noiSinh
                    this.current_residence = response.dchiTmtru
                    this.blood_type = convertNumberToString(response.nhomMau)
                    this.family_background = convertNumberToString(
                        response.tphanGdinh
                    )
                    this.contact_phone = response.soDthoaiDdong
                    this.email = response.email
                    this.family_book = response.soDangBo
                    this.order = response.thuTu

                    this.area = response.maKhuVuc
                    this.is_disability = convertToBoolean(response.khuyetTat)
                    this.disability_type = convertNumberToString(
                        response.maLoaiKhuyetTat
                    )
                    this.is_unassessed_disability = response.khuyetTatKhongDanhGia

                    this.policy_object = convertNumberToString(
                        response.maDtuongCsach
                    )
                    this.priority_object = convertNumberToString(
                        response.dtuongUuTien
                    )
                    this.is_financial_support_for_education =
                        response.htChiPhiHocTap
                    this.is_monthly_stipend = response.capTienHangThang

                    this.is_housing_assistance = response.htroNhaO
                    this.is_eye_related_illness_support = response.benhMat

                    this.career_orientation = response.maLopHuongNghiepDayNghe
                    this.is_swimming_ability_semester_I = response.isBietBoiKy1
                    this.is_swimming_ability_semester_II = response.isBietBoiKy2

                    this.CMTND_TCC = response.soCmnd
                    this.date_of_issue = response.ngayCapCmnd
                    this.place_of_issue = response.noiCapCmnd
                    this.is_party_member = convertToBoolean(response.doiVien)
                    this.is_youth_union_member = convertToBoolean(
                        response.doanVien
                    )
                    this.is_ethnic_language_study = response.hocSinhTiengDanToc
                    this.is_bilingual_study = response.hocSongNgu
                    this.is_accepting_new_students = response.tuyenMoiMn
                    this.type_of_admission = convertNumberToString(
                        response.loaiNhapHoc
                    )
                    this.is_ethnic_mother = response.meDanToc
                    this.is_ethnic_father = response.chaDanToc
                    this.is_parent_has_smartphone = response.isPhCoSmartPhone
                    this.is_parent_has_computer_internet =
                        response.isPhCoMayTinhInternet
                    this.number_of_study_sessions_per_week = `${response.soBuoiTrenTuan} buổi học/tuần - [${response.soBuoiTrenTuan}]`
                    this.is_boarding_class_student = convertToBoolean(
                        response.lopBanTru
                    )
                    this.boarding_school_student = response.maHocBanTru

                    this.is_boarding_student_foster =
                        response.hocSinhBanTruDanNuoi
                    this.is_boarding_student_resident =
                        response.hocSinhNoiTruDanNuoi
                    this.is_boarding_student = response.hocSinhBanTru
                    this.is_resident_student = response.hocSinhNoiTru
                    this.is_boarding_ethnic_student = response.hocSinhPtdtBanTru
                    this.is_students_need_language_support =
                        response.nhuCauHtroNngu
                    this.is_ethnic_minority_teaching_assistants =
                        response.hocSinhDanTocTroGiang
                    this.is_enhance_vietnamese_language_skills =
                        response.tangCuongTviet
                    this.is_have_attended_kindergarte_at_age_5 = convertToBoolean(
                        response.hocLopMg5T
                    )
                    this.is_ministry_education_program = response.hocCtgdCuaBo

                    this.father_name = response.hoTenBo
                    this.mother_name = response.hoTenMe
                    this.guardian_name = response.hoTenNguoiGiamHo
                    this.father_occupation = response.ngheNghiepBo
                    this.mother_occupation = response.ngheNghiepMe
                    this.guardian_occupation = response.ngheNghiepNguoiGiamHo
                    this.father_birth_year = formatDateToYYYYMMDD(
                        response.ngaySinhBo
                    )
                    this.mother_birth_year = formatDateToYYYYMMDD(
                        response.ngaySinhMe
                    )
                    this.guardian_birth_year = formatDateToYYYYMMDD(
                        response.ngaySinhNguoiGiamHo
                    )
                    this.father_workplace = response.noiLamViecBo
                    this.mother_workplace = response.noiLamViecMe
                    this.guardian_workplace = response.noiLamViecNguoiGiamHo

                    await this.get_class_name()
                    hideLoading()
                }
            } catch (error) {
                console.log(error)
            }
        },
        async get_class_name() {
            if (this.school_block) {
                try {
                    const request_params = {
                        khoiHoc: this.school_block,
                        maTruong: this.info_student.maTruongHoc,
                        namHoc: this.info_student.namHoc,
                        maNhomTuoiMn: this.info_student.maNhomTuoiMn
                    }

                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_hoc_sinh.danh_sach_lop_Hoc,
                        null,
                        this.request_headers,
                        request_params
                    )

                    this.list_class_name = convertListData(
                        response.tableData,
                        'tenLopHoc',
                        'maLopHoc'
                    )
                } catch (error) {
                    console.log(error)
                }
            }
        },
        onChangeSchoolBlock() {
            this.class_name = ''
            this.get_class_name()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                school_block: 'err_school_block',
                class_name: 'err_class_name',
                full_name: 'err_full_name',
                date_of_birth: 'err_date_of_birth',
                gender: 'err_gender',
                student_status: 'err_student_status',
                departure: 'err_departure',
                date_status: 'err_date_status',
                nation: 'err_nation'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        handleTabPrivateChanged(tabNumber) {
            this.active_tab_private = tabNumber
        },
        async handleUpdate() {
            try {
                showLoading()
                const fieldsToValidate = [
                    {
                        value: this.school_block,
                        errorField: 'err_school_block'
                    },

                    {
                        value: this.class_name,
                        errorField: 'err_class_name'
                    },

                    {
                        value: this.full_name,
                        errorField: 'err_full_name'
                    },

                    {
                        value: this.date_of_birth,
                        errorField: 'err_date_of_birth'
                    },

                    {
                        value: this.gender,
                        errorField: 'err_gender'
                    },
                    {
                        value: this.student_status,
                        errorField: 'err_student_status'
                    },
                    {
                        value: this.date_status,
                        errorField: 'err_date_status'
                    },
                    {
                        value: this.nation,
                        errorField: 'err_nation'
                    }
                ]

                if (validateFields(this, fieldsToValidate)) {
                    const request_data = {
                        updateHocSinhRequest: {
                            anhChanDung: this.info_student.anhChanDung,
                            avatar: this.info_student.avatar,
                            benhCotSong: this.info_student.benhCotSong,
                            benhMat: this.is_eye_related_illness_support,
                            buoiHoc: this.info_student.buoiHoc,
                            canNang: this.info_student.canNang,
                            canNangKy2: this.info_student.canNangKy2,
                            canThi: this.info_student.canThi,
                            capGao: this.info_student.capGao,
                            capHoc: this.info_student.capHoc,
                            capTienHangThang: this.is_monthly_stipend,
                            chaDanToc: this.is_ethnic_father,
                            chiSoBmi: this.info_student.chiSoBmi,
                            chieuCao: this.info_student.chieuCao,
                            chieuCaoKy2: this.info_student.chieuCaoKy2,
                            chuyenDenTuQuanHuyen: this.from_district,
                            chuyenDenTuTinh: convertNumberToString(
                                this.from_province
                            ),
                            chuyenDiDenQuanHuyen: this.to_district,
                            chuyenDiDenTinh: convertNumberToString(
                                this.to_province
                            ),
                            citizenId: this.info_student.citizenId,
                            conThuMay: this.info_student.conThuMay,
                            danTocTheoGiayKhaiSinh:
                                this.info_student.danTocTheoGiayKhaiSinh,
                            dangVien: this.info_student.dangVien,
                            dchiThtru: this.info_student.dchiThtru,
                            dchiTmtru: this.current_residence,
                            dienThoaiNguoiGiamHo:
                                this.info_student.dienThoaiNguoiGiamHo,
                            dienThoaiVoChong: this.info_student.dienThoaiVoChong,
                            doanVien: convertToBinary(this.is_youth_union_member),
                            doiVien: convertToBinary(this.is_party_member),
                            dongBoLoId: this.info_student.dongBoLoId,
                            dongBoThuTu: this.info_student.dongBoThuTu,
                            dtuongUuTien: convertStringToNumber(
                                this.priority_object
                            ),
                            email: this.email,
                            gioiTinh: convertStringToNumber(this.gender),
                            hash: this.info_student.hash,
                            hoNgheo: this.info_student.hoNgheo,
                            hoTen: this.full_name,
                            hoTenBo: this.father_name,
                            hoTenMe: this.mother_name,
                            hoTenNguoiGiamHo: this.guardian_name,
                            hoTenVoChong: this.info_student.hoTenVoChong,
                            hoTroAnTrua: this.info_student.hoTroAnTrua,
                            hocCtgdCuaBo: this.is_ministry_education_program,
                            hocKnsSkss: this.info_student.hocKnsSkss,
                            hocLopMg5T: convertToBinary(
                                this.is_have_attended_kindergarte_at_age_5
                            ),
                            hocSinhBanTru: this.is_boarding_student,
                            hocSinhBanTruDanNuoi: this.is_boarding_student_foster,
                            hocSinhDanTocTroGiang:
                                this.is_ethnic_minority_teaching_assistants,
                            hocSinhNoiTru: this.is_resident_student,
                            hocSinhNoiTruDanNuoi:
                                this.is_boarding_student_resident,
                            hocSinhPtdtBanTru: this.is_boarding_ethnic_student,
                            hocSinhTheoNamId: this.$route.params.id,
                            hocSinhTiengDanToc: this.is_ethnic_language_study,
                            hocSongNgu: this.is_bilingual_study,
                            htChiPhiHocTap: this.info_student.htChiPhiHocTap,
                            htHoTro: this.info_student.htHoTro,
                            htroNhaO: this.info_student.htroNhaO,
                            huyetApTamThu: this.info_student.huyetApTamThu,
                            huyetApTamTruong: this.info_student.huyetApTamTruong,
                            isBietBoiKy1: this.is_swimming_ability_semester_I,
                            isBietBoiKy2: this.is_swimming_ability_semester_II,
                            isChuyenSangHocNghe:
                                this.info_student.isChuyenSangHocNghe,
                            isPhCoMayTinhInternet:
                                this.is_parent_has_computer_internet,
                            isPhCoSmartPhone: this.is_parent_has_smartphone,
                            khoiHoc: this.school_block,
                            khuyetTat: convertToBinary(this.is_disability),
                            khuyetTatKhongDanhGia: this.is_unassessed_disability,
                            kiemTraSucKhoeDinhDuong:
                                this.info_student.kiemTraSucKhoeDinhDuong,
                            loaiNhapHoc: convertStringToNumber(
                                this.type_of_admission
                            ),
                            loaiTamTru: this.info_student.loaiTamTru,
                            lopBanTru: convertToBinary(
                                this.is_boarding_class_student
                            ),
                            maCongDan: this.info_student.maCongDan,
                            maDanToc: this.nation,
                            maDoiTac: this.info_student.syncMaDoiTac,
                            maDtuongCsach: convertStringToNumber(
                                this.policy_object
                            ),
                            maHinhThucHocTap: this.info_student.maHinhThucHocTap,
                            maHocBanTru: this.boarding_school_student,
                            maHocSinh: this.info_student.maHocSinh,
                            maKhuVuc: this.area,
                            maLoaiKhuyetTat: this.disability_type,
                            maLoiSync: this.info_student.maLoiSync,
                            maLoiSyncPh: this.info_student.maLoiSyncPh,
                            maLopHocCn: this.class_name,
                            maLopHocDn: this.class_name,
                            maLopHuongNghiepDayNghe: this.career_orientation,
                            maLyDoThoiHoc: this.info_student.maLyDoThoiHoc,
                            maNhomTuoiMn: this.info_student.maNhomTuoiMn,
                            maPxaThtru: this.wards_permanent_address,
                            maPxaTmtru: this.info_student.maPxaTmtru,
                            maQhuyenThtru: this.district_permanent_address,
                            maQhuyenTmtru: this.info_student.maQhuyenTmtru,
                            maQuocTich: this.nationality,
                            maSanKhoa: this.info_student.maSanKhoa,
                            maTienSuBenhTat: this.info_student.maTienSuBenhTat,
                            maTinhThanh: this.info_student.maTinhThanh,
                            maTonGiao: this.religion,
                            maTruongHoc: this.info_student.maTruongHoc,
                            maTthanhThtru: this.province_permanent_address,
                            maTthanhTmtru: this.info_student.maTthanhTmtru,
                            maTthonTmtru: this.info_student.maTthonTmtru,
                            maTthonThtru: this.village_permanent_address,
                            maTthanhQueQuan: this.province_hometown,
                            maQhuyenQueQuan: this.district_hometown,
                            maPxaQueQuan: this.wards_hometown,
                            maTthonQueQuan: this.village_hometown,
                            meBiBenhTatTrongTkMangThai:
                                this.info_student.meBiBenhTatTrongTkMangThai,
                            meDanToc: this.is_ethnic_mother,
                            message: this.info_student.message,
                            moetCode: this.info_student.moetCode,
                            moetHoTenMe: this.info_student.moetHoTenMe,
                            moetHoTenNguoiGiamHo:
                                this.info_student.moetHoTenNguoiGiamHo,
                            moetNamSinhMe: this.info_student.moetNamSinhMe,
                            moetNamSinhNguoiGiamHo:
                                this.info_student.moetNamSinhNguoiGiamHo,
                            moetNoiSinh: this.info_student.moetNoiSinh,
                            namHoc: this.info_student.namHoc,
                            namSinh: this.info_student.namSinh,
                            namSinhBo: this.info_student.namSinhBo,
                            namSinhMe: this.info_student.namSinhMe,
                            ngayCapCmnd: formatDateToDDMMYYYY(this.date_of_issue),
                            ngayCapNhat: this.info_student.ngayCapNhat,
                            ngaySinh: formatDateToDDMMYYYY(this.date_of_birth),
                            ngaySinhBo: formatDateToDDMMYYYY(
                                this.father_birth_year
                            ),
                            ngaySinhMe: formatDateToDDMMYYYY(
                                this.mother_birth_year
                            ),
                            ngaySinhNguoiGiamHo: formatDateToDDMMYYYY(
                                this.guardian_birth_year
                            ),
                            ngaySinhVoChong: this.info_student.ngaySinhVoChong,

                            ngayTrangThai: formatDateToDDMMYYYY(this.date_status),
                            ngayVaoDang: this.info_student.ngayVaoDang,
                            ngayVaoDoan: this.info_student.ngayVaoDoan,
                            ngayVaoDoi: this.info_student.ngayVaoDoi,
                            ngheNghiepBo: this.father_occupation,
                            ngheNghiepMe: this.mother_occupation,
                            ngheNghiepNguoiGiamHo: this.guardian_occupation,
                            ngheNghiepVoChong:
                                this.info_student.ngheNghiepVoChong,
                            nhipTim: this.info_student.nhipTim,
                            nhomMau: convertStringToNumber(this.blood_type),
                            nhuCauHtroNngu:
                                this.is_students_need_language_support,
                            noiCapCmnd: this.place_of_issue,
                            noiChuyenDi: this.departure,
                            noiLamViecBo: this.father_workplace,
                            noiLamViecMe: this.mother_workplace,
                            noiLamViecNguoiGiamHo: this.guardian_workplace,
                            noiLamViecVoChong:
                                this.info_student.noiLamViecVoChong,
                            noiSinh: this.place_of_birth,
                            noiVaoDang: this.info_student.noiVaoDang,
                            noiVaoDoan: this.info_student.noiVaoDoan,
                            noiVaoDoi: this.info_student.noiVaoDoi,
                            ptdtBanTru: this.info_student.ptdtBanTru,
                            qlntCode: this.info_student.qlntCode,
                            queQuan: this.info_student.queQuan,
                            reqMesId: this.info_student.reqMesId,
                            sdtSmsPhuHuynh: this.info_student.sdtSmsPhuHuynh,
                            soBuoiTrenTuan:
                                this.number_of_study_sessions_per_week,
                            soCmnd: this.CMTND_TCC,
                            soDangBo: this.info_student.soDangBo,
                            soDienThoaiBo: this.info_student.soDienThoaiBo,
                            soDienThoaiMe: this.info_student.soDienThoaiMe,
                            soDthoaiCdinh: this.info_student.soDthoaiCdinh,
                            soDthoaiDdong: this.contact_phone,
                            strDtuongUuTien: this.info_student.strDtuongUuTien,
                            strTphanGdinh: this.info_student.strTphanGdinh,
                            sync: this.info_student.sync,
                            syncLanDay: this.info_student.syncLanDay,
                            syncMaDoiTac: this.info_student.syncMaDoiTac,
                            syncMaPhongGd: this.info_student.syncMaPhongGd,
                            syncMaSoGd: this.info_student.syncMaSoGd,
                            syncMaTruongHoc: this.info_student.syncMaTruongHoc,
                            syncNgayCapNhat: this.info_student.syncNgayCapNhat,
                            syncPh: this.info_student.syncPh,
                            syncVersion: this.info_student.syncVersion,
                            tangCuongTviet:
                                this.is_enhance_vietnamese_language_skills,
                            tenHocSinh: this.info_student.tenHocSinh,
                            tenLopHoc: this.info_student.tenLopHoc,
                            tenNhomMau: this.info_student.tenNhomMau,
                            tenTat: this.info_student.tenTat,
                            tenTrangThai: this.info_student.tenTrangThai,
                            thiLucMatPhai: this.info_student.thiLucMatPhai,
                            thiLucMatTrai: this.info_student.thiLucMatTrai,

                            thuTu: this.info_student.thuTu,
                            tinhTrangDinhDuong:
                                this.info_student.tinhTrangDinhDuong,
                            tongSoConTrongGd: this.info_student.tongSoConTrongGd,
                            tphanGdinh: convertStringToNumber(
                                this.family_background
                            ),
                            trangThai: this.student_status,
                            trangThaiDdlBo: this.info_student.trangThaiDdlBo,
                            tuyenMoiMn: this.is_accepting_new_students,
                            thoiDiemRoiTruong: this.transfer_date
                        }
                    }
                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_hoc_sinh.chinh_sua_hoc_sinh,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        this.navigateLockUpStudent()
                        showNotication(this, 'success', response.rd)
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                }
                hideLoading()
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_info_student()
        this.viewVisible = this.$route.query.viewVisible === 'true'
    },
    computed: {
        list_nationality() {
            return getFromSessionStorage('DM_NUOC')
        },
        list_province() {
            return getFromSessionStorage('list_province')
        },
        list_province_filter() {
            const filters = {
                '01': (province) => province.value == this.auth_user.province,
                '02': (province) => province.value != this.auth_user.province
            }

            const filterFunction = filters[this.departure] || (() => true)

            const filtered = this.list_province.filter(filterFunction)

            return filtered
        },
        list_departure() {
            return getFromSessionStorage('NOI_CHUYEN_DI')
        },

        list_nation() {
            return getFromSessionStorage('DM_DAN_TOC')
        },
        list_religion() {
            return getFromSessionStorage('DM_TON_GIAO')
        },
        list_blood_type() {
            return getFromSessionStorage('DM_NHOM_MAU')
        },
        list_family_background() {
            return getFromSessionStorage('THANH_PHAN_GIA_DINH')
        },
        list_area() {
            return getFromSessionStorage('DM_KHU_VUC')
        },
        list_disability_type() {
            return getFromSessionStorage('DM_LOAI_KHUYET_TAT')
        },
        list_policy_object() {
            return getFromSessionStorage('DM_DOI_TUONG_CHINH_SACH')
        },
        list_priority_object() {
            return getFromSessionStorage('DM_VUNG_KHO_KHAN')
        },
        list_type_of_admission() {
            return getFromSessionStorage('LOAI_NHAP_HOC')
        },
        list_boarding_school_student() {
            return getFromSessionStorage('DM_HOC_BAN_TRU')
        },
        list_career_orientation() {
            return getFromSessionStorage('DM_LOP_HUONG_NGHIEP_DAY_NGHE')
        },

        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    },
    created() {}
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    color: black !important;
}
::v-deep .is-error .el-input__inner {
    border: 1px solid #f56c6c !important;
}
::v-deep .is-error .el-input__inner:focus {
    border: 1px solid #f56c6c !important;
    box-shadow: 0 0 3px #f56c6c;
}
</style>
