<template>
    <el-empty description="404 | Đường dẫn không tồn tại"></el-empty>
</template>

<script>
export default {
    name: 'NotFound',
    components: {},
    data() {
        return {}
    },

    methods: {}
}
</script>
<style scoped></style>
