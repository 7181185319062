<template>
    <div>
        <ToolBar :menu="'Báo cáo'" :content="'Nộp thống kê theo biểu mẫu'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    :btnToolBar="true"
                    @click="openModalAddNew"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tên báo cáo </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="name_report"
                        placeholder="Nhập "
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        :size="'small'"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Loại báo cáo:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_type_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type_report"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="1" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Thao tác</th>
                    <th>Tên báo cáo</th>
                    <th>Đơn vị yêu cầu</th>
                    <th>File biểu mẫu</th>
                    <th>Bắt đầu</th>
                    <th>Kết thúc</th>
                    <th>Trạng thái</th>
                    <th>Số đơn vị đã nộp</th>
                    <th>Xem báo cáo đơn vị yêu cầu nộp</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="211">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>
                        <CustomToolTip content="Xuất Excel">
                            <img
                                :src="iconExcel"
                                class="icon"
                                @click="openModalExcel(item)"
                            />
                        </CustomToolTip>

                        <CustomToolTip content="Chỉnh sửa">
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="openModalUpdate(item)"
                            />
                        </CustomToolTip>

                        <span v-if="item.trangThai === 2">
                            <CustomToolTip content="Mở khóa">
                                <img
                                    :src="iconUnLock"
                                    class="icon"
                                    @click="openModalStatus(item)"
                                />
                            </CustomToolTip>
                        </span>
                        <span v-else>
                            <CustomToolTip content="Khóa">
                                <img
                                    :src="iconLock"
                                    class="icon"
                                    @click="openModalStatus(item)"
                                />
                            </CustomToolTip>
                        </span>

                        <CustomToolTip content="Xóa">
                            <img
                                :src="iconDelete"
                                class="icon"
                                @click="openModalDelete(item)"
                            />
                        </CustomToolTip>
                    </td>
                    <td class="text-left">
                        {{ item.tenBaoCao }}
                    </td>

                    <td class="text-left">
                        {{ item.tenDonViTao }}
                    </td>

                    <td class="text-left">
                        <CustomToolTip content="Tải file">
                            <i
                                class="el-icon-download el-icon"
                                v-if="item.filePath"
                                @click="handleDownFile(item)"
                            >
                            </i>
                        </CustomToolTip>
                        <CustomToolTip content="Up file">
                            <i
                                class="el-icon-upload2 el-icon"
                                @click="openModalUpload(item)"
                            ></i>
                        </CustomToolTip>
                    </td>

                    <td>
                        {{ item.ngayApDung }}
                    </td>

                    <td>
                        {{ item.ngayKetThuc }}
                    </td>

                    <td class="min-width">
                        <TextStatus
                            :content="
                                item.trangThai === 1
                                    ? 'Đang hoạt động'
                                    : 'Ngưng hoạt động'
                            "
                            :status="item.trangThai"
                        />
                    </td>

                    <td>
                        {{ item.countSchoolDone }} /
                        {{ item.countSchoolTotal }} trường
                    </td>
                    <td>
                        <CustomButton
                            label="Xem"
                            :btnToolBar="true"
                            @click="openModalView(item)"
                        />
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
        />
        <ModalUpdate
            :closeModalUpdate="closeModalUpdate"
            :updateVisible="updateVisible"
            :dataUpdate="dataUpdate"
            @handleSearch="handleSearch"
        />
        <ModalView
            :closeModalView="closeModalView"
            :viewVisible="viewVisible"
            :dataView="dataView"
        />
        <ModalStatus
            :closeModalStatus="closeModalStatus"
            :statusVisible="statusVisible"
            :dataStatus="dataStatus"
            @handleSearch="handleSearch"
        />
        <ModalExcel
            :closeModalExcel="closeModalExcel"
            :excelVisible="excelVisible"
            :dataExcel="dataExcel"
            @handleSearch="handleSearch"
        />
        <ModalUpload
            :closeModalUpload="closeModalUpload"
            :uploadVisible="uploadVisible"
            :dataUpload="dataUpload"
            @handleSearch="handleSearch"
        />
        <ModalDelete
            :closeModalDelete="closeModalDelete"
            :deleteVisible="deleteVisible"
            :idDelete="idDelete"
            @handleSearch="handleSearch"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import { list_type_report } from '@/mock_data/nop-thong-ke-theo-bieu-mau'
import CustomInput from '@/components/CustomInput.vue'
import CustomToolTip from '@/components/CustomToolTip.vue'
import iconExcel from '@/assets/img/icon-table/excel.svg'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconLock from '@/assets/img/icon-table/lock.svg'
import iconUnLock from '@/assets/img/icon-table/un-lock.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import ModalView from './modal_view.vue'
import ModalStatus from './modal_status.vue'
import ModalExcel from './modal_excel.vue'
import ModalUpload from './modal_upload.vue'
import ModalDelete from './modal_delete.vue'
import TextStatus from '@/components/TextStatus.vue'
export default {
    name: 'DataTransferSchedule',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        CustomToolTip,
        TextStatus,
        ModalAddNew,
        ModalUpdate,
        ModalView,
        ModalUpload,
        ModalStatus,
        ModalExcel,
        ModalDelete
    },
    data() {
        return {
            iconExcel,
            iconEdit,
            iconLock,
            iconUnLock,
            iconDelete,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            name_report: '',

            list_type_report: list_type_report,
            type_report: '',

            tableData: [],

            addNewVisible: false,

            updateVisible: false,
            dataUpdate: {},

            viewVisible: false,
            dataView: [],

            statusVisible: false,
            dataStatus: {},

            excelVisible: false,
            dataExcel: {},

            uploadVisible: false,
            dataUpload: {},

            deleteVisible: false,
            idDelete: 0
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },

        openModalView(item) {
            this.dataView = item.truongHocNopList
            this.viewVisible = true
        },
        closeModalView() {
            this.viewVisible = false
        },
        openModalStatus(item) {
            this.dataStatus = item
            this.statusVisible = true
        },
        closeModalStatus() {
            this.statusVisible = false
        },
        openModalExcel(item) {
            this.dataExcel = item
            this.excelVisible = true
        },
        closeModalExcel() {
            this.excelVisible = false
        },
        openModalUpload(item) {
            this.dataUpload = item
            this.uploadVisible = true
        },
        closeModalUpload() {
            this.uploadVisible = false
        },
        openModalDelete(item) {
            this.idDelete = item.id
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        async handleDownFile(item) {
            const linkDowndLoad = `https://dongbo.csdl.edu.vn/${item.filePath}`

            window.open(linkDowndLoad, '_blank')
        },

        async handleSearch() {
            try {
                showLoading()
                const request_data = {
                    start: this.start,
                    limit: this.limit,
                    tenBaoCao: this.name_report,
                    loaiBaoCao: this.loaiBaoCao
                }

                const response = await sendRequest(
                    Api.bao_cao.nop_thong_ke_theo_bieu_mau.danh_sach,

                    request_data,
                    this.request_headers,
                    null
                )
                this.tableData = response.tableData
                this.total_rows = response.totalRows
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
.el-icon {
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    padding: 0px 10px 0px 10px;
}
.min-width {
    min-width: 150px;
}
</style>
