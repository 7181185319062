<template>
    <CustomTable :column-form="2" :tab="true" :hide-pagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <tr>
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="2">Tổng quy mô học sinh</th>
                <th colspan="2">Lớp 1</th>
                <th colspan="2">Lớp 2</th>
                <th colspan="2">Lớp 3</th>
                <th colspan="2">Lớp 4</th>
                <th colspan="2">Lớp 5</th>
            </tr>
            <tr>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 4">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_4" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 5">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_5" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 6">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_6" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 7">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_7" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 8">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_8" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 9">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_9" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 10">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_10" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 11">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_11" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 12">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_12" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 13">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_13" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportAge',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Tổng quy mô' },
                { number: 2, label: '5 tuổi' },
                { number: 3, label: '6 tuổi' },
                { number: 4, label: '7 tuổi' },
                { number: 5, label: '8 tuổi' },
                { number: 6, label: '9 tuổi' },
                { number: 7, label: '10 tuổi' },
                { number: 8, label: '11 tuổi' },
                { number: 9, label: '12 tuổi' },
                { number: 10, label: '13 tuổi' },
                { number: 11, label: '14 tuổi' },
                { number: 12, label: '15 tuổi' },
                { number: 13, label: '16 tuổi' }
            ],

            list_td_active_tab_1: [
                'E5',
                'F5',
                'E10',
                'F10',
                'E11',
                'F11',
                'E12',
                'F12',
                'E13',
                'F13',
                'E14',
                'F14'
            ],
            list_td_active_tab_2: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_3: [
                'E24',
                'F24',
                'E24',
                'F24',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_4: [
                'E25',
                'F25',
                '',
                '',
                'E25',
                'F25',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_5: [
                'E26',
                'F26',
                '',
                '',
                '',
                '',
                'E26',
                'F26',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_6: [
                'E27',
                'F27',
                '',
                '',
                '',
                '',
                '',
                '',
                'E27',
                'F27',
                '',
                ''
            ],
            list_td_active_tab_7: [
                'E28',
                'F28',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'E28',
                'F28'
            ],
            list_td_active_tab_8: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_9: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_10: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_11: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_12: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_13: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>
