<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="keyVisible"
        :modal-title="'Đổi mật khẩu'"
        :close-function="handleCloseModalKey"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <label>Mật khẩu mới: <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="pw_new"
                            :textError="err_pw_new"
                            @input="onChangeHandler('pw_new', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="col-sm-4">
                    <label>
                        Nhập lại mật khẩu mới <span class="error">*</span>
                    </label>
                    <div>
                        <CustomInput
                            v-model="retype_pw"
                            :textError="err_retype_pw"
                            @input="onChangeHandler('retype_pw', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handlePassword"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
import { validateFields, handlerError } from '@/utils/validator'
export default {
    name: 'ModalKey',
    data() {
        return {
            pw_new: '',
            retype_pw: '',

            err_pw_new: '',
            err_retype_pw: '',

            errModal: false
        }
    },
    props: {
        keyVisible: {
            type: Boolean,
            required: true
        },
        closeModalKey: Function,
        handleSearch: Function,
        dataKey: {
            type: Object,
            required: true
        }
    },
    components: { CustomButton, CustomModal, CustomInput },
    methods: {
        handleClearData() {
            this.pw_new = ''
            this.retype_pw = ''
            this.err_pw_new = ''
            this.err_retype_pw = ''
            this.errModal = false
        },
        handleCloseModalKey() {
            this.closeModalKey()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                pw_new: 'err_pw_new',
                retype_pw: 'err_retype_pw'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async handlePassword() {
            const fieldsToValidate = [
                {
                    errorField: 'err_pw_new',
                    value: this.pw_new
                },
                {
                    errorField: 'err_retype_pw',
                    value: this.retype_pw
                }
            ]
            const isValid = validateFields(this, fieldsToValidate)
            if (isValid) {
                if (
                    (this.pw_new || this.retype_pw) &&
                    this.pw_new !== this.retype_pw
                ) {
                    showNotication(
                        this,
                        'error',
                        'Vui lòng nhập lại giống mật khẩu cũ'
                    )
                    return
                }
                showLoading()

                const request_data = {
                    password: this.retype_pw,
                    username: this.dataKey.username
                }
                try {
                    const response = await sendRequest(
                        Api.he_thong.quan_ly_nguoi_dung.thay_doi_mat_khau,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        hideLoading()

                        showNotication(this, 'success', response.rd)

                        this.closeModalKey()
                        this.$emit('handleSearch')
                    } else {
                        hideLoading()
                        showNotication(this, 'error', response.rd)
                    }
                } catch (error) {
                    if (error.response?.data.rd) {
                        showNotication(this, 'error', error.response?.data.rd)
                    }
                    hideLoading()
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
