<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Biến động -->
            <tr v-if="active_tab === 1">
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="4">Học sinh chuyển đến từ tỉnh/thành phố khác</th>
                <th colspan="4">
                    Học sinh chuyển đi ngoài địa bàn tỉnh/thành phố
                </th>
                <th colspan="4">Học sinh bỏ học</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 2. Số lượng học sinh -->
            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="4">Tổng số</th>
                <th colspan="12">Quy mô chia theo lớp</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="4">Lớp 10</th>
                <th colspan="4">Lớp 11</th>
                <th colspan="4">Lớp 12</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!--3. Lưu ban -->
            <tr v-if="active_tab === 3">
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Lớp 10</th>
                <th colspan="4">Lớp 11</th>
                <th colspan="4">Lớp 12</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 4. Tốt nghiệp -->
            <tr v-if="active_tab === 4">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="8">Học sinh đủ điều kiện xét tốt nghiệp THPT</th>
                <th colspan="16">Học sinh được công nhận tốt nghiệp THPT</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="4">Thí sinh tự do</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="4">
                    Học sinh được miễn thi tất cả bài thi trong xét tốt nghiệp
                    THPT (Khoản 2 Điều 32 Quy chế thi THPTQG)
                </th>
                <th colspan="4">
                    Học sinh được miễn tất cả bài thi của kỳ thi THPTQG (Điều 33
                    Quy chế thi THPTQG)
                </th>
                <th colspan="4">Thí sinh tự do</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 4">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_4" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Biến động' },
                { number: 2, label: 'Số lượng học sinh' },
                { number: 3, label: 'Lưu ban' },
                { number: 4, label: 'Tốt nghiệp' }
            ],
            list_td_active_tab_1: [
                'E6',
                'F6',
                'G6',
                'H6',
                'E7',
                'F7',
                'G7',
                'H7',
                'E8',
                'F8',
                'G8',
                'H8'
            ],
            list_td_active_tab_2: [
                'E9',
                'F9',
                'G9',
                'H9',
                'E11',
                'F11',
                'G11',
                'H11',
                'E12',
                'F12',
                'G12',
                'H12',
                'E13',
                'F13',
                'G13',
                'H12'
            ],
            list_td_active_tab_3: [
                'E14',
                'F14',
                'G14',
                'H14',
                'E15',
                'F15',
                'G15',
                'H15',
                'E16',
                'F16',
                'G16',
                'H16',
                'E17',
                'F17',
                'G17',
                'H17'
            ],
            list_td_active_tab_4: [
                'E19',
                'F19',
                'G19',
                'H19',
                'E21',
                'F21',
                'G21',
                'H21',
                'E22',
                'F22',
                'G22',
                'H22',
                'E24',
                'F24',
                'G24',
                'H24',
                'E25',
                'F25',
                'G25',
                'H25',
                'E26',
                'F26',
                'G26',
                'H26'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
