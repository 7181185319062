<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="updateVisible"
        :modal-title="'Chỉnh sửa tài khoản kết chuyển lên Bộ'"
        :close-function="handleCloseModalUpdate"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-12">
                    <label> Trường học <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="true"
                            :data="list_school"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="school"
                            @input="onChangeHandler('school', $event)"
                            :class="{
                                'error-select': err_school.length > 0
                            }"
                        />
                    </div>
                    <span v-if="err_school.length > 0" class="error">
                        {{ err_school }}
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-6">
                    <label>Tên đăng nhập: <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="user_name"
                            @input="onChangeHandler('user_name', $event)"
                            :textError="err_user_name"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label>Mật khẩu: <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="password"
                            :show-password="true"
                            @input="onChangeHandler('password', $event)"
                            :textError="err_password.length > 0"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleUpdate"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { convertListData } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalUpdate',
    data() {
        return {
            list_school: [],
            school: '',
            user_name: '',
            password: '',

            err_school: '',
            err_user_name: '',
            err_password: '',

            errModal: false
        }
    },
    components: { CustomButton, CustomModal, CustomInput, ESelect },
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },
        dataUpdate: {
            type: Object,
            required: true
        },
        closeModalUpdate: Function,
        handleSearch: Function
    },

    watch: {
        updateVisible(newVal) {
            if (newVal === true) {
                this.get_list_school()
            }
        },
        dataUpdate(newVal) {
            this.user_name = newVal.tenDangNhap
            this.password = newVal.matKhau
            this.school = newVal.maTruong
            console.log(newVal)
        }
    },
    methods: {
        async get_list_school() {
            this.school = ''

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.dataUpdate.maDonVi ? [this.dataUpdate.maDonVi] : []
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        handleClearData() {
            this.errModal = false
        },
        handleCloseModalUpdate() {
            this.closeModalUpdate()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                school: 'err_school',
                user_name: 'err_user_name',
                password: 'err_password'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async handleUpdate() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.school,
                        errorField: 'err_school'
                    },
                    {
                        value: this.password,
                        errorField: 'err_password'
                    },

                    {
                        value: this.user_name,
                        errorField: 'err_user_name'
                    }
                ]

                if (validateFields(this, fieldsToValidate)) {
                    showLoading()

                    const request_data = {
                        maTruong: this.school,
                        matKhauKetChuyen: this.password,
                        tenDangNhap: this.user_name
                    }

                    const response = await sendRequest(
                        Api.ket_chuyen_du_lieu_len_bo.mat_khau_ket_chuyen
                            .chinh_sua,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        hideLoading()
                        showNotication(this, 'success', response.message)

                        this.handleCloseModalUpdate()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.message)
                    }
                    hideLoading()
                }
            } catch (error) {
                hideLoading()
                if (error.response?.data.rd) {
                    showNotication(this, 'error', error.response?.data.rd)
                }
            }
        }
    },
    mounted() {},
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
