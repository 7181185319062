<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="addNewVisible"
        :modal-title="`THÊM MỚI CẤU HÌNH NĂM HỌC ${selectedYearFromStore} - ${
            selectedYearFromStore + 1
        }`"
        :close-function="handleCloseModalAddNew"
        :size="'lg'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div>Thời gian đẩy dữ liệu:</div>
            <div class="row mt-3">
                <div class="col-sm-6">
                    <label class="label">
                        Ngày bắt đầu <span class="error">*</span>
                    </label>
                    <div>
                        <DatePicker
                            v-model="startDate"
                            @input="onChangeHandler('startDate', $event)"
                            :show-error="err_startDate.length > 0"
                        />
                        <span v-if="err_startDate.length > 0" class="error">
                            {{ err_startDate }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label class="label">
                        Ngày kết thúc<span class="error">*</span>
                    </label>
                    <div>
                        <DatePicker
                            v-model="endDate"
                            @input="onChangeHandler('endDate', $event)"
                            :show-error="err_endDate.length > 0"
                        />
                        <span v-if="err_endDate.length > 0" class="error">
                            {{ err_endDate }}
                        </span>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleAddNew"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
import { formatDateToDDMMYYYY } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
export default {
    name: 'ModalAdd',
    data() {
        return {
            startDate: '',
            endDate: '',

            err_startDate: '',
            err_endDate: '',

            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function
    },
    components: { CustomButton, CustomModal, DatePicker },
    methods: {
        onChangeHandler(source, newValue) {
            const errorFields = {
                startDate: 'err_startDate',
                endDate: 'err_endDate'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        handleClearData() {
            this.errModal = false
            this.startDate = ''
            this.endDate = ''

            this.err_startDate = ''
            this.err_endDate = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        async handleAddNew() {
            const fieldsToValidate = [
                {
                    value: this.startDate,
                    errorField: 'err_startDate'
                },
                {
                    value: this.endDate,
                    errorField: 'err_endDate'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                showLoading()
                const convertStartDate = formatDateToDDMMYYYY(this.startDate)

                const convertEndDate = formatDateToDDMMYYYY(this.endDate)
                const request_data = {
                    dauNamTuNgay: convertStartDate,
                    dauNamToiNgay: convertEndDate,
                    namHoc: this.selectedYearFromStore
                }
                try {
                    const response = await sendRequest(
                        Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo
                            .them_moi_ngay,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                        this.handleCloseModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                    hideLoading()
                } catch (error) {
                    hideLoading()
                    if (error.response?.data.rd) {
                        showNotication(this, 'error', error.response?.data.rd)
                    }
                }
            }
        }
    },
    mounted() {},
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}

.label {
    font-weight: 600;
}
::v-deep .is-error .el-input__inner {
    border: 1px solid #f56c6c !important;
}
::v-deep .is-error .el-input__inner:focus {
    border: 1px solid #f56c6c !important;
    box-shadow: 0 0 3px #f56c6c;
}
</style>
