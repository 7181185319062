<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới phường xã'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <label>Quận huyện <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_district"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="district"
                            @input="onChangeHandler('district', $event)"
                            :class="{
                                'error-select': err_district.length > 0
                            }"
                        />
                    </div>
                    <span v-if="err_district.length > 0" class="error">
                        {{ err_district }}
                    </span>
                </div>
                <div class="col-sm-4">
                    <label>Mã phường xã <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="code"
                            @input="onChangeHandler('code', $event)"
                            :textError="err_code"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center mt-3">
                <div class="col-sm-4">
                    <label>Tên phường xã: <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="name"
                            @input="onChangeHandler('name', $event)"
                            :textError="err_name"
                        >
                        </CustomInput>
                    </div>
                    <span v-if="err_name.length > 0" class="error">
                        {{ err_name }}
                    </span>
                </div>
                <div class="col-sm-4">
                    <label>Số lượng tổ: </label>
                    <div>
                        <CustomInput
                            v-model="number_village"
                            :type="'Number'"
                            @input="onChangeHandler('number_village', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleAddNew"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalAdd',
    data() {
        return {
            district: '',

            name: '',

            code: '',

            number_village: '',

            err_district: '',

            err_name: '',

            err_code: '',

            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        list_district: {
            type: Array,
            require: true
        },
        closeModalAddNew: Function,
        handleSearch: Function
    },
    components: { CustomButton, CustomModal, CustomInput, ESelect },
    methods: {
        handleClearData() {
            this.errModal = false
            this.district = ''
            this.err_district = ''

            this.name = ''
            this.err_name = ''

            this.code = ''
            this.err_code = ''

            this.number_village = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                district: 'err_district',
                name: 'err_name',
                code: 'err_code'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                // Xử lý lỗi
                handlerError(this, source, newValue, errorFields)
                // Nếu có giá trị mới hoặc có lỗi đang được hiển thị, đặt errModal là true
                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                // Không có validate, không hiển thị lỗi
                // Nếu có giá trị mới, đặt errModal là true
                // Nếu không có giá trị nào đang được nhập vào, đặt errModal là false
                this.errModal = !!newValue
            }
        },
        async handleAddNew() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.district,
                        errorField: 'err_district'
                    },
                    {
                        value: this.name,
                        errorField: 'err_name'
                    },
                    {
                        value: this.code,
                        errorField: 'err_code'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    showLoading()

                    const request_data = {
                        ten: this.name,
                        maTinhThanh: this.auth_user.ma_so,
                        ma: this.code,
                        maQuanHuyen: this.district,
                        slTo: this.number_village
                    }

                    const response = await sendRequest(
                        Api.super_admin.phuong_xa.them_phuong_xa,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        hideLoading()
                        showNotication(this, 'success', response.message)

                        this.handleCloseModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.message)
                    }
                }
                hideLoading()
            } catch (error) {
                hideLoading()
                if (error.response?.data.rd) {
                    showNotication(this, 'error', error.response?.data.rd)
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}

.text-center {
    text-align: center;
}

.error {
    color: red;
    font-style: italic;
}
</style>
