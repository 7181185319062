<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th>STT</th>
                <th class="min-w-300">Đơn vị</th>
                <th class="min-w-300">Tên trường</th>
                <th class="min-w-150">Mã trường</th>
                <th class="min-w-150">Loại hình đào tạo</th>
                <th class="min-w-300">Quy mô</th>
                <th class="min-w-150">Loại hình trường</th>
                <th class="min-w-150">Ngày thành lập</th>
                <th class="min-w-150">SĐT</th>
                <th class="min-w-150">Chuẩn quốc gia</th>
                <th class="min-w-150">Biên giới</th>
                <th class="min-w-150">Hải đảo</th>
                <th class="min-w-150">Chi bộ Đảng</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td class="text-left">{{ item.tenTruongHoc }}</td>
                <td class="text-left">{{ item.maTruongHoc }}</td>
                <td>{{ item.loaiHinhDaoTao }}</td>
                <td class="text-left">{{ item.nhomCapHoc }}</td>
                <td class="text-left">{{ item.loaiHinhTruong }}</td>
                <td>{{ item.ngayThanhLap }}</td>
                <td>{{ item.sDT }}</td>
                <td class="text-left">{{ item.chuanQuocGia }}</td>
                <td class="text-left">{{ item.bienGioi }}</td>
                <td class="text-left">{{ item.haiDao }}</td>
                <td class="text-left">{{ item.chiBoDang }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportListSchool',
    components: {
        CustomTable
    },

    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },
    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
