export const list_type = [
    {
        label: 'Trường học',
        value: 1
    },
    {
        label: 'Giáo viên',
        value: 2
    },
    {
        label: 'Lớp học',
        value: 3
    },
    {
        label: 'Học sinh',
        value: 4
    },
    {
        label: 'Kết quả học tập',
        value: 5
    }
]
