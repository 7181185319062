var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CustomTable',{attrs:{"column-form":_vm.column_form,"tab":true,"hideBtnTable":true,"hidePagi":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [(
                _vm.statistical === 1 ||
                _vm.statistical === 2 ||
                _vm.statistical === 3 ||
                _vm.statistical === 6
            )?_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("STT")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Trường")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Tổng số")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Lên lớp")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Lưu ban")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Chưa xét lên lớp/ lưu ban")])]):_vm._e(),(
                _vm.statistical === 1 ||
                _vm.statistical === 2 ||
                _vm.statistical === 3 ||
                _vm.statistical === 6
            )?_c('tr',[_c('th',{attrs:{"rowspan":"1"}},[_vm._v("SL")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("TL (%)")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("SL")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("TL (%)")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("SL")]),_c('th',{attrs:{"rowspan":"1"}},[_vm._v("TL (%)")])]):_vm._e()]},proxy:true},{key:"body",fn:function(){return [(
                _vm.statistical === 1 ||
                _vm.statistical === 2 ||
                _vm.statistical === 3 ||
                _vm.statistical === 6
            )?_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("12345678910JQK")]),_c('td',[_vm._v("12345678910JQK")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")]),_c('td',[_vm._v("55")])]):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }