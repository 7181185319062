export const list_status = [
    { label: 'Đang xử lý', value: 0 },
    {
        label: 'Đã nhận',
        value: 1
    },
    {
        label: 'Xảy ra lỗi',
        value: 2
    },
    {
        label: 'Đã đồng bộ',
        value: 3
    },
    {
        label: 'Lỗi đồng bộ',
        value: 4
    }
]
export const list_synchronou_stype = [
    {
        value: 1,
        label: 'Sửa học sinh theo lô'
    },
    {
        value: 2,
        label: 'Sửa giáo viên theo lô'
    },
    {
        value: 3,
        label: 'Đẩy thông tin đầu năm'
    },
    {
        value: 4,
        label: 'Đẩy thông tin học sinh biến động'
    },
    {
        value: 5,
        label: 'Đẩy thông tin điểm'
    },
    {
        value: 6,
        label: 'Đẩy thông tin cuối năm'
    },
    {
        value: 8,
        label: 'Đẩy dữ liệu đầu năm (*)'
    },
    {
        value: 9,
        label: 'Đẩy dữ liệu EQMS giữa năm'
    }
]

export const list_batch_type = [
    {
        label: 'Đẩy thông tin đầu năm',
        value: 1
    },
    {
        label: 'Đẩy thông tin phát sinh',
        value: 2
    },
    {
        label: 'Đẩy thông tin cuối năm',
        value: 3
    },
    {
        label: 'Đồng bộ dữ liệu cũ',
        value: 4
    }
]

export const list_table_name = [
    {
        label: 'Thông tin lớp học',
        value: 'LopHoc'
    },
    {
        label: 'Thông tin học sinh',
        value: 'HocSinhTheoNam'
    },
    {
        label: 'Thông tin CBQL, Giáo viên, Nhân viên',
        value: 'CanBoTheoNam'
    },
    {
        label: 'Điểm tổng kết môn',
        value: 'DiemTongKetMon'
    },
    {
        label: 'Tổng kết học sinh',
        value: 'TongKetHocSinh'
    },
    {
        label: 'Điểm tổng kết môn sau thi lại',
        value: 'DiemTongKetMonThiLai'
    },
    {
        label: 'Tổng kết học sinh sau thi lại',
        value: 'TongKetHocSinhThiLai'
    },
    {
        label: 'Thông tin trường học',
        value: 'TruongHocNam'
    },
    {
        label: 'Thông tin điểm trường',
        value: 'DiemTruong'
    },
    {
        label: 'Cơ sở vật chất',
        value: 'CoSoVatChat'
    },
    {
        label: 'Ngân sách',
        value: 'NganSach'
    },
    {
        label: 'Báo cáo EQMS',
        value: 'EQMS'
    },
    {
        label: 'Thí sinh tự do',
        value: 'ThiSinhTuDo'
    },
    {
        label: 'Xét tốt nghiệp học sinh',
        value: 'XetTotNghiepHocSinh'
    }
]
