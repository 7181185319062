<template>
    <div>
        <CustomModal
            :centered="false"
            :is-modal-visible="teachingAssignmentVisble"
            :modal-title="`Phân công giảng dạy ${dataTeachingAssignment.tenTruongHoc} - ${dataTeachingAssignment.tenLopHoc}`"
            :close-function="closeModalTeachingAssignment"
            :size="'xl'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label">Học kỳ I</label>
                        <CustomTable
                            :column-form="1"
                            :hide-btn-table="true"
                            :hidePagi="true"
                            :tableModal="true"
                        >
                            <template v-slot:header>
                                <th
                                    v-for="(column, index) in tableColumns"
                                    :key="index"
                                >
                                    {{ column }}
                                </th>
                            </template>
                            <template v-slot:body>
                                <template v-if="list_semester_1.length === 0">
                                    <tr>
                                        <td colspan="10">
                                            Không có bản ghi nào.
                                        </td>
                                    </tr>
                                </template>
                                <tr
                                    v-for="(item, index) in list_semester_1"
                                    :key="index"
                                >
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td class="text-left">
                                        {{ item.tenMonHoc }} - [{{
                                            item.maMonHoc
                                        }}]
                                    </td>
                                    <td class="text-left">
                                        <p
                                            v-for="(
                                                teacher, key
                                            ) in item.phanCongGvInfoList"
                                            :key="key"
                                        >
                                            {{ key + 1 }}.
                                            {{ teacher.tenCanBo }} - [{{
                                                teacher.maGiaoVien
                                            }}]
                                        </p>
                                    </td>
                                </tr>
                            </template>
                        </CustomTable>
                    </div>
                    <div class="col-md-6">
                        <label class="label">Học kỳ II</label>
                        <CustomTable
                            :column-form="1"
                            :hide-btn-table="true"
                            :hidePagi="true"
                            :tableModal="true"
                        >
                            <template v-slot:header>
                                <th
                                    v-for="(column, index) in tableColumns"
                                    :key="index"
                                >
                                    {{ column }}
                                </th>
                            </template>
                            <template v-slot:body>
                                <template v-if="list_semester_2.length === 0">
                                    <tr>
                                        <td colspan="10">
                                            Không có bản ghi nào.
                                        </td>
                                    </tr>
                                </template>
                                <tr
                                    v-for="(item, index) in list_semester_2"
                                    :key="index"
                                >
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td class="text-left">
                                        {{ item.tenMonHoc }} - [{{
                                            item.maMonHoc
                                        }}]
                                    </td>
                                    <td class="text-left">
                                        <p
                                            v-for="(
                                                teacher, key
                                            ) in item.phanCongGvInfoList"
                                            :key="key"
                                        >
                                            {{ key + 1 }}.
                                            {{ teacher.tenCanBo }} - [{{
                                                teacher.maGiaoVien
                                            }}]
                                        </p>
                                    </td>
                                </tr>
                            </template>
                        </CustomTable>
                    </div>
                </div>
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { extractValuesFromArray } from '@/utils'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalTeachingAssignment',
    data() {
        return {
            errModal: false,
            tableColumns: ['STT', 'Môn học', 'Giáo viên giảng dạy'],
            list_semester_1: [],
            list_semester_2: []
        }
    },
    props: {
        teachingAssignmentVisble: {
            type: Boolean,
            required: true
        },
        dataTeachingAssignment: {
            type: Object,
            require: true
        },
        closeModalTeachingAssignment: Function,
        handleSearch: Function
    },
    watch: {
        teachingAssignmentVisble(newVal) {
            if (newVal === true) {
                this.get_list_semester()
            }
        },
        dataTeachingAssignment(newVal) {
            console.log(newVal)
        }
    },

    components: { CustomModal, CustomTable },
    methods: {
        async get_list_semester() {
            const request_params = {
                maLopHoc: this.dataTeachingAssignment.maLopHoc
            }

            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_lop_hoc.phan_cong_giang_day,
                null,
                this.request_headers,
                request_params
            )
            if (response.rc == 0) {
                showNotication(this, 'success', response.rd)
            } else {
                showNotication(this, 'error', response.rd)
            }
            this.list_semester_1 = response.ky1 ? response.ky1 : []
            this.list_semester_2 = response.ky2 ? response.ky2 : []
        },
        async handleDelete() {
            try {
                showLoading()
                const listMa = extractValuesFromArray(
                    this.dataTeachingAssignment,
                    'maLopHoc'
                )

                const request_data = {
                    listMa: listMa,

                    namHoc: this.selectedYearFromStore
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_lop_hoc.xoa_lop_hoc,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.closeModalTeachingAssignment()
                    this.$emit('handleSearch')
                } else if (response.rc == 999) {
                    showNotication(this, 'error', response.rd)
                } else {
                    showNotication(this, 'error', response.rd)
                }
                hideLoading()
            } catch (error) {
                hideLoading()
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.label {
    font-weight: 600;
}
</style>
