export const list_status = [
    {
        label: 'Đang học',
        value: 1
    },
    {
        label: 'Chuyển khỏi trường',
        value: 3
    },
    {
        label: 'Bỏ học',
        value: 4
    }
]

export const list_gender = [
    {
        label: 'Nam',
        value: '1'
    },
    {
        label: 'Nữ',
        value: '0'
    }
]

export const list_content_displayed = [
    {
        key: 'maTruongHoc',
        title: 'Mã trường',
        value: 1
    },
    {
        key: 'tenTruongHoc',
        title: 'Tên trường',
        value: 2
    },
    {
        key: 'khoiHoc',
        title: 'Khối học',
        value: 3
    },
    {
        key: 'tenLopHoc',
        title: 'Lớp học',
        value: 4
    },
    {
        key: 'hoTen',
        title: 'Họ tên',
        value: 7
    },
    {
        key: 'maHocSinh',
        title: 'Mã học sinh',
        value: 5
    },

    {
        key: 'moetCode',
        title: 'Mã định danh',
        value: 6
    },

    {
        key: 'ngaySinh',
        title: 'Ngày sinh',
        value: 8
    },
    {
        key: 'gioiTinh',
        title: 'Giới tính',
        value: 9
    },
    {
        key: 'maDanToc',
        title: 'Dân tộc',
        value: 10
    },
    {
        key: 'trangThai',
        title: 'Trạng thái',
        value: 11
    },
    {
        key: 'soCmnd',
        title: 'CMTND/TCC',
        value: 12
    },
    {
        key: 'ngayCapCmnd',
        title: 'Ngày cấp CMTND/TCC',
        value: 13
    },
    {
        key: 'noiCapCmnd',
        title: 'Nơi cấp CMTND/TCC',
        value: 14
    },
    {
        key: 'sdt',
        title: 'Số điện thoại',
        value: 15
    },
    {
        key: 'email',
        title: 'Email',
        value: 16
    },
    // {
    //     key: 'soDienThoaiMe',
    //     title: 'Số điện thoại mẹ',
    //     value: 17
    // },
    {
        key: 'dchiThtru',
        title: 'Địa chỉ thường trú',
        value: 18
    },
    {
        key: 'dchiTmtru',
        title: 'Địa chỉ tạm trú',
        value: 19
    },
    {
        key: 'noiSinh',
        title: 'Nơi sinh',
        value: 20
    },
    {
        key: 'hoNgheo',
        title: 'Hộ nghèo',
        value: 21
    },
    {
        key: 'maDtuongCsach',
        title: 'Đối tượng chính sách',
        value: 22
    },
    // {
    //     key: 'soDienThoaiBo',
    //     title: 'Số điện thoại bố',
    //     value: 23
    // },
    {
        key: 'hoTenBo',
        title: 'Họ tên bố',
        value: 24
    },
    {
        key: 'hoTenMe',
        title: 'Họ tên mẹ',
        value: 25
    },
    {
        key: 'citizenId',
        title: 'Mã định danh công dân',
        value: 26
    },
    {
        key: 'kiemTraSucKhoeDinhDuong',
        title: 'Kiểm tra sức khỏe dinh dưỡng',
        value: 27
    },

    // { key: 'isKhamSkDky', title: 'Khám sức khỏe định kỳ', value: 28 },

    { key: 'chieuCao', title: 'Chiều cao kì I', value: 33 },

    { key: 'chieuCaoKy2', title: 'Chiều cao kì II', value: 34 },

    { key: 'canNang', title: 'Cân nặng kì I', value: 35 },

    { key: 'canNangKy2', title: 'Cân nặng kì II', value: 36 },

    { key: 'benhMat', title: 'Bệnh về mắt', value: 37 },

    { key: 'bietBoiKy1', title: 'Biết bơi kỳ I', value: 38 },

    { key: 'bietBoiKy2', title: 'Biết bơi kỳ II', value: 39 },
    { key: 'hocSinhBanTru', title: 'Học sinh bán trú', value: 40 }
]

export const list_advanced_search = [
    {
        value: 'ngaySinh',
        name: 'Ngày sinh'
    },
    {
        value: 'noiSinh',
        name: 'Nơi sinh'
    },
    {
        value: 'maDanToc',
        name: 'Dân tộc'
    },
    {
        value: 'maTonGiao',
        name: 'Tôn giáo'
    },
    {
        value: 'dchiThtru',
        name: 'Địa chỉ thường trú'
    },
    {
        value: 'dchiTmtru',
        name: 'Địa chỉ tạm trú'
    },
    {
        value: 'maKhuVuc',
        name: 'Khu vực'
    },
    {
        value: 'loaiNhapHoc',
        name: 'Loại nhập học'
    },
    {
        value: 'maLyDoThoiHoc',
        name: 'Lý do thôi học'
    },
    {
        value: 'doiVien',
        name: 'Đội viên'
    },
    {
        value: 'doanVien',
        name: 'Đoàn viên'
    },
    {
        value: 'khuyetTat',
        name: 'Khuyết tật'
    },
    {
        value: 'lopGhep',
        name: 'Lớp ghép'
    },
    {
        value: 'lopChuyen',
        name: 'Lớp chuyên'
    },
    {
        value: 'danTocThieuSo',
        name: 'Dân tộc thiểu số'
    },
    {
        value: 'hoNgheo',
        name: 'Hộ nghèo'
    },
    {
        value: 'moetCode',
        name: 'Mã định danh'
    },
    {
        value: 'maDtuongCsach',
        name: 'Đối tượng chính sách'
    },
    {
        value: 'isKhamSkDky',
        name: 'Khám sức khoẻ định kỳ'
    },
    {
        value: 'benhMat',
        name: 'Bệnh về mắt'
    },
    {
        value: 'bietBoiKy1',
        name: 'Biết bơi kỳ I'
    },
    {
        value: 'bietBoiKy2',
        name: 'Biết bơi kỳ II'
    },
    {
        value: 'hocSinhBanTru',
        name: 'Học sinh bán trú'
    }
]

export const list_yes_no = [
    {
        value: 1,
        label: 'Có'
    },
    {
        value: 0,
        label: 'Không'
    }
]

export const list_true_false = [
    {
        value: true,
        label: 'Có'
    },
    {
        value: false,
        label: 'Không'
    }
]

export const list_student_status = [
    {
        label: 'Đang học',
        value: 1
    },
    {
        label: 'Chuyển khỏi trường',
        value: 3
    },
    {
        label: 'Bỏ học',
        value: 4
    }
]
