<template>
    <CustomTable :column-form="2" :tab="true" :hide-pagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <tr v-if="active_tab === 1">
                <th rowspan="4">STT</th>
                <th rowspan="4">Địa phương</th>
                <th rowspan="4">Tổng số trường</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="10">Trong đó</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="3">Công lập</th>
                <th rowspan="3">Tư thục</th>
                <th colspan="6">Trường Tiểu học đạt chuẩn QG</th>
                <th rowspan="3">Trường dạy học 2 buổi trong ngày</th>
                <th rowspan="3">Trường PT dân tộc bán trú cấp tiểu học</th>
                <th rowspan="3">Trường chuyên biệt dành cho người khuyết tật</th>
                <th rowspan="3">Trường có tổ chức học trực tuyến</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="2">Tổng số</th>
                <th colspan="2">Công lập</th>
                <th colspan="2">Tư thục</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">Mức độ 1</th>
                <th rowspan="1">Mức độ 2</th>
                <th rowspan="1">Mức độ 1</th>
                <th rowspan="1">Mức độ 2</th>
                <th rowspan="1">Mức độ 1</th>
                <th rowspan="1">Mức độ 2</th>
            </tr>

            <tr v-if="active_tab === 2">
                <th rowspan="4">STT</th>
                <th rowspan="4">Địa phương</th>
                <th rowspan="4">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="5">Chia ra theo lớp</th>
                <th colspan="12">Trong đó</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="3">Công lập</th>
                <th rowspan="3">Tư thục</th>
                <th rowspan="3">Lớp 1</th>
                <th rowspan="3">Lớp 2</th>
                <th rowspan="3">Lớp 3</th>
                <th rowspan="3">Lớp 4</th>
                <th rowspan="3">Lớp 5</th>
                <th colspan="3">Lớp học 2 buổi/ngày</th>
                <th colspan="3">Lớp học bán trú trong trường PTDT bán trú</th>
                <th colspan="3">
                    Lớp học trong trường chuyên biệt dành cho người khuyết tật
                </th>
                <th colspan="3">Lớp ghép</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[0][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[0][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportSchoolClass',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trường học' },
                { number: 2, label: 'Lớp học' }
            ],
            list_td_active_tab_1: [
                'E10',
                'F10',
                'G10',
                'E19',
                'E20',
                'F19',
                'F20',
                'G19',
                'G20',
                'E21',
                'E22',
                'E23',
                ''
            ],

            list_td_active_tab_2: [
                'E38',
                'F38',
                'G38',
                'E40',
                'E41',
                'E42',
                'E43',
                'E44',
                'E46',
                'F46',
                'G56',
                'E47',
                'F47',
                'G47',
                'E48',
                'F48',
                'G48',
                'E49',
                'F49',
                'G49'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
