<template>
    <div>
        <CustomModal
            :centered="false"
            :is-modal-visible="teachingAssignmentVisible"
            :modal-title="`Phân công giảng dạy: ${dataTeachingAssignment.tenCanBo} (${dataTeachingAssignment.maGiaoVien})`"
            :close-function="closeModalTeachingAssignment"
            :size="'xl'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <div class="row">
                    <div class="col-md-6">
                        <CustomTable
                            :tableModal="true"
                            :hidePagi="true"
                            :hideBtnTable="true"
                        >
                            <template v-slot:header>
                                <tr>
                                    <th rowspan="2">STT</th>
                                    <th colspan="2">Học kỳ I</th>
                                    <th rowspan="2">Thao tác</th>
                                </tr>
                                <tr>
                                    <th>Lớp học <span class="error"> *</span></th>
                                    <th>Môn học <span class="error"> *</span></th>
                                </tr>
                            </template>
                            <template v-slot:body>
                                <template v-if="data_semester_1.length === 0">
                                    <tr>
                                        <td colspan="21">
                                            <div>Không có bản ghi nào.</div>
                                            <CustomToolTip content="Thêm">
                                                <el-button
                                                    size="small"
                                                    @click="
                                                        addNewEmptyItemSemester1
                                                    "
                                                    icon="el-icon-plus"
                                                    circle
                                                    class="bg"
                                                >
                                                </el-button>
                                            </CustomToolTip>
                                        </td>
                                    </tr>
                                </template>
                                <tr
                                    v-for="(item, index) in data_semester_1"
                                    :key="index"
                                >
                                    <td>
                                        {{ index + 1 }}
                                    </td>

                                    <td>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="false"
                                            :disabled="false"
                                            :data="list_class"
                                            :placeholder="'Chọn '"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['tenLopHoc', 'maLopHoc']"
                                            v-model="item.maLopHoc"
                                            @change="get_data_subject(item)"
                                        />
                                    </td>
                                    <td>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="false"
                                            :disabled="false"
                                            :data="list_subject"
                                            :placeholder="'Chọn '"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['tenMonHoc', 'maMonHoc']"
                                            v-model="item.maMonHoc"
                                            @change="onChangeSubject(item)"
                                        />
                                    </td>
                                    <td>
                                        <div class="action">
                                            <CustomToolTip content="Xóa">
                                                <img
                                                    :src="iconDelete"
                                                    class="icon"
                                                    @click="openModalDelete(item)"
                                                />
                                            </CustomToolTip>

                                            <CustomToolTip
                                                content="Thêm"
                                                v-if="
                                                    index ===
                                                    data_semester_1.length - 1
                                                "
                                            >
                                                <img
                                                    :src="iconAdd"
                                                    class="icon"
                                                    @click="addItem1(item)"
                                                />
                                            </CustomToolTip>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </CustomTable>
                    </div>

                    <div class="col-md-6">
                        <CustomTable
                            :tableModal="true"
                            :hidePagi="true"
                            :hideBtnTable="true"
                        >
                            <template v-slot:header>
                                <tr>
                                    <th rowspan="2">STT</th>
                                    <th colspan="2">Học kỳ II</th>
                                    <th rowspan="2">Thao tác</th>
                                </tr>
                                <tr>
                                    <th>Lớp học <span class="error"> *</span></th>
                                    <th>Môn học <span class="error"> *</span></th>
                                </tr>
                            </template>
                            <template v-slot:body>
                                <template v-if="data_semester_2.length === 0">
                                    <tr>
                                        <td colspan="21">
                                            <div>Không có bản ghi nào.</div>
                                            <CustomToolTip content="Thêm">
                                                <el-button
                                                    size="small"
                                                    @click="
                                                        addNewEmptyItemSemester2
                                                    "
                                                    icon="el-icon-plus"
                                                    circle
                                                    class="bg"
                                                >
                                                </el-button>
                                            </CustomToolTip>
                                        </td>
                                    </tr>
                                </template>
                                <tr
                                    v-for="(item, index) in data_semester_2"
                                    :key="index"
                                >
                                    <td>
                                        {{ index + 1 }}
                                    </td>

                                    <td>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="false"
                                            :disabled="false"
                                            :data="list_class"
                                            :placeholder="'Chọn '"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['tenLopHoc', 'maLopHoc']"
                                            v-model="item.maLopHoc"
                                            @change="get_data_subject(item)"
                                        />
                                    </td>
                                    <td>
                                        <ESelect
                                            style="width: 100%"
                                            :clearable="false"
                                            :disabled="false"
                                            :data="list_subject"
                                            :placeholder="'Chọn '"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['tenMonHoc', 'maMonHoc']"
                                            v-model="item.maMonHoc"
                                            @change="onChangeSubject(item)"
                                        />
                                    </td>
                                    <td>
                                        <div class="action">
                                            <CustomToolTip content="Xóa">
                                                <img
                                                    :src="iconDelete"
                                                    class="icon"
                                                    @click="openModalDelete(item)"
                                                />
                                            </CustomToolTip>

                                            <CustomToolTip
                                                content="Thêm"
                                                v-if="
                                                    index ===
                                                    data_semester_2.length - 1
                                                "
                                            >
                                                <img
                                                    :src="iconAdd"
                                                    class="icon"
                                                    @click="addItem2(item)"
                                                />
                                            </CustomToolTip>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </CustomTable>
                    </div>
                </div>
            </template>
        </CustomModal>

        <!-- ************Thêm mới -  Chỉnh sửa  ************ -->
        <CustomModal
            :centered="false"
            :is-modal-visible="actionVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalAction"
            :size="'sm'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                Xác nhận {{ action_add ? 'Thêm mới' : 'chỉnh sửa' }} thông tin ?
            </template>
            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleAction"
                />
            </template>
        </CustomModal>

        <!-- ************ Xóa  ************ -->
        <CustomModal
            :centered="false"
            :is-modal-visible="deleteVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDelete"
            :size="'sm'"
            :err-modal="errModal"
        >
            <template v-slot:content> Xác nhận xoá thông tin ? </template>
            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleDelete"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomTable from '@/components/CustomTable.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import ESelect from '@/components/ESelect.vue'
import CustomToolTip from '@/components/CustomToolTip.vue'
import iconAdd from '@/assets/img/icon-table/add.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalTeaching',
    components: {
        CustomButton,
        CustomModal,
        CustomTable,
        ESelect,
        CustomToolTip
    },
    data() {
        return {
            iconAdd,
            iconDelete,
            errModal: false,
            data_semester_1: [],
            data_semester_2: [],

            list_class: [],
            class: {},
            list_subject: [],

            action_update: true,
            action_add: false,

            actionVisible: false,
            dataAction: {},

            deleteVisible: false,
            dataDelete: {},

            dataCreateSemester1: {},
            dataCreateSemester2: {}
        }
    },
    props: {
        teachingAssignmentVisible: {
            type: Boolean,
            required: true
        },
        dataTeachingAssignment: {
            type: Object,
            require: true
        },
        closeModalTeachingAssignment: Function,
        handleSearch: Function
    },
    watch: {
        dataTeachingAssignment(newVal) {
            console.log(newVal)
        },
        teachingAssignmentVisible(newVal) {
            if (newVal === true) {
                this.get_data_semester()
                this.get_list_class()
            }
        }
    },

    methods: {
        filterBySemester(rows, semester) {
            const filteredRows = rows.filter((item) => item.hocKy === semester)
            return filteredRows.length > 0 ? filteredRows : []
        },
        getListSubjectBySemester(rows, semester) {
            const filteredRows = rows.filter((item) => item.hocKy === semester)

            return filteredRows.length > 0 ? filteredRows[0].listMonHoc : []
        },
        dataCreateSemester(rows, semester) {
            let newObject = {}

            rows.forEach((item) => {
                newObject = {
                    hocKy: semester,
                    maGiaoVien: item.maGiaoVien,
                    lopMonId: item.lopMonId
                }
            })

            return newObject
        },
        addNewEmptyItemSemester1() {
            this.action_update = false
            this.action_add = true

            const emptyItem = {
                maLopHoc: '',
                maMonHoc: '',
                lopMonId: this.dataCreateSemester1.lopMonId,
                maGiaoVien: this.dataCreateSemester1.maGiaoVien,
                hocKy: this.dataCreateSemester1.hocKy,
                maTruong: this.dataTeachingAssignment.maTruongHoc
            }

            this.data_semester_1.push(emptyItem)
        },
        addNewEmptyItemSemester2() {
            this.action_update = false
            this.action_add = true

            const emptyItem = {
                maLopHoc: '',
                maMonHoc: '',
                lopMonId: this.dataCreateSemester2.lopMonId,
                maGiaoVien: this.dataCreateSemester2.maGiaoVien,
                hocKy: this.dataCreateSemester2.hocKy,
                maTruong: this.dataTeachingAssignment.maTruongHoc
            }

            this.data_semester_2.push(emptyItem)
        },
        addItem1(item) {
            const isEmptySemester1 = this.data_semester_1.some(
                (item) => item.maMonHoc === ''
            )

            if (isEmptySemester1) {
                showNotication(
                    this,
                    'error',
                    'Thiếu dữ liệu phân công. Vui lòng bổ sung đầy đủ trước khi thêm phân công tiếp theo.'
                )
                return
            }

            this.action_add = true
            this.action_update = false
            this.list_subject = []
            const newItem = Object.assign({}, item)

            for (const key in newItem) {
                if (
                    Object.prototype.hasOwnProperty.call(newItem, key) &&
                    key !== 'hocKy' &&
                    key !== 'lopMonId' &&
                    key !== 'maGiaoVien'
                ) {
                    newItem[key] = ''
                }
            }

            this.data_semester_1.push(newItem)
        },
        addItem2(item) {
            const isEmptySemester2 = this.data_semester_2.some(
                (item) => item.maMonHoc === ''
            )

            if (isEmptySemester2) {
                showNotication(
                    this,
                    'error',
                    'Thiếu dữ liệu phân công. Vui lòng bổ sung đầy đủ trước khi thêm phân công tiếp theo.'
                )
                return
            }

            this.action_add = true
            this.action_update = false
            this.list_subject = []
            const newItem = Object.assign({}, item)

            for (const key in newItem) {
                if (
                    Object.prototype.hasOwnProperty.call(newItem, key) &&
                    key !== 'hocKy' &&
                    key !== 'lopMonId' &&
                    key !== 'maGiaoVien'
                ) {
                    newItem[key] = ''
                }
            }

            this.data_semester_2.push(newItem)
        },
        openModalAction() {
            this.actionVisible = true
        },
        closeModalAction() {
            this.actionVisible = false
        },

        openModalDelete(item) {
            if (this.action_add === true) {
                this.data_semester_1 = this.data_semester_1.filter(
                    (item) => item.maMonHoc !== ''
                )
                this.data_semester_2 = this.data_semester_2.filter(
                    (item) => item.maMonHoc !== ''
                )
            } else {
                this.dataDelete = item
                this.deleteVisible = true
            }
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        async handleDelete() {
            try {
                showLoading()
                const request_data = {
                    id: this.dataDelete.lopMonGiaoVienId,
                    maLopHoc: this.dataDelete.maLopHoc,
                    maMonHoc: this.dataDelete.maMonHoc
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien.xoa_phan_cong_giang_day,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.closeModalDelete()
                    this.get_data_semester()
                } else {
                    showNotication(this, 'error', response.rd)
                }
                hideLoading()
            } catch (error) {
                hideLoading()
                console.log(error)
            }
        },

        async get_data_semester() {
            try {
                const request_params = {
                    maGiaoVien: this.dataTeachingAssignment.maGiaoVien,
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien
                        .danh_sach_phan_cong_giang_day,
                    null,
                    this.request_headers,
                    request_params
                )

                this.data_semester_1 = this.filterBySemester(response.rows, 1)
                this.list_subject = this.getListSubjectBySemester(
                    response.rows,
                    1
                )

                this.dataCreateSemester1 = this.dataCreateSemester(
                    response.rows,
                    1
                )

                this.data_semester_2 = this.filterBySemester(response.rows, 2)
                this.dataCreateSemester2 = this.dataCreateSemester(
                    response.rows,
                    2
                )
            } catch (error) {
                hideLoading()
                console.log(error)
            }
        },
        async get_list_class() {
            try {
                showLoading()

                const request_params = {
                    maTruong: this.dataTeachingAssignment.maTruongHoc,
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien.danh_sach_lop_hoc,
                    null,
                    this.request_headers,
                    request_params
                )
                this.list_class = response.rows
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        },

        async get_data_subject(item) {
            try {
                this.list_subject = []
                item.maMonHoc = ''
                const request_params = {
                    maLopHoc: item.maLopHoc
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien.danh_sach_mon_hoc,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_subject = response.rows
            } catch (error) {
                console.log(error)
            }
        },
        async onChangeSubject(item) {
            this.dataAction = item
            this.openModalAction()
        },
        async handleUpdate() {
            try {
                const request_data = {
                    hocKy: this.dataAction.hocKy,
                    id: this.dataAction.lopMonGiaoVienId,
                    lopMonId: this.dataAction.lopMonId,
                    maGiaoVien: this.dataAction.maGiaoVien,
                    maLopHoc: this.dataAction.maLopHoc,
                    maMonHoc: this.dataAction.maMonHoc
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien
                        .chinh_sua_phan_cong_giang_day,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    this.get_data_semester()
                    showNotication(this, 'success', response.rd)
                    this.closeModalAction()
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleAdd() {
            try {
                const request_data = {
                    hocKy: this.dataAction.hocKy,
                    id: this.dataAction.lopMonGiaoVienId,
                    lopMonId: this.dataAction.lopMonId,
                    maGiaoVien: this.dataAction.maGiaoVien,
                    maLopHoc: this.dataAction.maLopHoc,
                    maMonHoc: this.dataAction.maMonHoc,
                    maTruong: this.dataTeachingAssignment.maTruongHoc
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien.them_phan_cong_giang_day,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    this.get_data_semester()
                    showNotication(this, 'success', response.rd)
                    this.action_add = false
                    this.action_update = true
                    this.closeModalAction()
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        },
        handleAction() {
            if (this.action_add === true) {
                this.handleAdd()
            }
            if (this.action_update === true) {
                this.handleUpdate()
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
.bg {
    background: #186ab2;
}
.action {
    display: flex;
}
::v-deep .el-icon-plus:before {
    color: #ffff;
    font-weight: 700;
}
.error {
    color: red;
}
</style>
