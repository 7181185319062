<template>
    <div>
        <ToolBar
            :menu="'Kết chuyển dữ liệu lên bộ '"
            :content="'Kết chuyển dữ liệu '"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Xuất Excel"
                    :icon="'el-icon-download'"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>

        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Loại dữ liệu</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_data_type"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="data_type"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trạng thái duyệt của bộ
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Mã cán bộ </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <CustomInput
                        v-model="code"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Họ và tên </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="name"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="2">
            <template v-slot:btn-table>
                <span class="count" v-if="isCheckedCount > 0">
                    <b>Đã chọn {{ isCheckedCount }} bản ghi</b>
                </span>
                <CustomButton
                    label="Đẩy lên bộ"
                    size="small"
                    :btnToolBar="true"
                    @click="openModalPushUp"
                />
            </template>
            <template v-slot:header>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th>STT</th>
                    <th>Mã trường</th>
                    <th>Tên trường</th>
                    <th>Mã cán bộ</th>
                    <th>Mã định danh bộ GD&ĐT</th>
                    <th>Họ và tên</th>
                    <th>Ngày sinh</th>
                    <th>Giới tính</th>
                    <th>Trạng thái GV</th>
                    <th>Vị trí việc làm</th>
                    <th>Hình thức hợp đồng</th>
                    <th>Trình độ chuyên môn nghiệp vụ</th>
                    <th>Môn dạy</th>
                    <th>Trạng thái duyệt của Bộ</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="20">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckboxTable(item)"
                        />
                    </td>
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>{{ item.maTruongHoc }}</td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>{{ item.maGv }}</td>
                    <td>{{ item.moetCode }}</td>
                    <td class="text-left">{{ item.hoTen }}</td>
                    <td>{{ item.ngaySinh }}</td>
                    <td>{{ item.gioiTinh }}</td>
                    <td>{{ item.trangThaiTitle }}</td>
                    <td class="text-left">{{ item.chucVuTitle }}</td>
                    <td class="text-left">{{ item.loaiHopDongTitle }}</td>
                    <td class="text-left">{{ item.trinhDoDaoTaoTitle }}</td>
                    <td>{{ item.monDayTitle }}</td>
                    <td>{{ item.trangThaiDuyet }}</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalPushUp
            :pushUpVisible="pushUpVisible"
            :dataPushUp="selectedItemsTable"
            @handleSearch="handleSearch"
            :closeModalPushUp="closeModalPushUp"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'

import {
    checkAllowedValues,
    countCheckedItems,
    getLabelByArray,
    updateSelectedItemsTable
} from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import iconView from '@/assets/img/icon-table/view.svg'
import { list_status } from '@/mock_data/ket-chuyen-du-lieu'
import { list_data_type } from '@/mock_data/ket-chuyen-du-lieu-role-truong'
import CustomInput from '@/components/CustomInput.vue'
import ModalPushUp from './modal_push_up.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'MoetTransferHistory',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        ModalPushUp
    },
    data() {
        return {
            iconView,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            isCheckedCount: 0,
            selectAllChecked: false,
            selectedItemsTable: [],

            list_data_type: list_data_type,
            data_type: 2,

            list_status: list_status,
            status: '',
            code: '',
            name: '',

            tableData: [],

            pushUpVisible: false
        }
    },

    methods: {
        getLabelByArray,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        toggleCheckboxTable(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            this.selectedItemsTable = updateSelectedItemsTable(
                this.selectedItemsTable,
                item
            )
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked
                if (
                    this.selectAllChecked &&
                    !this.selectedItemsTable.includes(item)
                ) {
                    this.selectedItemsTable.push(item)
                } else if (!this.selectAllChecked) {
                    this.selectedItemsTable = []
                }
            })
        },
        openModalPushUp() {
            if (this.selectedItemsTable.length === 0) {
                showNotication(this, 'error', 'Vui lòng chọn dữ liệu')
                return
            }
            this.pushUpVisible = true
        },
        closeModalPushUp() {
            this.pushUpVisible = false
        },

        async handleSearch() {
            try {
                showLoading()
                const request_data = {
                    namHoc: this.selectedYearFromStore,

                    hocKy: this.selectedSemesterFromStore,
                    limit: this.limit,

                    loaiDuLieu: this.data_type,
                    maGiaoVien: this.code,
                    hoTen: this.name,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu_role_truong
                        .danh_sach,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                } else {
                    showNotication(this, 'error', response.rd)
                }
                this.tableData = response.tableData
                this.total_rows = response.totalRows

                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },
    watch: {
        tableData: {
            handler(newVal) {
                this.isCheckedCount = countCheckedItems(newVal)
            },
            deep: true
        }
    },
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },

        conditionGradeLevel_1() {
            const allowedValues = ['1']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        conditionGradeLevel_2() {
            const allowedValues = ['2']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },
        conditionGradeLevel_3() {
            const allowedValues = ['3']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },

        conditionGradeLevel_4_5_45() {
            const allowedValues = ['4', '5', '45']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },

        conditionGradeLevel_6() {
            const allowedValues = ['6']
            return checkAllowedValues(
                this.selectedGradeLevelFromStore,
                allowedValues
            )
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },

        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.bg-btn-save {
    background-color: var(--bg-btn-save-modal);
    color: var(--btn-text-color);
}

.text-left {
    text-align: left !important;
}

.count {
    font-size: 14px;
    margin-right: 10px;
}
</style>
