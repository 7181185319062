<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-200">Đơn vị</th>
                <th colspan="10">Trường mẫu giáo</th>
                <th colspan="10">Trường mầm non</th>
                <th colspan="9">Tỷ lệ (%) trường cấp Mầm non đạt tiêu chí</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-200">Tổng số trường Mẫu giáo</th>
                <th colspan="9" class="min-w-200">Trong đó</th>
                <th rowspan="2" class="min-w-200">Tổng số trường Mầm non</th>
                <th colspan="9" class="min-w-200">Trong đó</th>
                <th rowspan="2" class="min-w-200">
                    Trường có sử dụng máy vi tính phục vụ quản lý và giáo dục trẻ
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường được kết nối internet
                </th>
                <th rowspan="2" class="min-w-200">Trường có điện (lưới)</th>
                <th rowspan="2" class="min-w-200">Trường có nguồn nước sạch</th>
                <th rowspan="2" class="min-w-200">Trường có nước uống</th>
                <th rowspan="2" class="min-w-200">
                    Trường có công trình vệ sinh
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có chường trình giáo dục vệ sinh đôi tay
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có chương trình giáo dục cơ bản về giới tính, phòng
                    chống bạo lực, xâm hại
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có kế hoạch giáo dục phòng, chống thiên tai và ứng phó
                    biến đổi khí hậu
                </th>
            </tr>
            <tr>
                <th rowspan="1" class="min-w-200">
                    Trường có sử dụng máy vi tính phục vụ quản lý và giáo dục trẻ
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường được kết nối internet
                </th>
                <th rowspan="1" class="min-w-200">Trường có điện (lưới)</th>
                <th rowspan="1" class="min-w-200">Trường có nguồn nước sạch</th>
                <th rowspan="1" class="min-w-200">Trường có nước uống</th>
                <th rowspan="1" class="min-w-200">
                    Trường có công trình vệ sinh
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có chương trình giáo dục vệ sinh đôi tay
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có chương trình giáo dục cơ bản về giới tính, phòng
                    chống bạo lực, xâm hại
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có kế hoạch giáo dục phòng, chống thiên tai và ứng phó
                    biến đổi khí hậu
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có sử dụng máy vi tính phục vụ quản lý và giáo dục trẻ
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường được kết nối internet
                </th>
                <th rowspan="1" class="min-w-200">Trường có điện (lưới)</th>
                <th rowspan="1" class="min-w-200">Trường có nguồn nước sạch</th>
                <th rowspan="1" class="min-w-200">Trường có nước uống</th>
                <th rowspan="1" class="min-w-200">
                    Trường có công trình vệ sinh
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có chường trình giáo dục vệ sinh đôi tay
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có chương trình giáo dục cơ bản về giới tính, phòng
                    chống bạo lực, xâm hại
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có kế hoạch giáo dục phòng, chống thiên tai và ứng phó
                    biến đổi khí hậu
                </th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left min-width">
                    {{ item['H3'] }}
                </td>

                <td v-for="td in list_td" :key="td.index">
                    {{ item[0][td] }}
                </td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportSchoolCcriteriaSDG',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            list_td: [
                'E26',
                'E38',
                'E39',
                'E40',
                'E41',
                'E42',
                '',
                '',
                'E44',
                'E45',
                'E46',
                'E58',
                'E59',
                'E60',
                'E61',
                '',
                '',
                '',
                'E64',
                'E65',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-200 {
    min-width: 200px;
}
</style>
