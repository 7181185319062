<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="statusVisible"
        :modal-title="'Xác nhận thay đổi trạng thái'"
        :close-function="closeModalStatus"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <CustomTable :hide-pagi="true" :table-modal="true">
                <template v-slot:header>
                    <tr>
                        <th>STT</th>

                        <th>Tên trường</th>
                        <th>Mã trường</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataStatus.length === 0">
                        <tr>
                            <td colspan="3">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in dataStatus" :key="index">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td class="text-left">{{ item.tenTruongHoc }}</td>
                        <td>{{ item.maTruongHoc }}</td>
                    </tr>
                </template>
            </CustomTable>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleStatus"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ModalStatus',
    data() {
        return { errModal: false }
    },
    components: { CustomButton, CustomModal, CustomTable },
    props: {
        statusVisible: {
            type: Boolean,
            required: true
        },
        dataStatus: {
            type: Array,
            required: true
        },
        statusUpdate: {
            type: Number,
            required: true
        },
        closeModalStatus: Function,
        handleSearch: Function
    },
    watch: {
        dataStatus(newVal) {
            if (newVal.length > 0) {
                this.errModal = true
            } else {
                this.errModal = false
            }
        }
    },
    methods: {
        async handleStatus() {
            showLoading()
            try {
                const dsMaTruong = this.dataStatus.map((item) => item.maTruongHoc)

                const request_data = {
                    tuNgay: null,
                    denNgay: null,
                    statusUpdate: this.statusUpdate,
                    dsMaTruong: dsMaTruong,
                    maSo: this.auth_user.ma_so,
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo
                        .toggleStatus_cap_truong,
                    request_data,
                    this.request_headers,
                    null
                )
                if (response.rc == 0) {
                    hideLoading()
                    showNotication(this, 'success', response.rd)
                    this.closeModalStatus()
                    this.$emit('handleSearch')
                } else {
                    showNotication(this, 'error', response.rd)
                    hideLoading()
                }
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {},
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
