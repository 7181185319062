<template>
    <div>
        <CustomModal
            :centered="true"
            :is-modal-visible="contentDisplayedVisible"
            :modal-title="'Cấu hình nội dung hiển thị'"
            :close-function="closeModalContentDisplayed"
            :size="'xl'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <div class="row">
                    <div class="col-md-6">
                        <draggable
                            class="list-group"
                            :list="list1"
                            group="people"
                            @change="log"
                        >
                            <div
                                class="list-group-item"
                                v-for="(element, index) in list1"
                                :key="element.name"
                            >
                                {{ element.name }} {{ index }}
                            </div>
                        </draggable>
                    </div>
                    <div>
                        <draggable
                            class="list-group"
                            :list="list2"
                            group="people"
                            @change="log"
                        >
                            <div
                                class="list-group-item"
                                v-for="(element, index) in list2"
                                :key="element.name"
                            >
                                {{ element.name }} {{ index }}
                            </div>
                        </draggable>
                    </div>
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton label="Lưu" :btnSaveModal="true" />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import CustomModal from '@/components/CustomModal.vue'

export default {
    name: 'ModalDelete',
    data() {
        return {
            errModal: false,
            list1: [
                { name: 'John', id: 1 },
                { name: 'Joao', id: 2 },
                { name: 'Jean', id: 3 },
                { name: 'Gerard', id: 4 }
            ],
            list2: [
                { name: 'Juan', id: 5 },
                { name: 'Edgard', id: 6 },
                { name: 'Johnson', id: 7 }
            ]
        }
    },
    props: {
        contentDisplayedVisible: {
            type: Boolean,
            required: true
        },

        closeModalContentDisplayed: Function
    },

    components: { CustomButton, CustomModal, draggable },
    methods: {
        add: function () {
            this.list.push({ name: 'Juan' })
        },
        replace: function () {
            this.list = [{ name: 'Edgard' }]
        },
        clone: function (el) {
            return {
                name: el.name + ' cloned'
            }
        },
        log: function (evt) {
            window.console.log(evt)
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            }
        }
    }
}
</script>

<style scoped>
.list-group {
    min-height: 20px;
    padding: 5px;
    border: 1px solid gainsboro;
}

.list-group-item {
    cursor: move;
    display: inline-flex !important;
    padding: 4px 10px;
}
.list-group-item span {
    font-size: 13px;
    font-weight: 500;
}
.list-group-item i {
    cursor: pointer;
}
</style>
