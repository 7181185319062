<template>
    <div class="tool-bar">
        <div class="item">
            <div class="text" v-if="!tab">
                Trang chủ <i class="el-icon-arrow-right icon"></i> {{ menu }}
                <span v-if="content">
                    <i class="el-icon-arrow-right icon"></i> {{ content }}
                </span>
                <span v-if="subContent">
                    <i class="el-icon-arrow-right icon"></i> {{ subContent }}
                </span>
            </div>
            <slot v-else name="tab"></slot>
        </div>

        <div class="mobi">
            <slot name="form"></slot>
            <div
                class="toggle-display"
                :style="{
                    display: hideToggle && hideZoom ? 'none' : ''
                }"
            >
                <CustomToolTip content="Ẩn/hiện khối lọc" v-if="!hideToggle">
                    <div
                        class="toggle-button"
                        :class="{ open: !toggleDisplay, closed: toggleDisplay }"
                        @click="handleToggleDisplay"
                    ></div>
                </CustomToolTip>
                <CustomToolTip content="Xem toàn màn hình" v-if="!hideZoom">
                    <div
                        class="zoom"
                        :class="{ open: !zoom, closed: zoom }"
                        @click="handleZoom"
                    ></div>
                </CustomToolTip>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import CustomToolTip from '@/components/CustomToolTip.vue'
export default {
    components: { CustomToolTip },
    props: {
        menu: {
            type: String
        },
        content: {
            type: String
        },
        subContent: {
            type: String
        },
        hideToggle: {
            type: Boolean,
            default: false
        },
        hideZoom: {
            type: Boolean,
            default: false
        },
        tab: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },

    computed: {
        ...mapState('toggleDisplay', ['toggleDisplay']),
        ...mapState('zoom', ['zoom'])
    },
    methods: {
        ...mapMutations('toggleDisplay', ['setToggleDisplay']),
        handleToggleDisplay() {
            this.setToggleDisplay(!this.toggleDisplay)
        },

        ...mapMutations('zoom', ['setZoom']),
        handleZoom() {
            this.setZoom(!this.zoom)
        }
    }
}
</script>

<style scoped>
.tool-bar {
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.item {
    display: flex;
    align-items: center;
}

.text {
    color: var(--blue-shared);

    font-size: 15px;
    line-height: 18.2px;
}
.icon {
    padding: 0 8px;
}
.toggle-display {
    display: flex;
    align-items: center;
    float: right;
    height: 45px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
}
.toggle-button,
.zoom {
    float: right;
    width: 29px;
    height: 29px;
    cursor: pointer;
    margin-right: 6px;
    margin-left: 6px;
}

.toggle-button.closed {
    background: url(https://pgd.csdl.moet.gov.vn/images/dong_mo.png) no-repeat 0 0;
}

.toggle-button.open {
    background: url(https://pgd.csdl.moet.gov.vn/images/dong_mo.png) no-repeat -30px
        0;
}
.zoom.open {
    background: url(https://pgd.csdl.moet.gov.vn/images/zoom.png) no-repeat 0 0;
}

.zoom.closed {
    background: url(https://pgd.csdl.moet.gov.vn/images/zoom.png) no-repeat -30px 0;
}

@media only screen and (max-width: 767px) {
    .tool-bar {
        height: 100% !important;
        padding: 10px;
        flex-direction: column;
    }

    .toggle-display,
    .item {
        display: none;
    }
}
</style>
