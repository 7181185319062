export const path = {
    login: '/login',
    dashboard: '/dashboard',
    index: '/',
    not_found: '/404',

    // <!-- ************ tiep-nhan-du-lieu-QLNT ************ -->

    configure_sync_calendar: '/Configure-sync-calendar', // cau-hinh-lich-dong-bo
    update_school_partners: '/update-school-partners', // he-thong-doi-tac-truong
    report_sync_status: '/report-sync-status', //  cau-hinh-lich-dong-bo
    data_transfer_schedule: '/Data-transfer-schedule', //lich-su-tiep-nhan-du-lieu

    // <!-- ************ ket-chuyen-du-lieu-len-bo ************ -->

    pass_data_transfer: '/pass-data-transfer', // mat-khau-ket-chuyen
    pass_data_transfer_ioc: '/pass-data-transfer-ioc', // mat-khau-ket-chuyen-ioc
    config_moet_transfer_permission: '/config-moet-transfer-permission', //cau-hinh-duyet-day-du-lieu-len-bo
    request_data_by_school: '/Request-data-by-school', //ket-chuyen-du-lieu
    request_data_by_school_ioc: '/Request-data-by-school-ioc', //ket-chuyen-du-lieu-ioc
    moet_transfer_history: '/moet-transfer-history', //lich-su-day-du-lieu-len-bo
    moet_transfer_history_ioc: '/moet-transfer-history-ioc', //lich-su-day-du-lieu-len-ioc

    // <!-- ************ bao-cao ************ -->

    report: {
        department: '/Report-to-the-department', // bao-cao-don-vi
        dynamic_report: {
            staff_report: '/report-emis', // bao-cao-can-bo
            student_report: '/report-emis-student', // bao-cao-hoc-sinh
            school_report: '/report-emis-school' // bao-cao-truong-hoc
        },
        emis: {
            begin: '/Report-to-the-ministry', // bao-cao-emis-dau-nam
            last: '/emis' // bao-cao-emis-cuoi-nam
        },
        eqms: '/Report-eqms', // bao-cao-eqms
        submit_statistical_sample: '/Submit_statistical_sample', //nop-thong-ke-theo-bieu-mau
        data_synchronization: '/data-synchronization', //tong-hop-du-lieu-bao-cao
        latch_data_report: {
            latch_school: '/Lock-data-report'
        }
    },
    // <!-- ************ tra-cuu ************ -->
    lock_up: {
        school: {
            home: '/category/schools', // tra-cuu-truong-hoc
            add_school: '/category/schools/add',
            update_school: '/category/schools/update'
        },
        teacher: {
            home: '/Look-up-officials', // tra-cuu-can-bo-giao-vien
            update_teacher: '/Look-up-officials/update'
        },
        class: {
            home: '/Look-up-classes', // tra-cuu-lop-hoc
            update_class: '/Look-up-classes/update'
        },
        student: {
            home: '/Look-up-students', // tra-cuu-hoc-sinh
            update_student: '/Look-up-students/update'
        },
        profileStudent: {
            home: '/Look-up-profile-students', // tra-cuu-ho-so-hoc-sinh
            update_profile_student: '/Look-up-profile-students/update'
        },

        academiResults: {
            home: '/Look-up-academi-results', // tra-cuu-ket-qua-hoc-tap
            update_academi_results: '/Look-up-academi-results/update'
        },
        attendance: '/lookup_attendance', // thong-tin-diem-danh

        history_delete: '/Look-up-history-delete', // tra-cuu-lich-su-xoa-du-lieu
        history_transfer_school: '/Look-up-history-transfer-school' // quan-ly-danh-sach-hoc-sinh-chuyen-truong
    },

    // <!-- ************ he-thong ************ -->
    system: {
        user_management: '/user-management', // quan-ly-nguoi-dung
        role_management: '/role_management', // quan-ly-vai-tro
        merge_schools: '/sap-nhap-truong', // sap-nhap-truong-hoc
        dissolve_schools: '/giai-the-truong', // giai-the-truong-hoc
        partner_management: '/Partner-management' // quan-ly-doi-tac
    },

    // <!-- ************ danh-muc ************ -->
    category: {
        province: '/category/city', //tinh-thanh
        district: '/category/district', // quan-huyen
        wards: '/category/wards', // phuong-xa
        village: '/category/village' // to-thon
    },

    // <!-- ************ tai-khoan ************ -->
    account: {
        user: {
            view: '/account/user' // thong-tin-tai-khoan
        },
        loginHistory: '/login-history',

        wards: '/category/wards', // phuong-xa

        village: '/category/village' // phuong-xa
    }
}
