<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Trường học -->
            <tr v-if="active_tab === 1">
                <th rowspan="5">STT</th>
                <th rowspan="5" class="min-w-200">Địa phương</th>
                <th colspan="3">Tổng số trường cấp THPT</th>
                <th colspan="13">Trường Trung Học Phổ Thông</th>
                <th colspan="11">
                    Trường Trung Học Cơ Sở và Trung Học Phổ Thông
                </th>
                <th colspan="10">
                    Trường Tiểu Học, Trung Học Cơ Sở và Trung Học Phổ Thông
                </th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="4" class="min-w-200">Tổng số trường</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th rowspan="4" class="min-w-200">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="10">Trong đó</th>
                <th rowspan="4" class="min-w-200">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="8">Trong đó</th>
                <th rowspan="4" class="min-w-200">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="7">Trong đó</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="3" class="min-w-200">Công lập</th>
                <th rowspan="3" class="min-w-200">Tư thục</th>
                <th rowspan="3" class="min-w-200">Công lập</th>
                <th rowspan="3" class="min-w-200">Tư thục</th>
                <th colspan="6">Trường THPT đạt chuẩn QG</th>
                <th rowspan="3" class="min-w-200">Trường THPT chuyên</th>
                <th rowspan="3" class="min-w-200">
                    Trường PT dân tộc nội trú tỉnh
                </th>
                <th rowspan="3" class="min-w-200">
                    Trường chuyên biệt dành cho người khuyết tật
                </th>
                <th rowspan="3" class="min-w-200">
                    Trường có tổ chức học trực tuyến
                </th>
                <th rowspan="3" class="min-w-200">Công lập</th>
                <th rowspan="3" class="min-w-200">Tư thục</th>
                <th colspan="6">Trường THCS đạt chuẩn QG</th>
                <th rowspan="3" class="min-w-200">
                    Trường phổ thông dân tộc nội trú huyện
                </th>
                <th rowspan="3" class="min-w-200">
                    Trường có tổ chức học trực tuyến
                </th>
                <th rowspan="3" class="min-w-200">Công lập</th>
                <th rowspan="3" class="min-w-200">Tư thục</th>
                <th colspan="6">Trường THCS đạt chuẩn QG</th>
                <th rowspan="3" class="min-w-200">
                    Trường có tổ chức học trực tuyến
                </th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="2">Tổng số</th>
                <th colspan="2">Công lập</th>
                <th colspan="2">Tư thục</th>
                <th colspan="2">Tổng số</th>
                <th colspan="2">Công lập</th>
                <th colspan="2">Tư thục</th>
                <th colspan="2">Tổng số</th>
                <th colspan="2">Công lập</th>
                <th colspan="2">Tư thục</th>
            </tr>

            <tr v-if="active_tab === 1">
                <th
                    v-for="(header, key) in list_th_active_tab_1"
                    :key="key"
                    :rowspan="header.rowspan"
                    class="min-w-100"
                >
                    {{ header.label }}
                </th>
            </tr>
            <!-- 2. Lớp học -->
            <tr v-if="active_tab === 2">
                <th rowspan="4">STT</th>
                <th rowspan="4">Địa phương</th>
                <th rowspan="4">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="3">Chia ra theo lớp</th>
                <th colspan="12">Trong đó</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="3">Công lập</th>
                <th rowspan="3">Tư thục</th>
                <th rowspan="3">Lớp 10</th>
                <th rowspan="3">Lớp 11</th>
                <th rowspan="3">Lớp 12</th>
                <th colspan="3">Lớp học 2 buổi/ngày</th>
                <th colspan="3">Lớp học trong trường THPT chuyên</th>
                <th colspan="3">Lớp học trong trường PTDT nội trú</th>
                <th colspan="3">Lớp ghép</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[0][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[0][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportSchoolClass',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trường học' },
                { number: 2, label: 'Lớp học' }
            ],
            list_th_active_tab_1: [
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 },
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 },
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 },
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 },
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 },
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 },
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 },
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 },
                { label: 'Mức độ 1', rowspan: 1 },
                { label: 'Mức độ 2', rowspan: 1 }
            ],

            list_td_active_tab_1: [
                'E9',
                'F9',
                'G9',
                'E10',
                'F10',
                'G10',
                'E21',
                'E22',
                'F21',
                'F22',
                'G21',
                'G22',
                'E23',
                'E24',
                'E25',
                '',
                'E38',
                'F38',
                'G38',
                'E49',
                'E50',
                'F49',
                'F50',
                'G49',
                'G50',
                'E51',
                '',
                'E64',
                'F64',
                'G64',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_2: [
                'E88',
                'F88',
                'G88',
                'E90',
                'E91',
                'E92',
                'E94',
                'F94',
                'G94',
                'E95',
                'F95',
                'G95',
                'E96',
                'F96',
                'G96',
                'E97',
                'F97',
                'G97'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-100 {
    min-width: 100px;
}
.min-w-200 {
    min-width: 200px;
}
</style>
