<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="2">STT</th>

                <th rowspan="2" class="min-w-300">Đơn vị</th>
                <th rowspan="2" class="min-w-150">Tổng số</th>
                <th colspan="8">Bệnh về mắt</th>
                <th colspan="2">Biết bơi</th>
                <th rowspan="2" class="min-w-300">
                    Phụ huynh có sử dụng Smartphone
                </th>
                <th rowspan="2" class="min-w-300">
                    Phụ huynh có máy tính Internet
                </th>
            </tr>
            <tr>
                <th rowspan="1" class="min-w-150">Cận thị</th>
                <th rowspan="1" class="min-w-150">Viễn thị</th>
                <th rowspan="1" class="min-w-150">Loạn thị</th>
                <th rowspan="1" class="min-w-150">Đục thủy tinh thể</th>
                <th rowspan="1" class="min-w-150">Thoái hóa điểm</th>
                <th rowspan="1" class="min-w-150">Bệnh khô mắt</th>
                <th rowspan="1" class="min-w-150">Bệnh lác</th>
                <th rowspan="1" class="min-w-150">Bệnh khác</th>
                <th rowspan="1" class="min-w-150">Học kỳ 1</th>
                <th rowspan="1" class="min-w-150">Học kỳ 2</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="21">Không có bản ghi nào.</td>
                </tr>
            </template>

            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="min-w-300 text-left">{{ item.tenDonVi }}</td>
                <td>{{ item.tongSoHs }}</td>
                <td>{{ item.soHsCanThi }}</td>
                <td>{{ item.soHsVienThi }}</td>
                <td>{{ item.soHsLoanThi }}</td>
                <td>{{ item.soHsDucThuyTinhThe }}</td>
                <td>{{ item.soHsThoaiHoaDiem }}</td>
                <td>{{ item.soHsKhoMat }}</td>
                <td>{{ item.soHsLacMat }}</td>
                <td>{{ item.soHsBenhKhac }}</td>
                <td>{{ item.soHsBietBoiKy1 }}</td>
                <td>{{ item.soHsBietBoiKy2 }}</td>
                <td>{{ item.soHsPhuHuynhDungSmartPhone }}</td>
                <td>{{ item.soHsPhuHuynhCoMayTinh }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StatisticsOfStudentsSwimmingAbilityEyeConditions',
    components: {
        CustomTable
    },
    props: {
        statistical: {
            type: Number,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
