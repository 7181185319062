<template>
    <div>
        <GraduationStatusOfstudents
            :tableData="tableData"
            v-if="detailed_report == 'BcTinhHinhHsTotNghiep'"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <StudentsbByacademicPerformanceConduct
            :tableData="tableData"
            v-if="detailed_report == 'BcTinhHinhHocLucCuaHocSinh'"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <GradeClassificationStatistics
            :tableData="tableData"
            v-if="detailed_report === 3"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <PromotionRetentionStatistics
            :tableData="tableData"
            v-if="detailed_report === 4"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <AssessmentOfChilddevelopment
            :tableData="tableData"
            v-if="detailed_report === 5"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <EvaluationOfStudentEducation
            :tableData="tableData"
            v-if="detailed_report === 6"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />
    </div>
</template>

<script>
import GraduationStatusOfstudents from './GraduationStatusOfstudents'
import StudentsbByacademicPerformanceConduct from './StudentsbByacademicPerformanceConduct'
import GradeClassificationStatistics from './GradeClassificationStatistics'
import PromotionRetentionStatistics from './PromotionRetentionStatistics'
import AssessmentOfChilddevelopment from './AssessmentOfChilddevelopment'
import EvaluationOfStudentEducation from './EvaluationOfStudentEducation'
export default {
    name: 'ReportSchool',
    props: {
        detailed_report: {
            type: String,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        },
        statistical: {
            type: Number,
            require: true
        }
    },
    components: {
        GraduationStatusOfstudents,
        StudentsbByacademicPerformanceConduct,
        GradeClassificationStatistics,
        PromotionRetentionStatistics,
        AssessmentOfChilddevelopment,
        EvaluationOfStudentEducation
    },
    data() {
        return {}
    },

    methods: {},
    mounted() {},
    watch: {},

    computed: {}
}
</script>

<style scoped></style>
