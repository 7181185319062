<template>
    <div>
        <div class="lock-up-container">
            <slot name="lock-up-container">
                <div class="lock-up">
                    <slot name="lock-up"></slot>
                </div>
            </slot>
        </div>
        <div class="pagi"></div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.lock-up-container {
    /* 100vh - chiều cao header - chiều cao navbar - chiều cao toolbar - pagi  */
    height: calc(100vh - 45px - 45px - 45px - 50px);
    overflow-y: auto;
    overflow-x: hidden;
}
.lock-up {
    padding: 0px 20px 0px 20px;
}
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
.error {
    color: red;
}
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
.form-update,
.form-add {
    padding: 0px 20px 0px 20px;
}
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}
.label-wrapper {
    display: flex;
    align-items: center;
    float: right;
    font-size: 13px;
}
.control-label {
    margin-bottom: 0;
    font-weight: 600;
}
.n-index {
    margin-left: 10px;
    font-weight: 600;
}
.col-md-4,
.col-sm-6 {
    padding: 0;
    margin: 0;
}
.blue {
    color: #1e99db;
    font-size: 14px !important;
}
</style>
