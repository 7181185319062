<template>
    <div>
        <ReportBasis
            :tableData="tableData"
            v-if="detailed_report === 1"
            :start="start"
            :limit="limit"
        />

        <ReportStudent
            :tableData="tableData"
            v-if="detailed_report === 2"
            :start="start"
            :limit="limit"
        />

        <ReportTeam
            :tableData="tableData"
            v-if="detailed_report === 3"
            :start="start"
            :limit="limit"
        />
    </div>
</template>

<script>
import ReportBasis from './ReportBasis'
import ReportStudent from './ReportStudent'
import ReportTeam from './ReportTeam'

export default {
    name: 'ReportEmis',
    props: {
        detailed_report: {
            type: Number,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    components: {
        ReportBasis,
        ReportStudent,
        ReportTeam
    },
    data() {
        return {}
    },

    methods: {},
    mounted() {},
    watch: {},

    computed: {}
}
</script>

<style scoped></style>
