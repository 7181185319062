import { render, staticRenderFns } from "./general_information.vue?vue&type=template&id=319b6669&scoped=true&"
import script from "./general_information.vue?vue&type=script&lang=js&"
export * from "./general_information.vue?vue&type=script&lang=js&"
import style0 from "./general_information.vue?vue&type=style&index=0&id=319b6669&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319b6669",
  null
  
)

export default component.exports