<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị quản lý</th>
                <th rowspan="3">Trường học</th>
                <th colspan="2">Tổng số</th>
                <th colspan="6">Trong đó</th>
                <th colspan="6">Trong đó</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="2">Mẫu giáo</th>
                <th rowspan="2">Nhà trẻ</th>

                <th colspan="2">Công lập</th>
                <th colspan="2">Dân lập</th>
                <th colspan="2">Tư thục</th>
                <th colspan="2">Công lập</th>
                <th colspan="2">Dân lập</th>
                <th colspan="2">Tư thục</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="1">Mẫu giáo</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Mẫu giáo</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Mẫu giáo</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Nhà trẻ</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Nhà trẻ</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Nhà trẻ</th>
                <th rowspan="1">Học sinh</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị quản lý</th>
                <th rowspan="3">Trường học</th>
                <th colspan="2">Tổng số</th>
                <th colspan="6">Trong đó</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="2">Lớp 1</th>
                <th rowspan="2">Học sinh</th>

                <th colspan="2">Công lập</th>
                <th colspan="2">Dân lập</th>
                <th colspan="2">Tư thục</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="1">Lớp 1</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Lớp 1</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Lớp 1</th>
                <th rowspan="1">Học sinh</th>
            </tr>

            <tr v-if="statistical === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị quản lý</th>
                <th rowspan="3">Trường học</th>
                <th colspan="2">Tổng số</th>
                <th colspan="6">Trong đó</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="2">Lớp 6</th>
                <th rowspan="2">Học sinh</th>

                <th colspan="2">Công lập</th>
                <th colspan="2">Dân lập</th>
                <th colspan="2">Tư thục</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="1">Lớp 6</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Lớp 6</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Lớp 6</th>
                <th rowspan="1">Học sinh</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị quản lý</th>
                <th rowspan="3">Trường học</th>
                <th colspan="2">Tổng số</th>
                <th colspan="6">Trong đó</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="2">Lớp 10</th>
                <th rowspan="2">Học sinh</th>

                <th colspan="2">Công lập</th>
                <th colspan="2">Dân lập</th>
                <th colspan="2">Tư thục</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="1">Lớp 10</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Lớp 10</th>
                <th rowspan="1">Học sinh</th>
                <th rowspan="1">Lớp 10</th>
                <th rowspan="1">Học sinh</th>
            </tr>
        </template>
        <template v-slot:body>
            <tr v-if="statistical === 4 || statistical === 5">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
            <template v-if="statistical === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ (start / limit) * limit + index + 1 }}</td>
                    <td class="text-left">{{ item.tenDonVi }}</td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>123</td>
                    <td>123</td>
                    <td>123</td>
                    <td>123</td>
                    <td>123</td>
                    <td>123</td>
                    <td>123</td>
                    <td>123</td>
                </tr>
            </template>
            <tr v-if="statistical === 2">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StatisticsOfPrimarySchoolEnrollment',
    components: {
        CustomTable
    },

    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        },
        statistical: { type: Number, require: true }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 120px;
}
</style>
