<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Cơ sở vật chất -->
            <tr v-if="active_tab === 1">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-100">Đơn vị</th>
                <th colspan="68">TRUNG HỌC CƠ SỞ</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="4">145</th>
                <th colspan="4">146</th>
                <th colspan="4">147</th>
                <th colspan="4">148</th>
                <th colspan="4">149</th>
                <th colspan="4">150</th>
                <th colspan="4">151</th>
                <th colspan="4">152</th>
                <th colspan="4">153</th>
                <th colspan="4">154</th>
                <th colspan="4">155</th>
                <th colspan="4">156</th>
                <th colspan="4">157</th>
                <th colspan="4">158</th>
                <th colspan="4">159</th>
                <th colspan="4">160</th>
                <th colspan="4">161</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="4">Phòng học</th>
                <th colspan="4">Phòng học nhờ, mượn</th>
                <th colspan="4">Phòng học bộ môn</th>
                <th colspan="4">Tin học</th>
                <th colspan="4">Ngoại ngữ</th>
                <th colspan="4">Vật lý/Hóa/Sinh</th>
                <th colspan="4">Công nghệ</th>
                <th colspan="4">Âm nhạc</th>
                <th colspan="4">Phòng phục vụ học tập</th>
                <th colspan="4">Thư viện</th>
                <th colspan="4">Phòng thiết bị GD</th>
                <th colspan="4">Phòng GD thể chất/ nhà đa năng</th>
                <th colspan="4">Phòng hỗ trợ GD khuyết tật học hòa nhập</th>
                <th colspan="4">Phòng khác</th>
                <th colspan="4">Phòng Đoàn-Đội</th>
                <th colspan="4">Phòng truyền thống</th>
                <th colspan="4">Phòng y tế học đường</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th
                    v-for="(header, key) in list_th_1"
                    :key="key"
                    :rowspan="header.rowspan"
                    class="min-w-100"
                >
                    {{ header.label }}
                </th>
            </tr>

            <!-- 2. Phòng học -->

            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-100">Đơn vị</th>
                <th colspan="12">Phòng học</th>
                <th colspan="12">Phòng học nhờ, mượn</th>
                <th colspan="12">Phòng học bộ môn</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
            </tr>

            <tr v-if="active_tab === 2">
                <th
                    v-for="(header, key) in list_th_2"
                    :key="key"
                    :rowspan="header.rowspan"
                    class="min-w-100"
                >
                    {{ header.label }}
                </th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[3][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[3][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportCSVC',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Cơ sở vật chất' },
                { number: 2, label: 'Phòng học' }
            ],

            list_th_1: [
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 }
            ],

            list_th_2: [
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 }
            ],

            list_td_active_tab_1: [
                'E4',
                'F4',
                'G4',
                'H4',
                'E5',
                'F5',
                'G5',
                'H5',
                'E6',
                'F6',
                'G6',
                'H6',
                'E8',
                'F8',
                'G8',
                'H8',
                'E9',
                'F9',
                'G9',
                'H9',
                'E10',
                'F10',
                'G10',
                'H10',
                'E11',
                'F11',
                'G11',
                'H11',
                'E12',
                'F12',
                'G12',
                'H12',
                'E13',
                'F13',
                'G13',
                'H13',
                'E15',
                'F15',
                'G15',
                'H15',
                'E16',
                'F16',
                'G16',
                'H16',
                'E17',
                'F17',
                'G17',
                'H17',
                'E18',
                'F18',
                'G18',
                'H18',
                'E19',
                'F19',
                'G19',
                'H19',
                'E20',
                'F20',
                'G20',
                'H20',
                'E21',
                'F21',
                'G21',
                'H21',
                'E22',
                'F22',
                'G22',
                'H22'
            ],
            list_td_active_tab_2: [
                'E4',
                'F4',
                'G4',
                'H4',
                'I4',
                'J4',
                'K4',
                'L4',
                'M4',
                'N4',
                'O4',
                'P4',
                'E5',
                'F5',
                'G5',
                'H5',
                'I5',
                'J5',
                'K5',
                'L5',
                'M5',
                'N5',
                'O5',
                'P5',
                'E6',
                'F6',
                'G6',
                'H6',
                'I6',
                'J6',
                'K6',
                'L6',
                'M6',
                'N6',
                'O6',
                'P6'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-100 {
    min-width: 100px;
}
</style>
