<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="2">STT</th>
                <th rowspan="2">Phòng</th>
                <th rowspan="2">Trường</th>
                <th rowspan="2">Loại trường</th>
                <th rowspan="2">Lớp</th>
                <th rowspan="2">GV hiện có</th>
                <th rowspan="2">Định mức (giáo viên/lớp)</th>
                <th rowspan="2">GV cần có theo định mức</th>
                <th colspan="2">Giáo viên thừa thiếu</th>
            </tr>
            <tr>
                <th rowspan="1">Thừa</th>
                <th rowspan="1">Thiếu</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td>{{ item.loaiTruong }}</td>
                <td>{{ item.soLop }}</td>
                <td>{{ item.soGiaoVienHienCo }}</td>
                <td>{{ item.dinhMuc }}</td>
                <td>{{ item.soGiaoVienCanCo }}</td>
                <td>{{ item.giaoVienThua }}</td>
                <td>{{ item.giaoVienThieu }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'TeacherSurplusShortageStatisticsByAllocation',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
