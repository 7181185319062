<template>
    <div>
        <ToolBar :menu="'Hệ thống'" :content="'Sáp nhập trường học'">
            <template v-slot:form>
                <CustomButton
                    label="Sáp nhập"
                    @click="handleMergeSchool"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="5">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Chọn trường chính <span class="error">*</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="main_school"
                        @input="onChangeHandler('main_school', $event)"
                        :class="{
                            'error-select': err_main_school.length > 0
                        }"
                    />
                    <div v-if="err_main_school.length > 0" class="error">
                        {{ err_main_school }}
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Chọn trường sáp nhập <span class="error">*</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="merge_school"
                        @input="onChangeHandler('merge_school', $event)"
                        :class="{
                            'error-select': err_merge_school.length > 0
                        }"
                    />
                    <div v-if="err_merge_school.length > 0" class="error">
                        {{ err_merge_school }}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label"> Hướng dẫn/ Ghi chú: </label>
                <br />
                <label class="control-label">
                    <span style="color: red"> (1)</span>
                    Điều kiện thực hiện chức năng sáp nhập trường:
                </label>
                <ul class="note-list">
                    <li>
                        Cả 2 trường cùng sát nhập phải thực hiện chuyển hồ sơ
                        (Trường, lớp, học sinh, giáo viên) từ năm học cũ lên năm
                        học hiện tại.
                    </li>
                    <li>
                        Chuẩn hóa lại Lớp học, Học sinh, Giáo viên, Điểm trường
                        đảm bảo không tồn tại Tên lớp, Mã học sinh, Mã giáo viên,
                        Mã điểm trường tại 2 trường giống nhau.
                    </li>
                </ul>
            </div>
        </FormSearch>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import { handlerError, validateFields } from '@/utils/validator'
import { showNotication } from '@/utils/notification'
export default {
    name: 'DataTransferSchedule',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect
    },
    data() {
        return {
            list_school: [],
            main_school: '',
            err_main_school: '',
            merge_school: '',
            err_merge_school: ''
        }
    },

    methods: {
        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                main_school: 'err_main_school',
                merge_school: 'err_merge_school'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        resetData() {
            this.main_school = ''
            this.err_main_school = ''
            this.merge_school = ''
            this.err_merge_school = ''
        },
        async handleMergeSchool() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.main_school,
                        errorField: 'err_main_school'
                    },
                    {
                        value: this.merge_school,
                        errorField: 'err_merge_school'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    const request_data = {
                        maTruongChinh: this.main_school,
                        maTruongDuocSapNhap: this.merge_school,
                        namHoc: this.selectedYearFromStore
                    }
                    const response = await sendRequest(
                        Api.he_thong.sap_nhap,
                        request_data,
                        this.request_headers,
                        null
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)

                        this.resetData()
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_school()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    margin-top: -20px;
    color: red;
}
.form-group {
    padding: 10px 0 0 10px;
    line-height: 20px !important;
}
.note-list {
    margin: 0 30px;
}
.note-item {
    font-size: 13px;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
