<template>
    <div>
        <CustomTable :column-form="2" :tab="true" :hide-pagi="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. Cán bộ quản lý -->

                <tr v-if="active_tab === 1">
                    <th rowspan="4">STT</th>
                    <th rowspan="4" class="min-w-150">Đơn vị</th>
                    <th rowspan="4" class="min-w-150">Cán bộ quản lý</th>
                    <th colspan="13">Hiệu trưởng</th>
                    <th colspan="13">Phó hiệu trưởng</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="3" class="min-w-150">Tổng Hiệu trưởng</th>
                    <th colspan="6">Chia theo trình độ đào tạo</th>
                    <th colspan="3">Hình thức hợp đồng</th>
                    <th colspan="3">Trong tổng số</th>
                    <th rowspan="3" class="min-w-150">Tổng phó hiệu trưởng</th>
                    <th colspan="6">Chia theo trình độ đào tạo</th>
                    <th colspan="3">Hình thức hợp đồng</th>
                    <th colspan="3">Trong tổng số</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="2" class="min-w-150">Tiến sĩ, TSKH</th>
                    <th rowspan="2" class="min-w-150">Thạc sĩ</th>
                    <th rowspan="2" class="min-w-150">Đại học sư phạm</th>
                    <th rowspan="2" class="min-w-150">Cao đẳng sư phạm</th>
                    <th rowspan="2" class="min-w-150">Trung cấp sư phạm</th>
                    <th rowspan="2" class="min-w-150">Khác</th>
                    <th colspan="3">Viên chức</th>
                    <th rowspan="2" class="min-w-150">Nữ</th>
                    <th rowspan="2" class="min-w-150">Dân tộc</th>
                    <th rowspan="2" class="min-w-150">Nữ dân tộc</th>
                    <th rowspan="2" class="min-w-150">Tiến sĩ, TSKH</th>
                    <th rowspan="2" class="min-w-150">Thạc sĩ</th>
                    <th rowspan="2" class="min-w-150">Đại học sư phạm</th>
                    <th rowspan="2" class="min-w-150">Cao đẳng sư phạm</th>
                    <th rowspan="2" class="min-w-150">Trung cấp sư phạm</th>
                    <th rowspan="2" class="min-w-150">Khác</th>
                    <th colspan="2">Viên chức</th>
                    <th rowspan="2" class="min-w-150">Hợp đồng lao động</th>
                    <th rowspan="2" class="min-w-150">Nữ</th>
                    <th rowspan="2" class="min-w-150">Dân tộc</th>
                    <th rowspan="2" class="min-w-150">Nữ dân tộc</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1" class="min-w-150">
                        HĐLV không xác định thời hạn
                    </th>
                    <th rowspan="1" class="min-w-150">HĐLV xác định thời hạn</th>
                    <th rowspan="1" class="min-w-150">Hợp đồng lao động</th>
                    <th rowspan="1" class="min-w-150">
                        HĐLV không xác định thời hạn
                    </th>
                    <th rowspan="1" class="min-w-150">HĐLV xác định thời hạn</th>
                </tr>

                <!-- 2. Giáo viên -->

                <tr v-if="active_tab === 2">
                    <th rowspan="3">STT</th>
                    <th rowspan="3" class="min-w-150">Đơn vị</th>
                    <th rowspan="3" class="min-w-150">Tổng số giáo viên</th>
                    <th rowspan="3" class="min-w-150">GV phụ trách đội</th>
                    <th colspan="6">Chia theo trình độ đào tạo</th>
                    <th colspan="3">Hình thức hợp đồng</th>
                    <th colspan="3">Trong tổng số</th>
                    <th colspan="7">Chia theo độ tuổi</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="2" class="min-w-150">Tiến sĩ, TSKH</th>
                    <th rowspan="2" class="min-w-150">Thạc sĩ</th>
                    <th rowspan="2" class="min-w-150">Đại học sư phạm</th>
                    <th rowspan="2" class="min-w-150">Cao đẳng sư phạm</th>
                    <th rowspan="2" class="min-w-150">Trung cấp sư phạm</th>
                    <th rowspan="2" class="min-w-150">Khác</th>
                    <th colspan="2">Viên chức</th>
                    <th rowspan="2" class="min-w-150">Hợp đồng lao động</th>
                    <th rowspan="2" class="min-w-150">Nữ</th>
                    <th rowspan="2" class="min-w-150">Dân tộc</th>
                    <th rowspan="2" class="min-w-150">Nữ dân tộc</th>
                    <th rowspan="2" class="min-w-150">
                        Tổng giáo viên chia theo độ tuổi
                    </th>
                    <th rowspan="2" class="min-w-150">Từ 20 - 29 Tuổi</th>
                    <th rowspan="2" class="min-w-150">Từ 30-39 Tuổi</th>
                    <th rowspan="2" class="min-w-150">Từ 40-49 Tuổi</th>
                    <th rowspan="2" class="min-w-150">Từ 50-54 Tuổi</th>
                    <th rowspan="2" class="min-w-150">Từ 55-59 Tuổi</th>
                    <th rowspan="2" class="min-w-150">Từ 60 tuổi</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1" class="min-w-150">
                        HĐLV không xác định thời hạn
                    </th>
                    <th rowspan="1" class="min-w-150">HĐLV xác định thời hạn</th>
                </tr>

                <!-- 3. Nhân viên -->

                <tr v-if="active_tab === 3">
                    <th rowspan="3">STT</th>
                    <th rowspan="3" class="min-w-150">Đơn vị</th>
                    <th rowspan="3" class="min-w-150">Tổng số nhân viên</th>
                    <th colspan="3">Trong tổng số</th>
                    <th colspan="10">Chia ra</th>
                    <th colspan="3">Hình thức hợp đồng</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="2" class="min-w-150">Nữ</th>
                    <th rowspan="2" class="min-w-150">Dân tộc</th>
                    <th rowspan="2" class="min-w-150">Nữ dân tộc</th>
                    <th rowspan="2" class="min-w-150">
                        Nhân viên thư viện, thiết bị
                    </th>
                    <th rowspan="2" class="min-w-150">
                        Nhân viên công nghệ thông tin
                    </th>
                    <th rowspan="2" class="min-w-150">Nhân viên kế toán</th>
                    <th rowspan="2" class="min-w-150">Nhân viên thủ quỹ</th>
                    <th rowspan="2" class="min-w-150">Nhân viên văn thư</th>
                    <th rowspan="2" class="min-w-150">Nhân viên y tế</th>
                    <th rowspan="2" class="min-w-150">
                        Nhân viên hỗ trợ GD khuyết tật
                    </th>
                    <th rowspan="2" class="min-w-150">Nhân viên giáo vụ</th>
                    <th rowspan="2" class="min-w-150">
                        Nhân viên kiêm nhiều việc
                    </th>

                    <th colspan="2">Viên chức</th>
                    <th rowspan="2" class="min-w-150">Hợp đồng lao động</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="1" class="min-w-150">
                        HĐLV không xác định thời hạn
                    </th>
                    <th rowspan="1" class="min-w-150">HĐLV xác định thời hạn</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <template v-if="active_tab === 1">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>

                        <td v-for="td in list_td_active_tab_1" :key="td.index">
                            {{ item[2][td] }}
                        </td>
                    </tr>
                </template>
                <template v-if="active_tab === 2">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>

                        <td v-for="td in list_td_active_tab_2" :key="td.index">
                            {{ item[2][td] }}
                        </td>
                    </tr>
                </template>
                <template v-if="active_tab === 3">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>

                        <td v-for="td in list_td_active_tab_3" :key="td.index">
                            {{ item[2][td] }}
                        </td>
                    </tr>
                </template>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportTeachers',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Cán bộ quản lý' },
                { number: 2, label: 'Giáo viên' },
                { number: 3, label: 'Nhân viên' }
            ],
            list_td_active_tab_1: [
                'E7',
                'E8',
                'E14',
                'E13',
                'E12',
                'E11',
                'E10',
                'E15',
                'J7',
                'K7',
                'L7',
                'F7',
                'G7',
                'H7',
                'E16',
                'E25',
                'E24',
                'E23',
                'E22',
                'E21',
                'E26',
                'J16',
                'K16',
                'L16',
                'F16',
                'G16',
                'H16'
            ],
            list_td_active_tab_2: [
                'E27',
                'E42',
                'E33',
                'E32',
                'E31',
                'E30',
                'E29',
                'E34',
                'J27',
                'K27',
                'L27',
                'F27',
                'G27',
                'H27',
                'E27',
                'E36',
                'E37',
                'E38',
                'E39',
                'E40',
                'E41'
            ],
            list_td_active_tab_3: [
                'E43',
                'F43',
                'G43',
                'H43',
                'E45',
                'E46',
                'E47',
                'E48',
                'E49',
                'E50',
                'E51',
                'E52',
                'E53',
                'J43',
                'K43',
                'L43'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-150 {
    min-width: 150px;
}
</style>
