<template>
    <el-dialog :visible="viewVisible" @close="closeModalView" :fullscreen="true">
        <span slot="title">
            {{ dataModalView.tenTruongHoc }} - {{ dataModalView.maTruongHoc }}
            <hr />
        </span>

        <CustomTable :hide-pagi="true" :hide-btn-table="true">
            <template v-slot:header>
                <tr>
                    <th class="min-w-50">STT</th>
                    <th>Tên</th>
                    <th>Mã</th>
                    <th>Lỗi</th>
                    <th>Mã lỗi</th>
                    <th>Mô tả</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td class="text-left min-w-200">{{ item.name }}</td>
                    <td class="text-left min-w-150">{{ item.ma }}</td>
                    <td class="text-left min-w-150">
                        {{ item.errorFieldTitle }}
                    </td>
                    <td class="text-left min-w-150">{{ item.error }}</td>
                    <td class="text-left">{{ item.errorDescription }}</td>
                </tr>
            </template>
        </CustomTable>

        <span slot="footer">
            <CustomButton
                label="Đóng"
                class="fixed-btn"
                :btnToolBar="true"
                @click="closeModalView"
            />
        </span>
    </el-dialog>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomTable from '@/components/CustomTable.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { getFromSessionStorage } from '@/utils/session'
export default {
    name: 'ModalView',
    data() {
        return { tableData: [] }
    },
    props: {
        viewVisible: {
            type: Boolean,
            required: true
        },
        closeModalView: Function,
        dataModalView: {
            type: Object,
            require: true
        }
    },
    watch: {
        viewVisible(newVal) {
            if (newVal === true) {
                this.getDataTable()
            }
        }
    },

    components: { CustomButton, CustomTable },
    methods: {
        async getDataTable() {
            try {
                const request_param = { messageId: this.dataModalView.messageId }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.lich_su_day_du_lieu_len_ioc
                        .chi_tiet,
                    null,
                    this.request_headers,
                    request_param
                )

                this.tableData = response.rows
            } catch (error) {
                console.log(error)
            }
        }
    },
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },

        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.fixed-btn {
    position: fixed;
    bottom: 20px;
    left: 95%;
    transform: translateX(-50%);
    z-index: 999;
}
.error-label {
    padding-left: 20px;
    padding-top: 7px;
    font-weight: 700;
}

.error-icon {
    color: red;
}

.error-text {
    color: blue;
    font-weight: 700;
}
::v-deep .el-dialog__body {
    padding: 0px 20px 0px 20px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #186ab2;
}
</style>
