<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Tra cứu lịch sử xóa dữ liệu'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Loại dữ liệu:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_type"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Từ ngày <span class="error">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <DatePicker v-model="start_date" />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đến ngày :</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <DatePicker v-model="end_date" />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="2" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Thời gian xóa</th>
                    <th>Loại dữ liệu</th>
                    <th>Nội dung xóa</th>
                    <th>Người xóa</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="211">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>{{ item.time }}</td>
                    <td>{{ getLabelByArray(list_type, item.deleteDataType) }}</td>
                    <td class="text-left">
                        <div v-if="item.deleteInfos">
                            <div
                                v-for="(info, infoIndex) in item.deleteInfos"
                                :key="infoIndex"
                            >
                                {{ formatDeleteInfo(info) }}
                            </div>
                        </div>
                        <div v-else></div>
                    </td>
                    <td>{{ item.userId }}</td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData, getLabelByArray } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import DatePicker from '@/components/DatePicker.vue'
import { list_type } from '@/mock_data/tra-cuu-lich-su-xoa-du-lieu'
export default {
    name: 'LookUpHistoryDelete',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        DatePicker
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: '',

            list_type: list_type,
            type: 4,

            start_date: '',

            end_date: '',

            tableData: []
        }
    },

    methods: {
        formatDeleteInfo(info) {
            const fieldMap = [
                { label: 'Mã', value: info.ma },
                { label: 'Tên', value: info.ten },
                { label: 'Mã Trường', value: info.maTruong },
                { label: 'Lớp', value: info.lop },
                { label: 'Học Kỳ', value: info.hocKy }
            ]

            const fields = fieldMap
                .filter((field) => field.value)
                .map((field) => `${field.label}: ${field.value}`)

            return fields.join(', ')
        },
        getLabelByArray,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        onChangeUnitEducation() {
            this.school = ''
            this.get_list_school()
        },
        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education ? [this.unit_education] : []
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        async handleSearch() {
            try {
                showLoading()
                const request_data = {
                    start: this.start,
                    limit: this.limit,
                    namHoc: this.selectedYearFromStore,
                    maSo: this.auth_user.ma_so,
                    maPhong: this.unit_education,
                    capHoc: this.selectedGradeLevelFromStore,
                    maTruong: this.school,
                    deleteDataType: this.type,
                    ngayBatDau: this.start_date,
                    ngayKetThuc: this.end_date
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_lich_su_xoa_du_lieu,
                    request_data,
                    this.request_headers,
                    null
                )

                this.total_rows = response.total
                this.tableData = response.rows
                hideLoading()
            } catch (error) {
                console.log(error)
            } finally {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
        this.get_list_school()
    },
    watch: {
        selectedGradeLevelFromStore(newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
::v-deep .is-error .el-input__inner {
    border: 1px solid #f56c6c !important;
}
::v-deep .is-error .el-input__inner:focus {
    border: 1px solid #f56c6c !important;
    box-shadow: 0 0 3px #f56c6c;
}
</style>
