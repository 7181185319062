<template>
    <CustomTable :column-form="2" :tab="true" :hide-pagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Cơ sở vật chất -->

            <tr v-if="active_tab === 1">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-100">Đơn vị</th>
                <th colspan="52">TIỂU HỌC</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="4">120</th>
                <th colspan="4">121</th>
                <th colspan="4">122</th>
                <th colspan="4">123</th>
                <th colspan="4">124</th>
                <th colspan="4">125</th>
                <th colspan="4">126</th>
                <th colspan="4">127</th>
                <th colspan="4">129</th>
                <th colspan="4">130</th>
                <th colspan="4">131</th>
                <th colspan="4">132</th>
                <th colspan="4">133</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="4">5.1. Phòng học</th>
                <th colspan="4">5.2. Phòng học nhờ, mượn</th>
                <th colspan="4">5.3. Phòng phục vụ học tập</th>
                <th colspan="4">- Thư viện</th>
                <th colspan="4">- Phòng thiết bị giáo dục</th>
                <th colspan="4">- Phòng tin học</th>
                <th colspan="4">- Phòng ngoại ngữ</th>
                <th colspan="4">- Phòng nghệ thuật</th>
                <th colspan="4">- Phòng âm nhạc</th>
                <th colspan="4">- Phòng GD rèn luyện thể chất/nhà đa năng</th>
                <th colspan="4">
                    - Phòng hỗ trợ giáo dục khuyết tật học hòa nhập
                </th>
                <th colspan="4">- Phòng truyền thống</th>
                <th colspan="4">- Phòng Y tế học đường</th>
            </tr>

            <tr v-if="active_tab === 1">
                <th
                    v-for="(header, key) in list_th_1"
                    :key="key"
                    :rowspan="header.rowspan"
                    class="min-w-100"
                >
                    {{ header.label }}
                </th>
            </tr>

            <!-- 2. Phòng học -->

            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th colspan="12">Phòng học</th>
                <th colspan="12">Phòng học nhờ, mượn</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Kiên cố</th>
                <th rowspan="1">Bán kiên cố</th>
                <th rowspan="1">Tạm</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Kiên cố</th>
                <th rowspan="1">Bán kiên cố</th>
                <th rowspan="1">Tạm</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Kiên cố</th>
                <th rowspan="1">Bán kiên cố</th>
                <th rowspan="1">Tạm</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Kiên cố</th>
                <th rowspan="1">Bán kiên cố</th>
                <th rowspan="1">Tạm</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Kiên cố</th>
                <th rowspan="1">Bán kiên cố</th>
                <th rowspan="1">Tạm</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Kiên cố</th>
                <th rowspan="1">Bán kiên cố</th>
                <th rowspan="1">Tạm</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[3][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[3][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportCSVC',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Cơ sở vật chất' },
                { number: 2, label: 'Phòng học' }
            ],
            list_th_1: [
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 }
            ],

            list_td_active_tab_1: [
                'E4',
                'F4',
                'G4',
                'H4',
                'E5',
                'F5',
                'G5',
                'H5',
                'E6',
                'F6',
                'G6',
                'H6',
                'E8',
                'F8',
                'G8',
                'H8',
                'E9',
                'F9',
                'G9',
                'H9',
                'E10',
                'F10',
                'G10',
                'H10',
                'E11',
                'F11',
                'G11',
                'H11',
                'E12',
                'F12',
                'G12',
                'H12',
                'E13',
                'F13',
                'G13',
                'H13',
                'E14',
                'F14',
                'G14',
                'H14',
                'E15',
                'F15',
                'G15',
                'H15',
                'E17',
                'F17',
                'G17',
                'H17',
                'E18',
                'F18',
                'G18',
                'H18'
            ],
            list_td_active_tab_2: [
                'E4',
                'F4',
                'G4',
                'H4',
                'I4',
                'J4',
                'K4',
                'L4',
                'M4',
                'N4',
                'O4',
                'P4',
                'E5',
                'F5',
                'G5',
                'H5',
                'I5',
                'J5',
                'K5',
                'L5',
                'M5',
                'N5',
                'O5',
                'P5'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-100 {
    min-width: 100px;
}
</style>
