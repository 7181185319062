export const list_advanced_search = [
    { value: 'hthanhCtrinhLhoc', name: 'HT c.trình lớp học' },
    { value: 'hoanThanhChuongTrinhMamMon', name: 'HT c.trình MN' },
    { value: 'hoanThanhChuongTrinhTieuHoc', name: 'HT c.trình TH' },
    { value: 'khamSkDky', name: 'Khám sức khỏe định kỳ' },
    { value: 'lamQuenThoc', name: 'Làm quen tin học' },
    { value: 'lenLop', name: 'Lên lớp' },
    { value: 'phoiIchayHhap', name: 'Có bệnh về ỉa chảy, hô hấp' },
    { value: 'suyDduongTheTcoi', name: 'Suy dinh dưỡng thể thấp còi' },
    { value: 'theoDoiBdoCcao', name: 'Theo dõi biểu đồ chiều cao' },
    { value: 'theoDoiBdoCnang', name: 'Theo dõi biểu đồ cân nặng' },
    { value: 'maHocLuc', name: 'Học lực' },
    { value: 'maHanhKiem', name: 'Hạnh kiểm' },
    { value: 'maDanhHieu', name: 'Danh hiệu' },
    { value: 'renLuyenLai', name: 'Rèn luyện lại' },
    { value: 'soNgayNghi', name: 'Số ngày nghỉ' },
    { value: 'thiLai', name: 'Thi lại' },
    { value: 'totNghiep', name: 'Tốt nghiệp' },
    { value: 'maLoaiTotNghiep', name: 'Loại tốt nghiệp' },
    { value: 'duXetTotNghiep', name: 'Dự xét tốt nghiệp' },
    { value: 'moetCode', name: 'Mã định danh bộ GD&ĐT' },
    { value: 'trangThai', name: 'Trạng thái' },
    { value: 'danhGiaKqGiaoDuc', name: 'Đánh giá kết quả giáo dục' },
    { value: 'tenLopHoc', name: 'Tên lớp học' },

    { value: 'isSuyDduongTheNheCan', name: 'Suy dinh dưỡng thể nhẹ cân' },

    { value: 'isSuyDduongTheCoiCoc', name: 'Suy dinh dưỡng thể còi cọc' },

    { value: 'beoPhi', name: 'Béo phì' }
]

export const list_content_displayed = [
    { key: 'maTruongHoc', title: 'Mã trường', value: 1 },
    { key: 'tenTruongHoc', title: 'Trường học', value: 2 },
    { key: 'capHoc', title: 'Cấp học', value: 3 },
    { key: 'khoiHoc', title: 'Khối học/Nhóm tuổi', value: 4 },
    { key: 'tenLopHoc', title: 'Tên lớp học', value: 5 },
    { key: 'tenHocSinh', title: 'Họ và tên', value: 6 },
    { key: 'maHocSinh', title: 'Mã học sinh', value: 7 },
    { key: 'namHoc', title: 'Năm học', value: 11 },
    { key: 'hocKy', title: 'Học kỳ', value: 12 },
    { key: 'moetCode', title: 'Mã định danh Bộ GD&ĐT', value: 8 },
    { key: 'trangThai', title: 'Trạng thái', value: 9 },
    { key: 'diemTongKet', title: 'Điểm tổng kết', value: 10 },

    { key: 'giaiDoan', title: 'Giai đoạn', value: 13 },
    { key: 'maHocLuc', title: 'Học lực', value: 14 },
    { key: 'maHanhKiem', title: 'Hạnh kiểm', value: 15 },
    { key: 'maDanhHieu', title: 'Danh hiệu', value: 16 },
    { key: 'hthanhCtrinhLhoc', title: 'HT c.trình lớp học', value: 17 },
    { key: 'hoanThanhChuongTrinhMamMon', title: 'HT c.trình MN', value: 18 },
    { key: 'hoanThanhChuongTrinhTieuHoc', title: 'HT c.trình TH', value: 19 },
    { key: 'kthuongCnam', title: 'Khen thưởng cuối năm', value: 20 },
    { key: 'kthuongDxuat', title: 'Khen thưởng đột xuất', value: 21 },
    { key: 'lenLop', title: 'Lên lớp', value: 22 },
    { key: 'renLuyenLai', title: 'Rèn luyện lại', value: 23 },
    { key: 'thiLai', title: 'Thi lại', value: 24 },
    { key: 'duXetTotNghiep', title: 'Dự xét tốt nghiệp', value: 25 },
    { key: 'totNghiep', title: 'Tốt nghiệp', value: 26 },
    { key: 'maLoaiTotNghiep', title: 'Loại tốt nghiệp', value: 27 },
    { key: 'soNgayNghi', title: 'Số ngày nghỉ', value: 28 },
    { key: 'kqHocTap', title: 'Kết quả học tập', value: 29 },
    { key: 'kqRenLuyen', title: 'Kết quả rèn luyện', value: 30 },
    {
        key: 'isKhamSkDky',
        title: 'Khám sức khỏe định kỳ',
        value: 41
    },
    { key: 'TvDgtx', title: 'Đánh giá Tiếng Việt', value: 37 },
    { key: 'LoaiChungChiNghe', title: 'Loại chứng chỉ nghề', value: 38 },
    { key: 'khenThuongCapHuyen', title: 'Khen thưởng cấp huyện', value: 39 },
    {
        key: 'isSuyDduongTheNheCan',
        title: 'Suy dinh dưỡng thể nhẹ cân',
        value: 42
    },
    {
        key: 'isSuyDduongTheCoiCoc',
        title: 'Suy dinh dưỡng thể còi cọc',
        value: 43
    },
    { key: 'beoPhi', title: 'Béo phì', value: 44 },
    { key: 'suyDduongTheTcoi', title: 'Suy dinh dưỡng thể thấp còi', value: 45 },
    { key: 'theoDoiBdoCcao', title: 'Theo dõi biểu đồ chiều cao', value: 46 },
    { key: 'theoDoiBdoCnang', title: 'Theo dõi biểu đồ cân nặng', value: 47 },
    { key: 'phoiIchayHhap', title: 'Có bệnh về ỉa chảy, hô hấp', value: 48 }
]

export const list_yes_no = [
    {
        value: 1,
        label: 'Có'
    },
    {
        value: 0,
        label: 'Không'
    }
]
export const list_true_false = [
    {
        value: true,
        label: 'Có'
    },
    {
        value: false,
        label: 'Không'
    }
]

export const list_complete = [
    { value: true, label: 'Hoàn thành' },
    { value: false, label: 'Không hoàn thành' }
]

export const list_academic_performance = [
    {
        label: 'Giỏi',
        value: 'G'
    },
    {
        label: 'Khá',
        value: 'K'
    },
    {
        label: 'Trung bình',
        value: 'TB'
    },
    {
        label: 'Yếu',
        value: 'Y'
    },
    {
        label: 'Kém',
        value: 'KEM'
    }
]

export const list_conduct = [
    {
        label: 'Tốt',
        value: 'T'
    },
    {
        label: 'Khá',
        value: 'K'
    },
    {
        label: 'Trung bình',
        value: 'TB'
    },
    {
        label: 'Yếu',
        value: 'Y'
    }
]

export const list_achievements = [
    {
        label: 'Vượt trội',
        value: 'VUOTTROI'
    },
    {
        label: 'Xuất sắc',
        value: 'XUATSAC'
    },
    {
        label: 'Giỏi',
        value: 'GIOI'
    },
    {
        label: 'Tiêu biểu',
        value: 'TIEUBIEU'
    },
    {
        label: 'Tiên tiến',
        value: 'TT'
    }
]

export const list_status = [
    {
        label: 'Đang học',
        value: '1'
    },
    {
        label: 'Chuyển khỏi trường',
        value: '3'
    },
    {
        label: 'Bỏ học',
        value: '4'
    }
]

export const list_educational_outcome_assessment = [
    { label: 'Hoàn thành xuất sắc', value: 'XS' },
    { label: 'Hoàn thành tốt', value: 'T' },
    { label: 'Hoàn thành ', value: 'HT' },
    { label: 'Chưa hoàn thành', value: 'CHT' }
]
