<template>
    <CustomTable :column-form="2" :hide-btn-table="true" :hidePagi="true">
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-200">Địa phương</th>
                <th colspan="3">Cơ sở GDTX</th>
                <th rowspan="3" class="min-w-200">Trung tâm GDTX cấp tỉnh</th>
                <th colspan="4">Trung tâm GDTX cấp huyện</th>
                <th colspan="2">Trung tâm học tập cộng đồng</th>
                <th colspan="13">Trung tâm ngoại ngữ - tin học</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-200">Tổng số</th>
                <th colspan="2">Chía theo loại hình</th>
                <th rowspan="2" class="min-w-200">Tổng số</th>
                <th colspan="3">Chía ra</th>
                <th rowspan="2" class="min-w-200">Tổng số</th>
                <th colspan="1">Trong đó</th>
                <th rowspan="2" class="min-w-200">Tổng số</th>
                <th colspan="2">Chía theo loại hình</th>
                <th colspan="3">Trung tâm ngoại ngữ tin học</th>
                <th colspan="3">Trung tâm ngoại ngữ</th>
                <th colspan="3">Trung tâm tin học</th>
                <th colspan="1">
                    TT ngoại ngữ có vốn đầu tư nước ngoài theo NĐ 86/2018/NĐ-CP
                </th>
            </tr>
            <tr>
                <th rowspan="1" class="min-w-200">Công lập</th>
                <th rowspan="1" class="min-w-200">Tư thục</th>
                <th rowspan="1" class="min-w-200">
                    Trung tâm giáo dục thường xuyên
                </th>
                <th rowspan="1" class="min-w-200">
                    Trung tâm giáo dục nghề nghiệp - Trung tâm giáo dục thường
                    xuyên
                </th>
                <th rowspan="1" class="min-w-200">TT GDTX- Hướng nghiệp</th>
                <th rowspan="1" class="min-w-200">
                    Trung tâm học tập cộng đồng đã kết hợp vói nhà văn hóa xã, bưu
                    điện xã
                </th>
                <th rowspan="1" class="min-w-200">Công lập</th>
                <th rowspan="1" class="min-w-200">Tư thục</th>
                <th rowspan="1" class="min-w-200">Tổng số</th>
                <th rowspan="1" class="min-w-200">Công lập</th>
                <th rowspan="1" class="min-w-200">Tư thục</th>
                <th rowspan="1" class="min-w-200">Tổng số</th>
                <th rowspan="1" class="min-w-200">Công lập</th>
                <th rowspan="1" class="min-w-200">Tư thục</th>
                <th rowspan="1" class="min-w-200">Tổng số</th>
                <th rowspan="1" class="min-w-200">Công lập</th>
                <th rowspan="1" class="min-w-200">Tư thục</th>
                <th rowspan="1" class="min-w-200">Tư thục</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>

            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left min-width">
                    {{ item['H3'] }}
                </td>

                <td v-for="td in list_td" :key="td.index">
                    {{ item[0][td] }}
                </td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
export default {
    name: 'ReportStudent',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            list_td: [
                'E9',
                'F9',
                'G9',
                'E10',
                'E11',
                'E13',
                'E14',
                'E15',
                'E16',
                'E18',
                'E19',
                'F19',
                'G19',
                'E21',
                'F21',
                'G21',
                '',
                '',
                '',
                '',
                '',
                '',
                'G22'
            ]
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-200 {
    min-width: 200px;
}
</style>
