<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <!-- Mẫu giáo - Mầm non -->
            <tr v-if="statistical == 4 || statistical == 5">
                <th rowspan="2">STT</th>
                <th rowspan="2">Trường học</th>
                <th rowspan="2">Tổng số Nhóm/Lớp trước khi ghép</th>
                <th rowspan="2">Tổng số Nhóm/Lớp sau khi ghép</th>
                <th rowspan="2">Số lớp đơn</th>
                <th rowspan="2">Số lớp ghép</th>
                <th colspan="4">Nhà trẻ</th>
                <th colspan="4">Mẫu giáo</th>
            </tr>
            <tr v-if="statistical == 4 || statistical == 5">
                <th rowspan="1">Số lượng</th>
                <th rowspan="1">3-12 tháng</th>
                <th rowspan="1">13-24 tháng</th>
                <th rowspan="1">25-36 tháng</th>
                <th rowspan="1">Số lượng</th>
                <th rowspan="1">3-4 tuổi</th>
                <th rowspan="1">4-5 tuổi</th>
                <th rowspan="1">5-6 tuổi</th>
            </tr>

            <!-- Tiểu học -->
            <tr v-if="statistical == 1">
                <th rowspan="2">STT</th>
                <th rowspan="2" class="min-w-200">Trường học</th>
                <th colspan="3">Tổng số</th>
                <th colspan="6">Lớp 1</th>
                <th colspan="6">Lớp 2</th>
                <th colspan="6">Lớp 3</th>
                <th colspan="6">Lớp 4</th>
                <th colspan="6">Lớp 5</th>
            </tr>
            <tr v-if="statistical == 1">
                <th rowspan="1" class="min-w-150">Lớp hành chính</th>
                <th rowspan="1" class="min-w-100">Lớp đơn</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Số lớp 1</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 2</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 3</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 4</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 5</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
            </tr>

            <!-- Trung học cơ sở -->
            <tr v-if="statistical == 2">
                <th rowspan="2">STT</th>
                <th rowspan="2" class="min-w-200">Trường học</th>
                <th colspan="3">Tổng số</th>
                <th colspan="6">Lớp 6</th>
                <th colspan="6">Lớp 7</th>
                <th colspan="6">Lớp 8</th>
                <th colspan="6">Lớp 9</th>
            </tr>
            <tr v-if="statistical == 2">
                <th rowspan="1" class="min-w-150">Lớp hành chính</th>
                <th rowspan="1" class="min-w-100">Lớp đơn</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Số lớp 6</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 7</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 8</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 9</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
            </tr>

            <!-- Trung học phổ thông -->
            <tr v-if="statistical == 3 || statistical == 6">
                <th rowspan="2">STT</th>
                <th rowspan="2" class="min-w-200">Trường học</th>
                <th colspan="3">Tổng số</th>
                <th colspan="6">Lớp 10</th>
                <th colspan="6">Lớp 11</th>
                <th colspan="6">Lớp 12</th>
            </tr>
            <tr v-if="statistical == 3 || statistical == 6">
                <th rowspan="1" class="min-w-150">Lớp hành chính</th>
                <th rowspan="1" class="min-w-100">Lớp đơn</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Số lớp 10</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 11</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
                <th rowspan="1" class="min-w-100">Số lớp 12</th>
                <th rowspan="1" class="min-w-100">Lớp VNEN</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Lớp chuyên</th>
                <th rowspan="1" class="min-w-100">Lớp NN</th>
                <th rowspan="1" class="min-w-150">Lớp học tin học</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>

            <template v-if="statistical == 4 || statistical == 5">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>{{ item.soLopSauGhep }}</td>
                    <td>{{ item.soLopSauGhep }}</td>
                    <td>{{ item.soLopDon }}</td>
                    <td>{{ item.soLopGhep }}</td>
                    <td>{{ item.tongSoTreNhaTre }}</td>
                    <td>{{ item.soTre3Den12Thang }}</td>
                    <td>{{ item.soTre13Den24Thang }}</td>
                    <td>{{ item.soTre25Den36Thang }}</td>
                    <td>{{ item.tongSoTreMauGiao }}</td>
                    <td>{{ item.soTre3Den4Tuoi }}</td>
                    <td>{{ item.soTre4Den5Tuoi }}</td>
                    <td>{{ item.soTre5Den6Tuoi }}</td>
                </tr>
            </template>
            <template v-if="statistical == 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>{{ item.lopHanhChinh }}</td>
                    <td>{{ item.lopDon }}</td>
                    <td>{{ item.lopGhep }}</td>
                    <td>{{ item?.khoiLopList[0].tong }}</td>
                    <td>{{ item?.khoiLopList[0].lopVnen }}</td>
                    <td>{{ item?.khoiLopList[0].lopGhep }}</td>
                    <td>{{ item?.khoiLopList[0].lopChuyen }}</td>
                    <td>{{ item?.khoiLopList[0].lopNgoaiNgu }}</td>
                    <td>{{ item?.khoiLopList[0].loptinHoc }}</td>
                    <td>{{ item?.khoiLopList[1].tong }}</td>
                    <td>{{ item?.khoiLopList[1].lopVnen }}</td>
                    <td>{{ item?.khoiLopList[1].lopGhep }}</td>
                    <td>{{ item?.khoiLopList[1].lopChuyen }}</td>
                    <td>{{ item?.khoiLopList[1].lopNgoaiNgu }}</td>
                    <td>{{ item?.khoiLopList[1].loptinHoc }}</td>
                    <td>{{ item?.khoiLopList[2].tong }}</td>
                    <td>{{ item?.khoiLopList[2].lopVnen }}</td>
                    <td>{{ item?.khoiLopList[2].lopGhep }}</td>
                    <td>{{ item?.khoiLopList[2].lopChuyen }}</td>
                    <td>{{ item?.khoiLopList[2].lopNgoaiNgu }}</td>
                    <td>{{ item?.khoiLopList[2].loptinHoc }}</td>
                    <td>{{ item?.khoiLopList[3].tong }}</td>
                    <td>{{ item?.khoiLopList[3].lopVnen }}</td>
                    <td>{{ item?.khoiLopList[3].lopGhep }}</td>
                    <td>{{ item?.khoiLopList[3].lopChuyen }}</td>
                    <td>{{ item?.khoiLopList[3].lopNgoaiNgu }}</td>
                    <td>{{ item?.khoiLopList[3].loptinHoc }}</td>
                    <td>{{ item?.khoiLopList[4].tong }}</td>
                    <td>{{ item?.khoiLopList[4].lopVnen }}</td>
                    <td>{{ item?.khoiLopList[4].lopGhep }}</td>
                    <td>{{ item?.khoiLopList[4].lopChuyen }}</td>
                    <td>{{ item?.khoiLopList[4].lopNgoaiNgu }}</td>
                    <td>{{ item?.khoiLopList[4].loptinHoc }}</td>
                </tr>
            </template>
            <template v-if="statistical == 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>{{ item.lopHanhChinh }}</td>
                    <td>{{ item.lopDon }}</td>
                    <td>{{ item.lopGhep }}</td>
                    <td>{{ item.khoiLopList[0].tong }}</td>
                    <td>{{ item.khoiLopList[0].lopVnen }}</td>
                    <td>{{ item.khoiLopList[0].lopGhep }}</td>
                    <td>{{ item.khoiLopList[0].lopChuyen }}</td>
                    <td>{{ item.khoiLopList[0].lopNgoaiNgu }}</td>
                    <td>{{ item.khoiLopList[0].loptinHoc }}</td>
                    <td>{{ item.khoiLopList[1].tong }}</td>
                    <td>{{ item.khoiLopList[1].lopVnen }}</td>
                    <td>{{ item.khoiLopList[1].lopGhep }}</td>
                    <td>{{ item.khoiLopList[1].lopChuyen }}</td>
                    <td>{{ item.khoiLopList[1].lopNgoaiNgu }}</td>
                    <td>{{ item.khoiLopList[1].loptinHoc }}</td>
                    <td>{{ item.khoiLopList[2].tong }}</td>
                    <td>{{ item.khoiLopList[2].lopVnen }}</td>
                    <td>{{ item.khoiLopList[2].lopGhep }}</td>
                    <td>{{ item.khoiLopList[2].lopChuyen }}</td>
                    <td>{{ item.khoiLopList[2].lopNgoaiNgu }}</td>
                    <td>{{ item.khoiLopList[2].loptinHoc }}</td>
                    <td>{{ item.khoiLopList[3].tong }}</td>
                    <td>{{ item.khoiLopList[3].lopVnen }}</td>
                    <td>{{ item.khoiLopList[3].lopGhep }}</td>
                    <td>{{ item.khoiLopList[3].lopChuyen }}</td>
                    <td>{{ item.khoiLopList[3].lopNgoaiNgu }}</td>
                    <td>{{ item.khoiLopList[3].loptinHoc }}</td>
                </tr>
            </template>
            <template v-if="statistical == 3 || statistical == 6">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>{{ item.lopHanhChinh }}</td>
                    <td>{{ item.lopDon }}</td>
                    <td>{{ item.lopGhep }}</td>
                    <td>{{ item.khoiLopList[0].tong }}</td>
                    <td>{{ item.khoiLopList[0].lopVnen }}</td>
                    <td>{{ item.khoiLopList[0].lopGhep }}</td>
                    <td>{{ item.khoiLopList[0].lopChuyen }}</td>
                    <td>{{ item.khoiLopList[0].lopNgoaiNgu }}</td>
                    <td>{{ item.khoiLopList[0].loptinHoc }}</td>
                    <td>{{ item.khoiLopList[1].tong }}</td>
                    <td>{{ item.khoiLopList[1].lopVnen }}</td>
                    <td>{{ item.khoiLopList[1].lopGhep }}</td>
                    <td>{{ item.khoiLopList[1].lopChuyen }}</td>
                    <td>{{ item.khoiLopList[1].lopNgoaiNgu }}</td>
                    <td>{{ item.khoiLopList[1].loptinHoc }}</td>
                    <td>{{ item.khoiLopList[2].tong }}</td>
                    <td>{{ item.khoiLopList[2].lopVnen }}</td>
                    <td>{{ item.khoiLopList[2].lopGhep }}</td>
                    <td>{{ item.khoiLopList[2].lopChuyen }}</td>
                    <td>{{ item.khoiLopList[2].lopNgoaiNgu }}</td>
                    <td>{{ item.khoiLopList[2].loptinHoc }}</td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StatisticsOnTheNumberOfClasses',
    components: {
        CustomTable
    },
    props: {
        statistical: {
            type: Number,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
