var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.isDetailedReport(['A.1.1', 'A.1.2']))?_c('ReportListSchool',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'B.1.1',
                'B.1.2',
                'C.1.1',
                'C.1.2',
                'D.1.1',
                'D.1.2',
                'E.1.1'
            ])
        )?_c('CountTheOddSchoolPoints',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }