<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Cán bộ quản lý -->
            <tr v-if="active_tab === 1">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th rowspan="3">Cán bộ quản lý</th>
                <th colspan="7">Hiệu trưởng</th>
                <th colspan="7">Phó Hiệu trưởng</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 2. GV chuẩn Nghề nghiệp -->
            <tr v-if="active_tab === 2">
                <th rowspan="4">STT</th>
                <th rowspan="4">Địa phương</th>
                <th rowspan="4">Tổng số GV</th>
                <th colspan="21">Giáo viên theo đánh giá chuẩn nghề nghiệp</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th colspan="7">Tốt</th>
                <th colspan="7">Khá</th>
                <th colspan="7">Đạt</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 3. GV biến động -->
            <tr v-if="active_tab === 3">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="7">Giáo viên nghỉ hưu trong năm học</th>
                <th colspan="7">Giáo viên tuyển mới trong năm học</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 4. Nhân viên -->
            <tr v-if="active_tab === 4">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="7">Nhân viên</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 5. GV bồi dưỡng  -->
            <tr v-if="active_tab === 5">
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="4">
                    Giáo viên được bồi dưỡng thường xuyên (TT 26/2012/TT-BGDĐT)
                </th>
                <th colspan="4">
                    Giáo viên được tập huấn và tham gia giảng dạy về phòng, chống
                    HIV trên nền tảng Kỹ năng sống và GD sức khỏe sinh sản
                </th>
                <th colspan="4">
                    Nhân viên được Tập huấn và tham gia giảng dạy về phòng, chống
                    HIV trên nền tảng Kỹ năng sống và GD sức khỏe sinh sản
                </th>
            </tr>
            <tr v-if="active_tab === 5">
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 4">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_4" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 5">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_5" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportPersonnel',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Cán bộ quản lý' },
                { number: 2, label: 'GV chuẩn Nghề nghiệp' },
                { number: 3, label: 'GV biến động' },
                { number: 4, label: 'Nhân viên' },
                { number: 5, label: 'GV bồi dưỡng' }
            ],
            list_td_active_tab_1: [
                'E7',
                'E8',
                'F8',
                'G8',
                'H8',
                'J8',
                'K8',
                'L8',
                'E9',
                'F9',
                'G9',
                'H9',
                'J9',
                'K9',
                'L9'
            ],
            list_td_active_tab_2: [
                'E10',
                'E12',
                'F12',
                'G12',
                'H12',
                'J12',
                'K12',
                'L12',
                'E13',
                'F13',
                'G13',
                'H13',
                'J13',
                'K13',
                'L13',
                'E14',
                'F14',
                'G14',
                'H14',
                'J14',
                'K14',
                'L14'
            ],
            list_td_active_tab_3: [
                'E15',
                'F15',
                'G15',
                'H15',
                'J15',
                'K15',
                'L15',
                'E16',
                'F16',
                'G16',
                'H16',
                'J16',
                'K16',
                'L16'
            ],
            list_td_active_tab_4: [
                'E17',
                'F17',
                'G17',
                'H17',
                'J17',
                'K17',
                'L17'
            ],
            list_td_active_tab_5: [
                'E19',
                'F19',
                'G19',
                'H19',
                'E20',
                'F20',
                'G20',
                'H20',
                'E21',
                'F21',
                'G21',
                'H21'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
