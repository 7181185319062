<template>
    <div>
        <ToolBar :tab="true">
            <template v-slot:tab>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                >
                    <el-menu-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :index="index.toString()"
                        @click="handleMenuItemClick(index)"
                    >
                        {{ item.label }}
                    </el-menu-item>
                </el-menu>
            </template>
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    :btnToolBar="true"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Xuất Excel"
                    :icon="'el-icon-download'"
                    :btnToolBar="true"
                    @click="handleSearch"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Chi tiết báo cáo:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_detailed_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="detailed_report"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Cấp học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_grade_level"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="grade_level"
                        @change="onChangeGradeLevel"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-3">
                    <el-radio-group v-model="is_school">
                        <el-radio :label="3">Phòng</el-radio>
                        <el-radio :label="6">Trường</el-radio>
                    </el-radio-group>
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="is_school === 3 ? true : false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Loại hình:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_type"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type"
                    />
                </div>
            </div>
        </FormSearch>

        <PreSchool
            :detailed_report="detailed_report"
            :tableData="tableData"
            v-if="grade_level == '45' || grade_level == '4' || grade_level == '5'"
            :start="start"
            :limit="limit"
        />

        <PrimarySchool
            :detailed_report="detailed_report"
            :tableData="tableData"
            v-if="grade_level == '1'"
            :start="start"
            :limit="limit"
        />

        <SecondarySchool
            v-if="grade_level == '2'"
            :detailed_report="detailed_report"
            :tableData="tableData"
            :start="start"
            :limit="limit"
        />

        <HighSchool
            v-if="grade_level == '3'"
            :detailed_report="detailed_report"
            :tableData="tableData"
            :start="start"
            :limit="limit"
        />

        <ContinuingEducationSchool
            v-if="grade_level == '6'"
            :detailed_report="detailed_report"
            :tableData="tableData"
            :start="start"
            :limit="limit"
        />

        <div class="pagi">
            <CustomPagination
                v-show="grade_level && total_rows > 0"
                :tongbanghi="total_rows"
                :pageSize="pageSize"
                :pageStart="pageStart"
                @pageChange="pageChange($event)"
                @pageSizeChange="pageSizeChange($event)"
            >
            </CustomPagination>
        </div>
    </div>
</template>

<script>
import PreSchool from './man-non/index.vue'
import PrimarySchool from './tieu-hoc/index.vue'
import SecondarySchool from './trung-hoc-co-so/index.vue'
import HighSchool from './trung-hoc-pho-thong/index.vue'
import ContinuingEducationSchool from './giao-duc-thuong-xuyen/index.vue'
import { getFromSessionStorage } from '@/utils/session'
import CustomPagination from '@/components/CustomPagination.vue'
import ToolBar from '@/components/ToolBar.vue'
import FormSearch from '@/components/FormSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import {
    list_type,
    list_detailed_report_year_end,
    list_detailed_report_year_end_GDTX
} from '@/mock_data/bao-cao-emis'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ReportEmis',
    components: {
        ToolBar,
        FormSearch,
        CustomPagination,
        CustomButton,
        ESelect,
        PreSchool,
        PrimarySchool,
        SecondarySchool,
        HighSchool,
        ContinuingEducationSchool
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            selectAllChecked: false,

            unit_education: [],
            grade_level: '45',
            list_school: [],
            school: [],

            list_detailed_report: list_detailed_report_year_end,
            detailed_report: 1,

            list_type: list_type,
            type: [],

            is_school: 3,

            activeIndex: '1',
            menuItems: [
                { label: 'Báo cáo Emis đầu năm' },
                { label: 'Báo cáo Emis cuối năm' }
            ],

            tableData: [],
            PrimarySchool: []
        }
    },

    methods: {
        handleMenuItemClick(index) {
            this.activeIndex = index.toString()
            if (this.activeIndex === '0') {
                this.$router.push('/Report-to-the-ministry')
            }
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.grade_level ? [this.grade_level] : [],
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        async handleSearch() {
            try {
                showLoading()
                const request_data = {
                    idSo: this.auth_user.ma_so,
                    capHoc: this.grade_level,
                    maDonVis: this.unit_education,
                    maTruongHocs: this.school,
                    type: 1,
                    namHoc: this.selectedYearFromStore,
                    start: this.start,
                    limit: this.limit
                }

                const response = await sendRequest(
                    Api.bao_cao.bao_cao_emis.bao_cao_dau_nam.danh_sach,
                    request_data,
                    this.request_headers
                )

                if (response) {
                    const type = response.rc === 0 ? 'success' : 'error'

                    showNotication(this, type, response.rd)
                    this.tableData = response.data.map((item) => item[0])
                    this.total_rows = response.total
                    hideLoading()
                }
            } catch (error) {
                hideLoading()
            }
        },
        onChangeGradeLevel() {
            if (this.is_school === 6) {
                this.get_list_school()
                this.school = []
            }
            this.handleSearch()
            this.detailed_report = 1
        },
        onChangeUnitEducation() {
            if (this.is_school === 6) {
                this.get_list_school()
                this.school = []
            }
        }
    },
    mounted() {
        this.handleSearch()
    },
    watch: {
        is_school: {
            handler(newVal) {
                if (newVal === 3) {
                    this.school = []
                }
                if (newVal === 6) {
                    this.get_list_school()
                }
            },
            immediate: true
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_grade_level() {
            return getFromSessionStorage('list_grade_level')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
.el-menu-item {
    height: 45px;
    line-height: 45px;
    background: #f5f5f5;
    color: black;
    border-top: 1px solid #ddd;
    font-size: 14px;
}

.el-menu.el-menu--horizontal {
    margin-left: -20px;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 1.5px solid #186ab2;
    color: #186ab2 !important;
}
</style>
