<template>
    <div>
        <ToolBar
            :menu="'Kết chuyển dữ liệu lên Bộ'"
            :content="'Cấu hình duyệt đẩy dữ liệu'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    :btnToolBar="true"
                    @click="openModalAddNew"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Loại đơn vị </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_type"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="unit_type"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        @change="get_list_school"
                        v-model="unit_education"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Trường học </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="1" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Thao tác</th>
                    <th>Loại đơn vị</th>
                    <th>Đơn vị quản lý</th>
                    <th v-if="unit_type != 2">Mã trường</th>
                    <th v-if="unit_type != 2">Trường học</th>
                    <th v-if="unit_type != 2">Trường đẩy lên bộ</th>
                    <th v-if="unit_type != 3">Phòng đẩy lên bộ</th>
                </tr>
            </template>

            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="25">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>
                        <CustomToolTip content="Xóa">
                            <img
                                :src="iconDelete"
                                class="icon"
                                @click="openModalDelete(item.id)"
                            />
                        </CustomToolTip>
                    </td>
                    <td class="text-left">
                        {{ item.loaiDonVi === 3 ? 'Trường' : 'Phòng' }}
                    </td>
                    <td class="text-left">{{ item.tenDonVi }}</td>
                    <td class="text-left" v-if="unit_type != 2">
                        {{ item.maTruongHoc }}
                    </td>
                    <td class="text-left" v-if="unit_type != 2">
                        {{ item.tenTruongHoc }}
                    </td>
                    <td v-if="unit_type != 2">
                        <img
                            :src="iconCheck"
                            class="icon"
                            v-if="item.truongDuocNopLenBo === true"
                        />
                    </td>
                    <td v-if="unit_type != 3">
                        <img
                            :src="iconCheck"
                            class="icon"
                            v-if="item.phongDuocNopLenBo === true"
                        />
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
        />
        <ModalDelete
            :closeModalDelete="closeModalDelete"
            :deleteVisible="deleteVisible"
            @handleSearch="handleSearch"
            :idDelete="idDelete"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomToolTip from '@/components/CustomToolTip.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import iconCheck from '@/assets/img/icon-table/check.svg'
import ModalAddNew from './modal_add.vue'
import ModalDelete from './modal_delete.vue'

import { list_unit_type } from '@/mock_data/cau-hinh-duyet-day-du-lieu'
export default {
    name: 'DataTransferSchedule',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        CustomToolTip,
        ESelect,
        CustomTable,
        CustomPagination,
        ModalAddNew,
        ModalDelete
    },
    data() {
        return {
            iconDelete,
            iconCheck,
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: [],

            list_unit_type: list_unit_type,
            unit_type: [],

            tableData: [],
            addNewVisible: false,

            deleteVisible: false,
            idDelete: null
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalDelete(id) {
            this.idDelete = id
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        async get_list_school() {
            this.school = []

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        async handleSearch() {
            try {
                showLoading()
                const request_data = {
                    capHocs: this.selectedGradeLevelFromStore,
                    start: this.start,
                    limit: this.limit,
                    loaiDonVis: this.unit_type,
                    maDonVis: this.unit_education,
                    maTruongs: this.school
                }
                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.cau_hinh_duyet_day_du_lieu
                        .danh_sach,
                    request_data,
                    this.request_headers
                )

                this.total_rows = response.total
                this.tableData = response.rows
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 15px;
    cursor: pointer;
}
</style>
