<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-100">Đơn vị</th>
                <th colspan="12">Phòng học</th>
                <th colspan="12">Phòng học nhờ, mượn</th>
                <th colspan="12">Phòng học bộ môn</th>
            </tr>
            <tr>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
            </tr>
            <tr>
                <th
                    v-for="(header, key) in list_th"
                    :key="key"
                    :rowspan="header.rowspan"
                    class="min-w-100"
                >
                    {{ header.label }}
                </th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>

            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left min-width">
                    {{ item['H3'] }}
                </td>

                <td v-for="td in list_td" :key="td.index">
                    {{ item[3][td] }}
                </td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportEducationBySchoolType',

    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            list_th: [
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 }
            ],
            list_td: [
                'E4',
                'F4',
                'G4',
                'H4',
                'I4',
                'J4',
                'K4',
                'L4',
                'M4',
                'N4',
                'O4',
                'P4',
                'E5',
                'F5',
                'G5',
                'H5',
                'I5',
                'J5',
                'K5',
                'L5',
                'M5',
                'N5',
                'O5',
                'P5',
                'E6',
                'F6',
                'G6',
                'H6',
                'I6',
                'J6',
                'K6',
                'L6',
                'M6',
                'N6',
                'O6',
                'P6'
            ]
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-100 {
    min-width: 100px;
}
</style>
