<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới quận huyện'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <label>Mã quận huyện <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="code"
                            @input="onChangeHandler('code', $event)"
                            :textError="err_code"
                        >
                        </CustomInput>
                    </div>
                    <span v-if="err_code.length > 0" class="error">
                        {{ err_code }}
                    </span>
                </div>
                <div class="col-sm-4">
                    <label>Tên quận huyện: <span class="error">*</span></label>
                    <div>
                        <CustomInput
                            v-model="name"
                            @input="onChangeHandler('name', $event)"
                            :textError="err_name"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleAddNew"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { validateFields, handlerError } from '@/utils/validator'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalAdd',
    data() {
        return {
            name: '',

            code: '',

            err_name: '',

            err_code: '',

            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function
    },
    components: { CustomButton, CustomModal, CustomInput },
    methods: {
        handleClearData() {
            this.errModal = false
            this.name = ''
            this.err_name = ''

            this.code = ''
            this.err_code = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                name: 'err_name',

                code: 'err_code'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async handleAddNew() {
            const fieldsToValidate = [
                {
                    value: this.name,
                    errorField: 'err_name'
                },

                {
                    value: this.code,
                    errorField: 'err_code'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                showLoading()
                const request_data = {
                    ma: this.code,
                    maTinhThanh: this.auth_user.ma_don_vi,
                    ten: this.name
                }
                try {
                    const response = await sendRequest(
                        Api.super_admin.quan_huyen.them_moi,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        showNotication(this, 'success', response.message)
                        this.handleCloseModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.message)
                    }
                    hideLoading()
                } catch (error) {
                    hideLoading()
                    if (error.response?.data.rd) {
                        showNotication(this, 'error', error.response?.data.rd)
                    }
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}
</style>
