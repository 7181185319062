<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-100">Đơn vị</th>
                <th colspan="32">NHÀ TRẺ</th>
                <th colspan="32">MẪU GIÁO</th>
            </tr>
            <tr>
                <th colspan="16">Phòng học (nuôi,dạy, chăm sóc trẻ)</th>
                <th colspan="16">Phòng học nhờ, mượn</th>
                <th colspan="16">Phòng học</th>
                <th colspan="16">Phòng học nhờ, mượn</th>
            </tr>
            <tr>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Dân lập</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Dân lập</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Dân lập</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Dân lập</th>
            </tr>
            <tr>
                <th
                    v-for="(header, key) in list_th"
                    :key="key"
                    :rowspan="header.rowspan"
                    class="min-w-100"
                >
                    {{ header.label }}
                </th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left min-width">
                    {{ item['H3'] }}
                </td>

                <td v-for="td in list_td" :key="td.index">
                    {{ item[3][td] }}
                </td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportEducationBySchoolType',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            list_th: [
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 }
            ],
            list_td: [
                'E5',
                'F5',
                'G5',
                'H5',
                'I5',
                'J5',
                'K5',
                'L5',
                'M5',
                'N5',
                'O5',
                'P5',
                'Q5',
                'R5',
                'S5',
                'T5',
                'E6',
                'F6',
                'G6',
                'H6',
                'I6',
                'J6',
                'K6',
                'L6',
                'M6',
                'N6',
                'O6',
                'P6',
                'Q6',
                'R6',
                'S6',
                'T6',
                'E16',
                'F16',
                'G16',
                'H16',
                'I16',
                'J16',
                'K16',
                'L16',
                'M16',
                'N16',
                'O16',
                'P16',
                'Q16',
                'R16',
                'S16',
                'T16',
                'E17',
                'F17',
                'G17',
                'H17',
                'I17',
                'J17',
                'K17',
                'L17',
                'M17',
                'N17',
                'O17',
                'P17',
                'Q17',
                'R17',
                'S17',
                'T17'
            ]
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-100 {
    min-width: 100px;
}
</style>
