<template>
    <div>
        <ToolBar
            :hideToggle="true"
            :hideZoom="true"
            :menu="'Tra cứu kết quả học tập'"
            :content="'Thông tin kết quả học tập'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Quay lại"
                    :btnToolBar="true"
                    @click="navigateViewLockUpAcademiResults"
                />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <CustomTab
                    :initialTab="active_tab_generalInfomation"
                    :tabs="list_tab_generalInfomation"
                    :hideHr="false"
                />
                <div class="form-update mt-3">
                    <GeneralInformation
                        :dataGeneralInformation="dataGeneralInformation"
                    />
                </div>

                <div class="mt-3">
                    <CustomTab
                        :initialTab="active_tab_academicResults"
                        :tabs="list_tab_academicResults"
                        :hideHr="false"
                    />

                    <AcademicResults :dataAcademicResults="dataAcademicResults" />
                </div>
            </template>
        </FormLockUp>
    </div>
</template>

<script>
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { getFromSessionStorage } from '@/utils/session'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import FormLockUp from '@/components/FormLockUp.vue'
import { path } from '@/router/path'
import GeneralInformation from './general_information.vue'
import AcademicResults from './academic_results.vue'
import CustomTab from '@/components/CustomTab.vue'
import { hideLoading, showLoading } from '@/utils/loading'

export default {
    name: 'UpdateLockUpStudent',
    components: {
        ToolBar,
        CustomButton,
        CustomTab,
        FormLockUp,
        GeneralInformation,
        AcademicResults
    },
    data() {
        return {
            errModal: false,

            dataGeneralInformation: {},
            dataAcademicResults: {},
            active_tab_generalInfomation: 1,
            list_tab_generalInfomation: [{ number: 1, label: 'Thông tin chung' }],

            active_tab_academicResults: 1,
            list_tab_academicResults: [{ number: 1, label: 'Thông tin khác' }]
        }
    },

    methods: {
        navigateViewLockUpAcademiResults() {
            this.$router.push({ path: path.lock_up.academiResults.home })
        },

        async get_academic_results() {
            try {
                showLoading()
                const request_params = {
                    id: this.$route.params.id
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_kqht.chi_tiet_kqht,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response) {
                    hideLoading()
                    this.dataAcademicResults = response.kqhtHocSinh
                    this.dataGeneralInformation = response.kqhtHocSinh
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_academic_results()
    },
    computed: {
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    },
    created() {}
}
</script>
<style scoped></style>
