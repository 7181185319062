<template>
    <CustomModal
        :is-modal-visible="trainingVisible"
        :modal-title="'Quá trình đào tạo bồi dưỡng'"
        :close-function="closeModalTraining"
        :size="'xl'"
        :err-modal="errModal"
        :scrollable="true"
    >
        <template v-slot:content>
            <label class="label">Quá trình bồi dưỡng</label>
            <CustomTable
                :column-form="2"
                :hide-btn-table="true"
                :hidePagi="true"
                :tableModal="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Tên trường<span class="error">*</span></th>
                        <th>
                            Chuyên ngành đào tạo, bồi dưỡng<span class="error"
                                >*</span
                            >
                        </th>
                        <th>Từ ngày - đến ngày<span class="error">*</span></th>
                        <th>Hình thức<span class="error">*</span></th>
                        <th>Văn bằng, chứng chỉ<span class="error">*</span></th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataTraining.length === 0">
                        <tr>
                            <td colspan="10">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in dataTraining" :key="index">
                        <td>
                            {{ index + 1 }}
                        </td>

                        <td>
                            <CustomInput
                                v-model="item.TenTruong"
                                placeholder=""
                                :disabled="true"
                            >
                            </CustomInput>
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_expertise"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaNhomChuyenNganh"
                            />
                        </td>

                        <td>
                            <div style="display: flex">
                                <CustomInput
                                    v-model="item.TuThangNam"
                                    placeholder=""
                                    :disabled="true"
                                >
                                </CustomInput>
                                <div class="input-group-addon">đến</div>
                                <CustomInput
                                    v-model="item.DenThangnam"
                                    placeholder=""
                                    :disabled="true"
                                >
                                </CustomInput>
                            </div>
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_methods"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaHinhThucDaoTao"
                            />
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_certificate"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaVbChungChi"
                            />
                        </td>
                    </tr>
                </template>
            </CustomTable>

            <label class="label mt-30"
                >Quá trình bồi dưỡng nâng cao năng lực sư phạm</label
            >
            <CustomTable
                :column-form="2"
                :hide-btn-table="true"
                :hidePagi="true"
                :tableModal="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Khóa đào tạo bồi dưỡng</th>
                        <th>Đơn vị bồi dưỡng</th>
                        <th>Đơn vị tổ chức</th>
                        <th>Thời lượng bồi dưỡng</th>
                        <th>Loại hình bồi dưỡng</th>
                        <th>Hình thức</th>
                        <th>Kết quả đạt được</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template
                        v-if="
                            pedagogical_competence_enhancement_training.length ===
                            0
                        "
                    >
                        <tr>
                            <td colspan="10">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr
                        v-for="(
                            item, index
                        ) in pedagogical_competence_enhancement_training"
                        :key="index"
                    >
                        <td>
                            {{ index + 1 }}
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_course_code"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaKhoaDaoTao"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_unit"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaDonViBoiDuong"
                            />
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_organizing_unit"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaDonViToChuc"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_duration"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaThoiLuongBoiDuong"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_type"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaLoaiHinhBoiDuong"
                            />
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_format"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaHinhThuc"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_out_come"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaKetQua"
                            />
                        </td>
                    </tr>
                </template>
            </CustomTable>

            <label class="label mt-30"
                >Quá trình đào tạo năng lực ngoại ngữ</label
            >
            <CustomTable
                :column-form="2"
                :hide-btn-table="true"
                :hidePagi="true"
                :tableModal="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Khóa đào tạo bồi dưỡng</th>
                        <th>Đơn vị bồi dưỡng</th>
                        <th>Đơn vị tổ chức</th>
                        <th>Thời lượng bồi dưỡng</th>
                        <th>Loại hình bồi dưỡng</th>
                        <th>Hình thức</th>
                        <th>Kết quả đạt được</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template
                        v-if="
                            language_proficiency_enhancement_training.length === 0
                        "
                    >
                        <tr>
                            <td colspan="10">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr
                        v-for="(
                            item, index
                        ) in language_proficiency_enhancement_training"
                        :key="index"
                    >
                        <td>
                            {{ index + 1 }}
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_course_code"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaKhoaDaoTao"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_unit"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaDonViBoiDuong"
                            />
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_organizing_unit"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaDonViToChuc"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_duration"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaThoiLuongBoiDuong"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_training_type"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaLoaiHinhBoiDuong"
                            />
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_format"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaHinhThuc"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_out_come"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaKetQua"
                            />
                        </td>
                    </tr>
                </template>
            </CustomTable>
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import CustomModal from '@/components/CustomModal.vue'
import CustomTable from '@/components/CustomTable.vue'
import { getFromSessionStorage } from '@/utils/session'
import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'ModalTraining',
    data() {
        return {
            errModal: false,
            pedagogical_competence_enhancement_training: [],
            language_proficiency_enhancement_training: []
        }
    },
    components: {
        CustomModal,
        CustomTable,
        ESelect,
        CustomInput
    },
    props: {
        trainingVisible: {
            type: Boolean,
            required: true
        },
        closeModalTraining: Function,
        dataTraining: {
            type: Array,
            required: true
        },
        idDetailTeacher: {
            type: Number
        }
    },
    watch: {
        trainingVisible(newValue) {
            if (newValue === true) {
                this.get_list_pedagogical_competence_enhancement_training()
                this.get_list_language_proficiency_enhancement_training()
            }
        }
    },

    methods: {
        async get_list_pedagogical_competence_enhancement_training() {
            const request_data = {
                id: this.$route.params.id,
                type: 2
            }
            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_can_bo_giao_vien.qua_trinh,
                request_data,
                this.request_headers
            )
            this.pedagogical_competence_enhancement_training = response.rows
        },

        async get_list_language_proficiency_enhancement_training() {
            const request_data = {
                id: this.$route.params.id,
                type: 1
            }
            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_can_bo_giao_vien.qua_trinh,
                request_data,
                this.request_headers
            )
            this.language_proficiency_enhancement_training = response.rows
        }
    },
    mounted() {},
    computed: {
        list_expertise() {
            return getFromSessionStorage('DM_CHUYEN_MON')
        },
        list_training_methods() {
            return getFromSessionStorage('DM_HINH_THUC_DAO_TAO')
        },
        list_certificate() {
            return getFromSessionStorage('DM_VAN_BAN_CHUNG_CHI')
        },
        list_training_course_code() {
            return getFromSessionStorage('MA_KHOA_DAO_TAO_SU_PHAM')
        },
        list_training_unit() {
            return getFromSessionStorage('MA_DON_VI')
        },
        list_organizing_unit() {
            return getFromSessionStorage('MA_DON_VI_TO_CHUC')
        },
        list_training_duration() {
            return getFromSessionStorage('MA_THOI_LUONG_BOI_DUONG')
        },
        list_training_type() {
            return getFromSessionStorage('MA_LOAI_HINH_BOI_DUONG')
        },
        list_format() {
            return getFromSessionStorage('MA_HINH_THUC')
        },
        list_out_come() {
            return getFromSessionStorage('MA_KET_QUA_SU_PHAM')
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
}
.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.label {
    font-weight: 600;
}
.mt-30 {
    margin-top: 30px;
}
</style>
