<template>
    <div>
        <CustomTable :column-form="2" :tab="true" :hidePagi="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. CBQL -->
                <tr v-if="active_tab === 1">
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Địa phương</th>
                    <th rowspan="2">Cán bộ quản lý</th>
                    <th colspan="3">Hiệu trưởng</th>
                    <th colspan="3">Phó Hiệu trưởng</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">HT Nhà trẻ</th>
                    <th rowspan="1">HT mẫu giáo, mầm non</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">PHT Nhà trẻ</th>
                    <th rowspan="1">PHT mẫu giáo, mầm non</th>
                </tr>

                <!-- 2. GV chuẩn NN -->
                <tr v-if="active_tab === 2">
                    <th rowspan="4">STT</th>
                    <th rowspan="4">Địa phương</th>
                    <th rowspan="4">Tổng số GV</th>
                    <th colspan="10">
                        Giáo viên theo đánh giá chuẩn nghề nghiệp
                    </th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th colspan="5">GV Nhà trẻ</th>
                    <th colspan="5">GV Mẫu giao</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="2">Tổng</th>
                    <th colspan="4">Chia ra</th>
                    <th rowspan="2">Tổng</th>
                    <th colspan="4">Chia ra</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Tốt</th>
                    <th rowspan="1">Khá</th>
                    <th rowspan="1">Đạt</th>
                    <th rowspan="1">Chưa đạt chuẩn</th>
                    <th rowspan="1">Tốt</th>
                    <th rowspan="1">Khá</th>
                    <th rowspan="1">Đạt</th>
                    <th rowspan="1">Chưa đạt chuẩn</th>
                </tr>

                <!-- 3. GV biến động -->
                <tr v-if="active_tab === 3">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Địa phương</th>
                    <th colspan="5">Giáo viên nghỉ hưu trong năm học</th>
                    <th colspan="5">Giáo viên tuyển mới trong năm học</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="2">Tổng số</th>
                    <th colspan="2">GV Nhà trẻ</th>
                    <th colspan="2">GV Mẫu giáo</th>
                    <th rowspan="2">Tổng số</th>
                    <th colspan="2">GV Nhà trẻ</th>
                    <th colspan="2">GV Mẫu giáo</th>
                </tr>
                <tr v-if="active_tab === 3">
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Trong đó Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Trong đó Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Trong đó Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Trong đó Nữ</th>
                </tr>

                <!-- 4. Nhân viên -->
                <tr v-if="active_tab === 4">
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Địa phương</th>
                    <th colspan="3">Nhân viên</th>
                </tr>
                <tr v-if="active_tab === 4">
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">NV Nhà trẻ</th>
                    <th rowspan="1">NV Mẫu giáo</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="active_tab === 1">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>
                        <td>{{ item['F40'] }}</td>
                        <td>{{ item['F41'] }}</td>
                        <td>{{ item['F42'] }}</td>
                        <td>{{ item['F43'] }}</td>
                        <td>{{ item['F44'] }}</td>
                        <td>{{ item['F45'] }}</td>
                        <td>{{ item['F46'] }}</td>
                    </tr>
                </template>
                <template v-if="active_tab === 2">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>
                        <td>{{ item['F47'] }}</td>
                        <td>{{ item['F48'] }}</td>
                        <td>{{ item['F50'] }}</td>
                        <td>{{ item['F51'] }}</td>
                        <td>{{ item['F52'] }}</td>
                        <td>{{ item['F53'] }}</td>
                        <td>{{ item['F54'] }}</td>
                        <td>{{ item['F56'] }}</td>
                        <td>{{ item['F57'] }}</td>
                        <td>{{ item['F58'] }}</td>
                        <td>{{ item['F59'] }}</td>
                    </tr>
                </template>

                <template v-if="active_tab === 3">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>
                        <td>{{ item['F60'] }}</td>
                        <td>{{ item['F61'] }}</td>
                        <td></td>
                        <td>{{ item['F62'] }}</td>
                        <td></td>
                        <td>{{ item['F63'] }}</td>
                        <td>{{ item['F64'] }}</td>
                        <td></td>
                        <td>{{ item['F65'] }}</td>
                        <td></td>
                    </tr>
                </template>
                <template v-if="active_tab === 4">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>

                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>
                        <td>{{ item['F66'] }}</td>
                        <td>{{ item['F67'] }}</td>

                        <td>{{ item['F68'] }}</td>
                    </tr>
                </template>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportPersonnel',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'CBQL' },
                { number: 2, label: 'GV chuẩn NN' },
                { number: 3, label: 'GV biến động' },
                { number: 4, label: 'Nhân viên' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
