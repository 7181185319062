<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th>STT</th>

                <th>Đơn vị</th>
                <th>Tổng số</th>
                <th>Văn thư</th>
                <th>Kế toán</th>
                <th>Y tế</th>
                <th>Thủ quỹ</th>
                <th>Thư viện</th>
                <th>Thiết bị, thí nghiệm</th>
                <th>C.nghệ thông tin</th>
                <th>Hỗ trợ khuyết tật</th>
                <th>Giáo vụ</th>
                <th>Khác</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td>{{ item.tongNhanVien }}</td>
                <td>{{ item.nhanVienVanThu }}</td>
                <td>{{ item.nhanVienkeToan }}</td>
                <td>{{ item.nhanVienYte }}</td>
                <td>{{ item.nhanVienThuQuy }}</td>
                <td>{{ item.nhanVienThuVien }}</td>
                <td>
                    {{
                        item.slNhanVienThiNghiem && item.slNhanVienThietBi
                            ? item.slNhanVienThietBi + item.slNhanVienThiNghiem
                            : 0
                    }}
                </td>
                <td>{{ item.nhanVienCntt }}</td>
                <td>{{ item.nhanVienHoTroKTat }}</td>
                <td>{{ item.nhanVienGiaoVu }}</td>
                <td>{{ item.nhanVienKhac }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'EmployeeCountStatistics',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
