export const list_school_block = [
    { value: 13, label: 'Trẻ từ 3-12 tháng' },
    { value: 14, label: 'Trẻ từ 13-24 tháng' },
    { value: 15, label: 'Trẻ từ 25-36 tháng' },
    { value: 16, label: 'Trẻ từ 3-4 tuổi' },
    { value: 17, label: 'Trẻ từ 4-5 tuổi' },
    { value: 18, label: 'Trẻ từ 5-6 tuổi' },
    { value: 1, label: 'Khối 1' },
    { value: 2, label: 'Khối 2' },
    { value: 3, label: 'Khối 3' },
    { value: 4, label: 'Khối 4' },
    { value: 5, label: 'Khối 5' },
    { value: 6, label: 'Khối 6' },
    { value: 7, label: 'Khối 7' },
    { value: 8, label: 'Khối 8' },
    { value: 9, label: 'Khối 9' },
    { value: 10, label: 'Khối 10' },
    { value: 11, label: 'Khối 11' },
    { value: 12, label: 'Khối 12' }
]
