<template>
    <div>
        <ToolBar :menu="'Báo cáo'" :content="'Tổng hợp dữ liệu báo cáo'">
            <template v-slot:form>
                <CustomButton
                    label="Kiểm tra quá trình tổng hợp"
                    :btnToolBar="true"
                    @click="handleCheckTheAggregationProcess"
                />
                <CustomButton
                    label="Tổng hợp"
                    :btnToolBar="true"
                    @click="handleAggregate"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Trường học <span class="error">*</span> :</label
                        >
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                        @input="onChangeHandler('school', $event)"
                        :class="{
                            'error-select': err_school.length > 0
                        }"
                    />
                    <div v-if="err_school.length > 0" class="error-select">
                        {{ err_school }}
                    </div>
                </div>
            </div>
        </FormSearch>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { convertListData } from '@/utils'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { handlerError, validateFields } from '@/utils/validator'
import { showNotication } from '@/utils/notification'
export default {
    name: 'AggregateReportData',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect
    },
    data() {
        return {
            unit_education: [],
            list_school: [],
            school: '',

            err_school: ''
        }
    },

    methods: {
        async get_list_school() {
            this.school = ''

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                school: 'err_school',
                data_type: 'err_data_type'
            }

            handlerError(this, source, newValue, errorFields)

            // if (source in errorFields) {
            //     handlerError(this, source, newValue, errorFields)

            //     this.errModal = !!newValue || !!this[errorFields[source]]
            // } else {
            //     this.errModal = !!newValue
            // }
        },
        async handleCheckTheAggregationProcess() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.school,
                        errorField: 'err_school'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    showLoading()

                    const request_data = {
                        namHoc: this.selectedYearFromStore,
                        maTruongHoc: this.school
                    }

                    const response = await sendRequest(
                        Api.bao_cao.tong_hop_du_lieu_bao_cao
                            .kiem_tra_qua_trinh_tong_hop,
                        request_data,
                        this.request_headers,
                        null
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                    hideLoading()
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleAggregate() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.school,
                        errorField: 'err_school'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    showLoading()

                    const request_data = {
                        namHoc: this.selectedYearFromStore,
                        maTruongHoc: this.school
                    }

                    const response = await sendRequest(
                        Api.bao_cao.tong_hop_du_lieu_bao_cao.tong_hop,
                        request_data,
                        this.request_headers,
                        null
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                    hideLoading()
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    watch: {
        selectedGradeLevelFromStore(newVal) {
            if (newVal) {
                this.school = ''
                this.get_list_school()
            }
        }
    },
    mounted() {
        this.get_list_school()
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },

        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
}
.error-select {
    margin-top: -20px;
    color: red;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
