<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Tra cứu kết quả học tập'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                    :btnToolBar="true"
                />

                <CustomButton
                    label="Xuất Excel"
                    :icon="'el-icon-download'"
                    :btnToolBar="true"
                    @click="handleExportExcel"
                    :disabled="tableData.length === 0"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-2" v-if="isRoleSuperAdmin()">
                    <div class="label-wrapper">
                        <label class="control-label">Tỉnh thành:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="isRoleSuperAdmin()">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_province"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="province"
                        @change="onChangeProvince"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool()"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Khối học/Nhóm lớp:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school_block"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school_block"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool()"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Từ khóa tìm kiếm:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="key_search"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Giai đoạn:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_stage"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="stage"
                    />
                </div>
                <div v-if="isRoleSuperAdmin()" class="col-md-4"></div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Nội dung hiển thị
                            <span class="error">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_content_displayed"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="content_displayed"
                    />
                </div>

                <div v-if="!isRoleSuperAdmin()" class="col-md-8"></div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tìm kiếm nâng cao:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_advanced_search"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="advanced_search"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hthanhCtrinhLhoc')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">HT c.trình lớp học:</label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hthanhCtrinhLhoc')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_complete"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="curriculum_for_course"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hoanThanhChuongTrinhMamMon')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">HT c.trình MN:</label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hoanThanhChuongTrinhMamMon')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_complete"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="curriculum_for_kindergarten"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hoanThanhChuongTrinhTieuHoc')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">HT c.trình TH:</label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hoanThanhChuongTrinhTieuHoc')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_complete"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="curriculum_for_primary_school"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('khamSkDky')">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Khám sức khỏe định kỳ:</label
                        >
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('khamSkDky')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="curriculum_for_periodic_health_check"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lamQuenThoc')">
                    <div class="label-wrapper">
                        <label class="control-label">Làm quen tin học:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lamQuenThoc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="computer_science_introductory_course"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lenLop')">
                    <div class="label-wrapper">
                        <label class="control-label">Lên lớp:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lenLop')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="promotion_to_next_grade"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('phoiIchayHhap')">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Có bệnh về ỉa chảy, hô hấp:</label
                        >
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('phoiIchayHhap')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="diarrhea_and_respiratory_illness"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('suyDduongTheTcoi')"
                >
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Suy dinh dưỡng thể thấp còi:</label
                        >
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('suyDduongTheTcoi')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="malnutrition_stunted_growth"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('theoDoiBdoCcao')">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Theo dõi biểu đồ chiều cao:</label
                        >
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('theoDoiBdoCcao')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="height_chart_monitoring"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('theoDoiBdoCnang')">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Theo dõi biểu đồ cân nặng:
                        </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('theoDoiBdoCnang')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="weight_chart_monitoring"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maHocLuc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Học lực: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maHocLuc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_academic_performance"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="academic_performance"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maHanhKiem')">
                    <div class="label-wrapper">
                        <label class="control-label"> Hạnh kiểm: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maHanhKiem')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_conduct"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="conduct"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDanhHieu')">
                    <div class="label-wrapper">
                        <label class="control-label">Danh hiệu: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDanhHieu')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_achievements"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="achievements"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('renLuyenLai')">
                    <div class="label-wrapper">
                        <label class="control-label">Rèn luyện lại: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('renLuyenLai')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="remedial_training"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('soNgayNghi')">
                    <div class="label-wrapper">
                        <label class="control-label">Số ngày nghỉ: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('soNgayNghi')">
                    <CustomInput
                        v-model="absent_days"
                        placeholder="Nhập ..."
                        :showCloseIcon="true"
                        :type="'Number'"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('thiLai')">
                    <div class="label-wrapper">
                        <label class="control-label">Thi lại: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('thiLai')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="retake_exam"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('totNghiep')">
                    <div class="label-wrapper">
                        <label class="control-label">Tốt nghiệp: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('totNghiep')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="graduation"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maLoaiTotNghiep')">
                    <div class="label-wrapper">
                        <label class="control-label">Loại tốt nghiệp: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maLoaiTotNghiep')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_graduation_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="graduation_type"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('duXetTotNghiep')">
                    <div class="label-wrapper">
                        <label class="control-label">Dự xét tốt nghiệp: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('duXetTotNghiep')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="graduation_eligibility"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('moetCode')">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Mã định danh bộ GD&ĐT:
                        </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('moetCode')">
                    <CustomInput
                        v-model="ministry_of_education_identification_code"
                        placeholder="Nhập ..."
                        :showCloseIcon="true"
                        :type="'Number'"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('trangThai')">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trangThai')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('danhGiaKqGiaoDuc')"
                >
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Đánh giá kết quả giáo dục:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('danhGiaKqGiaoDuc')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_educational_outcome_assessment"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="educational_outcome_assessment"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('tenLopHoc')">
                    <div class="label-wrapper">
                        <label class="control-label">Tên lớp học </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('tenLopHoc')">
                    <CustomInput
                        v-model="class_name"
                        placeholder="Nhập ..."
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('isSuyDduongTheNheCan')"
                >
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Suy dinh dưỡng thể nhẹ cân:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('isSuyDduongTheNheCan')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="malnutrition_underweight"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('isSuyDduongTheCoiCoc')"
                >
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Suy dinh dưỡng thể còi cọc:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('isSuyDduongTheCoiCoc')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="severe_malnutrition"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('beoPhi')">
                    <div class="label-wrapper">
                        <label class="control-label">Béo phì: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('beoPhi')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="obesity"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form">
            <template v-slot:btn-table>
                <span class="count" v-if="isCheckedCount > 0">
                    <b>Đã chọn {{ isCheckedCount }} bản ghi</b>
                </span>
                <CustomButton
                    label="Đẩy lên Bộ"
                    :btnToolBar="true"
                    @click="openModalPushUp"
                />
                <CustomButton
                    label="Xóa"
                    :btnToolBar="true"
                    @click="openModalDelete"
                />
            </template>
            <template v-slot:header>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th>STT</th>
                    <th>Xem điểm</th>
                    <th v-for="field in tableHeader" :key="field.value">
                        {{ field.title }}
                    </th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckboxTable(item)"
                        />
                    </td>
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td>
                        <img
                            :src="iconView"
                            class="icon"
                            @click="openModalView(item)"
                        />
                    </td>
                    <td class="text-left" v-if="hasProperty('maTruongHoc', item)">
                        {{ item.maTruongHoc }}
                    </td>
                    <td
                        class="text-left"
                        v-if="hasProperty('tenTruongHoc', item)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>

                    <td class="text-left" v-if="hasProperty('capHoc', item)">
                        {{ item.capHoc }}
                    </td>

                    <td class="text-left" v-if="hasProperty('khoiHoc', item)">
                        {{ item.khoiHoc }}
                    </td>

                    <td class="text-left" v-if="hasProperty('tenLopHoc', item)">
                        {{ item.tenLopHoc }}
                    </td>

                    <td
                        class="text-navigate text-left"
                        @click="navigateViewLockUpAcademiResults(item)"
                        v-if="hasProperty('tenHocSinh', item)"
                    >
                        {{ item.tenHocSinh }}
                    </td>
                    <td
                        class="text-navigate text-left"
                        @click="navigateViewLockUpAcademiResults(item)"
                        v-if="hasProperty('maHocSinh', item)"
                    >
                        {{ item.maHocSinh }}
                    </td>
                    <td v-if="hasProperty('moetCode', item)">
                        {{ item.moetCode }}
                    </td>
                    <td v-if="hasProperty('trangThai', item)">
                        {{ item.trangThai }}
                    </td>
                    <td v-if="hasProperty('diemTongKet', item)">
                        {{ item.diemTongKet }}
                    </td>
                    <td v-if="hasProperty('namHoc', item)">
                        {{ item.namHoc }}-{{ item.namHoc + 1 }}
                    </td>
                    <td v-if="hasProperty('hocKy', item)">
                        {{ item.hocKy }}
                    </td>
                    <td v-if="hasProperty('giaiDoan', item)">
                        {{ item.giaiDoan }}
                    </td>
                    <td v-if="hasProperty('maHocLuc', item)">
                        {{ item.maHocLuc }}
                    </td>
                    <td v-if="hasProperty('maHanhKiem', item)">
                        {{ item.maHanhKiem }}
                    </td>
                    <td v-if="hasProperty('maDanhHieu', item)">
                        {{ item.maDanhHieu }}
                    </td>
                    <td v-if="hasProperty('hthanhCtrinhLhoc', item)">
                        {{ item.hthanhCtrinhLhoc }}
                    </td>
                    <td v-if="hasProperty('hoanThanhChuongTrinhMamMon', item)">
                        {{ item.hoanThanhChuongTrinhMamMon }}
                    </td>
                    <td v-if="hasProperty('hoanThanhChuongTrinhTieuHoc', item)">
                        {{ item.hoanThanhChuongTrinhTieuHoc }}
                    </td>
                    <td v-if="hasProperty('kthuongCnam', item)">
                        {{ item.kthuongCnam }}
                    </td>
                    <td v-if="hasProperty('kthuongDxuat', item)">
                        {{ item.kthuongDxuat }}
                    </td>
                    <td v-if="hasProperty('lenLop', item)">
                        {{ item.lenLop }}
                    </td>
                    <td v-if="hasProperty('renLuyenLai', item)">
                        {{ item.renLuyenLai }}
                    </td>
                    <td v-if="hasProperty('thiLai', item)">
                        {{ item.thiLai }}
                    </td>
                    <td v-if="hasProperty('duXetTotNghiep', item)">
                        {{ item.duXetTotNghiep }}
                    </td>
                    <td v-if="hasProperty('totNghiep', item)">
                        {{ item.totNghiep }}
                    </td>
                    <td v-if="hasProperty('maLoaiTotNghiep', item)">
                        {{ item.maLoaiTotNghiep }}
                    </td>
                    <td v-if="hasProperty('soNgayNghi', item)">
                        {{ item.soNgayNghi }}
                    </td>
                    <td v-if="hasProperty('kqHocTap', item)">
                        {{ item.kqHocTap }}
                    </td>
                    <td v-if="hasProperty('kqRenLuyen', item)">
                        {{ item.kqRenLuyen }}
                    </td>
                    <td v-if="hasProperty('isKhamSkDky', item)">
                        {{ item.isKhamSkDky }}
                    </td>
                    <td v-if="hasProperty('TvDgtx', item)">
                        {{ item.TvDgtx }}
                    </td>
                    <td v-if="hasProperty('LoaiChungChiNghe', item)">
                        {{ item.LoaiChungChiNghe }}
                    </td>
                    <td v-if="hasProperty('khenThuongCapHuyen', item)">
                        {{ item.khenThuongCapHuyen }}
                    </td>
                    <td v-if="hasProperty('isSuyDduongTheNheCan', item)">
                        {{ item.isSuyDduongTheNheCan }}
                    </td>
                    <td v-if="hasProperty('isSuyDduongTheCoiCoc', item)">
                        {{ item.isSuyDduongTheCoiCoc }}
                    </td>
                    <td v-if="hasProperty('beoPhi', item)">
                        {{ item.beoPhi === false ? 'Không' : 'Có' }}
                    </td>
                    <td v-if="hasProperty('suyDduongTheTcoi', item)">
                        {{ item.suyDduongTheTcoi }}
                    </td>
                    <td v-if="hasProperty('theoDoiBdoCcao', item)">
                        {{ item.theoDoiBdoCcao }}
                    </td>
                    <td v-if="hasProperty('theoDoiBdoCnang', item)">
                        {{ item.theoDoiBdoCnang }}
                    </td>
                    <td v-if="hasProperty('phoiIchayHhap', item)">
                        {{ item.phoiIchayHhap }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>

        <ModalPushUp
            :pushUpVisible="pushUpVisible"
            :closeModalPushUp="closeModalPushUp"
            :dataPushUp="selectedItemsTable"
            :stage="stage"
            @handleSearch="handleSearch"
        />

        <ModalDelete
            :deleteVisible="deleteVisible"
            :closeModalDelete="closeModalDelete"
            :dataDelete="selectedItemsTable"
            @handleSearch="handleSearch"
        />

        <ModalView
            :viewVisible="viewVisible"
            :closeModalView="closeModalView"
            :dataView="dataView"
            @handleSearch="handleSearch"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import iconView from '@/assets/img/icon-table/view.svg'
import {
    convertDownloadFileExcel,
    getLabelByArray,
    convertListData,
    updateContentDisplayed,
    filterItemKeysTableData,
    addIsCheckedProperty,
    setInitialContentDisplayed,
    hasProperty,
    calculateColumnFormLength,
    generateDataSearchList,
    countCheckedItems,
    updateSelectedItemsTable,
    filterListSchoolBlock,
    convertNumberToString,
    processUnitEducationList
} from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ModalView from './modal_view.vue'
import ModalDelete from './modal_delete.vue'
import ModalPushUp from './modal_push_up.vue'
import {
    list_content_displayed,
    list_advanced_search,
    list_yes_no,
    list_true_false,
    list_complete,
    list_academic_performance,
    list_conduct,
    list_achievements,
    list_status,
    list_educational_outcome_assessment
} from '@/mock_data/tra-cuu-ket-qua-hoc-tap'
import { list_school_block } from '@/mock_data'
import { getFromSessionStorage } from '@/utils/session'
import { path } from '@/router/path'
import {
    isRoleSuperAdmin,
    isRoleEducationOfficeOrSchool,
    isRoleSchool
} from '@/constants/Roles'
import { showNotication } from '@/utils/notification'
export default {
    name: 'LockUpAcademiResults',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        // TextStatus,
        CustomInput,
        CustomPagination,

        ModalView,
        ModalDelete,
        ModalPushUp
    },
    data() {
        return {
            iconView,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            isCheckedCount: 0,
            selectAllChecked: false,
            selectedItemsTable: [],
            province: '',
            list_unit_education: [],
            unit_education: [],

            list_school_block: list_school_block,
            school_block: [],

            list_school: [],
            school: [],

            key_search: '',

            stage: '',

            list_content_displayed: list_content_displayed,
            content_displayed: [],

            list_advanced_search: list_advanced_search,
            advanced_search: [],

            list_complete: list_complete,
            list_true_false: list_true_false,
            list_yes_no: list_yes_no,

            curriculum_for_course: '', // HT c.trình lớp học
            curriculum_for_kindergarten: '', // HT c.trình MN
            curriculum_for_primary_school: '', // HT c.trình TH
            curriculum_for_periodic_health_check: '', // Khám sức khỏe định kỳ
            computer_science_introductory_course: '', // Làm quen tin học
            promotion_to_next_grade: '', // Lên lớp
            diarrhea_and_respiratory_illness: '', // Có bệnh về ỉa chảy, hô hấp
            malnutrition_stunted_growth: '', // Suy dinh dưỡng thể thấp còi
            height_chart_monitoring: '', // Theo dõi biểu đồ chiều cao
            weight_chart_monitoring: '', // Theo dõi biểu đồ cân nặng
            list_academic_performance: list_academic_performance,
            academic_performance: '', // Học lực
            list_conduct: list_conduct,
            conduct: '', // Hạnh kiểm
            list_achievements: list_achievements,
            achievements: '', // Danh hiệu
            remedial_training: '', // Rèn luyện lại
            absent_days: '', // Số ngày nghỉ
            retake_exam: '', // Thi lại
            graduation: '', // Tốt nghiệp
            graduation_type: '', // Loại tốt nghiệp
            graduation_eligibility: '', // Dự xét tốt nghiệp
            ministry_of_education_identification_code: '', // Mã định danh bộ GD&ĐT
            list_status: list_status,
            status: '', // Trạng thái
            list_educational_outcome_assessment:
                list_educational_outcome_assessment,
            educational_outcome_assessment: '', // Đánh giá kết quả giáo dục
            class_name: '', // Tên lớp học
            malnutrition_underweight: '', // Suy dinh dưỡng thể nhẹ cân
            severe_malnutrition: '', // Suy dinh dưỡng thể còi cọc
            obesity: '', // Béo phì

            tableData: [],
            tableHeader: [],
            column_form: 3,

            deleteVisible: false,
            idDelete: 0,

            pushUpVisible: false,

            uploadVisible: false,

            viewVisible: false,
            dataView: {}
        }
    },

    methods: {
        isRoleSuperAdmin,
        isRoleEducationOfficeOrSchool,
        isRoleSchool,
        hasProperty,
        getLabelByArray,
        shouldShowElement(element) {
            return this.advanced_search.includes(element)
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        navigateViewLockUpAcademiResults(item) {
            this.$router.push({
                path: `${path.lock_up.academiResults.update_academi_results}/${item.tongKetHsId}`
            })
        },
        openModalPushUp() {
            if (this.selectedItemsTable.length === 0) {
                showNotication(this, 'error', 'Vui lòng chọn bản ghi')
                return
            }
            this.pushUpVisible = true
        },
        closeModalPushUp() {
            this.resetCheckedTable()
            this.pushUpVisible = false
        },
        openModalDelete() {
            if (this.selectedItemsTable.length === 0) {
                showNotication(this, 'error', 'Vui lòng chọn bản ghi')
                return
            }
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.resetCheckedTable()
            this.deleteVisible = false
        },
        toggleCheckboxTable(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            this.selectedItemsTable = updateSelectedItemsTable(
                this.selectedItemsTable,
                item
            )
        },
        openModalView(item) {
            this.dataView = item
            this.viewVisible = true
        },
        closeModalView() {
            this.viewVisible = false
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked
                if (
                    this.selectAllChecked &&
                    !this.selectedItemsTable.includes(item)
                ) {
                    this.selectedItemsTable.push(item)
                } else if (!this.selectAllChecked) {
                    this.selectedItemsTable = []
                }
            })
        },
        resetCheckedTable() {
            this.tableData.forEach((item) => {
                item.isChecked = false
            })
            this.selectedItemsTable = []
            this.selectAllChecked = false
        },
        onChangeProvince() {
            this.get_list_unit_education()
            this.unit_education = []
            this.get_list_school()
            this.school = []
        },

        async get_list_unit_education() {
            try {
                const request_params = {
                    start: 0,
                    limit: 9999,
                    maTinhThanh: this.province,
                    check: false
                }

                const response = await sendRequest(
                    Api.internal_api.dm_DonVi,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_unit_education = processUnitEducationList(response.rows)
            } catch (error) {
                console.error('Error fetching unit education list:', error)
            }
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            showLoading()

            if (
                isRoleSuperAdmin() &&
                this.unit_education.length === 0 &&
                this.province.length === 0
            ) {
                hideLoading()
                return
            }
            const request_data = {
                maSo: this.province,
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        syncTableHeaderWithContentDisplay() {
            this.tableHeader = updateContentDisplayed(
                this.list_content_displayed,
                this.content_displayed
            )
        },

        async buildRequestParams() {
            const mappings = [
                {
                    key: 'hthanhCtrinhLhoc',
                    value: this.curriculum_for_course,
                    check: 'includes'
                },
                {
                    key: 'hoanThanhChuongTrinhMamMon',
                    value: this.curriculum_for_kindergarten,
                    check: 'includes'
                },
                {
                    key: 'hoanThanhChuongTrinhTieuHoc',
                    value: this.curriculum_for_primary_school,
                    check: 'includes'
                },
                {
                    key: 'khamSkDky',
                    value: this.curriculum_for_periodic_health_check,
                    check: 'includes'
                },
                {
                    key: 'lamQuenThoc',
                    value: this.computer_science_introductory_course,
                    check: 'includes'
                },
                {
                    key: 'lenLop',
                    value: this.promotion_to_next_grade,
                    check: 'includes'
                },
                {
                    key: 'phoiIchayHhap',
                    value: this.diarrhea_and_respiratory_illness,
                    check: 'includes'
                },
                {
                    key: 'suyDduongTheTcoi',
                    value: this.malnutrition_stunted_growth,
                    check: 'includes'
                },
                {
                    key: 'theoDoiBdoCcao',
                    value: this.height_chart_monitoring,
                    check: 'includes'
                },
                {
                    key: 'theoDoiBdoCnang',
                    value: this.weight_chart_monitoring,
                    check: 'includes'
                },
                {
                    key: 'maHocLuc',
                    value: this.academic_performance,
                    check: 'includes'
                },
                { key: 'maHanhKiem', value: this.conduct, check: 'includes' },
                {
                    key: 'maDanhHieu',
                    value: this.achievements,
                    check: 'includes'
                },
                {
                    key: 'renLuyenLai',
                    value: this.remedial_training,
                    check: 'includes'
                },
                {
                    key: 'soNgayNghi',
                    value: this.absent_days,
                    check: 'includes'
                },

                {
                    key: 'thiLai',
                    value: this.retake_exam,
                    check: 'includes'
                },
                {
                    key: 'totNghiep',
                    value: this.graduation,
                    check: 'includes'
                },
                {
                    key: 'maLoaiTotNghiep',
                    value: this.graduation_type,
                    check: 'includes'
                },
                {
                    key: 'duXetTotNghiep',
                    value: this.graduation_eligibility,
                    check: 'includes'
                },
                {
                    key: 'moetCode',
                    value: this.ministry_of_education_identification_code,
                    check: 'includes'
                },
                {
                    key: 'trangThai',
                    value: this.status,
                    check: 'includes'
                },
                {
                    key: 'danhGiaKqGiaoDuc',
                    value: this.educational_outcome_assessment,
                    check: 'includes'
                },
                {
                    key: 'tenLopHoc',
                    value: this.class_name,
                    check: 'includes'
                },
                {
                    key: 'isSuyDduongTheNheCan',
                    value: this.malnutrition_underweight,
                    check: 'includes'
                },
                {
                    key: 'isSuyDduongTheCoiCoc',
                    value: this.severe_malnutrition,
                    check: 'includes'
                },
                {
                    key: 'beoPhi',
                    value: this.obesity,
                    check: 'includes'
                }
            ]

            const dataSearchList = generateDataSearchList(
                this.advanced_search,
                mappings
            )
            this.syncTableHeaderWithContentDisplay()
            const colExcels = this.tableHeader.map((item) => ({
                key: item.key,
                name: item.title,
                stt: item.value
            }))
            return {
                maSo: convertNumberToString(this.province),
                colExcels: colExcels,
                capHocs: this.selectedGradeLevelFromStore,
                dataSearchList: dataSearchList,
                giaiDoan: this.stage,
                hocKy: this.selectedSemesterFromStore,
                keyword: this.key_search,
                khoiHocs: this.school_block,
                limit: this.limit,
                maPhongs: this.unit_education,
                maTruongHocs: this.school,
                namHoc: this.selectedYearFromStore,
                start: this.start
            }
        },
        async handleSearch() {
            try {
                showLoading()
                const request_data = await this.buildRequestParams()
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_kqht.danh_sach,
                    request_data,
                    this.request_headers,
                    null
                )

                if (response.rc == 0) {
                    this.total_rows = response.totalRows
                    const keysToKeep = this.tableHeader.map(
                        (header) => header.key
                    )
                    const additionalKeysToKeep = [
                        'tongKetHsId',
                        'maHocSinh',
                        'hocKy',
                        'namHoc'
                    ]
                    const filteredTableData = response.tableData.map((item) => {
                        return filterItemKeysTableData(
                            item,
                            keysToKeep,
                            additionalKeysToKeep
                        )
                    })
                    this.advanced_search = []
                    this.tableData = addIsCheckedProperty(filteredTableData)

                    hideLoading()
                } else {
                    hideLoading()
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                hideLoading()
            }
        },
        async handleExportExcel() {
            showLoading()
            const domain = process.env.VUE_APP_BACKEND_BASE_URL
            const request_params = await this.buildRequestParams()
            const apiUrl = `${domain}/${Api.tra_cuu.tra_cuu_kqht.excel.url}`
            const fileName = 'tra_cuu_kqht.xlsx'
            const authToken = this.auth_user.token

            convertDownloadFileExcel(apiUrl, request_params, fileName, authToken)
            hideLoading()
        }
    },

    mounted() {
        this.content_displayed = setInitialContentDisplayed(
            this.list_content_displayed,
            this.content_displayed,
            9
        )
        this.list_unit_education = getFromSessionStorage('list_unit_education')
        if (!isRoleSuperAdmin()) {
            this.get_list_school()
        }
        // this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore(newVal) {
            if (newVal) {
                this.school_block = []
                this.list_school_block = filterListSchoolBlock(
                    list_school_block,
                    newVal
                )

                this.school = []
                this.get_list_school()
            }
        },
        tableData: {
            handler(newVal) {
                this.isCheckedCount = countCheckedItems(newVal)
            },
            deep: true
        },
        advanced_search(newVal, oldVal) {
            this.column_form = calculateColumnFormLength(newVal.length, 3, 12)
        },
        auth_user: {
            handler(user) {
                if (isRoleEducationOfficeOrSchool()) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (isRoleSchool()) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },
    computed: {
        list_province() {
            return getFromSessionStorage('list_province')
        },
        list_stage() {
            return getFromSessionStorage('DM_GIAI_DOAN_KQHT')
        },
        list_graduation_type() {
            return getFromSessionStorage('DM_TOT_NGHIEP')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },

        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
