<template>
    <div>
        <ToolBar :menu="'Hệ thống'" :content="'Quản lý đối tác'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    @click="openModalAddNew"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Mã đối tác</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="code"> </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Tên đối tác</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="name"> </CustomInput>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Tên đăng nhập</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="user_name"> </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
                <div class="col-md-2"></div>
            </div>
        </FormSearch>
        <CustomTable
            :tableData="tableData"
            :hide-btn-table="true"
            :column-form="2"
        >
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Thao tác</th>
                    <th>Mã đối tác</th>
                    <th>Tên đối tác</th>
                    <th>Tên đăng nhập</th>

                    <th>Trạng thái</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Chỉnh sửa"
                            placement="top"
                        >
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="openModalUpdate(item)"
                            />
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Xóa"
                            placement="top"
                        >
                            <img
                                :src="iconDelete"
                                class="icon"
                                @click="openModalDelete(item)"
                            />
                        </el-tooltip>
                    </td>
                    <td class="text-left">
                        {{ item.maDoiTac }}
                    </td>
                    <td class="text-left">
                        {{ item.tenDoiTac }}
                    </td>
                    <td class="text-left">
                        {{ item.tenDangNhap }}
                    </td>
                    <td>
                        <TextStatus
                            :content="
                                item.trangThai === 1
                                    ? 'Đang hoạt động'
                                    : 'Ngưng hoạt động'
                            "
                            :status="item.trangThai"
                        />
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="deleteVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDelete"
            :size="'sm'"
        >
            <template v-slot:content>
                <div>Xác nhận xóa đối tác ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleDelete"
                />
            </template>
        </CustomModal>

        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
        />
        <ModalUpdate
            :closeModalUpdate="closeModalUpdate"
            :updateVisible="updateVisible"
            @handleSearch="handleSearch"
            :dataUpdate="dataUpdate"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomTable from '@/components/CustomTable.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { formatDateToHHMMSS } from '@/utils'
import { showNotication } from '@/utils/notification'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconLock from '@/assets/img/icon-table/lock.svg'
import iconUnLock from '@/assets/img/icon-table/un-lock.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import { list_status } from '@/mock_data/quan-ly-doi-tac'
import TextStatus from '@/components/TextStatus.vue'
export default {
    name: 'PartnerManagement',

    components: {
        ToolBar,
        CustomTable,
        CustomButton,
        ModalAddNew,
        CustomModal,
        ModalUpdate,

        FormSearch,
        CustomInput,
        CustomPagination,
        ESelect,
        TextStatus
    },
    data() {
        return {
            iconEdit,
            iconLock,
            iconUnLock,
            iconDelete,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            name: '',
            code: '',
            user_name: '',
            list_status: list_status,
            status: [],
            tableData: [],
            addNewVisible: false,

            updateVisible: false,
            dataUpdate: {},

            deleteVisible: false,
            idDelete: null
        }
    },
    methods: {
        formatDateToHHMMSS,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },
        openModalDelete(item) {
            this.idDelete = item.maDoiTac
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },

        async handleDelete() {
            try {
                showLoading()
                const request_params = {
                    ma: this.idDelete
                }
                const response = await sendRequest(
                    Api.he_thong.quan_ly_doi_tac.xoa,
                    null,
                    this.request_headers,
                    request_params
                )

                if (response.code == 200) {
                    showNotication(this, 'success', response.message)
                    await this.handleSearch()
                    this.closeModalDelete()
                } else {
                    showNotication(this, 'error', response.message)
                }
            } catch (error) {
                console.log(error)
            } finally {
                hideLoading()
            }
        },
        async handleSearch() {
            try {
                const request_params = {
                    limit: this.limit,
                    maDoiTac: this.code,
                    start: this.start,
                    tenDangNhap: this.user_name,
                    tenDoitac: this.name,
                    trangThai: this.status
                }
                const response = await sendRequest(
                    Api.he_thong.quan_ly_doi_tac.danh_sach,
                    null,
                    this.request_headers,
                    request_params
                )

                this.tableData = response.rows
                this.total_rows = response.total
            } catch (error) {
                console.log(error)
            } finally {
                hideLoading()
            }
        }
    },
    mounted() {
        this.handleSearch()
    },
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
