<template>
    <div>
        <CustomTable :column-form="2" :tab="true" :hidePagi="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. Trường học -->
                <tr v-if="active_tab === 1">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Địa phương</th>
                    <th colspan="4">Tổng số Nhà trẻ</th>
                    <th colspan="4">Trường mầm non</th>
                    <th colspan="4">Trường mẫu giáo</th>
                    <th colspan="4">Nhóm trẻ độc lập</th>
                    <th colspan="4">Lớp mẫu giáo độc lập</th>
                    <th colspan="4">Nhóm trẻ, lớp mẫu giáo độc lập</th>
                    <th colspan="4">Số điểm trường</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="2" class="min-width">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2" class="min-width">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2" class="min-width">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2" class="min-width">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2" class="min-width">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2" class="min-width">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2" class="min-width">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th
                        v-for="(header, key) in list_header_active_tab_school_3"
                        :key="key"
                        class="min-width"
                    >
                        {{ header.label }}
                    </th>
                </tr>
                <!-- 2. Lớp học -->
                <tr v-if="active_tab === 2">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Địa phương</th>
                    <th colspan="4">Nhóm trẻ</th>
                    <th colspan="4">Lớp mẫu giáo</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="2">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Công lập</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Công lập</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                </tr>
            </template>

            <template v-slot:body>
                <template v-if="active_tab === 1">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>

                        <td>{{ item['F10'] }}</td>
                        <td>{{ item['G10'] }}</td>
                        <td>{{ item['H10'] }}</td>
                        <td>{{ item['I10'] }}</td>
                        <td>{{ item['F12'] }}</td>
                        <td>{{ item['G12'] }}</td>
                        <td>{{ item['H12'] }}</td>
                        <td>{{ item['I12'] }}</td>
                        <td>{{ item['F11'] }}</td>
                        <td>{{ item['G11'] }}</td>
                        <td>{{ item['H11'] }}</td>
                        <td>{{ item['I11'] }}</td>
                        <td>{{ item['F13'] }}</td>
                        <td>{{ item['G13'] }}</td>
                        <td>{{ item['H13'] }}</td>
                        <td>{{ item['I13'] }}</td>
                        <td>{{ item['F14'] }}</td>
                        <td>{{ item['G14'] }}</td>
                        <td>{{ item['H14'] }}</td>
                        <td>{{ item['I14'] }}</td>
                        <td>{{ item['F15'] }}</td>
                        <td>{{ item['G15'] }}</td>
                        <td>{{ item['H15'] }}</td>
                        <td>{{ item['I15'] }}</td>
                        <td>{{ item['F16'] }}</td>
                        <td>{{ item['G16'] }}</td>
                        <td>{{ item['H16'] }}</td>
                        <td>{{ item['I16'] }}</td>
                    </tr>
                </template>
                <template v-if="active_tab === 2">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>
                        <td>{{ item['F18'] }}</td>
                        <td>{{ item['G18'] }}</td>
                        <td>{{ item['H18'] }}</td>
                        <td>{{ item['I18'] }}</td>
                        <td>{{ item['F19'] }}</td>
                        <td>{{ item['G19'] }}</td>
                        <td>{{ item['H19'] }}</td>
                        <td>{{ item['I19'] }}</td>
                    </tr>
                </template>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportSchoolClass',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },

    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trường học' },
                { number: 2, label: 'Lớp học' }
            ],
            list_header_active_tab_school_3: [
                { label: 'Công lập' },
                { label: 'Tư thục' },
                { label: 'Dân lập' },
                { label: 'Công lập' },
                { label: 'Tư thục' },
                { label: 'Dân lập' },
                { label: 'Công lập' },
                { label: 'Tư thục' },
                { label: 'Dân lập' },
                { label: 'Công lập' },
                { label: 'Tư thục' },
                { label: 'Dân lập' },
                { label: 'Công lập' },
                { label: 'Tư thục' },
                { label: 'Dân lập' },
                { label: 'Công lập' },
                { label: 'Tư thục' },
                { label: 'Dân lập' },
                { label: 'Công lập' },
                { label: 'Tư thục' },
                { label: 'Dân lập' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 80px;
}
</style>
