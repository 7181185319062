<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="updateVisible"
        :modal-title="'Chỉnh sửa điểm trường'"
        :close-function="handleCloseModalUpdate"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-3">
                    <label>
                        {{ titleObject.school_name }} <span class="error">*</span>
                    </label>
                    <div>
                        <CustomInput
                            v-model="school_name"
                            @input="onChangeHandler('school_name', $event)"
                            :textError="err_school_name"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>{{ titleObject.address }}</label>
                    <div>
                        <CustomInput
                            v-model="address"
                            @input="onChangeHandler('address', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>{{ titleObject.distance }}</label>
                    <div>
                        <CustomInput
                            v-model="distance"
                            :type="'Number'"
                            @input="onChangeHandler('distance', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>{{ titleObject.phone }}</label>
                    <div>
                        <CustomInput
                            v-model="phone"
                            @input="onChangeHandler('phone', $event)"
                            :type="'Number'"
                            :trimAll="true"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-3">
                    <label>{{ titleObject.vehicle }}</label>
                    <div>
                        <CustomInput
                            v-model="vehicle"
                            @input="onChangeHandler('vehicle', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>{{ titleObject.acreage }}</label>
                    <div>
                        <CustomInput
                            v-model="acreage"
                            placeholder="Nhập ..."
                            @input="onChangeHandler('acreage', $event)"
                            :type="'Number'"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="col-sm-3">
                    <label
                        >{{ titleObject.province
                        }}<span class="error">*</span></label
                    >
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="true"
                            :data="list_province"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="province"
                            @input="onChangeHandler('province', $event)"
                            :class="{
                                'error-select': err_province.length > 0
                            }"
                        />
                        <span v-if="err_province.length > 0" class="error">
                            {{ err_province }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label
                        >{{ titleObject.district
                        }}<span class="error">*</span></label
                    >
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_district"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="district"
                            @input="onChangeHandler('district', $event)"
                            :class="{
                                'error-select': err_district.length > 0
                            }"
                        />
                        <span v-if="err_district.length > 0" class="error">
                            {{ err_district }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-3">
                    <label>{{ titleObject.desc }}</label>
                    <div>
                        <CustomInput
                            v-model="desc"
                            @input="onChangeHandler('desc', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleUpdate"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import {
    validateFields,
    handlerError,
    updateChangedTitles
} from '@/utils/validator'
import { convertListData } from '@/utils'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalUpdate',
    data() {
        return {
            titleObject: {
                school_name: 'Tên điểm trường',
                address: 'Địa chỉ',
                distance: 'Khoảng cách tới trường chính (km)',
                phone: 'Điện thoại',
                vehicle: 'Phương tiện đi lại',
                acreage: 'Diện tích (m2)',
                province: 'Tỉnh thành',
                district: 'Quận huyện',
                desc: 'Mô tả'
            },
            changedTitleObject: [],
            errModal: false,
            school_name: '',
            err_school_name: '',

            address: '',
            distance: '',
            phone: '',

            vehicle: '',
            acreage: '',

            province: '',
            err_province: '',

            list_district: [],
            district: '',
            err_district: '',

            desc: ''
        }
    },
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },
        closeModalUpdate: Function,
        get_data_school_site: Function,
        dataUpdate: {
            type: Object,
            required: true
        }
    },
    watch: {
        dataUpdate(newValue) {
            this.school_name = newValue.tenDiemTruong
            this.address = newValue.diaChi
            this.distance = newValue.khoangCach
            this.phone = newValue.dienThoai
            this.vehicle = newValue.phuongTien
            this.acreage = newValue.dienTich
            this.province = this.auth_user.ma_so
            if (this.province) {
                this.get_list_district()
            }
            this.district = newValue.maQuanHuyen
            this.desc = newValue.moTa
        }
    },
    components: { CustomButton, CustomModal, CustomInput, ESelect },
    methods: {
        onChangeHandler(source, newValue) {
            this.changedTitleObject = updateChangedTitles(
                this.titleObject,
                this.changedTitleObject,
                source,
                newValue
            )
            const errorFields = {
                school_name: 'err_school_name',
                province: 'err_province',
                district: 'err_district'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },

        async get_list_district() {
            try {
                showLoading()
                const request_params = {
                    maTinhThanh: this.province,
                    check: true,
                    start: 0,
                    limit: 9999
                }
                const response = await sendRequest(
                    Api.app.quan_huyen,
                    null,
                    this.request_headers,
                    request_params
                )

                hideLoading()
                this.list_district = convertListData(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
            } catch (error) {
                hideLoading()
            }
        },
        handleCloseModalUpdate() {
            this.errModal = false
            this.closeModalUpdate()
        },

        async handleUpdate() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.school_name,
                        errorField: 'err_school_name'
                    },
                    {
                        value: this.province,
                        errorField: 'err_province'
                    },
                    {
                        value: this.district,
                        errorField: 'err_district'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    showLoading()
                    const request_data = {
                        id: this.dataUpdate.id,
                        maTruongHoc: this.dataUpdate.maTruongHoc,
                        namHoc: this.selectedYearFromStore,
                        tenDiemTruong: this.school_name,
                        diaChi: this.address,
                        khoangCach: this.distance,
                        dienTich: this.acreage,
                        dienThoai: this.phone,
                        phuongTien: this.vehicle,
                        tinhThanh: this.province,
                        quanHuyen: this.district,
                        moTa: this.desc
                    }

                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_truong_hoc.chinh_sua_diem_truong,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)

                        this.$emit('get_data_school_site')
                        this.handleCloseModalUpdate()
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                    hideLoading()
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        },
        list_province() {
            return getFromSessionStorage('list_province')
        }
    }
}
</script>

<style scoped>
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
