const state = {
    contentDisplayStaffReport: [
        { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true }
    ],
    contentDisplayStudentReport: [
        { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true }
    ],
    contentDisplaySchoolReport: [
        { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true }
    ]
}

const mutations = {
    setcontentDisplayStaffReport(state, value) {
        state.contentDisplayStaffReport = value
    },
    setcontentDisplayStudentReport(state, value) {
        state.contentDisplayStudentReport = value
    },
    setcontentDisplaySchoolReport(state, value) {
        state.contentDisplaySchoolReport = value
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
