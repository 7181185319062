<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th colspan="7">Nhân sự</th>
                <th rowspan="3">Chưa kê khai dân tộc</th>
            </tr>
            <tr>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Hiệu trưởng</th>
                <th rowspan="2">Phó Hiệu trưởng</th>
                <th colspan="3">Giáo viên</th>
                <th rowspan="2">Nhân viên</th>
            </tr>
            <tr>
                <th rowspan="1">Tổng GV</th>
                <th rowspan="1">Trong đó: Tổ trưởng</th>
                <th rowspan="1">Trong đó: Tổ phó</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td>{{ item.tongDt }}</td>
                <td>{{ item.tongDtHieuTruong }}</td>
                <td>{{ item.tongDtHieuPho }}</td>
                <td>{{ item.tongDtGiaoVien }}</td>
                <td>{{ item.tongDtToTruong }}</td>
                <td>{{ item.tongDtToPho }}</td>
                <td>{{ item.tongDtNhanVien }}</td>

                <td>{{ item.tongChuaKeKhai }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StaffStatisticsByEthnicity',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
