<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Cán bộ quản lý -->
            <tr v-if="active_tab === 1">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th rowspan="3">Cán bộ quản lý</th>
                <th colspan="7">Hiệu trưởng</th>
                <th colspan="7">Phó Hiệu trưởng</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 2. GV chuẩn Nghề nghiệp -->
            <tr v-if="active_tab === 2">
                <th rowspan="4">STT</th>
                <th rowspan="4">Địa phương</th>
                <th rowspan="4">Tổng số GV</th>
                <th colspan="21">Giáo viên theo đánh giá chuẩn nghề nghiệp</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th colspan="7">Tốt</th>
                <th colspan="7">Khá</th>
                <th colspan="7">Đạt</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 3. GV biến động -->
            <tr v-if="active_tab === 3">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="7">Giáo viên nghỉ hưu trong năm học</th>
                <th colspan="7">Giáo viên tuyển mới trong năm học</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 4. Nhân viên -->
            <tr v-if="active_tab === 4">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="7">Nhân viên</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 5. GV bồi dưỡng  -->
            <tr v-if="active_tab === 5">
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="4">
                    Giáo viên được bồi dưỡng thường xuyên (TT 26/2012/TT-BGDĐT)
                </th>
                <th colspan="4">
                    Giáo viên được tập huấn và tham gia giảng dạy về phòng, chống
                    HIV trên nền tảng Kỹ năng sống và GD sức khỏe sinh sản
                </th>
                <th colspan="4">
                    Nhân viên được Tập huấn và tham gia giảng dạy về phòng, chống
                    HIV trên nền tảng Kỹ năng sống và GD sức khỏe sinh sản
                </th>
            </tr>
            <tr v-if="active_tab === 5">
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td>{{ item['E7'] }}</td>
                    <td>{{ item['E8'] }}</td>
                    <td>{{ item['F8'] }}</td>
                    <td>{{ item['G8'] }}</td>
                    <td>{{ item['H8'] }}</td>
                    <td>{{ item['J8'] }}</td>
                    <td>{{ item['K8'] }}</td>
                    <td>{{ item['L8'] }}</td>
                    <td>{{ item['E9'] }}</td>
                    <td>{{ item['F9'] }}</td>
                    <td>{{ item['G9'] }}</td>
                    <td>{{ item['H9'] }}</td>
                    <td>{{ item['J9'] }}</td>
                    <td>{{ item['K9'] }}</td>
                    <td>{{ item['L9'] }}</td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td>{{ item['E10'] }}</td>
                    <td>{{ item['E12'] }}</td>
                    <td>{{ item['F12'] }}</td>
                    <td>{{ item['G12'] }}</td>
                    <td>{{ item['H12'] }}</td>
                    <td>{{ item['J12'] }}</td>
                    <td>{{ item['K12'] }}</td>
                    <td>{{ item['L12'] }}</td>
                    <td>{{ item['E13'] }}</td>
                    <td>{{ item['F13'] }}</td>
                    <td>{{ item['G13'] }}</td>
                    <td>{{ item['H13'] }}</td>
                    <td>{{ item['J13'] }}</td>
                    <td>{{ item['K13'] }}</td>
                    <td>{{ item['L13'] }}</td>
                    <td>{{ item['E14'] }}</td>
                    <td>{{ item['F14'] }}</td>
                    <td>{{ item['G14'] }}</td>
                    <td>{{ item['H14'] }}</td>
                    <td>{{ item['J14'] }}</td>
                    <td>{{ item['K14'] }}</td>
                    <td>{{ item['L14'] }}</td>
                </tr>
            </template>
            <template v-if="active_tab === 3">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td>
                        {{ item['E15'] }}
                    </td>
                    <td>
                        {{ item['F15'] }}
                    </td>
                    <td>
                        {{ item['G15'] }}
                    </td>
                    <td>
                        {{ item['H15'] }}
                    </td>
                    <td>
                        {{ item['J15'] }}
                    </td>
                    <td>
                        {{ item['K15'] }}
                    </td>
                    <td>
                        {{ item['L15'] }}
                    </td>
                    <td>
                        {{ item['E16'] }}
                    </td>
                    <td>
                        {{ item['F16'] }}
                    </td>
                    <td>
                        {{ item['G16'] }}
                    </td>
                    <td>
                        {{ item['H16'] }}
                    </td>
                    <td>
                        {{ item['J16'] }}
                    </td>
                    <td>
                        {{ item['K16'] }}
                    </td>
                    <td>
                        {{ item['L16'] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 4">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td>
                        {{ item['E17'] }}
                    </td>
                    <td>
                        {{ item['F17'] }}
                    </td>
                    <td>
                        {{ item['G17'] }}
                    </td>
                    <td>
                        {{ item['H17'] }}
                    </td>
                    <td>
                        {{ item['J17'] }}
                    </td>
                    <td>
                        {{ item['K17'] }}
                    </td>
                    <td>
                        {{ item['L17'] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 5">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td>
                        {{ item['E19'] }}
                    </td>
                    <td>
                        {{ item['F19'] }}
                    </td>
                    <td>
                        {{ item['G19'] }}
                    </td>
                    <td>
                        {{ item['H19'] }}
                    </td>
                    <td>
                        {{ item['E20'] }}
                    </td>
                    <td>
                        {{ item['F20'] }}
                    </td>
                    <td>
                        {{ item['G20'] }}
                    </td>
                    <td>
                        {{ item['H20'] }}
                    </td>
                    <td>
                        {{ item['E21'] }}
                    </td>
                    <td>
                        {{ item['F21'] }}
                    </td>
                    <td>
                        {{ item['G21'] }}
                    </td>
                    <td>
                        {{ item['H21'] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportPersonnel',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Cán bộ quản lý' },
                { number: 2, label: 'GV chuẩn Nghề nghiệp' },
                { number: 3, label: 'GV biến động' },
                { number: 4, label: 'Nhân viên' },
                { number: 5, label: 'GV bồi dưỡng' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
