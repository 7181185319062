<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="exportExcelVisible"
        :modal-title="'Thông báo'"
        :close-function="closeModalExportExcel"
        :size="'sm'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            Tải xuống
            <b>
                {{ dataExportExcel.tenTruongHoc }} - [
                {{ dataExportExcel.maTruongHoc }}]
            </b>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleExportExcel"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { convertDownloadFileExcel } from '@/utils'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalExportExcel',
    data() {
        return {
            errModal: false
        }
    },
    props: {
        exportExcelVisible: {
            type: Boolean,
            required: true
        },
        dataExportExcel: {
            type: Object,
            require: true
        },
        closeModalExportExcel: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handleExportExcel() {
            try {
                showLoading()
                const domain = process.env.VUE_APP_BACKEND_BASE_URL
                const request_data = { messageId: this.dataExportExcel.messageId }
                const apiUrl = `${domain}/${Api.ket_chuyen_du_lieu_len_bo.lich_su_day_du_lieu_len_ioc.excel.url}`
                const fileName = 'file_loi_lich_su_day_du_lieu_len_ioc.xlsx'
                const authToken = this.auth_user.token

                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken,
                    'GET'
                )
                hideLoading()
                this.closeModalExportExcel()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
