<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hideBtnTable="true"
        :hide-pagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-200">&nbsp;</th>
                <th rowspan="3" class="min-w-100">Sĩ số</th>
                <th rowspan="3" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="6">Lớp 1</th>
                <th colspan="6">Lớp 2</th>
                <th colspan="6">Lớp 3</th>
                <th colspan="6">Lớp 4</th>
                <th colspan="6">Lớp 5</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
            </tr>
            <tr>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-for="(item, index) in tableData">
                <tr
                    v-for="rowItem in rowItems"
                    :key="'row_' + rowItem.index + '_' + index"
                >
                    <td>{{ rowItem.index }}</td>
                    <td :class="rowItem.class">{{ rowItem.label }}</td>
                    <td></td>
                    <td v-for="td in rowItem.list_td" :key="td.index">
                        {{ numberWithCommas(item[1][td]) }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import { numberWithCommas, generateListTdReportEqms } from '@/utils'
export default {
    name: 'ReportStateBudget',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            rowItems: [
                {
                    index: 1,
                    label: 'I. Kết quả học tập',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(8)
                },

                {
                    index: 2,
                    label: '1. Tiếng việt',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(9)
                },

                {
                    index: 3,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(10)
                },

                {
                    index: 4,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(11)
                },

                {
                    index: 5,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(12)
                },

                {
                    index: 6,
                    label: '2. Toán',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(13)
                },

                {
                    index: 7,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(14)
                },

                {
                    index: 8,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(15)
                },

                {
                    index: 9,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(16)
                },

                {
                    index: 10,
                    label: '3. Đạo đức',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(17)
                },

                {
                    index: 11,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(18)
                },

                {
                    index: 12,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(19)
                },

                {
                    index: 13,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(20)
                },

                {
                    index: 14,
                    label: '4. Khoa học',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(25)
                },

                {
                    index: 15,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(26)
                },

                {
                    index: 16,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(27)
                },

                {
                    index: 17,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(28)
                },

                {
                    index: 18,
                    label: '5. LS&ĐL',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(29)
                },

                {
                    index: 19,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(30)
                },

                {
                    index: 20,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(31)
                },

                {
                    index: 21,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(32)
                },

                {
                    index: 22,
                    label: '6. Âm nhạc',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(33)
                },

                {
                    index: 23,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(34)
                },

                {
                    index: 24,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(35)
                },

                {
                    index: 25,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(36)
                },

                {
                    index: 26,
                    label: '7. Mỹ thuật',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(37)
                },

                {
                    index: 27,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(38)
                },

                {
                    index: 28,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(39)
                },

                {
                    index: 29,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(40)
                },

                {
                    index: 30,
                    label: '8. Kỹ thuật',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(41)
                },

                {
                    index: 31,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(42)
                },

                {
                    index: 32,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(43)
                },

                {
                    index: 33,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(44)
                },

                {
                    index: 34,
                    label: '9. Thể dục',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(45)
                },

                {
                    index: 35,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(46)
                },

                {
                    index: 36,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(47)
                },

                {
                    index: 37,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(48)
                },

                {
                    index: 38,
                    label: '10. Ngoại ngữ',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(49)
                },

                {
                    index: 39,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(50)
                },

                {
                    index: 40,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(51)
                },

                {
                    index: 41,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(52)
                },

                {
                    index: 42,
                    label: '11. TH-CN (Tin học)',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(53)
                },

                {
                    index: 43,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(54)
                },

                {
                    index: 44,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(55)
                },

                {
                    index: 45,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(56)
                },

                {
                    index: 46,
                    label: '12. Tiếng dân tộc',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(57)
                },

                {
                    index: 47,
                    label: 'Hoàn thành tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(58)
                },

                {
                    index: 48,
                    label: 'Hoàn thành',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(59)
                },

                {
                    index: 49,
                    label: 'Chưa hoàn thành ',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(60)
                },

                {
                    index: 50,
                    label: 'III. Năng lực',
                    class: 'bold-italic text-left',
                    list_td: []
                },

                {
                    index: 51,
                    label: 'Tự phục vụ tự quản',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(62)
                },

                {
                    index: 52,
                    label: 'Tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(63)
                },

                {
                    index: 53,
                    label: 'Đạt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(64)
                },
                {
                    index: 54,
                    label: 'Cần cố gắng',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(65)
                },

                {
                    index: 55,
                    label: 'Hợp tác',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(66)
                },

                {
                    index: 56,
                    label: 'Tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(67)
                },

                {
                    index: 57,
                    label: 'Đạt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(68)
                },
                {
                    index: 58,
                    label: 'Cần cố gắng',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(69)
                },

                {
                    index: 59,
                    label: 'Tự học và giải quyết vấn đề',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(70)
                },

                {
                    index: 60,
                    label: 'Tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(71)
                },

                {
                    index: 61,
                    label: 'Đạt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(72)
                },
                {
                    index: 62,
                    label: 'Cần cố gắng',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(73)
                },

                {
                    index: 63,
                    label: 'III. Phẩm chất',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(74)
                },

                {
                    index: 64,
                    label: 'Chăm học chăm làm',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(75)
                },

                {
                    index: 65,
                    label: 'Tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(76)
                },
                {
                    index: 66,
                    label: 'Đạt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(77)
                },
                {
                    index: 67,
                    label: 'Cần cố gắng',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(78)
                },

                {
                    index: 68,
                    label: 'Tự tin trách nhiệm',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(79)
                },

                {
                    index: 69,
                    label: 'Tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(80)
                },
                {
                    index: 70,
                    label: 'Đạt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(81)
                },
                {
                    index: 71,
                    label: 'Cần cố gắng',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(82)
                },

                {
                    index: 72,
                    label: 'Trung thực kỷ luật',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(83)
                },

                {
                    index: 73,
                    label: 'Tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(84)
                },
                {
                    index: 74,
                    label: 'Đạt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(85)
                },
                {
                    index: 75,
                    label: 'Cần cố gắng',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(86)
                },

                {
                    index: 76,
                    label: 'Đoàn kết yêu thương',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(87)
                },

                {
                    index: 77,
                    label: 'Tốt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(88)
                },
                {
                    index: 78,
                    label: 'Đạt',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(89)
                },
                {
                    index: 79,
                    label: 'Cần cố gắng',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(90)
                },

                {
                    index: 80,
                    label: 'IV. Khen thưởng',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(91)
                },
                {
                    index: 81,
                    label: 'Giấy khen thưởng cấp trường',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(92)
                },
                {
                    index: 82,
                    label: 'Giấy khen thưởng cấp trên',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(93)
                },
                {
                    index: 83,
                    label: 'V. HSDT được trợ giảng',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(94)
                },
                {
                    index: 84,
                    label: 'VI. HS.K. Tật',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(95)
                },
                {
                    index: 85,
                    label: 'VII. HS bỏ học học kì I',
                    class: 'bold-italic text-left',
                    list_td: this.generateListTdReportEqms(96)
                },

                {
                    index: 86,
                    label: '+ Hoàn cảnh GĐKK',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(97)
                },
                {
                    index: 87,
                    label: 'KK trong học tập',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(98)
                },
                {
                    index: 88,
                    label: 'Xa trường, đi lại K.khăn',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(99)
                },
                {
                    index: 89,
                    label: 'Thiên tai, dịch bệnh',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(100)
                },
                {
                    index: 90,
                    label: '+ Nguyên nhân khác',
                    class: 'text-left',
                    list_td: this.generateListTdReportEqms(101)
                }
            ]
        }
    },

    methods: {
        numberWithCommas,
        generateListTdReportEqms
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.bold-italic {
    font-weight: bold;
    font-style: italic;
}
.min-w-200 {
    min-width: 200px;
}
.min-w-150 {
    min-width: 150px;
}
.min-w-100 {
    min-width: 100px;
}
</style>
