<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th rowspan="3">Tổng số (CBQL, GV, NV)</th>
                <th colspan="3">Hiệu trưởng</th>
                <th colspan="3">Phó hiệu trưởng</th>
                <th colspan="3">Giáo viên</th>
                <th colspan="3">Trong đó: Tổ trưởng</th>
                <th colspan="3">Trong đó: Tổ phó</th>
                <th colspan="3">Nhân viên</th>
                <th rowspan="3">Chưa kê khai</th>
            </tr>
            <tr>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Nam</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Nam</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Nam</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Nam</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Nam</th>
                <th rowspan="2">Tổng</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Nam</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td>{{ item.soLuongTong }}</td>
                <td>{{ item.tongHieuTruong }}</td>
                <td>{{ item.hieuTruongNu }}</td>
                <td>{{ item.hieuTruongNam }}</td>
                <td>{{ item.tongHieuPho }}</td>
                <td>{{ item.hieuPhoNu }}</td>
                <td>{{ item.hieuPhoNam }}</td>

                <td>{{ item.tongGv }}</td>
                <td>{{ item.gvNu }}</td>
                <td>{{ item.gvNam }}</td>
                <td>{{ item.tongToTruong }}</td>
                <td>{{ item.toTruongNu }}</td>
                <td>{{ item.toTruongNam }}</td>

                <td>{{ item.tongToPho }}</td>
                <td>{{ item.toPhoNu }}</td>
                <td>{{ item.toPhoNam }}</td>
                <td>{{ item.tongNv }}</td>
                <td>{{ item.nvNu }}</td>
                <td>{{ item.nvNam }}</td>
                <td>{{ item.chuaKeKhai }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'GenderStaffStatistics',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
