<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Học sinh THPT -->
            <tr v-if="active_tab === 1">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th colspan="11">Tổng số</th>
                <th colspan="4">Lớp 10</th>
                <th colspan="4">Lớp 11</th>
                <th colspan="4">Lớp 12</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Học sinh tuyển mới đầu cấp</th>
                <th colspan="4">
                    Học sinh lưu ban năm trước<br />
                    đang học trong năm học này
                </th>
                <th rowspan="2">Học sinh học 2 buổi</th>
                <th rowspan="2">Học sinh dân tộc nội trú</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">Tổng</th>
                <th rowspan="1">LB Lớp 10</th>
                <th rowspan="1">LB Lớp 11</th>
                <th rowspan="1">LB Lớp 12</th>
            </tr>

            <!-- 2. Độ tuổi -->
            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th rowspan="3">Tổng số HS</th>
                <th colspan="20">Chia ra theo độ tuổi</th>
                <th colspan="12">Học sinh đi học đúng tuổi</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th colspan="4">Dưới 15 tuổi</th>
                <th colspan="4">15 Tuổi</th>
                <th colspan="4">16 Tuổi</th>
                <th colspan="4">17 Tuổi</th>
                <th colspan="4">Trên 17 tuổi</th>
                <th colspan="4">15 Tuổi học lớp 10</th>
                <th colspan="4">16 Tuổi học lớp 11</th>
                <th colspan="4">17 Tuổi học lớp 12</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 3. Khuyết tật -->
            <tr v-if="active_tab === 3">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th rowspan="3">Tổng số</th>
                <th colspan="24">Học sinh khuyết tật</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th colspan="4">Tổng số học sinh KT</th>
                <th colspan="4">KT nhìn</th>
                <th colspan="4">KT nghe, nói</th>
                <th colspan="4">KT Trí tuệ</th>
                <th colspan="4">KT vận động</th>
                <th colspan="4">KT thần kinh, tâm thần</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Học sinh THPT' },
                { number: 2, label: 'Độ tuổi ' },
                { number: 3, label: 'Khuyết tật' }
            ],

            list_td_active_tab_1: [
                'E5',
                'F5',
                'G5',
                'H5',
                'E26',
                'E27',
                '',
                '',
                '',
                'E28',
                'E29',
                'E5',
                'F12',
                'G12',
                'H12',
                'E13',
                'F13',
                'G13',
                'H13',
                'E14',
                'F14',
                'G14',
                'H14'
            ],
            list_td_active_tab_2: [
                'E5',
                'E16',
                'F16',
                'G16',
                'H16',
                'E17',
                'F17',
                'G17',
                'H17',
                'E18',
                'F18',
                'G18',
                'H18',
                'E19',
                'F19',
                'G19',
                'H19',
                'E20',
                'F20',
                'G20',
                'H20',
                'E22',
                'F22',
                'G22',
                'H22',
                'E23',
                'F23',
                'G23',
                'H23',
                'E24',
                'F24',
                'G24',
                'H24'
            ],
            list_td_active_tab_3: [
                'E5',
                'E30',
                'F30',
                'G30',
                'H30',
                'E32',
                'F32',
                'G32',
                'H32',
                'E33',
                'F33',
                'G33',
                'H33',
                'E34',
                'F34',
                'G34',
                'H34',
                'E35',
                'F35',
                'G35',
                'H35',
                'E36',
                'F36',
                'G36',
                'H36'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
