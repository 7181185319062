<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Học sinh bổ túc văn hóa -->

            <tr v-if="active_tab === 1">
                <th rowspan="5">STT</th>
                <th rowspan="5" class="min-w-100">Địa phương</th>
                <th rowspan="5" class="min-w-100">Tổng số</th>
                <th colspan="24" class="min-w-100">
                    Bổ túc chương trình cấp THCS
                </th>
                <th colspan="32" class="min-w-100">
                    Bổ túc chương trình cấp THPT
                </th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="4">Tổng số học viên</th>
                <th rowspan="4">Nữ</th>
                <th rowspan="4">Dân tộc</th>
                <th rowspan="4">Nữ DT</th>
                <th colspan="4">Học viên bỏ học</th>
                <th colspan="4">Học viên lưu ban</th>
                <th colspan="12">Học viên tốt nghiệp bổ túc THCS</th>
                <th rowspan="4">Tổng số học viên</th>
                <th rowspan="4">Nữ</th>
                <th rowspan="4">Dân tộc</th>
                <th rowspan="4">Nữ DT</th>
                <th colspan="4">Học viên bỏ học</th>
                <th colspan="4">Học viên lưu ban</th>
                <th colspan="20">Học viên tốt nghiệp bổ túc THPT</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="3">Tổng số</th>
                <th rowspan="3">Nữ</th>
                <th rowspan="3">Dân tộc</th>
                <th rowspan="3">Nữ DT</th>
                <th rowspan="3">Tổng số</th>
                <th rowspan="3">Nữ</th>
                <th rowspan="3">Dân tộc</th>
                <th rowspan="3">Nữ DT</th>
                <th colspan="4">Học viên có đủ điều kiện xét TN</th>
                <th colspan="8">Học viên tốt nghiệp THCS</th>
                <th rowspan="3">Tổng số</th>
                <th rowspan="3">Nữ</th>
                <th rowspan="3">Dân tộc</th>
                <th rowspan="3">Nữ DT</th>
                <th rowspan="3">Tổng số</th>
                <th rowspan="3">Nữ</th>
                <th rowspan="3">Dân tộc</th>
                <th rowspan="3">Nữ DT</th>
                <th colspan="8">Học viên có đủ điều kiện dự thi TN</th>
                <th colspan="12">Học viên được công nhận tốt nghiệp THPT</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="4">Học viên khuyết tật</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="4">Thí sinh tự do</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="4">
                    Học viên được miễn thi tất cả các bài thi trong xét tốt nghiệp
                    THPT (Khoản 2 điều 32 Quy chế thi THPTQG&amp; xét công nhận
                    TNTHPT)
                </th>
                <th colspan="4">Thí sinh tự do</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 2. Học sinh xóa mù chữ -->

            <tr v-if="active_tab === 2">
                <th rowspan="4">STT</th>
                <th rowspan="4">Địa phương</th>
                <th colspan="20">
                    Xóa mù chữ và giáo dục tiếp tục sau khi biết chữ
                </th>
                <th colspan="21">Các chương trình đào tạo, bồi dưỡng</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="3">Tổng số học viên</th>
                <th rowspan="3">Nữ</th>
                <th rowspan="3">Dân tộc</th>
                <th rowspan="3">Nữ DT</th>
                <th colspan="4">Học viên bỏ học</th>
                <th colspan="12">Học viên được công nhận đạt chuẩn biết chữ</th>
                <th rowspan="3">Tổng số học viên</th>
                <th colspan="4">Tin học</th>
                <th colspan="4">Ngoại ngữ</th>
                <th colspan="4">Hướng nghiệp, học nghề phổ thông</th>
                <th colspan="4">Học tiếng dân tộc</th>
                <th colspan="4">
                    Bồi dưỡng thường xuyên cho giáo viên mầm non và phổ thông
                    (theo thông tư 26/2012/TT-BGDĐT)
                </th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="4">
                    Đạt chuẩn biết chữ mức độ 1 (Hoàn thành lớp 3 CTGD tiểu học)
                </th>
                <th colspan="4">
                    Đạt chuẩn biết chữ mức độ 2 (Hoàn thành CTGD tiểu học)
                </th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Học sinh bổ túc văn hóa' },
                { number: 2, label: 'Học sinh xóa mù chữ' }
            ],
            list_td_active_tab_1: [
                'E5',
                'E7',
                'F7',
                'G7',
                'H7',
                'E8',
                'F8',
                'G8',
                'H8',
                'E9',
                'F9',
                'G9',
                'H9',
                'E11',
                'F11',
                'G11',
                'H11',
                'E12',
                'F12',
                'G12',
                'H12',
                'E13',
                'F13',
                'G13',
                'H13',
                'E15',
                'F15',
                'G15',
                'H15',
                'E16',
                'F16',
                'G16',
                'H16',
                'E17',
                'F17',
                'G17',
                'H17',
                'E19',
                'F19',
                'G19',
                'H19',
                'E21',
                'F19',
                'G19',
                'H19',
                'E22',
                'F22',
                'G22',
                'H22',
                'E24',
                'F24',
                'G24',
                'H24',
                'E25',
                'F25',
                'G25',
                'H25'
            ],
            list_td_active_tab_2: [
                'E27',
                'F27',
                'G27',
                'H27',
                'E28',
                'F28',
                'G28',
                'H28',
                'E29',
                'F29',
                'G29',
                'H29',
                'E30',
                'F30',
                'G30',
                'H30',
                'E31',
                'F31',
                'G31',
                'H31',
                'E32',
                'E34',
                'F34',
                'G34',
                'H34',
                'E35',
                'F35',
                'G35',
                'H35',
                'E36',
                'F36',
                'G36',
                'H36',
                'E37',
                'F37',
                'G37',
                'H37',
                'E38',
                'F38',
                'G38',
                'H38'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-100 {
    min-width: 100px;
}
</style>
