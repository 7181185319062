<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <tr v-if="active_tab === 1">
                <th rowspan="5">STT</th>
                <th rowspan="5" class="min-w-150">Địa phương</th>
                <th colspan="3">Tổng số trường cấp THCS</th>
                <th colspan="13">Trường Trung Học Cơ Sở</th>
                <th colspan="11">Trường Tiểu Học và Trung Học Cơ Sở</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="4" class="min-w-150">Tổng số trường</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th rowspan="4" class="min-w-150">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="10">Trong đó</th>
                <th rowspan="4" class="min-w-150">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="8">Trong đó</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="3" class="min-w-150">Công lập</th>
                <th rowspan="3" class="min-w-150">Tư thục</th>
                <th rowspan="3" class="min-w-150">Công lập</th>
                <th rowspan="3" class="min-w-150">Tư thục</th>
                <th colspan="6">Trường THCS đạt chuẩn QG</th>
                <th rowspan="3" class="min-w-150">
                    Trường PT dân tộc bán trú cấp THCS
                </th>
                <th rowspan="3" class="min-w-150">
                    Trường PT dân tộc nội trú huyện
                </th>
                <th rowspan="3" class="min-w-150">
                    Trường chuyên biệt dành cho người khuyết tật
                </th>
                <th rowspan="3" class="min-w-150">
                    Trường có tổ chức học trực tuyến
                </th>
                <th rowspan="3" class="min-w-150">Công lập</th>
                <th rowspan="3" class="min-w-150">Tư thục</th>
                <th colspan="6">Trường THCS đạt chuẩn QG</th>
                <th rowspan="3" class="min-w-150">
                    Trường chuyên biệt dành cho người khuyết tật
                </th>
                <th rowspan="3" class="min-w-150">
                    Trường có tổ chức học trực tuyến
                </th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="2">Tổng số</th>
                <th colspan="2">Công lập</th>
                <th colspan="2">Tư thục</th>
                <th colspan="2">Tổng số</th>
                <th colspan="2">Công lập</th>
                <th colspan="2">Tư thục</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1" class="min-w-150">Mức độ 1</th>
                <th rowspan="1" class="min-w-150">Mức độ 2</th>
                <th rowspan="1" class="min-w-150">Mức độ 1</th>
                <th rowspan="1" class="min-w-150">Mức độ 2</th>
                <th rowspan="1" class="min-w-150">Mức độ 1</th>
                <th rowspan="1" class="min-w-150">Mức độ 2</th>
                <th rowspan="1" class="min-w-150">Mức độ 1</th>
                <th rowspan="1" class="min-w-150">Mức độ 2</th>
                <th rowspan="1" class="min-w-150">Mức độ 1</th>
                <th rowspan="1" class="min-w-150">Mức độ 2</th>
                <th rowspan="1" class="min-w-150">Mức độ 1</th>
                <th rowspan="1" class="min-w-150">Mức độ 2</th>
            </tr>

            <tr v-if="active_tab === 2">
                <th rowspan="4" class="min-w-150">STT</th>
                <th rowspan="4" class="min-w-150">Địa phương</th>
                <th rowspan="4" class="min-w-150">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="4">Chia ra theo lớp</th>
                <th colspan="15">Trong đó</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="3" class="min-w-150">Công lập</th>
                <th rowspan="3" class="min-w-150">Tư thục</th>
                <th rowspan="3" class="min-w-150">Lớp 6</th>
                <th rowspan="3" class="min-w-150">Lớp 7</th>
                <th rowspan="3" class="min-w-150">Lớp 8</th>
                <th rowspan="3" class="min-w-150">Lớp 9</th>
                <th colspan="3">Lớp học 2 buổi/ngày</th>
                <th colspan="3">Lớp học bán trú trong trường PTDT bán trú</th>
                <th colspan="3">Lớp học trong trường PTDT nội trú huyện</th>
                <th colspan="3">
                    Lớp học trong trường chuyên biệt dành cho người khuyết tật
                </th>
                <th colspan="3">Lớp ghép</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2" class="min-w-150">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2" class="min-w-150">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2" class="min-w-150">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2" class="min-w-150">Tổng số</th>
                <th colspan="2">Chia ra</th>
                <th rowspan="2" class="min-w-150">Tổng số</th>
                <th colspan="2">Chia ra</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1" class="min-w-150">Công lập</th>
                <th rowspan="1" class="min-w-150">Tư thục</th>
                <th rowspan="1" class="min-w-150">Công lập</th>
                <th rowspan="1" class="min-w-150">Tư thục</th>
                <th rowspan="1" class="min-w-150">Công lập</th>
                <th rowspan="1" class="min-w-150">Tư thục</th>
                <th rowspan="1" class="min-w-150">Công lập</th>
                <th rowspan="1" class="min-w-150">Tư thục</th>
                <th rowspan="1" class="min-w-150">Công lập</th>
                <th rowspan="1" class="min-w-150">Tư thục</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[0][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[0][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportSchoolClass',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trường học' },
                { number: 2, label: 'Lớp học' }
            ],
            list_td_active_tab_1: [
                'E9',
                'F9',
                'G9',
                'E10',
                'F10',
                'G10',
                'E21',
                'E22',
                'F21',
                'F22',
                'G21',
                'G22',
                'E22',
                'E23',
                'E24',
                '',
                'E37',
                'F37',
                'G37',
                'E47',
                'E48',
                'F47',
                'F48',
                'G47',
                'G48',
                'E49',
                ''
            ],
            list_td_active_tab_2: [
                'E62',
                'F62',
                'G62',
                'E64',
                'E65',
                'E66',
                'E67',
                'E69',
                'F69',
                'G69',
                'E70',
                'F70',
                'G70',
                'E71',
                'F71',
                'G71',
                'E72',
                'F72',
                'G72',
                'E73',
                'F73',
                'G73'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-150 {
    min-width: 150px;
}
</style>
