<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <tr v-if="active_tab === 1">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-100">Địa phương</th>
                <th rowspan="4" class="min-w-100">Tổng số</th>
                <th colspan="4">Tổng số cơ sở Nhà trẻ, Mẫu Giáo, Mầm non</th>
                <th colspan="8">
                    Tổng số trường Mẫu giáo và Mầm non đạt chuẩn QG
                </th>
                <th colspan="5">Nhà trẻ</th>
                <th colspan="13">Mẫu giáo</th>
                <th colspan="13">Mầm non</th>
                <th colspan="4">Cơ sở độc lập</th>
                <th rowspan="4">Điểm trường</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="3" class="min-w-100">Công lập</th>
                <th colspan="3">Chia ra</th>
                <th colspan="2" class="min-w-100">Tổng số</th>
                <th colspan="6">Chia ra</th>
                <th rowspan="3" class="min-w-100">Tổng số</th>
                <th colspan="4">Chia ra</th>
                <th rowspan="3" class="min-w-100">Tổng số</th>
                <th colspan="4">Chia ra</th>
                <th colspan="8">Trường mẫu giáo đạt chuẩn QG</th>
                <th rowspan="3" class="min-w-100">Tổng số</th>
                <th colspan="4">Chia ra</th>
                <th colspan="8">Trường mầm non đạt chuẩn QG</th>
                <th rowspan="3" class="min-w-100">Tổng số</th>
                <th colspan="3">Chia ra</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="3">Ngoài công lập</th>
                <th rowspan="2" class="min-w-100">Mức độ 1</th>
                <th rowspan="2" class="min-w-100">Mức độ 2</th>
                <th colspan="2" class="min-w-100">Công lập</th>
                <th colspan="2">Tư thục</th>
                <th colspan="2">Dân lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3">Ngoài công lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3">Ngoài công lập</th>
                <th colspan="2">Tổng số</th>
                <th colspan="2" class="min-w-100">Công lập</th>
                <th colspan="2">Tư thục</th>
                <th colspan="2">Dân lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3">Ngoài công lập</th>
                <th colspan="2">Tổng số</th>
                <th colspan="2" class="min-w-100">Công lập</th>
                <th colspan="2">Tư thục</th>
                <th colspan="2">Dân lập</th>
                <th rowspan="2" class="min-w-100">Nhóm trẻ độc lập</th>
                <th rowspan="2" class="min-w-100">Lớp mẫu giáo độc lập</th>
                <th rowspan="2" class="min-w-100">
                    Nhóm trẻ, lớp mẫu giáo độc lập
                </th>
            </tr>

            <tr v-if="active_tab === 1">
                <th
                    v-for="(header, key) in list_active_tab_1_th_4"
                    :key="key"
                    rowspan="1"
                    class="min-w-100"
                >
                    {{ header }}
                </th>
            </tr>

            <tr v-if="active_tab === 2">
                <th rowspan="5">STT</th>
                <th rowspan="5" class="min-w-100">Địa phương</th>
                <th colspan="13">Tổng số Nhóm, Lớp</th>
                <th colspan="13">Nhóm trẻ</th>
                <th colspan="17">Lớp mẫu giáo</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="4" class="min-w-100">Tổng số</th>
                <th colspan="4">Chia ra</th>
                <th colspan="8">Trong đó</th>
                <th rowspan="4" class="min-w-100">Tổng số</th>
                <th colspan="4">Chia ra</th>
                <th colspan="8">Trong đó</th>
                <th rowspan="4" class="min-w-100">Tổng số</th>
                <th colspan="4">Chia ra</th>
                <th colspan="12">Trong đó</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="3" class="min-w-100">Công lập</th>
                <th colspan="3">Ngoài công lập</th>
                <th colspan="4">Nhóm, lớp học 2 buổi/ngày</th>
                <th colspan="4">Nhóm lớp học bán trú</th>
                <th rowspan="3" class="min-w-100">Công lập</th>
                <th colspan="3">Ngoài công lập</th>
                <th colspan="4">Nhóm trẻ học 2 buổi/ngày</th>
                <th colspan="4">Nhóm trẻ học bán trú</th>
                <th rowspan="3" class="min-w-100">Công lập</th>
                <th colspan="3">Ngoài công lập</th>
                <th colspan="4">Lớp mẫu giáo 5 tuổi</th>
                <th colspan="4">Lớp mẫu giáo học 2 buổi/ngày</th>
                <th colspan="4">Lớp mẫu giáo học bán trú</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2" class="min-w-100">Tổng</th>
                <th rowspan="2" class="min-w-100">Tư thục</th>
                <th rowspan="2" class="min-w-100">Dân lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3" class="min-w-100">Ngoài công lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3" class="min-w-100">Ngoài công lập</th>
                <th rowspan="2" class="min-w-100">Tổng</th>
                <th rowspan="2" class="min-w-100">Tư thục</th>
                <th rowspan="2" class="min-w-100">Dân lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3" class="min-w-100">Ngoài công lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3" class="min-w-100">Ngoài công lập</th>
                <th rowspan="2" class="min-w-100">Tổng</th>
                <th rowspan="2" class="min-w-100">Tư thục</th>
                <th rowspan="2" class="min-w-100">Dân lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3" class="min-w-100">Ngoài công lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3" class="min-w-100">Ngoài công lập</th>
                <th rowspan="2" class="min-w-100">Công lập</th>
                <th colspan="3" class="min-w-100">Ngoài công lập</th>
            </tr>

            <tr v-if="active_tab === 2">
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Tư thục</th>
                <th rowspan="1" class="min-w-100">Dân lập</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Tư thục</th>
                <th rowspan="1" class="min-w-100">Dân lập</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Tư thục</th>
                <th rowspan="1" class="min-w-100">Dân lập</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Tư thục</th>
                <th rowspan="1" class="min-w-100">Dân lập</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Tư thục</th>
                <th rowspan="1" class="min-w-100">Dân lập</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Tư thục</th>
                <th rowspan="1" class="min-w-100">Dân lập</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Tư thục</th>
                <th rowspan="1" class="min-w-100">Dân lập</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[0][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[0][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportSchoolClass',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trường học' },
                { number: 2, label: 'Lớp học' }
            ],

            list_active_tab_1_th_4: [
                'Tổng',
                'Tư thục',
                'Dân lập',
                'Mức độ 1',
                'Mức độ 2',
                'Mức độ 1',
                'Mức độ 2',
                'Mức độ 1',
                'Mức độ 2',
                'Tổng',
                'Tư thục',
                'Dân lập',
                'Tổng',
                'Tư thục',
                'Dân lập',
                'Mức độ 1',
                'Mức độ 2',
                'Mức độ 1',
                'Mức độ 2',
                'Mức độ 1',
                'Mức độ 2',
                'Mức độ 1',
                'Mức độ 2',
                'Tổng',
                'Tư thục',
                'Dân lập',
                'Mức độ 1',
                'Mức độ 2',
                'Mức độ 1',
                'Mức độ 2',
                'Mức độ 1',
                'Mức độ 2',
                'Mức độ 1',
                'Mức độ 2'
            ],

            list_td_active_tab_1: [
                'E10',
                'F10',
                '',
                'G10',
                'H10',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'E26',
                'F26',
                '',
                'G26',
                'H26',
                'E35',
                'E36',
                'F35',
                'F36',
                'G35',
                'F36',
                'H35',
                'H36',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'E55',
                'E56',
                'F55',
                'F56',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],

            columnHeaders: [
                { label: 'Tổng', rowspan: 2 },
                { label: 'Tư thục', rowspan: 2 },
                { label: 'Dân lập', rowspan: 2 },
                { label: 'Công lập', rowspan: 2 },
                { label: 'Ngoài công lập', colspan: 3 },
                { label: 'Công lập', rowspan: 2 },
                { label: 'Ngoài công lập', colspan: 3 },
                { label: 'Tổng', rowspan: 2 },
                { label: 'Tư thục', rowspan: 2 },
                { label: 'Dân lập', rowspan: 2 },
                { label: 'Công lập', rowspan: 2 },
                { label: 'Ngoài công lập', colspan: 3 },
                { label: 'Công lập', rowspan: 2 },
                { label: 'Ngoài công lập', colspan: 3 },
                { label: 'Công lập', rowspan: 2 },
                { label: 'Ngoài công lập', colspan: 3 }
            ],
            list_td_active_tab_2: [
                'E71',
                'F71',
                '',
                'G71',
                'H71',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'E72',
                'F72',
                '',
                'G72',
                'H72',
                'F74',
                '',
                'G74',
                'H74',
                'F75',
                '',
                'G75',
                'H75',
                'E86',
                'F86',
                '',
                'G86',
                'H86',
                'E88',
                '',
                'G88',
                'H88',
                'E89',
                '',
                'G89',
                'H89',
                'E90',
                '',
                'G90',
                'H90'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
