l
<template>
    <div>
        <ToolBar :tab="true">
            <template v-slot:tab>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                >
                    <el-menu-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :index="index.toString()"
                        @click="handleMenuItemClick(index)"
                    >
                        {{ item.label }}
                    </el-menu-item>
                </el-menu>
            </template>
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    :btnToolBar="true"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Xuất Excel"
                    :icon="'el-icon-download'"
                    :btnToolBar="true"
                    @click="handleExportExcel"
                />
                <CustomButton
                    label="Cấu hình cột hiển thị"
                    :btnToolBar="true"
                    @click="openModalContentDisplayed"
                />
                <CustomButton
                    label="Lưu mẫu"
                    :btnToolBar="true"
                    @click="openModalSaveTemplate"
                />
                <CustomButton
                    label="Tải mẫu"
                    :btnToolBar="true"
                    @click="openModalDownloadTemplate"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tìm kiếm nâng cao:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_advanced_search_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="advanced_search"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHinhDaoTao')">
                    <div class="label-wrapper">
                        <label class="control-label">Loại hình đào tạo:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHinhDaoTao')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_training_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="training_type"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHinhTruong')">
                    <div class="label-wrapper">
                        <label class="control-label">Loại hình trường:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHinhTruong')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school_type"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('chuanQuocGia')">
                    <div class="label-wrapper">
                        <label class="control-label">Chuẩn quốc gia:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('chuanQuocGia')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_national_standards"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="national_standards"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maVungKhoKhan')">
                    <div class="label-wrapper">
                        <label class="control-label">Vùng khó khăn:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maVungKhoKhan')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_difficult_areas"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="difficult_areas"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('chuongTrinhGiaoDucCoBan')"
                >
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Chương trình giáo dục cơ bản:</label
                        >
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('chuongTrinhGiaoDucCoBan')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="basic_education_program"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('coHaTangTlhtPhuHopHskt')"
                >
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Môi trường phù hợp hs khuyết tật:</label
                        >
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('coHaTangTlhtPhuHopHskt')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="
                            environment_suitable_for_students_with_disabilities
                        "
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('coLopKhongChuyen')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">Có lớp không chuyên:</label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('coLopKhongChuyen')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="specialized_classes_available"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('congTrinhVeSinh')">
                    <div class="label-wrapper">
                        <label class="control-label"> Công trình vệ sinh: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('congTrinhVeSinh')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="sanitation_facilities"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('ctGdvsDoiTay')">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Giáo dục vệ sinh đôi tay:
                        </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('ctGdvsDoiTay')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="hand_hygiene_education_program"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('datChatLuongToiThieu')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Đạt chất lượng tối thiểu:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('datChatLuongToiThieu')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="minimum_quality_achieved"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dayNghePhoThong')">
                    <div class="label-wrapper">
                        <label class="control-label"> Dạy nghề phổ thông: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dayNghePhoThong')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="general_vocational_education"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dienLuoi')">
                    <div class="label-wrapper">
                        <label class="control-label"> Điện lưới: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dienLuoi')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="power_grid"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hoc2BuoiNgay')">
                    <div class="label-wrapper">
                        <label class="control-label"> Học 2 buổi/ngày: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hoc2BuoiNgay')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="study_twice_a_day"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hocSinhBanTru')">
                    <div class="label-wrapper">
                        <label class="control-label"> Học sinh bán trú: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocSinhBanTru')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="boarding_students"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hocSinhNoiTru')">
                    <div class="label-wrapper">
                        <label class="control-label"> Học sinh nội trú: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocSinhNoiTru')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="day_students"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhKhuyetTat')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh khuyết tật:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhKhuyetTat')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="students_with_disabilities"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('khaiThacInternetDayHoc')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Khai thác Internet dạy học:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('khaiThacInternetDayHoc')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="utilization_of_the_internet_for_teaching"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('kyNangSongGdxh')">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Kỹ năng sống giáo dục xã hội:
                        </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('kyNangSongGdxh')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="life_skills_and_social_education"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('truongQuocTe')">
                    <div class="label-wrapper">
                        <label class="control-label"> Trường quốc tế: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('truongQuocTe')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="international_school"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('vungDacBietKhoKhan')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Vùng đặc biệt khó khăn:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('vungDacBietKhoKhan')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="extremely_difficult_areas"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('ttGdtxHuongNghiep')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trung tâm hướng nghiệp:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('ttGdtxHuongNghiep')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="career_guidance_center"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('ttNnCoVonNuocNgoai')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trung tâm ngoại ngữ có vốn nước ngoài:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('ttNnCoVonNuocNgoai')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="foreign_language_center_with_foreign_capital"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th v-for="column in tableHeader" :key="column.key">
                        {{ column.label }}
                    </th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableRows.length === 0">
                    <tr>
                        <td colspan="211">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(row, index) in tableRows" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td
                        class="text-left"
                        v-for="(col, colIndex) in row.col"
                        :key="colIndex"
                    >
                        {{ col.value }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalContentDisplayed
            :contentDisplayedVisible="contentDisplayedVisible"
            :closeModalContentDisplayed="closeModalContentDisplayed"
        />
        <ModalSaveTemplate
            :saveTemplateVisible="saveTemplateVisible"
            :closeModalSaveTemplate="closeModalSaveTemplate"
            :prepareRequestData="prepareRequestData"
        />
        <ModalDownloadTemplate
            :downloadTemplateVisible="downloadTemplateVisible"
            :closeModalDownloadTemplate="closeModalDownloadTemplate"
            :prepareRequestData="prepareRequestData"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertListData,
    calculateColumnFormLength,
    generateDataSearchList,
    convertDownloadFileExcel
} from '@/utils'
import ESelect from '@/components/ESelect.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ToolBar from '@/components/ToolBar.vue'
import {
    list_advanced_search_school,
    list_yes_of_no,
    list_true_of_false,
    list_training_type
} from '@/mock_data/bao-cao-dong'
import CustomTable from '@/components/CustomTable.vue'
import ModalContentDisplayed from './modal_content_displayed.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
import { mapState } from 'vuex'
import ModalSaveTemplate from './modal_save_template.vue'
import ModalDownloadTemplate from './modal_download_template.vue'
import { path } from '@/router/path'
export default {
    name: 'RePortStaff',

    components: {
        ToolBar,
        CustomButton,
        FormSearch,
        ESelect,
        CustomTable,
        CustomPagination,

        ModalContentDisplayed,
        ModalSaveTemplate,
        ModalDownloadTemplate
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: [],

            list_advanced_search_school: list_advanced_search_school,
            advanced_search: [],

            list_training_type: list_training_type,
            training_type: '', // Loại hình đào tạo
            school_type: '', // Loại hình trường
            national_standards: '', // Chuẩn quốc gia
            difficult_areas: '', // Vùng khó khăn
            basic_education_program: '', // Chương trình giáo dục cơ bản
            environment_suitable_for_students_with_disabilities: '', // Môi trường phù hợp với học sinh khuyết tật
            specialized_classes_available: '', // Có lớp không chuyên
            sanitation_facilities: '', // Cơ sở vệ sinh
            hand_hygiene_education_program: '', // Chương trình giáo dục vệ sinh đôi tay
            minimum_quality_achieved: '', // Đạt chất lượng tối thiểu
            general_vocational_education: '', // Dạy nghề phổ thông
            power_grid: '', // Điện lưới
            study_twice_a_day: '', // Học 2 buổi/ngày
            boarding_students: '', // Học sinh bán trú
            day_students: '', // Học sinh nội trú
            students_with_disabilities: '', // Học sinh khuyết tật
            utilization_of_the_internet_for_teaching: '', // Khai thác Internet để dạy học
            life_skills_and_social_education: '', // Giáo dục kỹ năng sống và xã hội
            international_school: '', // Trường quốc tế
            extremely_difficult_areas: '', // Vùng đặc biệt khó khăn
            career_guidance_center: '', // Trung tâm hướng nghiệp
            foreign_language_center_with_foreign_capital: '', // Trung tâm ngoại ngữ có vốn nước ngoài

            tableRows: [],
            tableHeader: [],

            list_true_of_false: list_true_of_false,
            list_yes_of_no: list_yes_of_no,

            activeIndex: '2',
            menuItems: [
                { label: 'Báo cáo cán bộ' },
                { label: 'Báo cáo học sinh' },
                { label: 'Báo cáo trường học' }
            ],

            column_form: 1,
            contentDisplayedVisible: false,
            saveTemplateVisible: false,
            downloadTemplateVisible: false
        }
    },
    methods: {
        shouldShowElement(element) {
            return this.advanced_search.includes(element)
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleMenuItemClick(index) {
            switch (index) {
                case 0:
                    this.$router.push({
                        path: path.report.dynamic_report.staff_report
                    })
                    break
                case 1:
                    this.$router.push({
                        path: path.report.dynamic_report.student_report
                    })
                    break
                case 2:
                    this.$router.push({
                        path: path.report.dynamic_report.school_report
                    })
                    break
                default:
            }
        },
        openModalContentDisplayed() {
            this.contentDisplayedVisible = true
        },
        closeModalContentDisplayed() {
            this.contentDisplayedVisible = false
        },
        openModalSaveTemplate() {
            this.saveTemplateVisible = true
        },
        closeModalSaveTemplate() {
            this.saveTemplateVisible = false
        },
        openModalDownloadTemplate() {
            this.downloadTemplateVisible = true
        },
        closeModalDownloadTemplate() {
            this.downloadTemplateVisible = false
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },
        prepareRequestData() {
            const mappings = [
                {
                    key: 'loaiHinhDaoTao',
                    value: this.training_type,
                    check: 'includes'
                },
                {
                    key: 'loaiHinhTruong',
                    value: this.school_type,
                    check: 'includes'
                },
                {
                    key: 'chuanQuocGia',
                    value: this.national_standards,
                    check: 'includes'
                },
                {
                    key: 'maVungKhoKhan',
                    value: this.difficult_areas,
                    check: 'includes'
                },
                {
                    key: 'chuongTrinhGiaoDucCoBan',
                    value: this.basic_education_program,
                    check: 'includes'
                },
                {
                    key: 'coHaTangTlhtPhuHopHskt',
                    value: this
                        .environment_suitable_for_students_with_disabilities,
                    check: 'includes'
                },
                {
                    key: 'coLopKhongChuyen',
                    value: this.specialized_classes_available,
                    check: 'includes'
                },
                {
                    key: 'congTrinhVeSinh',
                    value: this.sanitation_facilities,
                    check: 'includes'
                },
                {
                    key: 'ctGdvsDoiTay',
                    value: this.hand_hygiene_education_program,
                    check: 'includes'
                },

                {
                    key: 'datChatLuongToiThieu',
                    value: this.minimum_quality_achieved,
                    check: 'includes'
                },
                {
                    key: 'dayNghePhoThong',
                    value: this.general_vocational_education,
                    check: 'includes'
                },
                {
                    key: 'dienLuoi',
                    value: this.power_grid,
                    check: 'includes'
                },
                {
                    key: 'hoc2BuoiNgay',
                    value: this.study_twice_a_day,
                    check: 'includes'
                },
                {
                    key: 'hocSinhBanTru',
                    value: this.boarding_students,
                    check: 'includes'
                },

                {
                    key: 'hocSinhNoiTru',
                    value: this.day_students,
                    check: 'includes'
                },

                {
                    key: 'hocSinhKhuyetTat',
                    value: this.students_with_disabilities,
                    check: 'includes'
                },

                {
                    key: 'khaiThacInternetDayHoc',
                    value: this.utilization_of_the_internet_for_teaching,
                    check: 'includes'
                },

                {
                    key: 'kyNangSongGdxh',
                    value: this.life_skills_and_social_education,
                    check: 'includes'
                },

                {
                    key: 'truongQuocTe',
                    value: this.international_school,
                    check: 'includes'
                },

                {
                    key: 'vungDacBietKhoKhan',
                    value: this.extremely_difficult_areas,
                    check: 'includes'
                },

                {
                    key: 'ttGdtxHuongNghiep',
                    value: this.career_guidance_center,
                    check: 'includes'
                },
                {
                    key: 'ttNnCoVonNuocNgoai',
                    value: this.foreign_language_center_with_foreign_capital,
                    check: 'includes'
                }
            ]

            const requestLists = generateDataSearchList(
                this.advanced_search,
                mappings
            )
            const requestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: this.selectedYearFromStore
                }
            ]
            const colList = this.contentDisplaySchoolReport
            return {
                start: this.start,
                limit: this.limit,
                requestList: requestList,
                requestLists: requestLists,
                colList: colList
            }
        },
        async handleSearch() {
            try {
                showLoading()
                const request_data = this.prepareRequestData()
                const response = await sendRequest(
                    Api.bao_cao.bao_cao_dong.bao_cao_truong_hoc.danh_sach,
                    request_data,
                    this.request_headers
                )
                this.tableRows = response.tableRows
                this.tableHeader = response.tableHeaders.map((item) => ({
                    ...item,
                    key: item.index,
                    label: item.value
                }))
                this.total_rows = response.total
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        },
        async handleExportExcel() {
            showLoading()

            try {
                showLoading()
                const domain = process.env.VUE_APP_BACKEND_BASE_URL
                const request_data = this.prepareRequestData()
                const apiUrl = `${domain}/${Api.bao_cao.bao_cao_dong.bao_cao_truong_hoc.xuat_excel.url}`
                const fileName = 'bao_cao_truong_hoc.xlsx'
                const authToken = this.auth_user.token

                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken
                )
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore: {
            handler(newVal) {
                if (newVal) {
                    this.get_list_school()
                }
            },
            immediate: true
        },
        advanced_search(newVal, oldVal) {
            this.column_form = calculateColumnFormLength(newVal.length, 1, 12)
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        },
        contentDisplaySchoolReport(newVal) {
            if (newVal) {
                this.handleSearch()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_school_type() {
            return getFromSessionStorage('DM_LOAI_HINH_TRUONG')
        },
        list_national_standards() {
            return getFromSessionStorage('DM_MUC_DAT_CHUAN_QG_CLGD')
        },
        list_difficult_areas() {
            return getFromSessionStorage('DM_VUNG_KHO_KHAN')
        },

        ...mapState('dynamicReport', {
            contentDisplaySchoolReport: (state) =>
                state.contentDisplaySchoolReport
        }),

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
.el-menu-item {
    height: 45px;
    line-height: 45px;
    background: #f5f5f5;
    color: black;
    border-top: 1px solid #ddd;
    font-size: 14px;
}
.el-menu.el-menu--horizontal {
    margin-left: -20px;
}
</style>
