<template>
    <div>
        <CustomTable :column-form="2" :tab="true" :hidePagi="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <tr>
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Địa phương</th>
                    <th colspan="2">Tổng số</th>
                    <th colspan="2">Trẻ &lt; 24 tháng</th>
                    <th colspan="2">Trẻ 25 - 36 tháng</th>
                    <th colspan="2">Trẻ 36 - 48 tháng</th>
                    <th colspan="2">Trẻ 48 - 60 tháng</th>
                    <th colspan="2">Trẻ 61 - 72 tháng</th>
                    <th colspan="2">Trẻ MG từ 73 tháng trở lên</th>
                </tr>
                <tr>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Nữ</th>
                    <th rowspan="1">Tổng</th>
                    <th rowspan="1">Nữ</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="active_tab === 1">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>

                        <td v-for="td in list_td_active_tab_1" :key="td.index">
                            {{ item[1][td] }}
                        </td>
                    </tr>
                </template>

                <template v-if="active_tab === 2">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>

                        <td v-for="td in list_td_active_tab_2" :key="td.index">
                            {{ item[1][td] }}
                        </td>
                    </tr>
                </template>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportAge',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Nhà trẻ' },
                { number: 2, label: 'Mẫu giáo' }
            ],

            list_td_active_tab_1: [
                'E23',
                'F23',
                'E24',
                'F24',
                'E25',
                'F25',
                'E30',
                'F30',
                'E31',
                'F31',
                'E32',
                'F32',
                'E33',
                'F33'
            ],
            list_td_active_tab_2: [
                'E23',
                'F23',
                'E24',
                'F24',
                'E25',
                'F25',
                'E30',
                'F30',
                'E31',
                'F31',
                'E32',
                'F32',
                'E33',
                'F33'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>
