<template>
    <div>
        <ToolBar
            :menu="'Kết chuyển dữ liệu lên Bộ'"
            :content="'Mật khẩu kết chuyển IOC'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    :btnToolBar="true"
                    @click="openModalAddNew"
                />
                <CustomButton
                    label="Nhập theo biểu mẫu"
                    :icon="'el-icon-document'"
                    :btnToolBar="true"
                    @click="openModalUpload"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        @change="get_list_school"
                        v-model="unit_education"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Trường học </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="1" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Thao tác</th>
                    <th>Đơn vị quản lý</th>
                    <th>Trường học</th>
                    <th>Mã trường</th>
                    <th>Tên đăng nhập</th>
                    <!-- <th>Tỉnh thành</th>
                    <th>Cấp học</th> -->
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="25">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>
                        <CustomToolTip content="Chỉnh sửa">
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="openModalUpdate(item)"
                            />
                        </CustomToolTip>
                    </td>
                    <td class="text-left">{{ item.tenDonvi }}</td>
                    <td class="text-left">{{ item.tenTruong }}</td>
                    <td class="text-left">{{ item.maTruong }}</td>
                    <td class="text-left">{{ item.tenDangNhap }}</td>
                    <!-- <td class="text-left">{{ item.tenTinhThanh }}</td>
                    <td class="text-left">
                        {{ getNameGradeLevel(item.capHoc) }}
                    </td> -->
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
        />
        <ModalUpdate
            :closeModalUpdate="closeModalUpdate"
            :updateVisible="updateVisible"
            @handleSearch="handleSearch"
            :dataUpdate="dataUpdate"
        />
        <ModalUpload
            :closeModalUpload="closeModalUpload"
            :uploadVisible="uploadVisible"
            @handleSearch="handleSearch"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomToolTip from '@/components/CustomToolTip.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import ModalUpload from './modal_upload.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'DataTransferSchedule',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomToolTip,
        ModalAddNew,
        ModalUpdate,
        ModalUpload
    },
    data() {
        return {
            iconEdit,
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: '',

            list_school: [],
            school: '',

            tableData: [],
            addNewVisible: false,
            updateVisible: false,
            dataUpdate: {},
            uploadVisible: false
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },
        openModalUpload() {
            this.uploadVisible = true
        },
        closeModalUpload() {
            this.uploadVisible = false
        },
        getNameGradeLevel(type) {
            const typeMapping = {
                1: 'Tiểu học',
                2: 'THCS',
                3: 'THPT',
                4: 'Nhà trẻ',
                5: 'Mẫu giáo',
                12: 'PTCS',
                1245: 'MN-PTCS',
                145: 'MN-TH',
                23: 'Trung học',
                123: 'Phổ thông',
                12345: 'MN-PT',
                45: 'Mầm non',
                6: 'GDTX'
            }

            return typeMapping[type] || type
        },
        async get_list_school() {
            this.school = ''

            showLoading()

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education ? [this.unit_education] : []
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
            hideLoading()
        },

        async handleSearch() {
            try {
                showLoading()
                const request_data = {
                    start: this.start,
                    limit: this.limit,
                    maTinhThanh: this.auth_user.ma_so,
                    maDonVi: this.unit_education,
                    maTruongHoc: this.school,
                    // capHoc: this.selectedGradeLevelFromStore
                    capHoc: ''
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.mat_khau_ket_chuyen_ioc
                        .danh_sach,
                    request_data,
                    this.request_headers,
                    null
                )
                if (response.code == 200) {
                    this.tableData = response.rows
                    this.total_rows = response.total
                } else {
                    showNotication(this, 'error', response.message)
                    this.tableData = []
                    this.total_rows = 0
                }
                hideLoading()
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 15px;
    cursor: pointer;
}
</style>
