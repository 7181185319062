<template>
    <div>
        <el-dialog
            :visible="viewVisible"
            @close="closeModalView"
            :fullscreen="true"
        >
            <span slot="title">
                <span class="uppercase-text">
                    {{ getNameBatch() }}: TRƯỜNG {{ dataView.tenTruongHoc }} (MÃ:
                    {{ dataView.maTruongHoc }}) - Năm học:
                    {{ dataView.namHoc }}-{{ dataView.namHoc + 1 }}
                </span>

                <hr />
                <div class="row">
                    <div class="col-md-4">
                        <div>
                            {{ dataView.maDoiTac }}
                        </div>
                        <div>Mã lần đẩy: {{ dataView.reqMessId }}</div>
                    </div>

                    <div class="col-md-4">
                        <div>
                            Duration time phản hồi: {{ dataView.timePhanHoi }}
                        </div>
                        <div>
                            Dữ liệu đẩy lên:
                            <CustomButton
                                label="Xem chi tiết"
                                :btnToolBar="true"
                                @click="openModalDataUp"
                            />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div>
                            Đợt đồng bộ:
                            {{
                                getLabelByArray(
                                    list_synchronou_stype,
                                    dataView.loaiDuLieu
                                )
                            }}
                        </div>
                        <div>
                            Dữ liệu nhận được:
                            <CustomButton
                                label="Xem chi tiết"
                                :btnToolBar="true"
                                @click="openModalDataReceived"
                            />
                        </div>
                    </div>
                </div>
            </span>

            <CustomTable
                :table-modal="true"
                :hide-pagi="true"
                :hide-btn-table="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Tên dữ liệu</th>
                        <th>Nhận thành công</th>
                        <th>Nhận thất bại</th>
                        <th>Kết quả nhận</th>
                        <th>Đồng bộ thành công</th>
                        <th>Đồng bộ thất bại</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="tableDataView.length === 0">
                        <tr>
                            <td colspan="11">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, key) in tableDataView" :key="key">
                        <td>
                            {{ key + 1 }}
                        </td>
                        <td>
                            {{ getLabelByArray(list_table_name, item.tableName) }}
                        </td>
                        <td>
                            {{ item.numRecordRecievedSuccess }}
                        </td>
                        <td>
                            {{ item.numRecordRecievedError }}
                        </td>
                        <td>
                            <span v-if="item.numRecordRecievedError != 0">
                                <CustomButton
                                    label="Xem "
                                    :btnToolBar="true"
                                    @click="
                                        openModalGetResults(item.id, item.subId)
                                    "
                                />
                            </span>
                            <span></span>
                        </td>
                        <td>
                            {{ item.numRecordSyncSuccess }}
                        </td>
                        <td>
                            {{ item.numRecordSyncError }}
                        </td>
                    </tr>
                </template>
            </CustomTable>

            <span slot="footer">
                <CustomButton
                    label="Đóng"
                    class="fixed-btn"
                    :btnToolBar="true"
                    @click="handleCloseModalView"
                />
            </span>
        </el-dialog>

        <!-- ************ Modal Dữ liệu đẩy lên - Chi tiết ************ -->
        <el-dialog
            :visible.sync="dataUpVisible"
            width="50%"
            @close="closeModalDataUp"
        >
            <span slot="title">
                Chi tiết (Dữ liệu đẩy lên)
                <hr />
            </span>

            <textarea
                class="language-json myTextArea"
                style="width: 100%; margin: 0 auto; height: 50vh"
                v-model="dataUpJSon"
                id="myTextArea"
                cols="50"
                rows="10"
            >
            </textarea>

            <hr />
            <span slot="footer">
                <CustomButton
                    label="Đóng"
                    :btnToolBar="true"
                    @click="closeModalDataUp"
                />
            </span>
        </el-dialog>

        <!-- ************ Modal Dữ liệu nhận về - Chi tiết ************ -->
        <el-dialog
            :visible.sync="dataReceivedVisible"
            width="50%"
            @close="closeModalDataReceived"
        >
            <span slot="title">
                Chi tiết (Dữ liệu nhận về)
                <hr />
            </span>

            <textarea
                class="language-json myTextArea"
                style="width: 100%; margin: 0 auto; height: 50vh"
                v-model="dataReceivedJSon"
                id="myTextArea"
                cols="50"
                rows="10"
            >
            </textarea>
            <hr />
            <span slot="footer">
                <CustomButton
                    label="Đóng"
                    :btnToolBar="true"
                    @click="closeModalDataReceived"
                />
            </span>
        </el-dialog>

        <!-- ************ Modal Kết quả nhận -  Chi tiết ************ -->
        <el-dialog
            :visible.sync="getResultsVisible"
            width="60%"
            @close="closeModalGetResults"
        >
            <span slot="title"> Chi tiết </span>
            <hr />

            <CustomTable
                :table-modal="true"
                :hide-pagi="true"
                :hide-btn-table="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Mã học sinh</th>
                        <th>Trạng thái</th>
                        <th>Mô tả</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="tableDataGetResult.length === 0">
                        <tr>
                            <td colspan="11">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, i) in tableDataGetResult" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td>
                            <div>{{ item.MaHocSinh }}</div>
                        </td>
                        <td>
                            {{
                                parseInt(item.Status) === 1
                                    ? 'Thành công'
                                    : 'Thất bại'
                            }}
                        </td>
                        <td>
                            {{ item.Message }}
                        </td>
                    </tr>
                </template>
            </CustomTable>

            <hr />
            <span slot="footer">
                <CustomButton
                    label="Đóng"
                    :btnToolBar="true"
                    @click="closeModalGetResults"
                />
            </span>
        </el-dialog>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomTable from '@/components/CustomTable.vue'
import {
    list_synchronou_stype,
    list_table_name
} from '@/mock_data/list-su-tiep-nhan-du-lieu'
import { getLabelByArray } from '@/utils'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { getFromSessionStorage } from '@/utils/session'
export default {
    name: 'ModalView',
    components: { CustomButton, CustomTable },
    props: {
        viewVisible: {
            type: Boolean,
            required: true
        },
        closeModalView: Function,
        dataView: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            list_synchronou_stype: list_synchronou_stype,
            list_table_name: list_table_name,
            tableDataView: [],

            dataUpVisible: false,
            dataUpJSon: {},

            dataReceivedVisible: false,
            dataReceivedJSon: {},

            getResultsVisible: false,
            tableDataGetResult: [],

            errModal: false
        }
    },

    watch: {
        viewVisible(newVal) {
            if (newVal === true) {
                this.get_data_table_view()
            }
        }
    },

    methods: {
        getLabelByArray,
        getNameBatch() {
            const batch = this.dataView.loaiDot

            const batchMapping = {
                1: 'Đẩy thông tin đầu năm',
                2: 'Đẩy thông tin phát sinh',
                3: 'Đẩy thông tin cuối năm',
                4: 'Đồng bộ dữ liệu cũ'
            }

            return batchMapping[batch] || ''
        },
        async get_data_table_view() {
            try {
                const request_params = {
                    id: this.dataView.maGiaoDich
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.lich_su_tiep_nhan_du_lieu.chi_tiet,
                    null,
                    this.request_headers,
                    request_params
                )
                this.tableDataView = response.item.listDetail
            } catch (error) {
                console.log(error)
            }
        },

        async openModal(type) {
            try {
                const modalVisibilityKey =
                    type === 1 ? 'dataUpVisible' : 'dataReceivedVisible'
                const dataKey = type === 1 ? 'dataUpJSon' : 'dataReceivedJSon'

                this[modalVisibilityKey] = true

                const request_params = {
                    id: this.dataView.maGiaoDich,
                    type: type
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.lich_su_tiep_nhan_du_lieu
                        .ket_qua_nhan_chi_tiet,
                    null,
                    this.request_headers,
                    request_params
                )

                const convertJsonParse = JSON.parse(response.item.detail)
                this[dataKey] = JSON.stringify(convertJsonParse, null, 2)
            } catch (error) {
                console.log(error)
            }
        },

        openModalDataUp() {
            this.openModal(1)
        },

        closeModalDataUp() {
            this.dataUpVisible = false
        },
        openModalDataReceived() {
            this.openModal(2)
        },

        closeModalDataReceived() {
            this.dataReceivedVisible = false
        },

        async openModalGetResults(id, subId) {
            this.getResultsVisible = true

            try {
                const request_params = {
                    id: id,
                    subId: subId,
                    type: 3
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.lich_su_tiep_nhan_du_lieu
                        .ket_qua_nhan_chi_tiet,
                    null,
                    this.request_headers,
                    request_params
                )

                this.tableDataGetResult = JSON.parse(response.item.detail)
            } catch (error) {
                console.log(error)
            }
        },
        closeModalGetResults() {
            this.getResultsVisible = false
        },

        handleCloseModalView() {
            this.closeModalView()
        }
    },
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.uppercase-text {
    text-transform: uppercase;
}
.lowercase-text {
    text-transform: lowercase;
}

.fixed-btn {
    position: fixed;
    bottom: 20px;
    left: 95%;
    transform: translateX(-50%);
    z-index: 999;
}
.error-label {
    padding-left: 20px;
    padding-top: 7px;
    font-weight: 700;
}

.error-icon {
    color: red;
}

.error-text {
    color: blue;
    font-weight: 700;
}
::v-deep .el-dialog__body {
    padding: 0px 20px 0px 20px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #186ab2;
}
</style>
