import { Loading } from 'element-ui'

let loadingInstance

export const showLoading = () => {
    loadingInstance = Loading.service({
        lock: true,
        text: 'Hệ thống đang tải dữ liệu ...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}

export const hideLoading = () => {
    if (loadingInstance) {
        loadingInstance.close()
    }
}
