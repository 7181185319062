<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th colspan="5">Nhân sự chia theo chức vụ</th>
                <th colspan="18">Trình độ chuyên ngành chính</th>
            </tr>
            <tr>
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Hiệu trưởng</th>
                <th rowspan="2">Phó hiệu trưởng</th>
                <th rowspan="2">Giáo viên</th>
                <th rowspan="2">Nhân viên</th>

                <th colspan="6">Hiệu trưởng</th>
                <th colspan="6">Phó Hiệu trưởng</th>
                <th colspan="6">Giáo viên</th>
            </tr>
            <tr>
                <th rowspan="1">Tổng cộng</th>
                <th rowspan="1">Tiến sĩ</th>
                <th rowspan="1">Thạc sĩ</th>
                <th rowspan="1">Đại học</th>
                <th rowspan="1">Cao đẳng</th>
                <th rowspan="1">Trung cấp</th>
                <th rowspan="1">Tổng cộng</th>
                <th rowspan="1">Tiến sĩ</th>
                <th rowspan="1">Thạc sĩ</th>
                <th rowspan="1">Đại học</th>
                <th rowspan="1">Cao đẳng</th>
                <th rowspan="1">Trung cấp</th>
                <th rowspan="1">Tổng cộng</th>
                <th rowspan="1">Tiến sĩ</th>
                <th rowspan="1">Thạc sĩ</th>
                <th rowspan="1">Đại học</th>
                <th rowspan="1">Cao đẳng</th>
                <th rowspan="1">Trung cấp</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td>{{ item.soLuongTong }}</td>
                <td>{{ item.soLuongHieuTruong }}</td>
                <td>{{ item.soLuongHieuPho }}</td>
                <td>{{ item.soLuongGiaoVien }}</td>
                <td>{{ item.soLuongNhanVien }}</td>

                <td>{{ item.soLuongHieuTruong }}</td>
                <td>{{ item.hieuTruongTienSi }}</td>
                <td>{{ item.hieuTruongThacSi }}</td>
                <td>{{ item.hieuTruongDaiHoc }}</td>
                <td>{{ item.hieuTruongCaoDang }}</td>
                <td>{{ item.hieuTruongTrungCap }}</td>

                <td>{{ item.soLuongHieuPho }}</td>
                <td>{{ item.hieuPhoTienSi }}</td>
                <td>{{ item.hieuPhoThacSi }}</td>
                <td>{{ item.hieuPhoDaiHoc }}</td>
                <td>{{ item.hieuPhoCaoDang }}</td>
                <td>{{ item.hieuPhoTrungCap }}</td>

                <td>{{ item.soLuongGiaoVien }}</td>
                <td>{{ item.giaoVienTienSi }}</td>
                <td>{{ item.giaoVienThacSi }}</td>
                <td>{{ item.giaoVienDaiHoc }}</td>
                <td>{{ item.giaoVienCaoDang }}</td>
                <td>{{ item.giaoVienTrungCap }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'TeachersInTheTrainingFieldSubjects',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
