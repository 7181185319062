<template>
    <CustomTable :column-form="2" :hide-btn-table="true" :hidePagi="true">
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="3">Tổng số</th>
                <th colspan="1">Trung tâm GDTX cấp tỉnh</th>
                <th colspan="1">
                    Trung tâm GDTX cấp huyện (quận, huyện, thị xã, tp thuộc tỉnh)
                </th>
                <th colspan="3">Trung tâm học tập cộng đồng</th>
                <th colspan="3">Trung tâm ngoại ngữ - tin học</th>
            </tr>
            <tr>
                <th rowspan="2">Tổng số trường</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th rowspan="2">Công lập</th>
                <th rowspan="2">Công lập</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
            </tr>
            <tr>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="21">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left min-width">
                    {{ item['H3'] }}
                </td>

                <td v-for="td in list_td" :key="td.index">
                    {{ item[td] }}
                </td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportBasis',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            list_td: [
                'F9',
                'G9',
                'H9',
                'G10',
                'H10',
                'F12',
                'G12',
                'H12',
                'F13',
                'G13',
                'H13'
            ]
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
