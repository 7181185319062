import { render, staticRenderFns } from "./TextStatus.vue?vue&type=template&id=bfe22570&scoped=true&"
import script from "./TextStatus.vue?vue&type=script&lang=js&"
export * from "./TextStatus.vue?vue&type=script&lang=js&"
import style0 from "./TextStatus.vue?vue&type=style&index=0&id=bfe22570&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfe22570",
  null
  
)

export default component.exports