<template>
    <CustomModal
        :centered="false"
        :is-modal-visible="updateVisible"
        :modal-title="'Chỉnh sửa cấu hình lịch đồng bộ cấp trường'"
        :close-function="closeModalUpdate"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div>
                Hệ thống sẽ thực hiện thay đổi thời gian tiếp nhận dữ liệu của
                trường sau.
            </div>
            <div class="mt-3 mb-3 d-flex">
                <div class="flex-grow-1">
                    <DatePicker
                        v-model="start_date"
                        @input="onChangeHandler('start_date', $event)"
                        :showError="err_start_date.length > 0"
                    />
                    <span v-if="err_start_date.length > 0" class="error">
                        {{ err_start_date }}
                    </span>
                </div>

                <div class="to-date">Đến</div>

                <div class="flex-grow-1">
                    <DatePicker
                        v-model="end_date"
                        @input="onChangeHandler('end_date', $event)"
                        :showError="err_end_date.length > 0"
                    />
                    <span v-if="err_end_date.length > 0" class="error">
                        {{ err_end_date }}
                    </span>
                </div>
            </div>
            <CustomTable :table-modal="true" :hide-pagi="true">
                <template v-slot:header>
                    <tr>
                        <th>STT</th>

                        <th>Tên trường</th>
                        <th>Mã trường</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataUpdate.length === 0">
                        <tr>
                            <td colspan="3">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in dataUpdate" :key="index">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td class="text-left">{{ item.tenTruongHoc }}</td>
                        <td>{{ item.maTruongHoc }}</td>
                    </tr>
                </template>
            </CustomTable>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleUpdate"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
import CustomTable from '@/components/CustomTable.vue'
import DatePicker from '@/components/DatePicker.vue'
import { formatDateToDDMMYYYY, formatDateToYYYYMMDD } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
export default {
    name: 'ModalUpdate',
    data() {
        return {
            start_date: '',
            end_date: '',
            err_end_date: '',
            err_start_date: '',
            errModal: false
        }
    },
    components: { CustomButton, CustomModal, CustomTable, DatePicker },
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },
        dataUpdate: {
            type: Array,
            required: true
        },

        closeModalUpdate: Function,
        handleSearch: Function
    },
    watch: {
        dataUpdate(newVal) {
            if (newVal.length === 1) {
                const start_date = newVal.map((item) => item.tuNgay)
                this.start_date = formatDateToYYYYMMDD(start_date)
                const end_date = newVal.map((item) => item.denNgay)
                this.end_date = formatDateToYYYYMMDD(end_date)
            } else {
                this.start_date = ''
                this.end_date = ''
            }
        }
    },
    methods: {
        onChangeHandler(source, newValue) {
            const errorFields = {
                start_date: 'err_start_date',
                end_date: 'err_end_date'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async handleUpdate() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.start_date,
                        errorField: 'err_start_date'
                    },
                    {
                        value: this.end_date,
                        errorField: 'err_end_date'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    showLoading()
                    const dsMaTruong = this.dataUpdate.map(
                        (item) => item.maTruongHoc
                    )

                    const request_data = {
                        tuNgay: formatDateToDDMMYYYY(this.start_date),
                        denNgay: formatDateToDDMMYYYY(this.end_date),
                        statusUpdate: this.statusUpdate,
                        dsMaTruong: dsMaTruong,
                        maSo: this.auth_user.ma_so,
                        namHoc: this.selectedYearFromStore
                    }
                    const response = await sendRequest(
                        Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo
                            .thay_doi_ngay_cap_truong,
                        request_data,
                        this.request_headers,
                        null
                    )
                    if (response.rc == 0) {
                        hideLoading()
                        showNotication(this, 'success', response.rd)
                        this.closeModalUpdate()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.rd)
                        hideLoading()
                    }
                }
            } catch (error) {
                hideLoading()
            }
        }
    },
    mounted() {},
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.text-center {
    text-align: center;
}
.to-date {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.error {
    color: red;
}
::v-deep .is-error .el-input__inner {
    border: 1px solid #f56c6c !important;
}
::v-deep .is-error .el-input__inner:focus {
    border: 1px solid #f56c6c !important;
    box-shadow: 0 0 3px #f56c6c;
}
</style>
