<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="salaryProcessVisible"
        :modal-title="'Quá trình lương'"
        :close-function="closeModalSalaryProcess"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <CustomTable
                :column-form="2"
                :hide-btn-table="true"
                :hidePagi="true"
                :tableModal="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Ngày hưởng</th>
                        <th>Ngạch/hạng *</th>
                        <th>Bậc lương *</th>
                        <th>Hệ số lương *</th>
                        <th>% vượt khung</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataSalaryProcess.length === 0">
                        <tr>
                            <td colspan="10">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in dataSalaryProcess" :key="index">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td>
                            <DatePicker
                                v-model="item.NgayThangNam"
                                :disabled="true"
                            />
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_rank"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaNgach"
                            />
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_pay_scale"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.MaBacLuong"
                            />
                        </td>
                        <td>
                            <CustomInput
                                size="small"
                                v-model="item.HeSoLuong"
                                placeholder="Nhập..."
                                :disabled="true"
                                :clearable="false"
                                :show-password="false"
                                :showCloseIcon="true"
                            >
                            </CustomInput>
                        </td>
                        <td>
                            <CustomInput
                                size="small"
                                v-model="item.PhanTramVuotKhung"
                                placeholder="Nhập..."
                                :disabled="true"
                                :clearable="false"
                                :show-password="false"
                                :showCloseIcon="true"
                            >
                            </CustomInput>
                        </td>
                    </tr>
                </template>
            </CustomTable>
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import CustomModal from '@/components/CustomModal.vue'
import CustomTable from '@/components/CustomTable.vue'
import DatePicker from '@/components/DatePicker.vue'
import { getFromSessionStorage } from '@/utils/session'
import { list_pay_scale } from '@/mock_data/tra-cuu-can-bo-giao-vien'
export default {
    name: 'ModalSalaryProcess',
    data() {
        return { errModal: false, tableData: [], list_pay_scale: list_pay_scale }
    },
    props: {
        salaryProcessVisible: {
            type: Boolean,
            required: true
        },
        closeModalSalaryProcess: Function,
        dataSalaryProcess: {
            type: Array,
            required: true
        }
    },

    components: {
        CustomModal,
        CustomTable,
        DatePicker,
        ESelect,
        CustomInput
    },
    methods: {},
    mounted() {},
    computed: {
        list_rank() {
            return getFromSessionStorage('DM_NGACH_CC')
        }
    }
}
</script>

<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    color: black !important;
}
</style>
