<template>
    <div>
        <CustomTable :column-form="2" :tab="true" :hidePagi="true">
            <template v-slot:btn-table>
                <CustomTab
                    :initialTab="active_tab"
                    :tabs="list_tab"
                    @tabChanged="handleTabChanged"
                />
            </template>
            <template v-slot:header>
                <!-- 1. Trẻ em -->
                <tr v-if="active_tab === 1">
                    <th rowspan="3">STT</th>
                    <th rowspan="3">Địa phương</th>
                    <th colspan="4">Tổng số</th>
                    <th colspan="4">Trẻ em nhà trẻ</th>
                    <th colspan="5">Trẻ em mẫu giáo</th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="2">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2">Tổng số</th>
                    <th colspan="3">Chia ra</th>
                    <th rowspan="2">
                        Trong đó: <br />
                        Trẻ em 5 đến 6 tuổi
                    </th>
                </tr>
                <tr v-if="active_tab === 1">
                    <th rowspan="1">Công lập</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Công lập</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                    <th rowspan="1">Công lập</th>
                    <th rowspan="1">Tư thục</th>
                    <th rowspan="1">Dân lập</th>
                </tr>

                <!-- 2. Độ tuổi nhà trẻ -->
                <tr v-if="active_tab === 2">
                    <th rowspan="2">STT</th>
                    <th rowspan="2">Địa phương</th>
                    <th rowspan="2">Trẻ em nhà trẻ được kiểm tra sức khỏe</th>
                    <th colspan="4">Trong đó</th>
                    <th rowspan="2">Trẻ em mẫu giáo được kiểm tra sức khỏe</th>
                    <th colspan="4">Trong đó</th>
                </tr>
                <tr v-if="active_tab === 2">
                    <th rowspan="1">Trẻ suy dinh dưỡng thể nhẹ cân</th>
                    <th rowspan="1">Trẻ suy dinh dưỡng thể thấp còi</th>
                    <th rowspan="1">Trẻ suy dinh dưỡng thể còi cọc</th>
                    <th rowspan="1">Trẻ thừa cân, béo phì</th>
                    <th rowspan="1">Trẻ suy dinh dưỡng thể nhẹ cân</th>
                    <th rowspan="1">Trẻ suy dinh dưỡng thể thấp còi</th>
                    <th rowspan="1">Trẻ suy dinh dưỡng thể còi cọc</th>
                    <th rowspan="1">Trẻ thừa cân, béo phì</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="active_tab === 1">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>
                        <td>{{ item['F21'] }}</td>
                        <td>{{ item['G21'] }}</td>
                        <td>{{ item['H21'] }}</td>
                        <td>{{ item['I21'] }}</td>
                        <td>{{ item['F22'] }}</td>
                        <td>{{ item['G22'] }}</td>
                        <td>{{ item['H22'] }}</td>
                        <td>{{ item['I22'] }}</td>
                        <td>{{ item['F23'] }}</td>
                        <td>{{ item['G23'] }}</td>
                        <td>{{ item['H23'] }}</td>
                        <td>{{ item['I23'] }}</td>
                        <td>{{ item['F25'] }}</td>
                    </tr>
                </template>
                <template v-if="active_tab === 2">
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="50">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-left min-width">
                            {{ item['H3'] }}
                        </td>
                        <td>{{ item['F27'] }}</td>
                        <td>{{ item['F29'] }}</td>
                        <td>{{ item['F30'] }}</td>
                        <td>{{ item['F31'] }}</td>
                        <td>{{ item['F32'] }}</td>
                        <td>{{ item['F33'] }}</td>
                        <td>{{ item['F35'] }}</td>
                        <td>{{ item['F36'] }}</td>
                        <td>{{ item['F37'] }}</td>
                        <td>{{ item['F38'] }}</td>
                    </tr>
                </template>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trẻ em' },
                { number: 2, label: 'Tình trạng dinh dưỡng trẻ em' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    }
}
</script>

<style scoped></style>
