<template>
    <div>
        <ReportSchoolClass
            :tableData="tableData"
            v-if="detailed_report === 1"
            :start="start"
            :limit="limit"
        />

        <ReportSchoolCcriteriaSDG
            :tableData="tableData"
            v-if="detailed_report === 2"
            :start="start"
            :limit="limit"
        />

        <ReportStudent
            :tableData="tableData"
            v-if="detailed_report === 3"
            :start="start"
            :limit="limit"
        />

        <ReportAge
            :tableData="tableData"
            v-if="detailed_report === 4"
            :start="start"
            :limit="limit"
        />
        <ReportTeachers
            :tableData="tableData"
            v-if="detailed_report === 5"
            :start="start"
            :limit="limit"
        />
        <ReportCSVC
            :tableData="tableData"
            v-if="detailed_report === 6"
            :start="start"
            :limit="limit"
        />
        <ReportEducationBySchoolType
            :tableData="tableData"
            v-if="detailed_report === 7"
            :start="start"
            :limit="limit"
        />
        <ReportStateBudget
            :tableData="tableData"
            v-if="detailed_report === 8"
            :start="start"
            :limit="limit"
        />
    </div>
</template>

<script>
import ReportSchoolClass from './ReportSchoolClass'
import ReportSchoolCcriteriaSDG from './ReportSchoolCcriteriaSDG'
import ReportStudent from './ReportStudent'
import ReportAge from './ReportAge.vue'
import ReportTeachers from './ReportTeachers.vue'
import ReportCSVC from './ReportCSVC.vue'
import ReportEducationBySchoolType from './ReportEducationBySchoolType.vue'
import ReportStateBudget from './ReportStateBudget'
export default {
    name: 'ReportEmis',
    props: {
        detailed_report: {
            type: Number,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    components: {
        ReportSchoolClass,
        ReportSchoolCcriteriaSDG,
        ReportStudent,
        ReportAge,
        ReportTeachers,
        ReportCSVC,
        ReportEducationBySchoolType,
        ReportStateBudget
    },
    data() {
        return {}
    },

    methods: {},
    mounted() {},
    watch: {},

    computed: {}
}
</script>

<style scoped></style>
