<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. CBQL -->
            <tr v-if="active_tab === 1">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-200">Địa phương</th>
                <th rowspan="4" class="min-w-200">Cán bộ quản lý</th>
                <th colspan="5">Chia theo trình độ đào tạo</th>
                <th rowspan="4" class="min-w-200">Trong đó: người nước ngoài</th>
                <th colspan="7">Giám đốc</th>
                <th colspan="7">Phó giám đốc</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="3" class="min-w-200">Tiến sĩ</th>
                <th rowspan="3" class="min-w-200">Thạc sĩ</th>
                <th rowspan="3" class="min-w-200">Đại học</th>
                <th rowspan="3" class="min-w-200">Cao đẳng</th>
                <th rowspan="3" class="min-w-200">Khác</th>
                <th rowspan="3" class="min-w-200">Tổng số</th>
                <th colspan="3">Trong tổng số</th>
                <th colspan="3">Hình thức hợp đồng</th>
                <th rowspan="3" class="min-w-200">Tổng số</th>
                <th colspan="3">Trong tổng số</th>
                <th colspan="3">Hình thức hợp đồng</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ DT</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 2. Giáo viên -->
            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-200">Địa phương</th>
                <th rowspan="3" class="min-w-200">Tổng số giáo viên</th>
                <th colspan="5">Chia theo trình độ đào tạo</th>
                <th rowspan="3" class="min-w-200">Trong đó: người nước ngoài</th>
                <th colspan="7">Giáo viên giảng dạy văn hóa</th>
                <th colspan="7">Giáo viên dạy hướng nghiệp và nghề</th>
                <th colspan="6">Chia ra theo độ tuổi</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2" class="min-w-200">Tiến sĩ</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học</th>
                <th rowspan="2" class="min-w-200">Cao đẳng</th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th rowspan="2" class="min-w-200">Tổng số</th>
                <th colspan="3">Trong tổng số</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Tổng số</th>
                <th colspan="3">Trong tổng số</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Từ 20-29 Tuổi</th>
                <th rowspan="2" class="min-w-200">Từ 30-39 Tuổi</th>
                <th rowspan="2" class="min-w-200">Từ 40-49 Tuổi</th>
                <th rowspan="2" class="min-w-200">Từ 50-54 Tuổi</th>
                <th rowspan="2" class="min-w-200">Từ 55-59 Tuổi</th>
                <th rowspan="2" class="min-w-200">Từ 60 tuổi</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1" class="min-w-200">Nữ</th>
                <th rowspan="1" class="min-w-200">Dân tộc</th>
                <th rowspan="1" class="min-w-200">Nữ DT</th>
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
                <th rowspan="1" class="min-w-200">Nữ</th>
                <th rowspan="1" class="min-w-200">Dân tộc</th>
                <th rowspan="1" class="min-w-200">Nữ DT</th>
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 3. Nhân viên -->
            <tr v-if="active_tab === 3">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-200">Địa phương</th>
                <th rowspan="3" class="min-w-200">Tổng số nhân viên</th>
                <th colspan="3">Trong tổng số</th>
                <th colspan="7">Chia ra</th>
                <th colspan="3">Hình thức hợp đồng</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ DT</th>
                <th rowspan="2" class="min-w-200">Nhân viên thư viện</th>
                <th rowspan="2" class="min-w-200">Nhân viên thiết bị</th>
                <th rowspan="2" class="min-w-200">Nhân viên y tế</th>
                <th rowspan="2" class="min-w-200">Nhân viên kế toán</th>
                <th rowspan="2" class="min-w-200">Nhân viên thủ quỹ</th>
                <th rowspan="2" class="min-w-200">
                    Nhân viên kiêm nhiệm nhiều việc
                </th>
                <th rowspan="2" class="min-w-200">
                    Cán bộ, giáo viên theo hình thức cộng tác, phối hợp
                </th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 4. CBGV công tác -->
            <tr v-if="active_tab === 4">
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th rowspan="2">
                    Cán bộ quản lý, giáo viên theo hình thức cộng tác, phối hợp
                </th>
                <th colspan="3">Chia ra</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="1">Cán bộ quản lý</th>
                <th rowspan="1">Giáo viên biệt phái, kiêm nhiệm</th>
                <th rowspan="1">Báo cáo viên, cộng tác viên</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 3">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 4">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_4" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportTeachers',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'CBQL' },
                { number: 2, label: 'Giáo viên' },
                { number: 3, label: 'Nhân viên' },
                { number: 4, label: 'CBGV công tác' }
            ],

            list_td_active_tab_1: [
                'E6',
                'E15',
                'E14',
                'E13',
                'E12',
                'E16',
                '',
                'E9',
                'F9',
                'G9',
                'H9',
                'J9',
                'K9',
                'L9',
                'E10',
                'F10',
                'G10',
                'H10',
                'J10',
                'K10',
                'L10'
            ],
            list_td_active_tab_2: [
                'E17',
                'E22',
                'E21',
                'E20',
                'E19',
                'E23',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'E25',
                'E26',
                'E27',
                'E28',
                'E29',
                'E30'
            ],
            list_td_active_tab_3: [
                'E31',
                'F31',
                'G31',
                'H31',
                'E33',
                'E34',
                'E35',
                'E36',
                'E37',
                'E38',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_4: ['', '', '', '']
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>
<style scoped>
.min-w-200 {
    min-width: 200px;
}
</style>
