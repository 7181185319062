<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="careerAssessmentVisible"
        :modal-title="'Đánh giá chuẩn nghề nghiệp'"
        :close-function="closeModalCareerAssessment"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <CustomTable
                :column-form="2"
                :hide-btn-table="true"
                :hidePagi="true"
                :tableModal="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Tiêu chí</th>
                        <th>Nội dung đánh giá</th>
                        <th>Tự đánh giá</th>
                        <th>Cấp trên đánh giá</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataCareerAssessment.length === 0">
                        <tr>
                            <td colspan="10">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr
                        v-for="(item, index) in dataCareerAssessment"
                        :key="index"
                    >
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td>
                            <CustomInput
                                v-model="item.maTieuChi"
                                placeholder=""
                                :disabled="true"
                            >
                            </CustomInput>
                        </td>
                        <td>
                            <CustomInput
                                v-model="item.noiDungDanhGia"
                                placeholder=""
                                :disabled="true"
                            >
                            </CustomInput>
                        </td>
                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_career_assessment"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                :value="convertNumberToString(item.tuDanhGia)"
                            />
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_career_assessment"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                :value="
                                    convertNumberToString(item.capTrenDanhGia)
                                "
                            />
                        </td>
                    </tr> </template
                >Career assessment
            </CustomTable>
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import CustomModal from '@/components/CustomModal.vue'
import CustomTable from '@/components/CustomTable.vue'
import { getFromSessionStorage } from '@/utils/session'
import { convertNumberToString } from '@/utils'
export default {
    name: 'ModalCareerAssessment',
    data() {
        return {
            errModal: false
        }
    },
    components: {
        CustomModal,
        CustomTable,
        ESelect,
        CustomInput
    },
    props: {
        careerAssessmentVisible: {
            type: Boolean,
            required: true
        },
        closeModalCareerAssessment: Function,
        dataCareerAssessment: {
            type: Array,
            required: true
        }
    },

    methods: { convertNumberToString },
    mounted() {},
    computed: {
        list_career_assessment() {
            return getFromSessionStorage('DM_BOI_DUONG_TX')
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
}
::v-deep .el-input.is-disabled .el-input__inner {
    color: black;
}
</style>
