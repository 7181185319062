<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Trường học -->
            <tr v-if="active_tab === 1">
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th rowspan="2">Tổng số trường</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Điểm trường</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
            </tr>

            <!-- 2. Lớp học -->
            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th rowspan="3">Tổng số lớp</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="5">Chia ra theo lớp</th>
                <th colspan="3">Trong đó số Lớp ghép</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Công lập</th>
                <th rowspan="2">Tư thục</th>
                <th rowspan="2">Lớp 1</th>
                <th rowspan="2">Lớp 2</th>
                <th rowspan="2">Lớp 3</th>
                <th rowspan="2">Lớp 4</th>
                <th rowspan="2">Lớp 5</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>
                    <td>{{ item['F10'] }}</td>
                    <td>{{ item['G10'] }}</td>
                    <td>{{ item['H10'] }}</td>
                    <td>{{ item['F14'] }}</td>
                    <td>{{ item['G14'] }}</td>
                    <td>{{ item['H14'] }}</td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>
                    <td>{{ item['F15'] }}</td>
                    <td>{{ item['G15'] }}</td>
                    <td>{{ item['H15'] }}</td>
                    <td>{{ item['F17'] }}</td>
                    <td>{{ item['F18'] }}</td>
                    <td>{{ item['F19'] }}</td>
                    <td>{{ item['F20'] }}</td>
                    <td>{{ item['F21'] }}</td>
                    <td>{{ item['F23'] }}</td>
                    <td>{{ item['G23'] }}</td>
                    <td>{{ item['H23'] }}</td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportSchoolClass',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trường học' },
                { number: 2, label: 'Lớp học' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
