<template>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Khu vực </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_area"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="area"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Khuyết tật </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_disability" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Loại khuyết tật </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_disability_type"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="disability_type"
                    />
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Khuyết tật không đánh giá
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_unassessed_disability"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Đối tượng chính sách
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_policy_object"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="policy_object"
                    />
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Hướng nghiệp, dạy nghề
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_career_orientation"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="career_orientation"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Đối tượng ưu tiên </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_priority_object"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="priority_object"
                    />
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Hỗ trợ chi phí học tập
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_financial_support_for_education"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Cấp tiền hàng tháng </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_monthly_stipend" :disabled="true">
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Hỗ trợ nhà ở </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_housing_assistance" :disabled="true">
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Bệnh về mắt </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_eye_related_illness_support"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh biết bơi kỳ I
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_swimming_ability_semester_I"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh biết bơi kỳ II
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_swimming_ability_semester_II"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group" v-if="isGradeLevelValid([2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> CMTND/TCC </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_CMTND_TCC" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Ngày cấp </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <DatePicker v-model="date_of_issue" :disabled="true" />
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Nơi cấp </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="place_of_issue"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Loại nhập học </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_type_of_admission"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type_of_admission"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Có mẹ dân tộc </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_ethnic_mother" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Có cha dân tộc </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_ethnic_father" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Phụ huynh có Smartphone
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_parent_has_smartphone"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Phụ huynh có Máy tính Internet
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_parent_has_computer_internet"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Số buổi học trên tuần
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <CustomInput
                        v-model="number_of_study_sessions_per_week"
                        placeholder=""
                        :disabled="true"
                    >
                    </CustomInput>
                </div>
            </div>

            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh lớp bán trú
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_boarding_class_student"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Học bán trú </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_boarding_school_student"
                        :placeholder="''"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="boarding_school_student"
                    />
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Đội viên </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_party_member" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Đoàn viên </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_youth_union_member" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Học tiếng dân tộc </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_ethnic_language_study"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Học song ngữ </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_bilingual_study" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([4, 5, 45])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Học 2 buổi </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_two_sessions_per_day"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([4, 5, 45])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> HS tuyển mới </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_accepting_new_students"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([4, 5, 45])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Trẻ lớp bán trú </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_boarding_school_student"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3, 6])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh bán trú dân nuôi
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_boarding_student_foster"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3, 6])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh nội trú dân nuôi
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_boarding_student_resident"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3, 6])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Học sinh bán trú </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_boarding_student" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3, 6])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label"> Học sinh nội trú </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox v-model="is_resident_student" :disabled="true">
                    </el-checkbox>
                </div>
            </div>
            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3, 6])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh phổ thông dân tộc bán trú
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_boarding_ethnic_student"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group" v-if="isGradeLevelValid([1])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh nhu cầu hỗ trợ ngoại ngữ
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_students_need_language_support"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group" v-if="isGradeLevelValid([1])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh dân tộc trợ giảng
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_ethnic_minority_teaching_assistants"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group" v-if="isGradeLevelValid([1])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Tăng cường tiếng Việt
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_enhance_vietnamese_language_skills"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group" v-if="isGradeLevelValid([1])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Đã học lớp mẫu giáo 5 tuổi
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_have_attended_kindergarte_at_age_5"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>

            <div class="form-group" v-if="isGradeLevelValid([1, 2, 3, 6])">
                <div class="col-md-6">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học chương trình giáo dục của Bộ
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-checkbox
                        v-model="is_ministry_education_program"
                        :disabled="true"
                    >
                    </el-checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import { convertNumberToString, convertToBoolean } from '@/utils'
export default {
    name: 'PersonalInforMation',
    components: { DatePicker, CustomInput, ESelect },
    data() {
        return {
            area: '', // Khu vực
            is_disability: false, // Khuyết tật
            disability_type: '', // Loại khuyết tật
            is_unassessed_disability: false, // Khuyết tật không đánh giá
            policy_object: '', // Đối tượng chính sách
            priority_object: '', // Đối tượng ưu tiên
            is_financial_support_for_education: false, // Hỗ trợ chi phí học tập
            is_monthly_stipend: false, //  cấp tiền hàng tháng
            is_housing_assistance: false, //  hỗ trợ nhà ở
            is_eye_related_illness_support: false, //  hỗ trợ bệnh về mắt
            career_orientation: '', // Hướng nghiệp, dạy nghề
            is_swimming_ability_semester_I: false, // Học sinh biết bơi kỳ I
            is_swimming_ability_semester_II: false, // Học sinh biết bơi kỳ II

            is_CMTND_TCC: false, // CMTND/TCC
            date_of_issue: '', // Ngày cấp
            place_of_issue: '', // Nơi cấp

            type_of_admission: '', // Loại nhập học
            is_ethnic_mother: false, // Có mẹ dân tộc
            is_ethnic_father: false, // Có cha dân tộc
            is_parent_has_smartphone: false, // Phụ huynh có Smartphone
            is_parent_has_computer_internet: false, // Phụ huynh có Máy tính Internet
            number_of_study_sessions_per_week: '', // Số buổi học trên tuần
            is_boarding_class_student: false, // Học sinh lớp bán trú
            boarding_school_student: '', // Học bán trú

            is_party_member: false, // Đội viên
            is_youth_union_member: false, // Đoàn viên
            is_ethnic_language_study: false, // Học tiếng dân tộc
            is_bilingual_study: false, // Học song ngữ
            is_two_sessions_per_day: false, // Học 2 buổi
            is_accepting_new_students: false, // Học sinh mới được tuyển
            is_boarding_school_student: false, // Trẻ lớp bán trú
            is_boarding_student_foster: false, // Học sinh bán trú dân nuôi
            is_boarding_student_resident: false, // Học sinh nội trú dân nuôi
            is_boarding_student: false, // Học sinh bán trú
            is_resident_student: false, // Học sinh nội trú
            is_boarding_ethnic_student: false, // Học sinh phổ thông dân tộc bán trú
            is_students_need_language_support: false, // Học sinh nhu cầu hỗ trợ ngoại ngữ
            is_ethnic_minority_teaching_assistants: false, // Học sinh dân tộc trợ giảng
            is_enhance_vietnamese_language_skills: false, // Tăng cường tiếng Việt
            is_have_attended_kindergarte_at_age_5: false, // Đã học lớp mẫu giáo 5 tuổi
            is_ministry_education_program: false // Học chương trình giáo dục của Bộ
        }
    },
    props: {
        dataPersonalInformation: {
            type: Object,
            require: true
        }
    },
    watch: {
        dataPersonalInformation(newValue) {
            this.area = newValue.maKhuVuc
            this.is_disability = convertToBoolean(newValue.khuyetTat)
            this.disability_type = convertNumberToString(newValue.maLoaiKhuyetTat)
            this.is_unassessed_disability = convertToBoolean(
                newValue.khuyetTatKhongDanhGia
            )
            this.policy_object = convertNumberToString(newValue.maDtuongCsach)
            this.priority_object = convertNumberToString(newValue.dtuongUuTien)
            this.is_financial_support_for_education = newValue.htChiPhiHocTap
            this.is_monthly_stipend = newValue.capTienHangThang

            this.is_housing_assistance = newValue.htroNhaO
            this.is_eye_related_illness_support = newValue.benhMat

            this.career_orientation = newValue.maLopHuongNghiepDayNghe
            this.is_swimming_ability_semester_I = newValue.isBietBoiKy1
            this.is_swimming_ability_semester_II = newValue.isBietBoiKy2

            this.is_CMTND_TCC = newValue.soCmnd
            this.date_of_issue = newValue.ngayCapCmnd
            this.place_of_issue = newValue.noiCapCmnd
            this.is_party_member = convertToBoolean(newValue.doiVien)
            this.is_youth_union_member = convertToBoolean(newValue.doanVien)
            this.is_ethnic_language_study = newValue.hocSinhTiengDanToc
            this.is_bilingual_study = newValue.hocSongNgu
            this.is_accepting_new_students = newValue.tuyenMoiMn
            this.type_of_admission = convertNumberToString(newValue.loaiNhapHoc)
            this.is_ethnic_mother = newValue.meDanToc
            this.is_ethnic_father = newValue.chaDanToc
            this.is_parent_has_smartphone = newValue.isPhCoSmartPhone
            this.is_parent_has_computer_internet = newValue.isPhCoMayTinhInternet
            this.number_of_study_sessions_per_week = `${newValue.soBuoiTrenTuan} buổi học/tuần - [${newValue.soBuoiTrenTuan}]`
            this.is_boarding_class_student = newValue.lopBanTru
            this.boarding_school_student = newValue.maHocBanTru

            this.is_boarding_student_foster = newValue.hocSinhBanTruDanNuoi
            this.is_boarding_student_resident = newValue.hocSinhNoiTruDanNuoi
            this.is_boarding_student = newValue.hocSinhBanTru
            this.is_resident_student = newValue.hocSinhNoiTru
            this.is_boarding_ethnic_student = newValue.hocSinhPtdtBanTru
            this.is_students_need_language_support = newValue.nhuCauHtroNngu
            this.is_ethnic_minority_teaching_assistants =
                newValue.hocSinhDanTocTroGiang
            this.is_enhance_vietnamese_language_skills = newValue.tangCuongTviet
            this.is_have_attended_kindergarte_at_age_5 = convertToBoolean(
                newValue.hocLopMg5T
            )
            this.is_ministry_education_program = newValue.hocCtgdCuaBo
        }
    },

    methods: {
        isGradeLevelValid(allowedValues) {
            return allowedValues.includes(this.dataPersonalInformation.capHoc)
        }
    },
    computed: {
        list_area() {
            return getFromSessionStorage('DM_KHU_VUC')
        },
        list_disability_type() {
            return getFromSessionStorage('DM_LOAI_KHUYET_TAT')
        },
        list_policy_object() {
            return getFromSessionStorage('DM_DOI_TUONG_CHINH_SACH')
        },
        list_priority_object() {
            return getFromSessionStorage('DM_VUNG_KHO_KHAN')
        },
        list_type_of_admission() {
            return getFromSessionStorage('LOAI_NHAP_HOC')
        },
        list_boarding_school_student() {
            return getFromSessionStorage('DM_HOC_BAN_TRU')
        },
        list_career_orientation() {
            return getFromSessionStorage('DM_LOP_HUONG_NGHIEP_DAY_NGHE')
        }
    }
}
</script>
<style scoped>
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}
.label-wrapper {
    display: flex;
    align-items: center;
    float: right;
    font-size: 13px;
}
.control-label {
    margin-bottom: 0;
    font-weight: 600;
}
</style>
