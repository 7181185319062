export const list_student_status = [
    {
        label: 'Đang học',
        value: 1
    },
    {
        label: 'Chuyển khỏi trường',
        value: 3
    },
    {
        label: 'Bỏ học',
        value: 4
    }
]

export const list_gender = [
    {
        label: 'Nam',
        value: '1'
    },
    {
        label: 'Nữ',
        value: '0'
    }
]

export const fakeData = [
    {
        monHoc: 'Toán',
        ddgTX1: 8,
        ddgTX2: 7,
        ddgCK: 9,
        tbmHK: 8.0,
        diemDG: 8.5,
        diemThiLai: 0,
        diemDGLai: 8.5,
        nhanXet: 'Tốt'
    },
    {
        monHoc: 'Văn',
        ddgTX1: 7,
        ddgTX2: 8,
        ddgCK: 8,
        tbmHK: 7.7,
        diemDG: 8.0,
        diemThiLai: 0,
        diemDGLai: 8.0,
        nhanXet: 'Khá'
    },
    {
        monHoc: 'Lý',
        ddgTX1: 9,
        ddgTX2: 9,
        ddgCK: 10,
        tbmHK: 9.3,
        diemDG: 9.5,
        diemThiLai: 0,
        diemDGLai: 9.5,
        nhanXet: '  Xuất sắc '
    }
    // Thêm dữ liệu cho các môn học khác ở đây nếu cần
]
