<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hide-btn-table="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="2">Tổng quy mô học sinh</th>
                <th colspan="2">Lớp 10</th>
                <th colspan="2">Lớp 11</th>
                <th colspan="2">Lớp 12</th>
            </tr>
            <tr>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Tổng</th>
                <th rowspan="1">Nữ</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>

            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left min-width">
                    {{ item['H3'] }}
                </td>

                <td v-for="td in list_td" :key="td.index">
                    {{ item[1][td] }}
                </td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportAge',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_td: ['E5', 'F5', 'E12', 'F12', 'E13', 'F13', 'E14', 'F14']
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>
