<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị quản lý</th>
                <th rowspan="3">Trường học</th>
                <th rowspan="3">Mã trường</th>
                <th rowspan="3">Tổng số học sinh</th>

                <th colspan="3">Nhà trẻ</th>
                <th colspan="3">Mẫu giáo</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="2">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2">Số học sinh</th>
                <th colspan="2">Trong đó</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="1">Học sinh dân tộc</th>
                <th rowspan="1">Học sinh khuyết tật</th>
                <th rowspan="1">Học sinh dân tộc</th>
                <th rowspan="1">Học sinh khuyết tật</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-300">Đơn vị quản lý</th>
                <th rowspan="3" class="min-w-300">Trường học</th>
                <th rowspan="3" class="min-w-150">Mã trường</th>
                <th rowspan="3" class="min-w-150">Tổng số học sinh</th>

                <th colspan="3">Lớp 1</th>
                <th colspan="3">Lớp 2</th>
                <th colspan="3">Lớp 3</th>
                <th colspan="3">Lớp 4</th>
                <th colspan="3">Lớp 5</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-300">Đơn vị quản lý</th>
                <th rowspan="3" class="min-w-300">Trường học</th>
                <th rowspan="3" class="min-w-150">Mã trường</th>
                <th rowspan="3" class="min-w-150">Tổng số học sinh</th>

                <th colspan="3">Lớp 6</th>
                <th colspan="3">Lớp 7</th>
                <th colspan="3">Lớp 8</th>
                <th colspan="3">Lớp 9</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2" class="min-w-100">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-300">Đơn vị quản lý</th>
                <th rowspan="3" class="min-w-300">Trường học</th>
                <th rowspan="3" class="min-w-150">Mã trường</th>
                <th rowspan="3" class="min-w-150">Tổng số học sinh</th>

                <th colspan="3">Lớp 10</th>
                <th colspan="3">Lớp 11</th>
                <th colspan="3">Lớp 12</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
                <th rowspan="2" class="min-w-100">Số học sinh</th>
                <th colspan="2">Trong đó</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
                <th rowspan="1" class="min-w-150">Học sinh dân tộc</th>
                <th rowspan="1" class="min-w-150">Học sinh khuyết tật</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="21">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-if="statistical === 4 || statistical === 5">
                <td>123123</td>
                <td>123123</td>
                <td>123123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
                <td>123</td>
            </tr>
            <template v-if="statistical == 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="min-w-300 text-left">{{ item.tenDonVi }}</td>
                    <td class="min-w-300 text-left">{{ item.tenTruongHoc }}</td>
                    <td class="min-w-150">{{ item.maTruongHoc }}</td>
                    <td class="min-w-150">{{ item.tongHocSinh }}</td>
                    <td class="min-w-100">
                        {{ item?.khoiLopList[0]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[0]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[0]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[1]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[1]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[1]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[2]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[2]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[2]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[3]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[3]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[3]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[4]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[4]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[4]?.hsKhuyetTat }}
                    </td>
                </tr>
            </template>

            <template v-if="statistical == 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="min-w-300 text-left">{{ item.tenDonVi }}</td>
                    <td class="min-w-300 text-left">{{ item.tenTruongHoc }}</td>
                    <td class="min-w-150">{{ item.maTruongHoc }}</td>
                    <td class="min-w-150">{{ item.tongHocSinh }}</td>
                    <td class="min-w-100">
                        {{ item?.khoiLopList[0]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[0]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[0]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[1]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[1]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[1]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[2]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[2]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[2]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[3]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[3]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[3]?.hsKhuyetTat }}
                    </td>
                </tr>
            </template>
            <template v-if="statistical == 3 || statistical == 6">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="min-w-300 text-left">{{ item?.tenDonVi }}</td>
                    <td class="min-w-300 text-left">{{ item?.tenTruongHoc }}</td>
                    <td class="min-w-150">{{ item?.maTruongHoc }}</td>
                    <td class="min-w-150">{{ item?.tongHocSinh }}</td>
                    <td class="min-w-100">
                        {{ item?.khoiLopList[0]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[0]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[0]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[1]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[1]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[1]?.hsKhuyetTat }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[2]?.soHocSinhKhoi }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[2]?.hsDanToc }}
                    </td>
                    <td class="min-w-150">
                        {{ item?.khoiLopList[2]?.hsKhuyetTat }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StatisticsOfDisabledStudents',
    components: {
        CustomTable
    },
    props: {
        statistical: {
            type: Number,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
