<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Số lượng học sinh -->
            <tr v-if="active_tab === 1">
                <th rowspan="2">STT</th>
                <th rowspan="2">Đơn vị</th>
                <th colspan="4">Tổng số</th>
                <th colspan="8">Trẻ em nhà trẻ</th>
                <th colspan="9">Trẻ em mẫu giáo</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Dân tộc nữ</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Học 2 buổi</th>
                <th rowspan="1">Bán trú</th>
                <th rowspan="1">Khuyết tật</th>
                <th rowspan="1">Học trong nhóm trẻ độc lập</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Học 2 buổi</th>
                <th rowspan="1">Bán trú</th>
                <th rowspan="1">Khuyết tật</th>
                <th rowspan="1">Học trong lớp mẫu giáo độc lập</th>
                <th rowspan="1">Học trong lớp mẫu giáo 5 tuổi</th>
            </tr>

            <!-- 2. Độ tuổi nhà trẻ -->
            <tr v-if="active_tab === 2">
                <th rowspan="2">STT</th>
                <th rowspan="2">Đơn vị</th>
                <th colspan="4">Trẻ em nhà trẻ</th>
                <th colspan="4">3 - 12 Tháng</th>
                <th colspan="4">13 - 24 Tháng</th>
                <th colspan="4">25 - 36 Tháng</th>
                <th colspan="4">Trên 36 Tháng</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Dân tộc nữ</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 3. Độ tuổi mẫu giáo -->
            <tr v-if="active_tab === 3">
                <th rowspan="2">STT</th>
                <th rowspan="2">Đơn vị</th>
                <th colspan="4">Trẻ em mẫu giáo</th>
                <th colspan="4">Dưới 3 tuổi</th>
                <th colspan="4">Từ 3 - 4 tuổi</th>
                <th colspan="4">Từ 4 - 5 tuổi</th>
                <th colspan="4">Từ 5 - 6 tuổi</th>
                <th colspan="4">Trên 6 tuổi</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Dân tộc nữ</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 4. KQ dinh dưỡng NT -->
            <tr v-if="active_tab === 4">
                <th rowspan="2">STT</th>
                <th rowspan="2">Đơn vị</th>
                <th colspan="4">Trẻ em nhà trẻ kiểm tra sức khỏe</th>
                <th colspan="4">Trẻ suy dưỡng thể nhẹ cân</th>
                <th colspan="4">Trẻ suy dinh dưỡng thể thấp còi</th>
                <th colspan="4">Trẻ suy dinh dưỡng thể còi cọc</th>
                <th colspan="4">Trẻ thừa cân, béo phì</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Dân tộc nữ</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 5. KQ dinh dưỡng MG -->
            <tr v-if="active_tab === 5">
                <th rowspan="2">STT</th>
                <th rowspan="2">Đơn vị</th>
                <th colspan="4">Trẻ em mẫu giáo kiểm tra sức khỏe</th>
                <th colspan="4">Trẻ suy dưỡng thể nhẹ cân</th>
                <th colspan="4">Trẻ suy dinh dưỡng thể thấp còi</th>
                <th colspan="4">Trẻ suy dinh dưỡng thể còi cọc</th>
                <th colspan="4">Trẻ thừa cân, béo phì</th>
            </tr>
            <tr v-if="active_tab === 5">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Dân tộc nữ</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 4">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_4" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 5">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_5" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Học sinh mầm non' },
                { number: 2, label: 'Độ tuổi nhà trẻ' },
                { number: 3, label: 'Độ tuổi mẫu giáo' },
                { number: 4, label: 'KQ dinh dưỡng NT' },
                { number: 5, label: 'KQ dinh dưỡng MG' }
            ],

            list_td_active_tab_1: [
                'E5',
                'F5',
                'G5',
                'H5',
                'E6',
                'F6',
                'G6',
                'H6',
                'E11',
                'E12',
                'E13',
                '',
                'E14',
                'F14',
                'G14',
                'H14',
                'E19',
                'E20',
                'E21',
                '',
                ''
            ],

            list_td_active_tab_2: [
                'E23',
                'F23',
                'G23',
                'H23',
                'E24',
                'F24',
                'G24',
                'H24',
                'E25',
                'F25',
                'G25',
                'H25',
                'E26',
                'F26',
                'G26',
                'H26',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_3: [
                'E28',
                'F28',
                'G28',
                'H28',
                'E29',
                'F29',
                'G29',
                'H29',
                'E30',
                'F30',
                'G30',
                'H30',
                'E31',
                'F31',
                'G31',
                'H31',
                'E32',
                'F32',
                'G32',
                'H32',
                'E33',
                'F33',
                'G33',
                'H33'
            ],

            list_td_active_tab_4: [
                'E35',
                'F35',
                'G35',
                'H35',
                'E37',
                'F37',
                'G37',
                'H37',
                'E38',
                'F38',
                'G38',
                'H38',
                'E39',
                'F39',
                'G39',
                'H39',
                'E40',
                'F40',
                'G40',
                'H40'
            ],

            list_td_active_tab_5: [
                'E41',
                'F41',
                'G41',
                'H41',
                'E43',
                'F43',
                'G43',
                'H43',
                'E44',
                'F44',
                'G44',
                'H44',
                'E45',
                'F45',
                'G45',
                'H45',
                'E46',
                'F46',
                'G46',
                'H46'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
