<template>
    <div>
        <StudentEnrollmentStatistics
            :tableData="tableData"
            v-if="detailed_report === 1"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />
        <StudentEnrollmentStatisticsByCitizenID
            :tableData="tableData"
            v-if="this.isDetailedReport(['B.4.4', 'C.4.6', 'D.4.7'])"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <StatisticsOfDisabledStudents
            :tableData="tableData"
            v-if="this.isDetailedReport(['B.4.3', 'C.4.5', 'D.4.6', 'E.4.4'])"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <BoardingResidentialStudentsStatistics
            :tableData="tableData"
            v-if="this.isDetailedReport(['B.4.5', 'C.4.7', 'D.4.8', 'E.4.6'])"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <StatisticsOfPrimarySchoolEnrollment
            :tableData="tableData"
            v-if="this.isDetailedReport(['B.4.7'])"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <StatisticsOfIncomingOutgoingDropoutStudents
            :tableData="tableData"
            v-if="detailed_report == 'BcSoLuongHocSinhBoHoc'"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <StatisticsOfStudentsSwimmingAbilityEyeConditions
            :tableData="tableData"
            v-if="this.isDetailedReport(['B.4.8', 'C.4.10', 'D.4.11', 'E.4.9'])"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />
    </div>
</template>

<script>
import StudentEnrollmentStatistics from './StudentEnrollmentStatistics'
import StudentEnrollmentStatisticsByCitizenID from './StudentEnrollmentStatisticsByCitizenID'
import StatisticsOfDisabledStudents from './StatisticsOfDisabledStudents'
import BoardingResidentialStudentsStatistics from './BoardingResidentialStudentsStatistics'
import StatisticsOfPrimarySchoolEnrollment from './StatisticsOfPrimarySchoolEnrollment'
import StatisticsOfIncomingOutgoingDropoutStudents from './StatisticsOfIncomingOutgoingDropoutStudents'
import StatisticsOfStudentsSwimmingAbilityEyeConditions from './StatisticsOfStudentsSwimmingAbilityEyeConditions'
export default {
    name: 'ReportSchool',
    props: {
        detailed_report: {
            type: String,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        },
        statistical: {
            type: Number,
            require: true
        }
    },
    components: {
        StudentEnrollmentStatistics,
        StudentEnrollmentStatisticsByCitizenID,
        StatisticsOfDisabledStudents,
        BoardingResidentialStudentsStatistics,
        StatisticsOfPrimarySchoolEnrollment,
        StatisticsOfIncomingOutgoingDropoutStudents,
        StatisticsOfStudentsSwimmingAbilityEyeConditions
    },
    data() {
        return {}
    },

    methods: {
        isDetailedReport(validReports) {
            return validReports.includes(this.detailed_report)
        }
    },
    mounted() {},
    watch: {},

    computed: {}
}
</script>

<style scoped></style>
