<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th rowspan="3">Trường học</th>
                <th rowspan="3">Loại hình trường</th>
                <th rowspan="3">Số học sinh chưa tốt nghiệp năm trước</th>
                <th rowspan="3">Số học sinh cuối cấp</th>

                <th colspan="8">Được công nhận tốt nghiệp</th>
                <th colspan="2">Chưa tốt nghiệp</th>
            </tr>
            <tr>
                <th rowspan="2">Số lượng</th>
                <th rowspan="2">TL%</th>
                <th rowspan="2">Giỏi</th>
                <th rowspan="2">TL%</th>
                <th rowspan="2">Khá</th>
                <th rowspan="2">TL%</th>
                <th rowspan="2">Trung bình</th>
                <th rowspan="2">TL%</th>
                <th rowspan="2">Số lượng</th>
                <th rowspan="2">TL%</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td class="text-left">{{ item.tenTruongHoc }}</td>
                <td class="text-left">{{ item.loaiHinhTruong }}</td>
                <td>{{ item.tongSoHsChuaTnNamTrc }}</td>
                <td></td>
                <td>{{ item.soHocSinhHoanThanh }}</td>
                <td>{{ item.tlSoHocSinhHoanThanh }}</td>
                <td>{{ item.soHocSinhHoanThanhG }}</td>
                <td>{{ item.tlSoHocSinhHoanThanhG }}</td>
                <td>{{ item.soHocSinhHoanThanhK }}</td>
                <td>{{ item.tlSoHocSinhHoanThanhK }}</td>
                <td>{{ item.soHocSinhHoanThanhTB }}</td>
                <td>{{ item.tlSoHocSinhHoanThanhTB }}</td>
                <td>{{ item.soHocSinhChuaHoanThanh }}</td>
                <td>{{ item.tlSoHocSinhChuaHoanThanh }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'GraduationStatusOfstudents',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
