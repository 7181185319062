<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-200">Phòng GD&amp;ĐT</th>
                <th rowspan="4" class="min-w-200">Đơn vị</th>
                <th rowspan="4" class="min-w-250">Tổng số kê khai TĐ tin học</th>
                <th colspan="9">Hiệu trưởng</th>
                <th colspan="9">Phó hiệu trưởng</th>
                <th colspan="27">Giáo viên</th>
                <th colspan="9">Nhân viên</th>
            </tr>
            <tr>
                <th rowspan="3" class="min-w-150">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="3" class="min-w-150">Trung cấp</th>
                <th rowspan="3" class="min-w-150">Cao đẳng</th>
                <th rowspan="3" class="min-w-150">Đại học</th>
                <th rowspan="3" class="min-w-150">Sau đại học</th>
                <th rowspan="3" class="min-w-150">Chuẩn IC3</th>
                <th rowspan="3" class="min-w-150">Khác</th>
                <th rowspan="3" class="min-w-150">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="3" class="min-w-150">Trung cấp</th>
                <th rowspan="3" class="min-w-150">Cao đẳng</th>
                <th rowspan="3" class="min-w-150">Đại học</th>
                <th rowspan="3" class="min-w-150">Sau đại học</th>
                <th rowspan="3" class="min-w-150">Chuẩn IC3</th>
                <th rowspan="3" class="min-w-150">Khác</th>
                <th colspan="9">Tổng số</th>
                <th colspan="9">Tổ trưởng chuyên môn</th>
                <th colspan="9">Tổ phó chuyên môn</th>
                <th rowspan="3" class="min-w-150">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="3" class="min-w-150">Trung cấp</th>
                <th rowspan="3" class="min-w-150">Cao đẳng</th>
                <th rowspan="3" class="min-w-150">Đại học</th>
                <th rowspan="3" class="min-w-150">Sau đại học</th>
                <th rowspan="3" class="min-w-150">Chuẩn IC3</th>
                <th rowspan="3" class="min-w-150">Khác</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-150">Cơ bản TT03</th>
                <th rowspan="2" class="min-w-150">Nâng cao TT03</th>
                <th rowspan="2" class="min-w-150">Cơ bản TT03</th>
                <th rowspan="2" class="min-w-150">Nâng cao TT03</th>
                <th rowspan="2" class="min-w-150">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="2" class="min-w-150">Trung cấp</th>
                <th rowspan="2" class="min-w-150">Cao đẳng</th>
                <th rowspan="2" class="min-w-150">Đại học</th>
                <th rowspan="2" class="min-w-150">Sau đại học</th>
                <th rowspan="2" class="min-w-150">Chuẩn IC3</th>
                <th rowspan="2" class="min-w-150">Khác</th>
                <th rowspan="2" class="min-w-150">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="2" class="min-w-150">Trung cấp</th>
                <th rowspan="2" class="min-w-150">Cao đẳng</th>
                <th rowspan="2" class="min-w-150">Đại học</th>
                <th rowspan="2" class="min-w-150">Sau đại học</th>
                <th rowspan="2" class="min-w-150">Chuẩn IC3</th>
                <th rowspan="2" class="min-w-150">Khác</th>
                <th rowspan="2" class="min-w-150">Tổng</th>
                <th colspan="2">Chứng chỉ</th>
                <th rowspan="2" class="min-w-150">Trung cấp</th>
                <th rowspan="2" class="min-w-150">Cao đẳng</th>
                <th rowspan="2" class="min-w-150">Đại học</th>
                <th rowspan="2" class="min-w-150">Sau đại học</th>
                <th rowspan="2" class="min-w-150">Chuẩn IC3</th>
                <th rowspan="2" class="min-w-150">Khác</th>
                <th rowspan="2" class="min-w-150">Cơ bản TT03</th>
                <th rowspan="2" class="min-w-150">Nâng cao TT03</th>
            </tr>
            <tr>
                <th rowspan="1" class="min-w-150">Cơ bản TT03</th>
                <th rowspan="1" class="min-w-150">Nâng cao TT03</th>
                <th rowspan="1" class="min-w-150">Cơ bản TT03</th>
                <th rowspan="1" class="min-w-150">Nâng cao TT03</th>
                <th rowspan="1" class="min-w-150">Cơ bản TT03</th>
                <th rowspan="1" class="min-w-150">Nâng cao TT03</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td>{{ item.tongKeKhai }}</td>
                <td>{{ item.tongHieuTruong }}</td>
                <td>{{ item.hieuTruongCoBan }}</td>
                <td>{{ item.hieuTruongNangCao }}</td>
                <td>{{ item.hieuTruongTrungCap }}</td>
                <td>{{ item.hieuTruongCaoDang }}</td>
                <td>{{ item.hieuTruongDaiHoc }}</td>
                <td>{{ item.hieuTruongSauDaiHoc }}</td>
                <td>{{ item.hieuTruongIc3 }}</td>
                <td>{{ item.hieuTruongKhac }}</td>

                <td>{{ item.tongHieuPho }}</td>
                <td>{{ item.hieuPhoCoBan }}</td>
                <td>{{ item.hieuPhoNangCao }}</td>
                <td>{{ item.hieuPhoTrungCap }}</td>
                <td>{{ item.hieuPhoCaoDang }}</td>
                <td>{{ item.hieuPhoDaiHoc }}</td>
                <td>{{ item.hieuPhoSauDaiHoc }}</td>
                <td>{{ item.hieuPhoIc3 }}</td>
                <td>{{ item.hieuPhoKhac }}</td>

                <td>{{ item.tongGiaoVien }}</td>
                <td>{{ item.giaoVienCoBan }}</td>
                <td>{{ item.giaoVienNangCao }}</td>
                <td>{{ item.giaoVienTrungCap }}</td>
                <td>{{ item.giaoVienCaoDang }}</td>
                <td>{{ item.giaoVienDaiHoc }}</td>
                <td>{{ item.giaoVienSauDaiHoc }}</td>
                <td>{{ item.giaoVienIc3 }}</td>
                <td>{{ item.giaoVienKhac }}</td>

                <td>{{ item.tongToTruong }}</td>
                <td>{{ item.toTruongCoBan }}</td>
                <td>{{ item.toTruongNangCao }}</td>
                <td>{{ item.toTruongTrungCap }}</td>
                <td>{{ item.toTruongCaoDang }}</td>
                <td>{{ item.toTruongDaiHoc }}</td>
                <td>{{ item.toTruongSauDaiHoc }}</td>
                <td>{{ item.toTruongIc3 }}</td>
                <td>{{ item.toTruongKhac }}</td>

                <td>{{ item.tongToPho }}</td>
                <td>{{ item.toPhoCoBan }}</td>
                <td>{{ item.toPhoNangCao }}</td>
                <td>{{ item.toPhoTrungCap }}</td>
                <td>{{ item.toPhoCaoDang }}</td>
                <td>{{ item.toPhoDaiHoc }}</td>
                <td>{{ item.toPhoSauDaiHoc }}</td>
                <td>{{ item.toPhoIc3 }}</td>
                <td>{{ item.toPhoKhac }}</td>

                <td>{{ item.tongNhanVien }}</td>
                <td>{{ item.nhanVienCoBan }}</td>
                <td>{{ item.nhanVienNangCao }}</td>
                <td>{{ item.nhanVienTrungCap }}</td>
                <td>{{ item.nhanVienCaoDang }}</td>
                <td>{{ item.nhanVienDaiHoc }}</td>
                <td>{{ item.nhanVienSauDaiHoc }}</td>
                <td>{{ item.nhanVienIc3 }}</td>
                <td>{{ item.nhanVienKhac }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StaffStatisticsByComputerLiteracyLevel',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
