<template>
    <CustomTable :column-form="2" :tab="true" :hide-pagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Số lượng học sinh -->
            <tr v-if="active_tab === 1">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-100">Đơn vị</th>
                <th colspan="14">Tổng số</th>
                <th colspan="4">Lớp 1</th>
                <th colspan="4">Lớp 2</th>
                <th colspan="4">Lớp 3</th>
                <th colspan="4">Lớp 4</th>
                <th colspan="4">Lớp 5</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th colspan="2">Học sinh tuyển mới đầu cấp</th>
                <th colspan="6">
                    Học sinh lưu ban năm trước đang học trong năm học này
                </th>
                <th rowspan="2" class="min-w-100">Học sinh học 2 buổi</th>
                <th rowspan="2" class="min-w-100">Học sinh PTDT bán trú</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">
                    Trong đó HS 6 tuổi vào Lớp 1
                </th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">LB Lớp 1</th>
                <th rowspan="1" class="min-w-100">LB Lớp 2</th>
                <th rowspan="1" class="min-w-100">LB Lớp 3</th>
                <th rowspan="1" class="min-w-100">LB Lớp 4</th>
                <th rowspan="1" class="min-w-100">LB Lớp 5</th>
            </tr>

            <!-- 2. Độ tuổi -->

            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-100">Đơn vị</th>
                <th rowspan="3" class="min-w-100">Tổng số HS</th>
                <th colspan="28">Chia ra theo độ tuổi</th>
                <th colspan="20">Học sinh đi học đúng tuổi</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th colspan="4">Dưới 6 tuổi</th>
                <th colspan="4">6 tuổi</th>
                <th colspan="4">7 tuổi</th>
                <th colspan="4">8 tuổi</th>
                <th colspan="4">9 tuổi</th>
                <th colspan="4">10 tuổi</th>
                <th colspan="4">Trên 10 tuổi</th>
                <th colspan="4">6 Tuổi học lớp 1</th>
                <th colspan="4">7 Tuổi học lớp 2</th>
                <th colspan="4">8 Tuổi học lớp 3</th>
                <th colspan="4">9 Tuổi học lớp 4</th>
                <th colspan="4">10 Tuổi học lớp 5</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
            </tr>

            <!-- 3. Khuyết tật -->

            <tr v-if="active_tab === 3">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-100">Đơn vị</th>
                <th rowspan="3" class="min-w-100">Tổng số</th>
                <th colspan="24">Học sinh khuyết tật</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th colspan="4">Tổng số KT</th>
                <th colspan="4">KT nhìn</th>
                <th colspan="4">KT nghe, nói</th>
                <th colspan="4">KT Trí tuệ</th>
                <th colspan="4">KT vận động</th>
                <th colspan="4">KT thần kinh, tâm thần</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Học sinh TH' },
                { number: 2, label: 'Độ tuổi ' },
                { number: 3, label: 'Khuyết tật' }
            ],

            list_td_active_tab_1: [
                'E5',
                'F5',
                'G5',
                'H5',
                'E30',
                '',
                'E31',
                '',
                '',
                '',
                '',
                '',
                'E32',
                'E33',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_2: [
                'E5',
                'E16',
                'F16',
                'G16',
                'H16',
                'E17',
                'F17',
                'G17',
                'H17',
                'E18',
                'F18',
                'G18',
                'H18',
                'E19',
                'F19',
                'G19',
                'H19',
                'E20',
                'F20',
                'G20',
                'H20',
                'E21',
                'F21',
                'G21',
                'H21',
                'E22',
                'F22',
                'G22',
                'H22',
                'E24',
                'F24',
                'G24',
                'H24',
                'E25',
                'F25',
                'G25',
                'H25',
                'E26',
                'F26',
                'G26',
                'H26',
                'E27',
                'F27',
                'G27',
                'H27',
                'E28',
                'F28',
                'G28',
                'H28'
            ],
            list_td_active_tab_3: [
                'E5',
                '',
                '',
                '',
                '',
                'E36',
                'F36',
                'G36',
                'H36',
                'E37',
                'F37',
                'G37',
                'H37',
                'E38',
                'F38',
                'G38',
                'H38',
                'E39',
                'F39',
                'G39',
                'H39',
                'E40',
                'F40',
                'G40',
                'H40'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-100 {
    min-width: 100px;
}
</style>
