<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th colspan="5">Nhân sự chia theo chức vụ</th>
                <th colspan="7">Hiệu trưởng chia theo trình độ chuyên môn NV</th>
                <th colspan="7">
                    Phó hiệu trưởng chia theo trình độ chuyên môn NV
                </th>
                <th colspan="21">
                    Giáo viên chia theo trình độ chuyên môn nghiệp vụ
                </th>
                <th colspan="7">Nhân viên chia theo trình độ chuyên môn NV</th>
                <th rowspan="3">Chưa khai</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-150">Tổng số</th>
                <th rowspan="2" class="min-w-150">Hiệu trưởng</th>
                <th rowspan="2" class="min-w-150">Phó hiệu trưởng</th>
                <th rowspan="2" class="min-w-150">Giáo viên</th>
                <th rowspan="2" class="min-w-150">Nhân viên</th>

                <th rowspan="2" class="min-w-150">Tổng</th>
                <th rowspan="2" class="min-w-150">Tiến sĩ</th>
                <th rowspan="2" class="min-w-150">Thạc sĩ</th>
                <th rowspan="2" class="min-w-150">Đại học</th>
                <th rowspan="2" class="min-w-150">Cao đẳng</th>
                <th rowspan="2" class="min-w-150">Trung cấp</th>
                <th rowspan="2" class="min-w-150">Khác</th>

                <th rowspan="2" class="min-w-150">Tổng</th>
                <th rowspan="2" class="min-w-150">Tiến sĩ</th>
                <th rowspan="2" class="min-w-150">Thạc sĩ</th>
                <th rowspan="2" class="min-w-150">Đại học</th>
                <th rowspan="2" class="min-w-150">Cao đẳng</th>
                <th rowspan="2" class="min-w-150">Trung cấp</th>
                <th rowspan="2" class="min-w-150">Khác</th>

                <th colspan="7">Tổng số</th>
                <th colspan="7">
                    Trong đó: Tổ trưởng chuyên môn chia theo trình độ chuyên môn
                    NV
                </th>
                <th colspan="7">
                    Trong đó: Tổ phó chuyên môn chia theo trình độ chuyên môn NV
                </th>

                <th rowspan="2" class="min-w-150">Tổng</th>
                <th rowspan="2" class="min-w-150">Tiến sĩ</th>
                <th rowspan="2" class="min-w-150">Thạc sĩ</th>
                <th rowspan="2" class="min-w-150">Đại học</th>
                <th rowspan="2" class="min-w-150">Cao đẳng</th>
                <th rowspan="2" class="min-w-150">Trung cấp</th>
                <th rowspan="2" class="min-w-150">Khác</th>
            </tr>

            <tr>
                <th rowspan="1" class="min-w-150">Tổng cộng</th>
                <th rowspan="1" class="min-w-150">Tiến sĩ</th>
                <th rowspan="1" class="min-w-150">Thạc sĩ</th>
                <th rowspan="1" class="min-w-150">Đại học</th>
                <th rowspan="1" class="min-w-150">Cao đẳng</th>
                <th rowspan="1" class="min-w-150">Trung cấp</th>
                <th rowspan="1" class="min-w-150">Khác</th>
                <th rowspan="1" class="min-w-150">Tổng cộng</th>
                <th rowspan="1" class="min-w-150">Tiến sĩ</th>
                <th rowspan="1" class="min-w-150">Thạc sĩ</th>
                <th rowspan="1" class="min-w-150">Đại học</th>
                <th rowspan="1" class="min-w-150">Cao đẳng</th>
                <th rowspan="1" class="min-w-150">Trung cấp</th>
                <th rowspan="1" class="min-w-150">Khác</th>
                <th rowspan="1" class="min-w-150">Tổng cộng</th>
                <th rowspan="1" class="min-w-150">Tiến sĩ</th>
                <th rowspan="1" class="min-w-150">Thạc sĩ</th>
                <th rowspan="1" class="min-w-150">Đại học</th>
                <th rowspan="1" class="min-w-150">Cao đẳng</th>
                <th rowspan="1" class="min-w-150">Trung cấp</th>
                <th rowspan="1" class="min-w-150">Khác</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td>{{ item.tongCanBo }}</td>
                <td>{{ item.tongHieuTruong }}</td>
                <td>{{ item.tongHieuPho }}</td>
                <td>{{ item.tongGiaoVien }}</td>
                <td>{{ item.tongNhanVien }}</td>

                <td>{{ item.tongHieuTruong }}</td>
                <td>{{ item.hieuTruongTienSi }}</td>
                <td>{{ item.hieuTruongThacSi }}</td>
                <td>{{ item.hieuTruongDaiHoc }}</td>
                <td>{{ item.hieuTruongCaoDang }}</td>
                <td>{{ item.hieuTruongTrungCap }}</td>
                <td>{{ item.hieuTruongKhac }}</td>

                <td>{{ item.tongHieuPho }}</td>
                <td>{{ item.hieuPhoTienSi }}</td>
                <td>{{ item.hieuPhoThacSi }}</td>
                <td>{{ item.hieuPhoDaiHoc }}</td>
                <td>{{ item.hieuPhoCaoDang }}</td>
                <td>{{ item.hieuPhoTrungCap }}</td>
                <td>{{ item.hieuPhoKhac }}</td>

                <td>{{ item.tongGiaoVien }}</td>
                <td>{{ item.giaoVienTienSi }}</td>
                <td>{{ item.giaoVienThacSi }}</td>
                <td>{{ item.giaoVienDaiHoc }}</td>
                <td>{{ item.giaoVienCaoDang }}</td>
                <td>{{ item.giaoVienTrungCap }}</td>
                <td>{{ item.giaoVienKhac }}</td>

                <td>{{ item.tongToTruong }}</td>
                <td>{{ item.toTruongTienSi }}</td>
                <td>{{ item.toTruongThacSi }}</td>
                <td>{{ item.toTruongDaiHoc }}</td>
                <td>{{ item.toTruongCaoDang }}</td>
                <td>{{ item.toTruongTrungCap }}</td>
                <td>{{ item.toTruongKhac }}</td>

                <td>{{ item.tongToPho }}</td>
                <td>{{ item.toPhoTienSi }}</td>
                <td>{{ item.toPhoThacSi }}</td>
                <td>{{ item.toPhoDaiHoc }}</td>
                <td>{{ item.toPhoCaoDang }}</td>
                <td>{{ item.toPhoTrungCap }}</td>
                <td>{{ item.toPhoKhac }}</td>

                <td>{{ item.tongNhanVien }}</td>
                <td>{{ item.nhanVienTienSi }}</td>
                <td>{{ item.nhanVienThacSi }}</td>
                <td>{{ item.nhanVienDaiHoc }}</td>
                <td>{{ item.nhanVienCaoDang }}</td>
                <td>{{ item.nhanVienTrungCap }}</td>
                <td>{{ item.nhanVienKhac }}</td>

                <td>{{ item.chuaKhai }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'TeachersProfessionalExpertise',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
