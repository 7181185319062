<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th>STT</th>
                <th>Trường học</th>
                <th>Mã trường</th>
                <th>Loại hình đào tạo</th>
                <th>Quy mô</th>
                <th>Số điểm trường</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenTruongHoc }}</td>
                <td class="text-left">{{ item.maTruongHoc }}</td>
                <td>{{ item.loaiHinhDaoTao }}</td>
                <td class="text-left">{{ item.nhomCapHoc }}</td>
                <td>{{ item.soDiemTruong }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'CountTheOddSchoolPoints',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
