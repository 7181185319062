<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="5" v-if="statistical === 4 || statistical === 5">
                    Mần non
                </th>
                <th colspan="5" v-if="statistical === 1">TH</th>
                <th colspan="5" v-if="statistical === 2">THCS</th>
                <th colspan="5" v-if="statistical === 3">THPT</th>
            </tr>
            <tr>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Hạng III</th>
                <th rowspan="1">Hạng II</th>
                <th rowspan="1">Hạng I</th>
                <th rowspan="1">Khác</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td class="text-left">{{ item.tongCanBo }}</td>
                <td class="text-left">{{ item.hang1 }}</td>

                <td class="text-left">{{ item.hang2 }}</td>

                <td class="text-left">{{ item.hang3 }}</td>

                <td class="text-left">{{ item.hangKhac }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StaffStatsByJobTitleQualification',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        },
        statistical: {
            type: Number
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 150px;
}
</style>
