<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="pushMinistryOfEducationVisible"
        :modal-title="'ĐẨY DỮ LIỆU LÊN BỘ'"
        :close-function="closeModalPushMinistryOfEducation"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="padding row">
                <div class="mr">
                    Năm học : {{ selectedYearFromStore }} -
                    {{ selectedYearFromStore + 1 }}
                </div>
                <div>Học kỳ : {{ selectedSemesterFromStore }}</div>
            </div>

            <CustomTable
                :table-modal="true"
                :hide-pagi="true"
                :hide-btn-table="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>

                        <th>Mã trường</th>
                        <th>Tên trường</th>
                        <th>Dữ liệu đẩy</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataPushMinistryOfEducation.length === 0">
                        <tr>
                            <td colspan="4">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr
                        v-for="(item, index) in dataPushMinistryOfEducation"
                        :key="index"
                    >
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td>{{ item.maTruong }}</td>
                        <td class="text-left">{{ item.tenTruong }}</td>
                        <td>{{ getNameDataPush(item.buocDays) }}</td>
                    </tr>
                </template>
            </CustomTable>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Đẩy"
                :btnSaveModal="true"
                @click="handlePushMinistryOfEducation"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
import CustomTable from '@/components/CustomTable.vue'
import { list_data_synchronization_modal_push } from '@/mock_data/ket-chuyen-du-lieu'

export default {
    name: 'ModalPush',
    data() {
        return { errModal: false }
    },
    components: { CustomButton, CustomModal, CustomTable },
    props: {
        pushMinistryOfEducationVisible: {
            type: Boolean,
            required: true
        },
        dataPushMinistryOfEducation: {
            type: Array,
            required: true
        },

        closeModalPushMinistryOfEducation: Function,
        handleSearch: Function
    },

    methods: {
        async handlePushMinistryOfEducation() {
            showLoading()

            try {
                const school_up_requests = this.dataPushMinistryOfEducation.map(
                    (item) => ({
                        maTruong: item.maTruong,
                        buocDays: item.buocDays
                    })
                )
                if (school_up_requests.length === 0) {
                    hideLoading()
                    showNotication(this, 'error', 'Vui lòng chọn dữ liệu cần đẩy')
                    return
                }
                const request_data = {
                    hocKy: this.selectedSemesterFromStore,
                    namHoc: this.selectedYearFromStore,

                    truongCanDayRequests: school_up_requests,
                    lstCapHoc: []
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.ket_chuyen_du_lieu.day_len_bo,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    hideLoading()

                    showNotication(
                        this,
                        'success',
                        'Hệ thống đang bắt đầu đẩy dữ liệu lên Bộ. Vui lòng theo dõi tiến trình ở chức năng Lịch sử đẩy dữ liệu lên Bộ'
                    )
                    await this.closeModalPushMinistryOfEducation()
                    await this.$emit('handleSearch')
                } else {
                    hideLoading()

                    showNotication(this, 'error', response?.rd)
                }
            } catch (error) {
                hideLoading()
                if (error.response?.data.rd) {
                    showNotication(this, 'error', error.response?.data.rd)
                }
            }
        },
        getNameDataPush(buocDays) {
            const keysToFind = buocDays.map(String)
            const foundNames = list_data_synchronization_modal_push
                .filter((item) => keysToFind.includes(item.key))
                .map((item) => item.name)

            return foundNames.join(', ')
        }
    },
    mounted() {},
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.padding {
    padding: 0px 15px 10px 15px;
}
.mr {
    margin-right: 20px;
}
</style>
