export const ROLES = {
    SuperAdmin: 1, // super admin
    EducationDepartment: 2, // vai trò sở
    EducationOffice: 4, // vai trò phòng
    School: 5 // vai trò trường
}

import { getFromSessionStorage } from '@/utils/session'
const auth_user = getFromSessionStorage('auth_user')

export function checkUserRole(user, role) {
    const checkRole = user.baseRole
    return checkRole ? checkRole === role : false
}

export function isRoleSuperAdmin() {
    return checkUserRole(auth_user, ROLES.SuperAdmin)
}

export function isRoleEducationOfficeOrSchool() {
    return (
        checkUserRole(auth_user, ROLES.EducationOffice) ||
        checkUserRole(auth_user, ROLES.School)
    )
}

export function isRoleSchool() {
    return checkUserRole(auth_user, ROLES.School)
}
