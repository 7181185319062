export const list_data_type = [
    {
        value: 'TRUONG_C1',
        label: 'Trường'
    },
    {
        value: 'DanhGiaGiaoVien_C1',
        label: 'Đánh giá giáo viên'
    },
    {
        value: 'GIAO_VIEN_C1',
        label: 'Cán bộ'
    },
    {
        value: 'LOP_C1',
        label: 'Lớp học'
    },
    {
        value: 'HOC_SINH_C1',
        label: 'Học sinh'
    },
    {
        value: 'KQHT_C1',
        label: 'KQ học tập'
    }
]
