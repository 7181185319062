<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="viewVisible"
        :modal-title="'Chi tiết Trường học được yêu cầu'"
        :close-function="closeModalView"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <CustomTable
                :table-modal="true"
                :hide-pagi="true"
                :hideBtnTable="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>

                        <th>Tên trường</th>
                        <th>Mã trường</th>
                        <th>Trạng thái nộp</th>
                        <th>File đã nộp</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataView.length === 0">
                        <tr>
                            <td colspan="12">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in dataView" :key="index">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td class="text-left">{{ item.tenTruongHoc }}</td>
                        <td>{{ item.maTruongHoc }}</td>
                        <td>
                            {{ item.trangThai === 0 ? 'Chưa nộp' : 'Đã nộp' }}
                        </td>
                        <td>
                            <span v-if="item.trangThai === 1"> a </span>
                        </td>
                    </tr>
                </template>
            </CustomTable>
        </template>
    </CustomModal>
</template>

<script>
import { getFromSessionStorage } from '@/utils/session'
import CustomModal from '@/components/CustomModal.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ModalUpdate',
    data() {
        return {
            errModal: false
        }
    },
    components: { CustomModal, CustomTable },
    props: {
        viewVisible: {
            type: Boolean,
            require: true
        },
        dataView: {
            type: Array,
            require: true
        },
        closeModalView: Function,
        handleSearch: Function
    },

    methods: {},
    mounted() {},
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
