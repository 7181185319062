<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Trường học -->
            <tr v-if="active_tab === 1">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="3">Tổng số trường cấp THPT</th>
                <th colspan="3">Trường Trung Học Phổ Thông</th>
                <th colspan="3">Trường THCS, THPT</th>
                <th colspan="3">Trường Tiểu Học, THCS, THPT</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2">Tổng số trường</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
            </tr>

            <!-- 2. Lớp học -->
            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th rowspan="3">Tổng số lớp</th>
                <th colspan="2">Chia ra theo loại hình trường</th>
                <th colspan="3">Chia ra theo lớp</th>
                <th colspan="3">Trong đó số Lớp ghép</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Công lập</th>
                <th rowspan="2">Tư thục</th>
                <th rowspan="2">Lớp 10</th>
                <th rowspan="2">Lớp 11</th>
                <th rowspan="2">Lớp 12</th>
                <th rowspan="2">Tổng số</th>
                <th colspan="2">Chia ra</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Công lập</th>
                <th rowspan="1">Tư thục</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportSchoolClass',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Trường học' },
                { number: 2, label: 'Lớp học' }
            ],
            list_td_active_tab_1: [
                'F9',
                'G9',
                'H9',
                'F10',
                'G10',
                'H10',
                'F14',
                'G14',
                'H14',
                'F18',
                'G18',
                'H18'
            ],
            list_td_active_tab_2: [
                'F22',
                'G22',
                'H22',
                'F24',
                'F25',
                'F26',
                'F28',
                'G28',
                'H28'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
