<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Học sinh biến động -->
            <tr v-if="active_tab === 1">
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="4">Học sinh chuyển đến từ tỉnh/thành phố khác</th>
                <th colspan="4">
                    Học sinh chuyển đi ngoài địa bàn tỉnh/thành phố
                </th>
                <th colspan="4">Học sinh bỏ học</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 2. Học sinh theo lớp -->
            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="4">Tổng số</th>
                <th colspan="20">Quy mô chia theo lớp</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th colspan="4">Lớp 1</th>
                <th colspan="4">Lớp 2</th>
                <th colspan="4">Lớp 3</th>
                <th colspan="4">Lớp 4</th>
                <th colspan="4">Lớp 5</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!--3. Học sinh lưu ban -->
            <tr v-if="active_tab === 3">
                <th rowspan="2">STT</th>
                <th rowspan="2">Địa phương</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Lớp 1</th>
                <th colspan="4">Lớp 2</th>
                <th colspan="4">Lớp 3</th>
                <th colspan="4">Lớp 4</th>
                <th colspan="4">Lớp 5</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th id rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th id rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>

            <!-- 4. Học sinh hoàn thành CT -->
            <tr v-if="active_tab === 4">
                <th rowspan="3">STT</th>
                <th rowspan="3">Địa phương</th>
                <th colspan="20">Học sinh hoàn thành chương trình tiểu học</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th colspan="4">Tổng số</th>
                <th colspan="4">Dưới 11 tuổi</th>
                <th colspan="4">11 tuổi</th>
                <th colspan="4">Trên 11 tuổi</th>
                <th colspan="4">Trong đó Học sinh khuyết tật</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Nữ</th>
                <th rowspan="1">Dân tộc</th>
                <th rowspan="1">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>
                    <td>
                        {{ item['E6'] }}
                    </td>
                    <td>
                        {{ item['F6'] }}
                    </td>
                    <td>
                        {{ item['G6'] }}
                    </td>
                    <td>
                        {{ item['H6'] }}
                    </td>
                    <td>
                        {{ item['E7'] }}
                    </td>
                    <td>
                        {{ item['F7'] }}
                    </td>
                    <td>
                        {{ item['G7'] }}
                    </td>
                    <td>
                        {{ item['H7'] }}
                    </td>
                    <td>
                        {{ item['E8'] }}
                    </td>
                    <td>
                        {{ item['F8'] }}
                    </td>
                    <td>
                        {{ item['G8'] }}
                    </td>
                    <td>
                        {{ item['H8'] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td>
                        {{ item['E9'] }}
                    </td>
                    <td>
                        {{ item['F9'] }}
                    </td>
                    <td>
                        {{ item['G9'] }}
                    </td>
                    <td>
                        {{ item['H9'] }}
                    </td>
                    <td>
                        {{ item['E10'] }}
                    </td>
                    <td>
                        {{ item['F10'] }}
                    </td>
                    <td>
                        {{ item['G10'] }}
                    </td>
                    <td>
                        {{ item['H10'] }}
                    </td>
                    <td>
                        {{ item['E11'] }}
                    </td>
                    <td>
                        {{ item['F11'] }}
                    </td>
                    <td>
                        {{ item['G11'] }}
                    </td>

                    <td>
                        {{ item['H11'] }}
                    </td>
                    <td>
                        {{ item['E12'] }}
                    </td>
                    <td>
                        {{ item['F12'] }}
                    </td>
                    <td>
                        {{ item['G12'] }}
                    </td>
                    <td>
                        {{ item['H12'] }}
                    </td>
                    <td>
                        {{ item['E13'] }}
                    </td>
                    <td>
                        {{ item['F13'] }}
                    </td>
                    <td>
                        {{ item['G13'] }}
                    </td>
                    <td>
                        {{ item['H13'] }}
                    </td>
                    <td>
                        {{ item['E14'] }}
                    </td>
                    <td>
                        {{ item['F14'] }}
                    </td>
                    <td>
                        {{ item['G14'] }}
                    </td>
                    <td>
                        {{ item['H14'] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>
                    <td>{{ item['E16'] }}</td>
                    <td>{{ item['F16'] }}</td>
                    <td>{{ item['G16'] }}</td>
                    <td>{{ item['H16'] }}</td>
                    <td>{{ item['E17'] }}</td>
                    <td>{{ item['F17'] }}</td>
                    <td>{{ item['G17'] }}</td>
                    <td>{{ item['H17'] }}</td>
                    <td>{{ item['E18'] }}</td>
                    <td>{{ item['F18'] }}</td>
                    <td>{{ item['G18'] }}</td>
                    <td>{{ item['H18'] }}</td>
                    <td>{{ item['E19'] }}</td>
                    <td>{{ item['F19'] }}</td>
                    <td>{{ item['G19'] }}</td>
                    <td>{{ item['H19'] }}</td>
                    <td>{{ item['E20'] }}</td>
                    <td>{{ item['F20'] }}</td>
                    <td>{{ item['G20'] }}</td>
                    <td>{{ item['H20'] }}</td>
                    <td>{{ item['E21'] }}</td>
                    <td>{{ item['F21'] }}</td>
                    <td>{{ item['G21'] }}</td>
                    <td>{{ item['H21'] }}</td>
                </tr>
            </template>

            <template v-if="active_tab === 4">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>
                    <td>{{ item['E22'] }}</td>
                    <td>{{ item['F22'] }}</td>
                    <td>{{ item['G22'] }}</td>
                    <td>{{ item['H22'] }}</td>
                    <td>{{ item['E24'] }}</td>
                    <td>{{ item['F24'] }}</td>
                    <td>{{ item['G24'] }}</td>
                    <td>{{ item['H24'] }}</td>
                    <td>{{ item['E25'] }}</td>
                    <td>{{ item['F25'] }}</td>
                    <td>{{ item['G25'] }}</td>
                    <td>{{ item['H25'] }}</td>
                    <td>{{ item['E26'] }}</td>
                    <td>{{ item['F26'] }}</td>
                    <td>{{ item['G26'] }}</td>
                    <td>{{ item['H26'] }}</td>
                    <td>{{ item['E28'] }}</td>
                    <td>{{ item['F28'] }}</td>
                    <td>{{ item['G28'] }}</td>
                    <td>{{ item['H28'] }}</td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Học sinh biến động' },
                { number: 2, label: 'Học sinh theo lớp' },
                { number: 3, label: 'Học sinh lưu ban' },
                { number: 4, label: 'Học sinh hoàn thành CT' }
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
