<template>
    <el-menu
        :default-active="activeMenu"
        :class="
            zoom ? 'el-menu-horizontal-demo zoomed' : 'el-menu-horizontal-demo'
        "
        mode="horizontal"
        menu-trigger="hover"
    >
        <template v-for="item in auth_user.permission.slice(0, 7)">
            <el-submenu
                mode="horizontal"
                v-if="item.hasChildren"
                :key="item.path"
                :index="item.path"
                v-on:click="navigateRouter(item.path)"
            >
                <template slot="title">
                    <span @click="navigateRouter(item.path)">
                        {{ item.name }}
                    </span>
                </template>

                <template v-for="(child, index) in item.children">
                    <el-submenu
                        v-if="child.hasChildren"
                        :key="index"
                        :index="child.path"
                    >
                        <template slot="title"> {{ child.name }}</template>
                        <el-menu-item
                            v-for="grandchild in child.children"
                            :key="grandchild.path"
                            :index="grandchild.path"
                            v-on:click="navigateRouter(grandchild.path)"
                        >
                            {{ grandchild.name }}
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item
                        v-else
                        :key="child.path"
                        :index="child.path"
                        v-on:click="navigateRouter(child.path)"
                    >
                        {{ child.name }}
                    </el-menu-item>
                </template>
            </el-submenu>
            <el-menu-item v-else :key="item.path" :index="item.path">
                <i v-if="item.icon" :class="item.icon"></i>
                <span @click="navigateRouter(item.path)">
                    {{ item.name }}
                </span>
            </el-menu-item>
        </template>
    </el-menu>
</template>

<script>
import { getFromSessionStorage } from '@/utils/session'
export default {
    name: 'AV',
    data() {
        return {
            activeMenu: null
        }
    },
    methods: {
        navigateRouter(path) {
            const route = this.$router.resolve({ path })

            if (route.resolved.matched.length > 0) {
                if (this.$route.path === path) {
                    window.location.reload()
                } else {
                    this.$router.push(path).catch((err) => {
                        if (err.name !== 'NavigationDuplicated') {
                            console.error(err)
                        }
                    })
                }
            } else {
                console.log(`Đường dẫn ${path} không tồn tại`)
            }
        },
        handleRouteChange(to) {
            const path = to.path

            this.activeMenu = path

            const fullPath = `${to.path}?${Object.entries(to.query)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')}`
            if (fullPath && to.query.type) {
                this.activeMenu = fullPath
            }
        }
    },
    watch: {
        $route(to) {
            this.handleRouteChange(to)
        }
    },
    mounted() {
        this.handleRouteChange(this.$route)
    },
    computed: {
        zoom() {
            return this.$store.state.zoom.zoom
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        }
    }
}
</script>

<style scoped>
.el-menu-horizontal-demo {
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
}
::v-deep .el-submenu .el-submenu__title,
::v-deep .el-menu-item {
    color: black;
}
::v-deep .el-menu-item.is-active {
    border-bottom: 0px;
}
::v-deep .el-submenu__title i {
    color: black;
}
.el-menu-horizontal-demo,
::v-deep .el-submenu .el-submenu__title,
::v-deep .el-menu-item.is-active,
.el-menu-item {
    height: 45px;
    line-height: 45px;
    font-size: 15px;
}

.zoomed {
    display: none;
}

::v-deep .el-submenu.is-active .el-submenu__title,
::v-deep .el-menu .el-menu-item.is-active {
    border-bottom: 0px;
    background-color: #d0ecff;
    color: #000000;
}
::v-deep .el-submenu.is-active .el-submenu__title i {
    color: var(--blue-shared);
}

.el-menu--horizontal .el-menu .el-menu-item.is-active {
    background: #d0ecff;
    color: #000000;
}
.el-menu--horizontal .el-menu .el-menu-item:hover {
    background: #186ab2;
    color: #ffffff;
}
::v-deep .el-menu--popup {
    margin-top: -5px !important;
}

::v-deep .el-submenu.is-active .el-submenu__title i {
    color: #000000;
}
@media only screen and (max-width: 767px) {
    .el-menu-horizontal-demo {
        display: none;
    }
}
</style>
