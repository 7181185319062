<template>
    <div>
        <StatisticsOfTeachingStaff
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.1',
                    'B.3.1',
                    'C.3.1',
                    'D.3.1',
                    'E.3.1'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />

        <TeachersInTheTrainingFieldSubjects
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.2',
                    'B.3.2',
                    'C.3.2',
                    'D.3.2',
                    'E.3.2'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />

        <GenderStaffStatistics
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.3',
                    'B.3.3',
                    'C.3.3',
                    'D.3.3',
                    'E.3.3'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />

        <EmployeeCountStatistics
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.4',
                    'B.3.4',
                    'C.3.4',
                    'D.3.4',
                    'E.3.4'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />

        <StaffStatisticsByEthnicity
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.5',
                    'B.3.5',
                    'C.3.5',
                    'D.3.5',
                    'E.3.5'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />

        <StaffStatisticsByPartyMembership
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.6',
                    'B.3.6',
                    'C.3.6',
                    'D.3.6',
                    'E.3.6'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />

        <StaffStatisticsByComputerLiteracyLevel
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.7',
                    'B.3.7',
                    'C.3.7',
                    'D.3.7',
                    'E.3.7'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />

        <TeachersProfessionalExpertise
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.8',
                    'B.3.8',
                    'C.3.8',
                    'D.3.8',
                    'E.3.8'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />

        <StaffStatsByJobTitleQualification
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.9',
                    'B.3.9',
                    'C.3.9',
                    'D.3.9',
                    'E.3.9'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
            :statistical="statistical"
        />

        <TeacherSurplusShortageStatisticsByAllocation
            :tableData="tableData"
            v-if="
                this.isDetailedReport([
                    'A.3.10',
                    'B.3.10',
                    'C.3.10',
                    'D.3.10',
                    'E.3.10'
                ])
            "
            :start="start"
            :limit="limit"
            :column_form="column_form"
        />
    </div>
</template>

<script>
import TeachersInTheTrainingFieldSubjects from './TeachersInTheTrainingFieldSubjects'
import TeachersProfessionalExpertise from './TeachersProfessionalExpertise'
import StatisticsOfTeachingStaff from './StatisticsOfTeachingStaff'
import GenderStaffStatistics from './GenderStaffStatistics'
import StaffStatisticsByEthnicity from './StaffStatisticsByEthnicity'
import StaffStatisticsByPartyMembership from './StaffStatisticsByPartyMembership'
import StaffStatisticsByComputerLiteracyLevel from './StaffStatisticsByComputerLiteracyLevel'
import EmployeeCountStatistics from './EmployeeCountStatistics'
import StaffStatsByJobTitleQualification from './StaffStatsByJobTitleQualification'
import TeacherSurplusShortageStatisticsByAllocation from './TeacherSurplusShortageStatisticsByAllocation'
export default {
    name: 'ReportSchool',
    props: {
        statistical: {
            type: Number,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        },
        detailed_report: {
            type: String,
            require: true
        }
    },
    components: {
        TeachersInTheTrainingFieldSubjects,
        TeachersProfessionalExpertise,
        StatisticsOfTeachingStaff,
        GenderStaffStatistics,
        StaffStatisticsByEthnicity,
        StaffStatisticsByPartyMembership,
        StaffStatisticsByComputerLiteracyLevel,
        EmployeeCountStatistics,
        StaffStatsByJobTitleQualification,
        TeacherSurplusShortageStatisticsByAllocation
    },
    data() {
        return {}
    },

    methods: {
        isDetailedReport(validReports) {
            return validReports.includes(this.detailed_report)
        }
    },
    mounted() {},
    watch: {},

    computed: {}
}
</script>

<style scoped></style>
