l
<template>
    <div>
        <ToolBar :menu="'Danh mục'" :content="'Quận huyện'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    @click="openModalAddNew"
                    :btnToolBar="true"
                />
                <CustomButton
                    label="Nhập theo biểu mẫu"
                    :icon="'el-icon-document'"
                    @click="openModalUpload"
                    :btnToolBar="true"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Tên quận huyện</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="name"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Mã quận huyện</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="code"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable
            :tableData="tableData"
            :hide-btn-table="true"
            :column-form="1"
        >
            <template v-slot:header>
                <tr class="text-center">
                    <th>STT</th>
                    <th>Thao tác</th>
                    <th>Tên quận huyện</th>
                    <th>Mã quận huyện</th>
                    <th>Trạng thái</th>
                    <th>Ngày cập nhật</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Chỉnh sửa"
                            placement="top"
                        >
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="openModalUpdate(item)"
                            />
                        </el-tooltip>
                        <span v-if="item.trangThai === 0">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Mở khóa"
                                placement="top"
                            >
                                <img
                                    :src="iconUnLock"
                                    class="icon"
                                    @click="handleUnLock(item)"
                                />
                            </el-tooltip>
                        </span>
                        <span v-else>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Khóa"
                                placement="top"
                            >
                                <img
                                    :src="iconLock"
                                    class="icon"
                                    @click="handleLock(item)"
                                />
                            </el-tooltip>
                        </span>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Xóa"
                            placement="top"
                        >
                            <img
                                :src="iconDelete"
                                class="icon"
                                @click="openModalDelete(item)"
                            />
                        </el-tooltip>
                    </td>
                    <td class="text-left">
                        {{ item.tenQuanHuyen }}
                    </td>
                    <td>
                        {{ item.maQuanHuyen }}
                    </td>
                    <td>
                        <TextStatus
                            :content="
                                item.trangThai === 1
                                    ? 'Đang hoạt động'
                                    : 'Ngưng hoạt động'
                            "
                            :status="item.trangThai"
                        />
                    </td>
                    <td>
                        {{ formatDateToHHMMSS(item.ngayCapNhat) }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="deleteVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDelete"
            :size="'sm'"
        >
            <template v-slot:content>
                <div>Xác nhận xóa quận huyện ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnDeleteModal="true"
                    @click="handleDelete"
                />
            </template>
        </CustomModal>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="statusVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalStatus"
        >
            <template v-slot:content>
                <div>Xác nhận {{ idstatus === 0 ? 'Khóa' : 'Mở khóa' }} ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    size="small"
                    :btnSaveModal="true"
                    @click="handleStatus"
                />
            </template>
        </CustomModal>
        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
        />
        <ModalUpdate
            :closeModalUpdate="closeModalUpdate"
            :updateVisible="updateVisible"
            @handleSearch="handleSearch"
            :dataUpdate="dataUpdate"
        />
        <ModalUpload
            :closeModalUpload="closeModalUpload"
            :uploadVisible="uploadVisible"
            @handleSearch="handleSearch"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomTable from '@/components/CustomTable.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { formatDateToHHMMSS } from '@/utils'
import { showNotication } from '@/utils/notification'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import ModalUpload from './modal_upload.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconLock from '@/assets/img/icon-table/lock.svg'
import iconUnLock from '@/assets/img/icon-table/un-lock.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import { list_status } from '@/mock_data/danh-muc'
import TextStatus from '@/components/TextStatus.vue'
export default {
    name: 'DisTrict',

    components: {
        ToolBar,
        CustomTable,
        CustomButton,
        ModalAddNew,
        CustomModal,
        ModalUpdate,
        ModalUpload,
        FormSearch,
        CustomInput,
        CustomPagination,
        ESelect,
        TextStatus
    },
    data() {
        return {
            iconEdit,
            iconLock,
            iconUnLock,
            iconDelete,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            name: '',
            code: '',
            list_status: list_status,
            status: [],
            tableData: [],
            addNewVisible: false,

            updateVisible: false,
            dataUpdate: {},

            deleteVisible: false,
            idDelete: null,

            statusVisible: false,
            idstatus: null,
            dataStatus: {},

            uploadVisible: false
        }
    },
    methods: {
        formatDateToHHMMSS,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },
        openModalDelete(item) {
            this.idDelete = item.maQuanHuyen
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        openModalSatus() {
            this.statusVisible = true
        },
        async handleStatus() {
            const request_data = {
                ma: this.dataStatus.maQuanHuyen,
                maTinhThanh: this.dataStatus.maTinhThanh,
                ten: this.dataStatus.tenQuanHuyen,
                trangThai: this.idstatus
            }
            const response = await sendRequest(
                Api.super_admin.quan_huyen.trang_thai,
                request_data,
                this.request_headers
            )
            if (response.code == 200) {
                showNotication(this, 'success', response.message)
                this.handleSearch()
                this.closeModalStatus()
            } else {
                showNotication(this, 'error', response.message)
            }
            hideLoading()
        },
        async handleLock(item) {
            this.openModalSatus()
            this.dataStatus = item
            this.idstatus = 0
        },

        async handleUnLock(item) {
            this.openModalSatus()
            this.dataStatus = item
            this.idstatus = 1
        },
        closeModalStatus() {
            this.statusVisible = false
        },
        openModalUpload() {
            this.uploadVisible = true
        },
        closeModalUpload() {
            this.uploadVisible = false
        },
        async handleDelete() {
            try {
                showLoading()
                const request_params = {
                    ma: this.idDelete
                }
                const response = await sendRequest(
                    Api.super_admin.quan_huyen.xoa,
                    null,
                    this.request_headers,
                    request_params
                )

                if (response.code == 200) {
                    hideLoading()
                    showNotication(this, 'success', response.message)
                    await this.handleSearch()
                    this.closeModalDelete()
                } else {
                    showNotication(this, 'error', response.message)
                }
            } catch (error) {
                hideLoading
            }
        },
        async handleSearch() {
            const request_params = {
                start: this.start,
                limit: this.limit,
                ma: this.code,
                ten: this.name,
                maTinhThanh: this.auth_user.ma_don_vi,
                trangthai: this.status
            }
            const response = await sendRequest(
                Api.super_admin.quan_huyen.danh_sach,
                null,
                this.request_headers,
                request_params
            )

            this.tableData = response.rows
            this.total_rows = response.total
        }
    },
    mounted() {
        this.handleSearch()
    },
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
