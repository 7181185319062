<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="changePasswordVisible"
        :modal-title="'Đối mật khẩu'"
        :close-function="closeModalChangePassword"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-md-12 notification" v-if="notification">
                    <span class="color-blue">Thông báo:</span>
                    <span class="error">
                        Bạn đang sử dụng mật khẩu mặc định. Vui lòng thay đổi mật
                        khẩu để đảm bảo an toàn thông tin.
                    </span>
                    <div class="hr"></div>
                </div>
                <div class="col-md-12">
                    <label class="label">
                        Mật khẩu cũ
                        <span class="error">*</span>
                    </label>
                    <CustomInput
                        v-model="current_password"
                        :show-password="true"
                        @input="onChangeHandler('current_password', $event)"
                        :textError="err_current_password"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="label">
                        Nhập mật khẩu mới
                        <span class="error">*</span>
                    </label>
                    <CustomInput
                        v-model="new_password"
                        :show-password="true"
                        @input="onChangeHandler('new_password', $event)"
                        :textError="err_new_password"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="label">
                        Nhập lại mật khẩu mới
                        <span class="error">*</span>
                    </label>
                    <CustomInput
                        v-model="confirm_new_password"
                        :show-password="true"
                        @input="onChangeHandler('confirm_new_password', $event)"
                        :textError="err_confirm_new_password"
                    >
                    </CustomInput>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleChangePassword"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { hideLoading, showLoading } from '@/utils/loading'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { validateFields, handlerError } from '@/utils/validator'
import { showNotication } from '@/utils/notification'
import CustomInput from '@/components/CustomInput.vue'
export default {
    name: 'ModalDelete',
    components: { CustomButton, CustomModal, CustomInput },
    data() {
        return {
            errModal: false,

            current_password: '',
            new_password: '',
            confirm_new_password: '',

            err_current_password: '',
            err_new_password: '',
            err_confirm_new_password: '',

            notification: false
        }
    },
    props: {
        changePasswordVisible: {
            type: Boolean,
            required: true
        },

        closeModalChangePassword: Function
    },

    methods: {
        onChangeHandler(source, newValue) {
            const errorFields = {
                current_password: 'err_current_password',
                new_password: 'err_new_password',
                confirm_new_password: 'err_confirm_new_password'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },

        async handleChangePassword() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.current_password,
                        errorField: 'err_current_password'
                    },

                    {
                        value: this.new_password,
                        errorField: 'err_new_password'
                    },

                    {
                        value: this.confirm_new_password,
                        errorField: 'err_confirm_new_password'
                    }
                ]

                if (
                    this.new_password !== this.confirm_new_password &&
                    this.new_password !== '' &&
                    this.confirm_new_password !== ''
                ) {
                    showNotication(
                        this,
                        'error',
                        'Vui lòng nhập lại đúng mật khẩu'
                    )
                    return
                }
                if (this.current_password === '123456Aa@dts') {
                    this.notification = true
                }
                if (validateFields(this, fieldsToValidate)) {
                    showLoading()
                    const request_data = {
                        password: this.confirm_new_password,
                        username: this.auth_user.username,
                        oldPassword: this.current_password
                    }

                    const response = await sendRequest(
                        Api.tai_khoan.doi_mat_khau,
                        request_data,
                        this.request_headers
                    )

                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                        this.closeModalChangePassword()
                        // this.$emit('handleLogOut')
                    } else {
                        showNotication(this, 'error', response.rd)
                    }

                    hideLoading()
                }
            } catch (error) {
                hideLoading()

                showNotication(this, 'error', error?.response?.data?.rd)
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.label {
    font-weight: 600;
}
.error {
    color: red;
}
.color-blue {
    color: blue;
    font-weight: 600;
}
.notification {
    text-align: center;
    padding: 15px 10px 15px 10px;
}
.hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}
</style>
