<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="bonusVisible"
        :modal-title="'Khen thưởng'"
        :close-function="closeModalBonus"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <CustomTable
                :column-form="2"
                :hide-btn-table="true"
                :hidePagi="true"
                :tableModal="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Khen thưởng <span class="error">*</span></th>
                        <th>Nội dung</th>
                        <th>Cấp khen thưởng</th>
                        <th>Số quyết định</th>
                        <th>Ngày khen thưởng</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataBonus.length === 0">
                        <tr>
                            <td colspan="10">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in dataBonus" :key="index">
                        <td>
                            {{ index + 1 }}
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_bonus"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.maKhenThuongGv"
                            />
                        </td>

                        <td>
                            <CustomInput
                                v-model="item.noiDung"
                                placeholder=""
                                :disabled="true"
                            >
                            </CustomInput>
                        </td>
                        <td>
                            <CustomInput
                                v-model="item.capKhenThuong"
                                placeholder=""
                                :disabled="true"
                            >
                            </CustomInput>
                        </td>
                        <td>
                            <CustomInput
                                v-model="item.soQuyetDinhKhenThuong"
                                placeholder=""
                                :disabled="true"
                            >
                            </CustomInput>
                        </td>

                        <td>
                            {{ item.ngayKhenThuong }}
                        </td>
                    </tr>
                </template>
            </CustomTable>
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import CustomModal from '@/components/CustomModal.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ModalBonus',
    data() {
        return {
            errModal: false,
            list_bonus: [
                {
                    label: 'Bằng khen - [01]',
                    value: '01'
                },
                {
                    label: 'Giấy khen - [02]',
                    value: '02'
                }
            ]
        }
    },
    components: {
        CustomModal,
        CustomTable,
        ESelect,
        CustomInput
    },
    props: {
        bonusVisible: {
            type: Boolean,
            required: true
        },
        closeModalBonus: Function,
        dataBonus: {
            type: Array,
            required: true
        }
    },

    methods: {},
    mounted() {},
    computed: {}
}
</script>

<style scoped>
.error {
    color: red;
}
::v-deep .el-input.is-disabled .el-input__inner {
    color: black;
}
</style>
