<template>
    <div>
        <ReportListSchool
            :tableData="tableData"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            v-if="this.isDetailedReport(['A.1.1', 'A.1.2'])"
        />

        <CountTheOddSchoolPoints
            :tableData="tableData"
            :start="start"
            :limit="limit"
            :column_form="column_form"
            v-if="
                this.isDetailedReport([
                    'B.1.1',
                    'B.1.2',
                    'C.1.1',
                    'C.1.2',
                    'D.1.1',
                    'D.1.2',
                    'E.1.1'
                ])
            "
        />
    </div>
</template>

<script>
import ReportListSchool from './ReportListSchool'
import CountTheOddSchoolPoints from './CountTheOddSchoolPoints'

export default {
    name: 'ReportSchool',
    props: {
        statistical: {
            type: Number,
            require: true
        },
        detailed_report: {
            type: String,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },
    components: {
        ReportListSchool,
        CountTheOddSchoolPoints
    },
    data() {
        return {}
    },

    methods: {
        isDetailedReport(validReports) {
            return validReports.includes(this.detailed_report)
        }
    },
    mounted() {},
    watch: {},

    computed: {}
}
</script>

<style scoped></style>
