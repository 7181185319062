export const list_report_criteria = [
    { name: 'Đơn vị quản lý', nameCol: 'maDonVi', index: 0, status: true },
    { name: 'Cấp dạy', nameCol: 'capHoc', index: 1, status: true },
    { name: 'Trường học', nameCol: 'maTruongHoc', index: 2, status: true },
    { name: 'Trạng thái', nameCol: 'trangThai', index: 4, status: true },
    { name: 'Giới tính', nameCol: 'gioiTinh', index: 5, status: true },
    {
        name: 'Loại hình trường',
        nameCol: 'loaiHinhDaoTao',
        index: 6,
        status: true
    },
    { name: 'Loại hợp đồng', nameCol: 'loaiHopDong', index: 7, status: true },
    { name: 'Nhóm cán bộ', nameCol: 'loaiCanBo', index: 8, status: true },
    {
        name: 'Trình độ chuyên môn',
        nameCol: 'trinhDoChuyenMonCaoNhat',
        index: 9,
        status: true
    },
    { name: 'Khu vực', nameCol: 'maKhuVuc', index: 10, status: true },
    { name: 'Độ tuổi', nameCol: 'doTuoi', index: 11, status: true },
    {
        name: 'Đánh giá chuẩn nghề nghiệp',
        nameCol: 'dgKqChuanNgheNghiep',
        index: 12,
        status: true
    },
    {
        name: 'Dân tộc thiểu số',
        nameCol: 'danTocThieuSo',
        index: 13,
        status: true
    },
    {
        name: 'Giáo viên phụ trách đội',
        nameCol: 'chuyenTrachDoi',
        index: 14,
        status: true
    },
    { name: 'Môn dạy', nameCol: 'maMonHoc', index: 15, status: true },
    { name: 'Dân tộc', nameCol: 'maDanToc', index: 16, status: true },
    { name: 'Chức vụ', nameCol: 'chucVu', index: 17, status: true },
    {
        name: 'Trình độ giáo viên',
        nameCol: 'trinhDoGvien',
        index: 18,
        status: true
    },
    {
        name: 'Trình độ đào tạo',
        nameCol: 'trinhDoDaoTao',
        index: 19,
        status: true
    },
    {
        name: 'Hình thức đào tạo',
        nameCol: 'hinhThucDaoTao',
        index: 20,
        status: true
    },
    {
        name: 'Chuyên ngành đào tạo',
        nameCol: 'maChuyenNganhDaoTao',
        index: 21,
        status: true
    },
    {
        name: 'Trình độ văn hóa',
        nameCol: 'trinhDoVanHoa',
        index: 22,
        status: true
    },
    {
        name: 'Trình độ tin học',
        nameCol: 'trinhDoTinHoc',
        index: 23,
        status: true
    },
    {
        name: 'Trình độ ngoại ngữ',
        nameCol: 'trinhDoNgoaiNgu',
        index: 24,
        status: true
    },
    {
        name: 'Trình độ lý luận chính trị',
        nameCol: 'trinhDoLyLuanChinhTri',
        index: 25,
        status: true
    },
    {
        name: 'Trình độ quản lý nhà nước',
        nameCol: 'trinhDoQuanLyNhaNuoc',
        index: 26,
        status: true
    },
    {
        name: 'Trình độ quản lý giáo dục',
        nameCol: 'trinhDoQuanLyGiaoDuc',
        index: 27,
        status: true
    },
    {
        name: 'Tham gia bồi dưỡng thường xuyên',
        nameCol: 'thamGiaBdTxuyen',
        index: 28,
        status: true
    },
    { name: 'Mã ngạch', nameCol: 'maNgach', index: 29, status: true }
]

export const list_advanced_search_staff = [
    { name: 'Trạng thái', value: 'trangThai' },
    { name: 'Giới tính', value: 'gioiTinh' },
    {
        name: 'Loại hình trường',
        value: 'loaiHinhDaoTao'
    },
    { name: 'Loại hợp đồng', value: 'loaiHopDong' },
    { name: 'Nhóm cán bộ', value: 'loaiCanBo' },
    {
        name: 'Trình độ chuyên môn',
        value: 'trinhDoChuyenMonCaoNhat'
    },
    { name: 'Khu vực', value: 'maKhuVuc' },
    { name: 'Độ tuổi', value: 'doTuoi' },
    {
        name: 'Đánh giá chuẩn nghề nghiệp',
        value: 'dgKqChuanNgheNghiep'
    },
    {
        name: 'Dân tộc thiểu số',
        value: 'danTocThieuSo'
    },
    {
        name: 'Giáo viên phụ trách đội',
        value: 'chuyenTrachDoi'
    },
    {
        name: 'Tham gia bồi dưỡng thường xuyên',
        value: 'thamGiaBdTxuyen'
    },
    { name: 'Môn dạy', value: 'maMonHoc' },
    { name: 'Dân tộc', value: 'maDanToc' },

    {
        name: 'Trình độ giáo viên',
        value: 'trinhDoGvien'
    },
    {
        name: 'Trình độ đào tạo',
        value: 'trinhDoDaoTao'
    },
    {
        name: 'Hình thức đào tạo',
        value: 'hinhThucDaoTao'
    },
    {
        name: 'Chuyên ngành đào tạo',
        value: 'maChuyenNganhDaoTao'
    },
    {
        name: 'Trình độ văn hóa',
        value: 'trinhDoVanHoa'
    },
    {
        name: 'Trình độ tin học',
        value: 'trinhDoTinHoc'
    },
    {
        name: 'Trình độ ngoại ngữ',
        value: 'trinhDoNgoaiNgu'
    },
    {
        name: 'Trình độ lý luận chính trị',
        value: 'trinhDoLyLuanChinhTri'
    },
    {
        name: 'Trình độ quản lý nhà nước',
        value: 'trinhDoQuanLyNhaNuoc'
    },
    {
        name: 'Trình độ quản lý giáo dục',
        value: 'trinhDoQuanLyGiaoDuc'
    },

    { name: 'Mã ngạch', value: 'maNgach' },
    {
        name: 'Dạy nhóm lớp',
        value: 'dayNhomLop'
    },
    {
        name: 'Dạy nhóm lớp kiêm nhiệm',
        value: 'dayNhomLopKiemNhiem'
    },
    {
        name: 'Môn dạy chính',
        value: 'monDayChinh'
    },
    {
        name: 'Môn dạy kiêm nhiệm',
        value: 'monDayKiemNhiem'
    },
    {
        name: 'Nhiệm vụ kiêm nhiệm',
        value: 'nhiemVuKiemNhiem'
    },
    {
        name: 'Bậc lương',
        value: 'bacLuong'
    },
    {
        name: 'Hệ số lương',
        value: 'heSo'
    },
    {
        name: 'Vượt khung',
        value: 'vuotKhung'
    },
    {
        name: 'Môn ngoại ngữ chính',
        value: 'monNgoaiNguChinh'
    },
    {
        name: 'Chuyên ngành đào tạo chính',
        value: 'chuyenNganhDaoTaoChinh'
    },
    {
        name: 'Trình độ đào tạo chuyên ngành chính',
        value: 'trinhDoDaoTaoCnChinh'
    },
    {
        name: 'Đánh giá viên chức',
        value: 'danhGiaVienChuc'
    },
    {
        name: 'Thành phần gia đình',
        value: 'thanhPhanGiaDinh'
    },
    {
        name: 'Đối tác',
        value: 'maDoiTac'
    }
]

export const list_gender = [
    {
        label: 'Nam - [1]',
        value: 1
    },
    {
        label: 'Nữ - [0]',
        value: 0
    }
]

export const list_staff_group = [
    {
        label: 'Cán bộ quản lý - [1]',
        value: 1
    },
    {
        label: 'Giáo viên - [2]',
        value: 2
    },
    {
        label: 'Nhân viên - [3]',
        value: 3
    }
]

export const list_age = [
    {
        value: '10',
        label: 'Dưới 20 tuổi'
    },
    {
        value: '20',
        label: 'Từ 20-29 tuổi'
    },
    {
        value: '30',
        label: 'Từ 30-39 tuổi'
    },
    {
        value: '40',
        label: 'Từ 40-49 tuổi'
    },
    {
        value: '50',
        label: 'Từ 50-54 tuổi'
    },
    {
        value: '55',
        label: 'Từ 55-59 tuổi'
    },
    {
        value: '60',
        label: 'Trên 60 tuôi'
    }
]

export const list_yes_of_no = [
    {
        value: 1,
        label: 'Có'
    },
    {
        value: 0,
        label: 'Không'
    }
]
export const list_true_of_false = [
    {
        value: true,
        label: 'Có'
    },
    {
        value: false,
        label: 'Không'
    }
]

export const list_teacher_level = [
    { value: '7', label: 'Trung cấp - [7]' },
    { value: '16', label: 'Đại học - [16]' },
    { value: '1', label: 'Tiến sĩ - [1]' },
    { value: '13', label: 'Cao đẳng - [13]' },
    { value: '3', label: 'Thạc sĩ - [3]' }
]

export const list_salary_rank = [
    {
        label: 'Bậc 1 - [1]',
        value: '1'
    },
    {
        label: 'Bậc 9 - [9]',
        value: '9'
    },
    {
        label: 'Bậc 11 - [11]',
        value: '11'
    },
    {
        label: 'Bậc 2 - [2]',
        value: '2'
    },
    {
        label: 'Bậc 4 - [4]',
        value: '4'
    },
    {
        label: 'Bậc 6 - [6]',
        value: '6'
    },
    {
        label: 'Bậc 7 - [7]',
        value: '7'
    },
    {
        label: 'Bậc 3 - [3]',
        value: '3'
    },
    {
        label: 'Bậc 12 - [12]',
        value: '12'
    },
    {
        label: 'Bậc 8 - [8]',
        value: '8'
    },
    {
        label: 'Bậc 5 - [5]',
        value: '5'
    },
    {
        label: 'Bậc 10 - [10]',
        value: '10'
    }
]
export const list_main_specialized_training_level = [
    { value: '7', label: 'Trung cấp - [7]' },
    { value: '16', label: 'Đại học - [16]' },
    { value: '1', label: 'Tiến sĩ - [1]' },
    { value: '13', label: 'Cao đẳng - [13]' },
    { value: '3', label: 'Thạc sĩ - [3]' }
]

export const list_advanced_search_student = [
    {
        name: 'Nhóm máu',
        value: 'nhomMau'
    },
    {
        name: 'Khuyết tật',
        value: 'khuyetTat'
    },
    {
        name: 'Diện chính sách',
        value: 'maDtuongCsach'
    },
    {
        name: 'Thành phần gia đình',
        value: 'tphanGdinh'
    },
    {
        name: 'Diện ưu tiên',
        value: 'dtuongUuTien'
    },
    {
        name: 'Đội viên',
        value: 'doiVien'
    },
    {
        name: 'Đoàn viên',
        value: 'doanVien'
    },
    {
        name: 'Đảng viên',
        value: 'dangVien'
    },
    {
        name: 'Loại khuyết tật',
        value: 'maLoaiKhuyetTat'
    },
    {
        name: 'Khu vực',
        value: 'maKhuVuc'
    },
    {
        name: 'Loại nhập học',
        value: 'loaiNhapHoc'
    },
    {
        name: 'Học kỹ năng skss',
        value: 'hocKnsSkss'
    },
    {
        name: 'Khối học',
        value: 'khoiHoc'
    },
    {
        name: 'Trạng thái',
        value: 'trangThai'
    },
    {
        name: 'Hỗ trợ học tập',
        value: 'htHoTro'
    },
    {
        name: 'Thời điểm rời trường',
        value: 'thoiDiemRoiTruong'
    },
    {
        name: 'Kiểm tra sức khỏe dinh dưỡng',
        value: 'kiemTraSucKhoeDinhDuong'
    },
    {
        name: 'Tình trạng dinh dưỡng',
        value: 'tinhTrangDinhDuong'
    },

    {
        name: 'Cha dân tộc',
        value: 'chaDanToc'
    },
    {
        name: 'Mẹ dân tộc',
        value: 'meDanToc'
    },
    {
        name: 'Lý do thôi học',
        value: 'maLyDoThoiHoc'
    },
    {
        name: 'Học tiếng dân tộc',
        value: 'hocSinhTiengDanToc'
    },
    {
        name: 'Học song ngữ',
        value: 'hocSongNgu'
    },
    {
        name: 'Học bán trú dân nuôi',
        value: 'hocSinhBanTruDanNuoi'
    },
    {
        name: 'Học nội trú dân nuôi',
        value: 'hocSinhNoiTruDanNuoi'
    },
    {
        name: 'Khuyết tật không đánh giá',
        value: 'khuyetTatKhongDanhGia'
    },
    {
        name: 'Hộ nghèo',
        value: 'hoNgheo'
    },
    {
        name: 'Hỗ trợ nhà ở',
        value: 'htroNhaO'
    },
    {
        name: 'Được cấp tiền hàng tháng',
        value: 'capTienHangThang'
    },
    {
        name: 'Được cấp gạo',
        value: 'capGao'
    },
    {
        name: 'Học chương trình GD của Bộ',
        value: 'hocCtgdCuaBo'
    },
    {
        name: 'Được hỗ trợ chi phí học tập',
        value: 'htChiPhiHocTap'
    },
    {
        name: 'Học sinh bán trú',
        value: 'hocSinhBanTru'
    },
    {
        name: 'Học sinh nội trú',
        value: 'hocSinhNoiTru'
    },
    {
        name: 'Học sinh PTDT bán trú',
        value: 'hocSinhPtdtBanTru'
    },
    {
        name: 'Chuyển sang học nghề',
        value: 'chuyenSangHocNghe'
    },
    {
        name: 'Biết bơi kỳ 1',
        value: 'bietBoiKy1'
    },
    {
        name: 'Biết bơi kỳ 2',
        value: 'bietBoiKy2'
    },
    {
        name: 'Mẹ bị bệnh trong thời kỳ mang thai',
        value: 'meBiBenhTatTrongTkMangThai'
    },
    {
        name: 'Cận thị',
        value: 'canThi'
    },
    {
        name: 'Chỉ số BMI',
        value: 'chiSoBMI'
    }
]

export const list_status = [
    {
        label: 'Đang học',
        value: 1
    },
    {
        label: 'Chuyển khỏi trường',
        value: 3
    },
    {
        label: 'Bỏ học',
        value: 4
    }
]

export const list_dropout_time = [
    {
        label: 'Học kỳ hè - [6]',
        value: 6
    },
    {
        label: 'Học kỳ 1 - [1]',
        value: 1
    },
    {
        label: 'Học kỳ 2 - [2]',
        value: 2
    }
]

export const list_report_criteria_student = [
    { name: 'Đơn vị quản lý', nameCol: 'maDonVi', index: 0, status: true },
    { name: 'Cấp dạy', nameCol: 'capHoc', index: 1, status: true },
    { name: 'Trường học', nameCol: 'maTruongHoc', index: 2, status: true },
    // { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true },
    { name: 'Nhóm máu', nameCol: 'nhomMau', index: 4, status: true },
    { name: 'Khuyết tật', nameCol: 'khuyetTat', index: 5, status: true },
    { name: 'Diện chính sách', nameCol: 'maDtuongCsach', index: 6, status: true },
    {
        name: 'Thành phần gia đình',
        nameCol: 'tphanGdinh',
        index: 7,
        status: true
    },
    { name: 'Diện ưu tiên', nameCol: 'dtuongUuTien', index: 8, status: true },
    { name: 'Đội viên', nameCol: 'doiVien', index: 9, status: true },
    { name: 'Đoàn viên', nameCol: 'doanVien', index: 10, status: true },
    { name: 'Đảng viên', nameCol: 'dangVien', index: 11, status: true },
    {
        name: 'Loại khuyết tật',
        nameCol: 'maLoaiKhuyetTat',
        index: 12,
        status: true
    },
    { name: 'Khu vực', nameCol: 'maKhuVuc', index: 13, status: true },
    { name: 'Loại nhập học', nameCol: 'loaiNhapHoc', index: 14, status: true },
    { name: 'Học kỹ năng skss', nameCol: 'hocKnsSkss', index: 15, status: true },
    { name: 'Khối học', nameCol: 'khoiHoc', index: 16, status: true },
    { name: 'Trạng thái', nameCol: 'trangThai', index: 17, status: true },
    { name: 'Hỗ trợ học tập', nameCol: 'htHoTro', index: 18, status: true },
    {
        name: 'Thời điểm rời trường',
        nameCol: 'thoiDiemRoiTruong',
        index: 19,
        status: true
    },
    {
        name: 'Kiểm tra sức khỏe dinh dưỡng',
        nameCol: 'kiemTraSucKhoeDinhDuong',
        index: 20,
        status: true
    },
    {
        name: 'Tình trạng dinh dưỡng',
        nameCol: 'tinhTrangDinhDuong',
        index: 21,
        status: true
    },
    { name: 'Cha dân tộc', nameCol: 'chaDanToc', index: 22, status: true },
    { name: 'Mẹ dân tộc', nameCol: 'meDanToc', index: 23, status: true },
    { name: 'Lý do thôi học', nameCol: 'maLyDoThoiHoc', index: 24, status: true },
    {
        name: 'Học tiếng dân tộc',
        nameCol: 'hocSinhTiengDanToc',
        index: 25,
        status: true
    },
    { name: 'Học song ngữ', nameCol: 'hocSongNgu', index: 26, status: true },
    {
        name: 'Học bán trú dân nuôi',
        nameCol: 'hocSinhBanTruDanNuoi',
        index: 27,
        status: true
    },
    {
        name: 'Học nội trú dân nuôi',
        nameCol: 'hocSinhNoiTruDanNuoi',
        index: 28,
        status: true
    },
    {
        name: 'Khuyết tật không đánh giá',
        nameCol: 'khuyetTatKhongDanhGia',
        index: 29,
        status: true
    }
]

export const list_advanced_search_school = [
    {
        name: 'Loại hình đào tạo',
        value: 'loaiHinhDaoTao'
    },
    {
        name: 'Loại hình trường',
        value: 'loaiHinhTruong'
    },
    {
        name: 'Chuẩn quốc gia',
        value: 'chuanQuocGia'
    },
    {
        name: 'Vùng khó khăn',
        value: 'maVungKhoKhan'
    },

    {
        name: 'Chương trình giáo dục cơ bản',
        value: 'chuongTrinhGiaoDucCoBan'
    },
    {
        name: 'Môi trường phù hợp hs khuyết tật',
        value: 'coHaTangTlhtPhuHopHskt'
    },
    {
        name: 'Có lớp không chuyên',
        value: 'coLopKhongChuyen'
    },
    {
        name: 'Công trình vệ sinh',
        value: 'congTrinhVeSinh'
    },
    {
        name: 'Giáo dục vệ sinh đôi tay',
        value: 'ctGdvsDoiTay'
    },
    {
        name: 'Đạt chất lượng tối thiểu',
        value: 'datChatLuongToiThieu'
    },
    {
        name: 'Dạy nghề phổ thông',
        value: 'dayNghePhoThong'
    },
    {
        name: 'Điện lưới',
        value: 'dienLuoi'
    },
    {
        name: 'Học 2 buổi/ngày',
        value: 'hoc2BuoiNgay'
    },
    {
        name: 'Học sinh bán trú',
        value: 'hocSinhBanTru'
    },
    {
        name: 'Học sinh nội trú',
        value: 'hocSinhNoiTru'
    },
    {
        name: 'Học sinh khuyết tật',
        value: 'hocSinhKhuyetTat'
    },
    {
        name: 'Khai thác Internet dạy học',
        value: 'khaiThacInternetDayHoc'
    },
    {
        name: 'Kỹ năng sống giáo dục xã hội',
        value: 'kyNangSongGdxh'
    },
    {
        name: 'Trường quốc tế',
        value: 'truongQuocTe'
    },
    {
        name: 'Vùng đặc biệt khó khăn',
        value: 'vungDacBietKhoKhan'
    },
    {
        name: 'Trung tâm hướng nghiệp',
        value: 'ttGdtxHuongNghiep'
    },
    {
        name: 'Trung tâm ngoại ngữ có vốn nước ngoài',
        value: 'ttNnCoVonNuocNgoai'
    }
]

export const list_training_type = [
    {
        label: 'Công lập - [1]',
        value: 1
    },
    {
        label: 'Tư thục - [2]',
        value: 2
    },
    {
        label: 'GDTX - [3]',
        value: 3
    },
    {
        label: 'Ngoài công lập - [4]',
        value: 4
    },
    {
        label: 'Dân lập - [5]',
        value: 5
    }
]

export const list_report_criteria_school = [
    { name: 'Đơn vị quản lý', nameCol: 'maDonVi', index: 0, status: true },
    { name: 'Cấp dạy', nameCol: 'capHoc', index: 1, status: true },
    { name: 'Trường học', nameCol: 'maTruongHoc', index: 2, status: true },

    {
        name: 'Loại hình đào tạo',
        nameCol: 'loaiHinhDaoTao',
        index: 4,
        status: true
    },
    {
        name: 'Loại hình trường',
        nameCol: 'loaiHinhTruong',
        index: 5,
        status: true
    },
    { name: 'Chuẩn quốc gia', nameCol: 'chuanQuocGia', index: 6, status: true },
    { name: 'Vùng khó khăn', nameCol: 'maVungKhoKhan', index: 7, status: true },
    {
        name: 'Chương trình giáo dục cơ bản',
        nameCol: 'chuongTrinhGiaoDucCoBan',
        index: 8,
        status: true
    },
    {
        name: 'Môi trường phù hợp với học sinh khuyết tật',
        nameCol: 'coHaTangTlhtPhuHopHskt',
        index: 9,
        status: true
    },
    {
        name: 'Lớp không chuyên',
        nameCol: 'coLopKhongChuyen',
        index: 10,
        status: true
    },
    {
        name: 'Công tác tư vấn học đường',
        nameCol: 'congTacTuVanHocDuong',
        index: 11,
        status: true
    },
    {
        name: 'Công trình vệ sinh',
        nameCol: 'congTrinhVeSinh',
        index: 12,
        status: true
    },
    {
        name: 'Giáo dục vệ sinh đôi tay',
        nameCol: 'ctGdvsDoiTay',
        index: 13,
        status: true
    },
    {
        name: 'Đạt chất lượng tối thiểu',
        nameCol: 'datChatLuongToiThieu',
        index: 14,
        status: true
    },
    {
        name: 'Dạy nghề phổ thông',
        nameCol: 'dayNghePhoThong',
        index: 15,
        status: true
    },
    { name: 'Điện lưới', nameCol: 'dienLuoi', index: 16, status: true },
    { name: 'Học 2 buổi/ngày', nameCol: 'hoc2BuoiNgay', index: 17, status: true },
    {
        name: 'Học sinh bán trú',
        nameCol: 'hocSinhBanTru',
        index: 18,
        status: true
    },
    {
        name: 'Học sinh nội trú',
        nameCol: 'hocSinhNoiTru',
        index: 19,
        status: true
    },
    {
        name: 'Học sinh khuyết tật',
        nameCol: 'hocSinhKhuyetTat',
        index: 20,
        status: true
    },
    {
        name: 'Khai tác Internet dạy học',
        nameCol: 'khaiThacInternetDayHoc',
        index: 21,
        status: true
    },
    {
        name: 'Kỹ năng sống giáo dục xã hội',
        nameCol: 'kyNangSongGdxh',
        index: 22,
        status: true
    },
    {
        name: 'Nguồn nước sạch',
        nameCol: 'nguonNuocSach',
        index: 23,
        status: true
    },
    {
        name: 'Sử dụng máy tính dạy học',
        nameCol: 'suDungMayTinhDayHoc',
        index: 24,
        status: true
    },
    { name: 'Trường quốc tế', nameCol: 'truongQuocTe', index: 25, status: true },
    {
        name: 'Vùng đặc biệt khó khăn',
        nameCol: 'vungDacBietKhoKhan',
        index: 26,
        status: true
    },
    {
        name: 'Trung tâm hướng nghiệp',
        nameCol: 'ttGdtxHuongNghiep',
        index: 27,
        status: true
    },
    {
        name: 'Trung tâm giáo dục thường xuyên',
        nameCol: 'ttGdtxHuyen',
        index: 28,
        status: true
    },
    {
        name: 'Trung tâm ngoại ngữ có vốn nước ngoài',
        nameCol: 'ttNnCoVonNuocNgoai',
        index: 29,
        status: true
    }
]
