<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <tr v-if="active_tab === 1">
                <th rowspan="4">STT</th>
                <th rowspan="4">Đơn vị</th>
                <th colspan="36">NHÀ TRẺ</th>
                <th colspan="40">MẪU GIÁO</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="4">198</th>
                <th colspan="4">199</th>
                <th colspan="4">200</th>
                <th colspan="4">201</th>
                <th colspan="4">202</th>
                <th colspan="4">203</th>
                <th colspan="4">204</th>
                <th colspan="4">205</th>
                <th colspan="4">206</th>
                <th colspan="4">207</th>
                <th colspan="4">208</th>
                <th colspan="4">209</th>
                <th colspan="4">210</th>
                <th colspan="4">211</th>
                <th colspan="4">212</th>
                <th colspan="4">213</th>
                <th colspan="4">214</th>
                <th colspan="4">215</th>
                <th colspan="4">216</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th colspan="4">Phòng học (nuôi,dạy, chăm sóc trẻ)</th>
                <th colspan="4">Phòng học nhờ, mượn</th>
                <th colspan="4">Phòng phục vụ học tập</th>
                <th colspan="4">Phòng giáo dục thể chất</th>
                <th colspan="4">Phòng giáo dục nghệ thuật</th>
                <th colspan="4">Phòng đa chức năng</th>
                <th colspan="4">Phòng khác</th>
                <th colspan="4">Nhà bếp</th>
                <th colspan="4">Phòng y tế</th>
                <th colspan="4">Phòng học</th>
                <th colspan="4">Phòng học nhờ, mượn</th>
                <th colspan="4">Phòng phục vụ học tập</th>
                <th colspan="4">Phòng giáo dục thể chất</th>
                <th colspan="4">Phòng giáo dục nghệ thuật</th>
                <th colspan="4">Phòng đa chức năng</th>
                <th colspan="4">Phòng hỗ trợ giáo dục hòa nhập</th>
                <th colspan="4">Phòng khác</th>
                <th colspan="4">Nhà bếp</th>
                <th colspan="4">Phòng y tế</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th
                    v-for="(header, key) in list_th_1"
                    :key="key"
                    :rowspan="header.rowspan"
                    class="min-w-100"
                >
                    {{ header.label }}
                </th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-150">Đơn vị</th>
                <th colspan="32">NHÀ TRẺ</th>
                <th colspan="32">MẪU GIÁO</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th colspan="16">Phòng học (nuôi,dạy, chăm sóc trẻ)</th>
                <th colspan="16">Phòng học nhờ, mượn</th>
                <th colspan="16">Phòng học</th>
                <th colspan="16">Phòng học nhờ, mượn</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Dân lập</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Dân lập</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Dân lập</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Công lập</th>
                <th colspan="4">Tư thục</th>
                <th colspan="4">Dân lập</th>
            </tr>

            <tr v-if="active_tab === 2">
                <th
                    v-for="(header, key) in list_th_2"
                    :key="key"
                    :rowspan="header.rowspan"
                    class="min-w-100"
                >
                    {{ header.label }}
                </th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_1" :key="td.index">
                        {{ item[3][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_2" :key="td.index">
                        {{ item[3][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'
export default {
    name: 'ReportCSVC',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'CSVC' },
                { number: 2, label: 'Phòng học' }
            ],

            list_th_2: [
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 }
            ],
            list_td_2: [
                'E5',
                'F5',
                'G5',
                'H5',
                'I5',
                'J5',
                'K5',
                'L5',
                'M5',
                'N5',
                'O5',
                'P5',
                'Q5',
                'R5',
                'S5',
                'T5',
                'E6',
                'F6',
                'G6',
                'H6',
                'I6',
                'J6',
                'K6',
                'L6',
                'M6',
                'N6',
                'O6',
                'P6',
                'Q6',
                'R6',
                'S6',
                'T6',
                'E16',
                'F16',
                'G16',
                'H16',
                'I16',
                'J16',
                'K16',
                'L16',
                'M16',
                'N16',
                'O16',
                'P16',
                'Q16',
                'R16',
                'S16',
                'T16',
                'E17',
                'F17',
                'G17',
                'H17',
                'I17',
                'J17',
                'K17',
                'L17',
                'M17',
                'N17',
                'O17',
                'P17',
                'Q17',
                'R17',
                'S17',
                'T17'
            ],

            list_th_1: [
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 },
                { label: 'Tổng số', rowspan: 1 },
                { label: 'Kiên cố', rowspan: 1 },
                { label: 'Bán kiên cố', rowspan: 1 },
                { label: 'Tạm', rowspan: 1 }
            ],
            list_td_1: [
                'E5',
                'F5',
                'G5',
                'H5',
                'E6',
                'F6',
                'G6',
                'H6',
                'E7',
                'F7',
                'G7',
                'H7',
                'E9',
                'F9',
                'G9',
                'H9',
                'E10',
                'F10',
                'G10',
                'H10',
                'E11',
                'F11',
                'G11',
                'H11',
                'E12',
                'F12',
                'G12',
                'H12',
                'E13',
                'F13',
                'G13',
                'H13',
                'E14',
                'F14',
                'G14',
                'H14',
                'E16',
                'F16',
                'G16',
                'H16',
                'E17',
                'F17',
                'G17',
                'H17',
                'E18',
                'F18',
                'G18',
                'H18',
                'E20',
                'F20',
                'G20',
                'H20',
                'E21',
                'F21',
                'G21',
                'H21',
                'E22',
                'F22',
                'G22',
                'H22',
                'E23',
                'F23',
                'G23',
                'H23',
                'E24',
                'F24',
                'G24',
                'H24',
                'E25',
                'F25',
                'G25',
                'H25',
                'E26',
                'F26',
                'G26',
                'H26'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-150 {
    min-width: 150px;
}
.min-w-100 {
    min-width: 100px;
}
</style>
