<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. Cán bộ quản lý -->
            <tr v-if="active_tab === 1">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-200">Đơn vị</th>
                <th rowspan="4" class="min-w-200">Cán bộ quản lý</th>
                <th colspan="12">Hiệu trưởng</th>
                <th colspan="12">Phó hiệu trưởng</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="3" class="min-w-200">Tổng Hiệu trưởng</th>
                <th colspan="5">Chia theo trình độ đào tạo</th>
                <th colspan="3">Hình thức hợp đồng</th>
                <th colspan="3">Trong tổng số</th>
                <th rowspan="3" class="min-w-200">Tổng phó hiệu trưởng</th>
                <th colspan="5">Chia theo trình độ đào tạo</th>
                <th colspan="3">Hình thức hợp đồng</th>
                <th colspan="3">Trong tổng số</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2" class="min-w-200">Tiến sĩ, TSKH</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học sư phạm</th>
                <th rowspan="2" class="min-w-200">
                    Đại học và có chứng chỉ BDNVSP
                </th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th colspan="3">Viên chức</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
                <th rowspan="2" class="min-w-200">Tiến sĩ, TSKH</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học sư phạm</th>
                <th rowspan="2" class="min-w-200">
                    Đại học và có chứng chỉ BDNVSP
                </th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
                <th rowspan="1" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 2. Giáo viên -->
            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th rowspan="3">Tổng số giáo viên</th>
                <th colspan="5">Chia theo trình độ đào tạo</th>
                <th colspan="3">Hình thức hợp đồng</th>
                <th colspan="3">Trong tổng số</th>
                <th colspan="7">Chia theo độ tuổi</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2">Tiến sĩ, TSKH</th>
                <th rowspan="2">Thạc sĩ</th>
                <th rowspan="2">Đại học sư phạm</th>
                <th rowspan="2">Đại học và có chứng chỉ BDNVSP</th>
                <th rowspan="2">Khác</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ dân tộc</th>
                <th rowspan="2">Tổng giáo viên chia theo độ tuổi</th>
                <th rowspan="2">Dưới 29 Tuổi</th>
                <th rowspan="2">Từ 30-39 Tuổi</th>
                <th rowspan="2">Từ 40-49 Tuổi</th>
                <th rowspan="2">Từ 50-54 Tuổi</th>
                <th rowspan="2">Từ 55-59 Tuổi</th>
                <th rowspan="2">Từ 60 tuổi</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 3. Nhân viên -->
            <tr v-if="active_tab === 3">
                <th rowspan="3">STT</th>
                <th rowspan="3">Đơn vị</th>
                <th colspan="4">Tổng số</th>
                <th colspan="10">Chia ra</th>
                <th colspan="3">Hình thức hợp đồng</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="2">Tổng số</th>
                <th rowspan="2">Nữ</th>
                <th rowspan="2">Dân tộc</th>
                <th rowspan="2">Nữ DT</th>
                <th rowspan="2">Thư viện</th>
                <th rowspan="2">Thiết bị, thí nghiệm</th>
                <th rowspan="2">Công nghệ thông tin</th>
                <th rowspan="2">Kế toán</th>
                <th rowspan="2">Thủ quỹ</th>
                <th rowspan="2">Văn thư</th>
                <th rowspan="2">Y tế</th>
                <th rowspan="2">Hỗ trợ GD Khuyết tật</th>
                <th rowspan="2">Giáo vụ</th>
                <th rowspan="2">Kiêm nhiều việc</th>
                <th colspan="2">Viên chức</th>
                <th rowspan="2">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1">HĐLV không xác định thời hạn</th>
                <th rowspan="1">HĐLV xác định thời hạn</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportTeachers',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'Cán bộ quản lý' },
                { number: 2, label: 'Giáo viên' },
                { number: 3, label: 'Nhân viên' }
            ],
            list_td_active_tab_1: [
                'E7',
                'E8',
                'E13',
                'E12',
                'E10',
                'E11',
                'E14',
                'J8',
                'K8',
                'L8',
                'F8',
                'G8',
                'H8',
                'E15',
                'E23',
                'E22',
                'E20',
                'E21',
                'E24',
                'J15',
                'K15',
                'L15',
                'F15',
                'G15',
                'H15'
            ],
            list_td_active_tab_2: [
                'E25',
                'E30',
                'E29',
                'E27',
                'E28',
                'E31',
                'J25',
                'K25',
                'L25',
                'F25',
                'G25',
                'H25',
                '',
                'E33',
                'E34',
                'E35',
                'E36',
                'E37',
                'E38'
            ],
            list_td_active_tab_3: [
                'E39',
                'F39',
                'G39',
                'H39',
                'E41',
                'E42',
                'E43',
                'E44',
                'E45',
                'E46',
                'E47',
                'E48',
                'E49',
                'E50',
                'J39',
                'K39',
                'L39'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>
<style scoped>
.min-w-200 {
    min-width: 200px;
}
</style>
