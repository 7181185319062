<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-300">Đơn vị</th>
                <th rowspan="4">Sĩ số</th>
                <th colspan="19">Thông tư 58</th>
                <th colspan="17">Thông tư 22</th>
            </tr>
            <tr>
                <th rowspan="3" style="min-width: 100px">Tổng số HS ĐG</th>
                <th colspan="10">Học lực</th>
                <th colspan="8">Hạnh kiểm</th>
                <th rowspan="3" style="min-width: 100px">Tổng số HS ĐG</th>
                <th colspan="8">Kết quả học tập</th>
                <th colspan="8">Kết quả rèn luyện</th>
            </tr>
            <tr>
                <th colspan="2">Giỏi</th>
                <th colspan="2">Khá</th>
                <th colspan="2">Trung bình</th>
                <th colspan="2">Yếu</th>
                <th colspan="2">Kém</th>
                <th colspan="2">Tốt</th>
                <th colspan="2">Khá</th>
                <th colspan="2">Trung bình</th>
                <th colspan="2">Yếu</th>
                <th colspan="2">Tốt</th>
                <th colspan="2">Khá</th>
                <th colspan="2">Đạt</th>
                <th colspan="2">Chưa đạt</th>
                <th colspan="2">Tốt</th>
                <th colspan="2">Khá</th>
                <th colspan="2">Đạt</th>
                <th colspan="2">Chưa đạt</th>
            </tr>
            <tr>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
                <th rowspan="1" class="min-width">SL</th>
                <th rowspan="1" class="min-width">TL (%)</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'GraduationStatusOfstudents',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-width {
    min-width: 80px;
}
.min-w-300 {
    min-width: 300px;
}
</style>
