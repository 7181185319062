var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
            this.isDetailedReport([
                'A.3.1',
                'B.3.1',
                'C.3.1',
                'D.3.1',
                'E.3.1'
            ])
        )?_c('StatisticsOfTeachingStaff',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.2',
                'B.3.2',
                'C.3.2',
                'D.3.2',
                'E.3.2'
            ])
        )?_c('TeachersInTheTrainingFieldSubjects',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.3',
                'B.3.3',
                'C.3.3',
                'D.3.3',
                'E.3.3'
            ])
        )?_c('GenderStaffStatistics',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.4',
                'B.3.4',
                'C.3.4',
                'D.3.4',
                'E.3.4'
            ])
        )?_c('EmployeeCountStatistics',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.5',
                'B.3.5',
                'C.3.5',
                'D.3.5',
                'E.3.5'
            ])
        )?_c('StaffStatisticsByEthnicity',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.6',
                'B.3.6',
                'C.3.6',
                'D.3.6',
                'E.3.6'
            ])
        )?_c('StaffStatisticsByPartyMembership',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.7',
                'B.3.7',
                'C.3.7',
                'D.3.7',
                'E.3.7'
            ])
        )?_c('StaffStatisticsByComputerLiteracyLevel',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.8',
                'B.3.8',
                'C.3.8',
                'D.3.8',
                'E.3.8'
            ])
        )?_c('TeachersProfessionalExpertise',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.9',
                'B.3.9',
                'C.3.9',
                'D.3.9',
                'E.3.9'
            ])
        )?_c('StaffStatsByJobTitleQualification',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form,"statistical":_vm.statistical}}):_vm._e(),(
            this.isDetailedReport([
                'A.3.10',
                'B.3.10',
                'C.3.10',
                'D.3.10',
                'E.3.10'
            ])
        )?_c('TeacherSurplusShortageStatisticsByAllocation',{attrs:{"tableData":_vm.tableData,"start":_vm.start,"limit":_vm.limit,"column_form":_vm.column_form}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }