<template>
    <CustomTable
        :column-form="column_form"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="2">STT</th>
                <th rowspan="2">Đơn vị</th>
                <th rowspan="2">Tổng số học sinh</th>
                <th colspan="2">Nhà trẻ</th>
                <th colspan="2">Mẫu giáo</th>
            </tr>
            <tr v-if="statistical === 4 || statistical === 5">
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Số học sinh đã nhập CCCD</th>
                <th rowspan="1">Tổng số</th>
                <th rowspan="1">Số học sinh đã nhập CCCD</th>
            </tr>

            <tr v-if="statistical === 1">
                <th rowspan="2">STT</th>
                <th rowspan="2" class="min-w-300">Đơn vị</th>
                <th rowspan="2" class="min-w-150">Tổng số học sinh</th>
                <th colspan="2">Khối 1</th>
                <th colspan="2">Khối 2</th>
                <th colspan="2">Khối 3</th>
                <th colspan="2">Khối 4</th>
                <th colspan="2">Khối 5</th>
            </tr>
            <tr v-if="statistical === 1">
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
            </tr>

            <tr v-if="statistical === 2">
                <th rowspan="2">STT</th>
                <th rowspan="2" class="min-w-300">Đơn vị</th>
                <th rowspan="2" class="min-w-150">Tổng số học sinh</th>
                <th colspan="2">Khối 6</th>
                <th colspan="2">Khối 7</th>
                <th colspan="2">Khối 8</th>
                <th colspan="2">Khối 9</th>
            </tr>
            <tr v-if="statistical === 2">
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
            </tr>

            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="2">STT</th>
                <th rowspan="2" class="min-w-300">Đơn vị</th>
                <th rowspan="2" class="min-w-150">Tổng số học sinh</th>
                <th colspan="2">Khối 10</th>
                <th colspan="2">Khối 11</th>
                <th colspan="2">Khối 12</th>
            </tr>
            <tr v-if="statistical === 3 || statistical === 6">
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-150">Số học sinh đã nhập CCCD</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="21">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-if="statistical === 4 || statistical === 5">
                <td>1</td>
                <td>12345678910JQK</td>
                <td>55</td>
                <td>55</td>
                <td>55</td>
                <td>1/1/5</td>
                <td>55</td>
            </tr>
            <template v-if="statistical == 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="min-w-300 text-left">{{ item.tenDonVi }}</td>

                    <td class="min-w-150">{{ item.tongHocSinh }}</td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[0]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[0]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[1]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[1]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[2]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[2]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[3]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[3]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[4]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[4]?.soNopCmnd }}
                    </td>
                </tr>
            </template>
            <template v-if="statistical == 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="min-w-300 text-left">{{ item.tenDonVi }}</td>

                    <td class="min-w-150">{{ item.tongHocSinh }}</td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[0]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[0]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[1]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[1]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[2]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[2]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[3]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[3]?.soNopCmnd }}
                    </td>
                </tr>
            </template>
            <template v-if="statistical == 3 || statistical == 6">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="min-w-300 text-left">{{ item.tenDonVi }}</td>

                    <td class="min-w-150">{{ item.tongHocSinh }}</td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[0]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[0]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[1]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[1]?.soNopCmnd }}
                    </td>

                    <td class="min-w-100">
                        {{ item?.khoiLopList[2]?.soHocSinhKhoi }}
                    </td>

                    <td class="min-w-150">
                        {{ item.khoiLopList[2]?.soNopCmnd }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StudentEnrollmentStatisticsByCitizenID',
    components: {
        CustomTable
    },
    props: {
        statistical: {
            type: Number,
            require: true
        },
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
