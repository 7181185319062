<template>
    <LayoutCard>
        <div class="row">
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title> Phổ điểm học kỳ I </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_line_chart_semester_1_grade_distribution
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <LineChart
                            :data_LineChart="
                                line_chart_semester_1_grade_distribution
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title> Phổ điểm học kỳ II </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_line_chart_semester_2_grade_distribution
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <LineChart
                            :data_LineChart="
                                line_chart_semester_2_grade_distribution
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title>
                        ĐÁNH GIÁ HỌC SINH HỌC KỲ I (THCS,THPT) TT58
                    </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_semester_1_student_assessment
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                column_chart_semester_1_student_assessment
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_semester_student_assessment
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title>
                        ĐÁNH GIÁ HỌC SINH HỌC KỲ II (THCS,THPT) TT58
                    </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_semester_2_student_assessment
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                column_chart_semester_2_student_assessment
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_semester_student_assessment
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title>
                        ĐÁNH GIÁ HỌC SINH HỌC KỲ CẢ NĂM (THCS,THPT) TT58
                    </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_semester_all_student_assessment
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                column_chart_semester_all_student_assessment
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_semester_student_assessment
                            "
                        />
                    </template>
                </Card>
            </div>
        </div>
    </LayoutCard>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import LayoutCard from '@/components/CardDashboard/LayoutCard'
import Card from '@/components/CardDashboard/Card'
import 'element-ui/lib/theme-chalk/index.css'
import { hideLoading, showLoading } from '@/utils/loading'
import LineChart from '@/components/LineChart.vue'
import StackedColumnChart from '@/components/StackedColumnChart.vue'
export default {
    name: 'ChartGradeScale',
    components: {
        CustomButton,
        LayoutCard,
        Card,
        LineChart,
        StackedColumnChart
    },
    data() {
        return {
            xaxisCategories: {
                column_chart_semester_student_assessment: [
                    'Giỏi',
                    'Khá',
                    'TB',
                    'Yếu',
                    'Kém',
                    'Khác'
                ]
            }
        }
    },
    props: {
        line_chart_semester_1_grade_distribution: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_line_chart_semester_1_grade_distribution: Function,
        line_chart_semester_2_grade_distribution: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_line_chart_semester_2_grade_distribution: Function,
        column_chart_semester_1_student_assessment: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_semester_1_student_assessment: Function,
        column_chart_semester_2_student_assessment: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_semester_2_student_assessment: Function,
        column_chart_semester_all_student_assessment: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_semester_all_student_assessment: Function
    },

    methods: {
        handleRefresh_line_chart_semester_1_grade_distribution() {
            showLoading()
            this.$emit('refresh_line_chart_semester_1_grade_distribution')
            hideLoading()
        },
        handleRefresh_line_chart_semester_2_grade_distribution() {
            showLoading()
            this.$emit('refresh_line_chart_semester_2_grade_distribution')
            hideLoading()
        },
        handleRefresh_column_chart_semester_1_student_assessment() {
            showLoading()
            this.$emit('refresh_column_chart_semester_1_student_assessment')
            hideLoading()
        },
        handleRefresh_column_chart_semester_2_student_assessment() {
            showLoading()
            this.$emit('refresh_column_chart_semester_2_student_assessment')
            hideLoading()
        },
        handleRefresh_column_chart_semester_all_student_assessment() {
            showLoading()
            this.$emit('refresh_column_chart_semester_all_student_assessment')
            hideLoading()
        }
    }
}
</script>

<style scoped></style>
