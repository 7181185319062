<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hideBtnTable="true"
        :hide-pagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="5">STT</th>
                <th rowspan="5" class="min-w-150">Đơn vị</th>
                <th colspan="3">Chi thường xuyên</th>
                <th colspan="3">Giáo dục Tiểu học</th>
                <th colspan="9">Chi theo nguồn</th>
                <th colspan="12">Chi theo nhóm chi</th>
                <th colspan="3">Đào tạo khác trong nước</th>
                <th colspan="9">Chi theo nguồn</th>
                <th colspan="12">Chi theo nhóm chi</th>
                <th colspan="3">
                    Đào tạo lại, bồi dưỡng nghiệp vụ cho cán bộ, công chức, viên
                    chức.
                </th>
                <th colspan="9">Chi theo nguồn</th>
                <th colspan="12">Chi theo nhóm chi</th>
            </tr>
            <tr>
                <th colspan="3">01</th>
                <th colspan="3">02</th>
                <th colspan="3">03</th>
                <th colspan="3">04</th>
                <th colspan="3">05</th>
                <th colspan="3">06</th>
                <th colspan="3">07</th>
                <th colspan="3">08</th>
                <th colspan="3">09</th>
                <th colspan="3">10</th>
                <th colspan="3">11</th>
                <th colspan="3">12</th>
                <th colspan="3">13</th>
                <th colspan="3">14</th>
                <th colspan="3">15</th>
                <th colspan="3">16</th>
                <th colspan="3">17</th>
                <th colspan="3">18</th>
                <th colspan="3">19</th>
                <th colspan="3">20</th>
                <th colspan="3">21</th>
                <th colspan="3">22</th>
                <th colspan="3">23</th>
                <th colspan="3">24</th>
                <th colspan="3">25</th>
            </tr>
            <tr>
                <th rowspan="3" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="3" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th colspan="3">Nguồn NSNN</th>
                <th colspan="3">Nguồn phí, lệ phí để lại</th>
                <th colspan="3">Nguồn khác</th>
                <th colspan="3">Chi thanh toán cá nhân</th>
                <th colspan="3">Chi hàng hóa dịch vụ</th>
                <th colspan="3">Chi hỗ trợ và bổ sung</th>
                <th colspan="3">Các khoản chi khác</th>
                <th rowspan="3" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th colspan="3">Nguồn NSNN</th>
                <th colspan="3">Nguồn phí, lệ phí để lại</th>
                <th colspan="3">Nguồn khác</th>
                <th colspan="3">Chi thanh toán cá nhân</th>
                <th colspan="3">Chi hàng hóa dịch vụ</th>
                <th colspan="3">Chi hỗ trợ và bổ sung</th>
                <th colspan="3">Các khoản chi khác</th>
                <th rowspan="3" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th colspan="3">Nguồn NSNN</th>
                <th colspan="3">Nguồn phí, lệ phí để lại</th>
                <th colspan="3">Nguồn khác</th>
                <th colspan="3">Chi thanh toán cá nhân</th>
                <th colspan="3">Chi hàng hóa dịch vụ</th>
                <th colspan="3">Chi hỗ trợ và bổ sung</th>
                <th colspan="3">Các khoản chi khác</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-150">Dự toán</th>
                <th rowspan="2" class="min-w-150">Ước thực hiện</th>
                <th rowspan="2" class="min-w-150">Dự toán</th>
                <th rowspan="2" class="min-w-150">Ước thực hiện</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Dự toán</th>
                <th rowspan="2" class="min-w-150">Ước thực hiện</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Dự toán</th>
                <th rowspan="2" class="min-w-150">Ước thực hiện</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
                <th rowspan="2" class="min-w-150">Quyết toán chi NSNN</th>
                <th colspan="2">Chi NSNN</th>
            </tr>
            <tr>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
                <th rowspan="1" class="min-w-150">Dự toán</th>
                <th rowspan="1" class="min-w-150">Ước thực hiện</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>

            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left min-width">
                    {{ item['H3'] }}
                </td>

                <td v-for="td in list_td" :key="td.index">
                    {{ item[4][td] }}
                </td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportStateBudget',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            data_header_1: [
                { label: 'STT', rowspan: 5, colspan: 1 },
                { label: 'Đơn vị', rowspan: 5, colspan: 1 },
                { label: 'Chi thường xuyên', rowspan: 1, colspan: 3 },
                { label: 'Giáo dục Mầm non', rowspan: 1, colspan: 3 },
                { label: 'Chi theo nguồn', rowspan: 1, colspan: 9 },
                { label: 'Chi theo nhóm chi', rowspan: 1, colspan: 12 },
                { label: 'Đào tạo khác trong nước', rowspan: 1, colspan: 3 },
                { label: 'Chi theo nguồn', rowspan: 1, colspan: 9 },
                { label: 'Chi theo nhóm chi', rowspan: 1, colspan: 12 },
                {
                    label: 'Đào tạo lại, bồi dưỡng nghiệp vụ cho cán bộ, công chức, viên chức.',
                    rowspan: 1,
                    colspan: 3
                },
                { label: 'Chi theo nguồn', rowspan: 1, colspan: 9 },
                { label: 'Chi theo nhóm chi', rowspan: 1, colspan: 12 }
            ],
            list_td: [
                'F4',
                'G4',
                'H4',
                'F5',
                'G5',
                'H5',
                'F7',
                'G7',
                'H7',
                'F8',
                'G8',
                'H8',
                'F9',
                'G9',
                'H9',
                'F11',
                'G11',
                'H11',
                'F12',
                'G12',
                'H12',
                'F13',
                'G13',
                'H13',
                'F14',
                'G14',
                'H14',
                'F15',
                'G15',
                'H15',
                'F17',
                'G17',
                'H17',
                'F18',
                'G18',
                'H18',
                'F19',
                'G19',
                'H19',
                'F21',
                'G21',
                'H21',
                'F22',
                'G22',
                'H22',
                'F23',
                'G23',
                'H23',
                'F24',
                'G24',
                'H24',
                'F25',
                'G25',
                'H25',
                'F27',
                'G27',
                'H27',
                'F28',
                'G28',
                'H28',
                'F29',
                'G29',
                'H29',
                'F31',
                'G31',
                'H31',
                'F32',
                'G32',
                'H32',
                'F33',
                'G33',
                'H33',
                'F34',
                'G34',
                'H34'
            ]
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-150 {
    min-width: 150px;
}
</style>
