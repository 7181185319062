<template>
    <LayoutCard>
        <div class="row">
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Thống kê lớp học </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_class_statistics_by_phase
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                column_chart_class_statistics_by_phase
                            "
                            :xaxis_categories="xaxisCategories"
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Thống kê giáo viên </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_teacher_statistics_by_phase
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                column_chart_teacher_statistics_by_phase
                            "
                            :xaxis_categories="xaxisCategories"
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Thống kê học sinh </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_student_statistics_by_phase
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                column_chart_student_statistics_by_phase
                            "
                            :xaxis_categories="xaxisCategories"
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title> Học sinh học lực </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_labeled_line_chart_educational_achievement
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <LabeledLineChart
                            :series="labeled_line_chart_educational_achievement"
                            :categories="xaxisCategories"
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title> Trình độ giáo viên </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_labeled_line_chart_teacher_qualification
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <LabeledLineChart
                            :series="labeled_line_chart_teacher_qualification"
                            :categories="xaxisCategories"
                        />
                    </template>
                </Card>
            </div>
        </div>
    </LayoutCard>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import LayoutCard from '@/components/CardDashboard/LayoutCard'
import Card from '@/components/CardDashboard/Card'
import 'element-ui/lib/theme-chalk/index.css'
import { hideLoading, showLoading } from '@/utils/loading'
import StackedColumnChart from '@/components/StackedColumnChart.vue'
import LabeledLineChart from '@/components/LabeledLineChart.vue'
export default {
    name: 'ChartStatisticsByPhase',
    components: {
        CustomButton,
        LayoutCard,
        Card,
        StackedColumnChart,
        LabeledLineChart
    },
    data() {
        return {
            xaxisCategories: ['2019', '2020', '2021', '2022', '2023']
        }
    },
    props: {
        column_chart_class_statistics_by_phase: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_class_statistics_by_phase: Function,
        column_chart_teacher_statistics_by_phase: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_teacher_statistics_by_phase: Function,
        column_chart_student_statistics_by_phase: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_student_statistics_by_phase: Function,
        labeled_line_chart_educational_achievement: {
            type: Array,
            required: true
        },
        refresh_labeled_line_chart_educational_achievement: Function,
        labeled_line_chart_teacher_qualification: {
            type: Array,
            required: true
        },
        refresh_labeled_line_chart_teacher_qualification: Function
    },

    methods: {
        handleRefresh_column_chart_class_statistics_by_phase() {
            showLoading()
            this.$emit('refresh_column_chart_class_statistics_by_phase')
            hideLoading()
        },
        handleRefresh_column_chart_teacher_statistics_by_phase() {
            showLoading()
            this.$emit('refresh_column_chart_teacher_statistics_by_phase')
            hideLoading()
        },
        handleRefresh_column_chart_student_statistics_by_phase() {
            showLoading()
            this.$emit('refresh_column_chart_student_statistics_by_phase')
            hideLoading()
        },
        handleRefresh_labeled_line_chart_educational_achievement() {
            showLoading()
            this.$emit('refresh_labeled_line_chart_educational_achievement')
            hideLoading()
        },
        handleRefresh_labeled_line_chart_teacher_qualification() {
            showLoading()
            this.$emit('refresh_labeled_line_chart_teacher_qualification')
            hideLoading()
        }
    }
}
</script>

<style scoped></style>
