<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. CBQL mẫu giáo -->
            <tr v-if="active_tab === 1">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-200">Đơn vị</th>
                <th rowspan="4" class="min-w-200">Cán bộ quản lý mẫu giáo</th>
                <th colspan="13" class="min-w-200">Hiệu trưởng mẫu giáo</th>
                <th colspan="13" class="min-w-200">Phó hiệu trưởng mẫu giáo</th>
            </tr>
            <tr v-if="active_tab === 1" class="min-w-200">
                <th rowspan="3" class="min-w-200">Tổng số hiệu trường</th>
                <th colspan="6" class="min-w-200">Chia theo trình độ đào tạo</th>
                <th colspan="3" class="min-w-200">Phân loại</th>
                <th colspan="3" class="min-w-200">Trong tổng số</th>
                <th rowspan="3" class="min-w-200">Tổng số phó hiệu trường</th>
                <th colspan="6" class="min-w-200">Chia theo trình độ đào tạo</th>
                <th colspan="3" class="min-w-200">Phân loại</th>
                <th colspan="3" class="min-w-200">Trong tổng số</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2" class="min-w-200">Tiến sĩ, TSKH</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học sư phạm</th>
                <th rowspan="2" class="min-w-200">Cao đẳng sư phạm</th>
                <th rowspan="2" class="min-w-200">Trung cấp sư phạm</th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th colspan="2" class="min-w-200">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
                <th rowspan="2" class="min-w-200">Tiến sĩ, TSKH</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học sư phạm</th>
                <th rowspan="2" class="min-w-200">Cao đẳng sư phạm</th>
                <th rowspan="2" class="min-w-200">Trung cấp sư phạm</th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th colspan="2" class="min-w-200">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 2. CBQL nhà trẻ -->
            <tr v-if="active_tab === 2">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-200">Đơn vị</th>
                <th rowspan="4" class="min-w-200">Cán bộ quản lý nhà trẻ</th>
                <th colspan="13" class="min-w-200">Hiệu trưởng nhà trẻ</th>
                <th colspan="13" class="min-w-200">Phó hiệu trưởng nhà trẻ</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="3" class="min-w-200">Tổng số hiệu trường</th>
                <th colspan="6" class="min-w-200">Chia theo trình độ đào tạo</th>
                <th colspan="3" class="min-w-200">Phân loại</th>
                <th colspan="3" class="min-w-200">Trong tổng số</th>
                <th rowspan="3" class="min-w-200">Tổng số phó hiệu trường</th>
                <th colspan="6" class="min-w-200">Chia theo trình độ đào tạo</th>
                <th colspan="3" class="min-w-200">Phân loại</th>
                <th colspan="3" class="min-w-200">Trong tổng số</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2" class="min-w-200">Tiến sĩ, TSKH</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học sư phạm</th>
                <th rowspan="2" class="min-w-200">Cao đẳng sư phạm</th>
                <th rowspan="2" class="min-w-200">Trung cấp sư phạm</th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th colspan="2" class="min-w-200">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
                <th rowspan="2" class="min-w-200">Tiến sĩ, TSKH</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học sư phạm</th>
                <th rowspan="2" class="min-w-200">Cao đẳng sư phạm</th>
                <th rowspan="2" class="min-w-200">Trung cấp sư phạm</th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th colspan="2" class="min-w-200">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 3. Giáo viên -->
            <tr v-if="active_tab === 3">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-200">Đơn vị</th>
                <th rowspan="4" class="min-w-200">Tổng số giáo viên</th>
                <th colspan="13" class="min-w-200">Giáo viên nhà trẻ</th>
                <th colspan="13" class="min-w-200">
                    Giáo viên mẫu giáo, mầm non
                </th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="3" class="min-w-200">Tổng giáo viên nhà trẻ</th>
                <th colspan="6" class="min-w-200">Chia theo trình độ đào tạo</th>
                <th colspan="3" class="min-w-200">Phân loại</th>
                <th colspan="3" class="min-w-200">Trong tổng số</th>
                <th rowspan="3" class="min-w-200">Tổng số giáo viên mầm non</th>
                <th colspan="6" class="min-w-200">Chia theo trình độ đào tạo</th>
                <th colspan="3" class="min-w-200">Phân loại</th>
                <th colspan="3" class="min-w-200">Trong tổng số</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="2" class="min-w-200">Tiến sĩ, TSKH</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học sư phạm</th>
                <th rowspan="2" class="min-w-200">Cao đẳng sư phạm</th>
                <th rowspan="2" class="min-w-200">Trung cấp sư phạm</th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th colspan="2" class="min-w-200">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
                <th rowspan="2" class="min-w-200">Tiến sĩ, TSKH</th>
                <th rowspan="2" class="min-w-200">Thạc sĩ</th>
                <th rowspan="2" class="min-w-200">Đại học sư phạm</th>
                <th rowspan="2" class="min-w-200">Cao đẳng sư phạm</th>
                <th rowspan="2" class="min-w-200">Trung cấp sư phạm</th>
                <th rowspan="2" class="min-w-200">Khác</th>
                <th colspan="2" class="min-w-200">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
            </tr>

            <!-- 4.Độ tuổi Giáo viên -->
            <tr v-if="active_tab === 4">
                <th rowspan="2">STT</th>
                <th rowspan="2">Đơn vị</th>
                <th colspan="7">Giáo viên nhà trẻ</th>
                <th colspan="7">Giáo viên mẫu giáo, mầm non</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="1">Tổng giáo viên chia theo độ tuổi</th>
                <th rowspan="1">Từ 20 - 29 Tuổi</th>
                <th rowspan="1">Từ 30-39 Tuổi</th>
                <th rowspan="1">Từ 40-49 Tuổi</th>
                <th rowspan="1">Từ 50-54 Tuổi</th>
                <th rowspan="1">Từ 55-59 Tuổi</th>
                <th rowspan="1">Từ 60 tuổi</th>
                <th rowspan="1">Tổng giáo viên chia theo độ tuổi</th>
                <th rowspan="1">Từ 20 - 29 Tuổi</th>
                <th rowspan="1">Từ 30-39 Tuổi</th>
                <th rowspan="1">Từ 40-49 Tuổi</th>
                <th rowspan="1">Từ 50-54 Tuổi</th>
                <th rowspan="1">Từ 55-59 Tuổi</th>
                <th rowspan="1">Từ 60 tuổi</th>
            </tr>

            <!-- 5.Nhân viên -->
            <tr v-if="active_tab === 5">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-200">Đơn vị</th>
                <th rowspan="4" class="min-w-200">Tổng số nhân viên</th>
                <th colspan="11" class="min-w-200">Nhân viên nhà trẻ</th>
                <th colspan="11" class="min-w-200">
                    Nhân viên mẫu giáo, mầm non
                </th>
            </tr>
            <tr v-if="active_tab === 5">
                <th colspan="4" class="min-w-200">Trong tổng số</th>
                <th colspan="4" class="min-w-200">Chia ra</th>
                <th colspan="3" class="min-w-200">Phân loại</th>
                <th colspan="4" class="min-w-200">Trong tổng số</th>
                <th colspan="4" class="min-w-200">Chia ra</th>
                <th colspan="3" class="min-w-200">Phân loại</th>
            </tr>
            <tr v-if="active_tab === 5">
                <th rowspan="2" class="min-w-200">Tổng số nhân viên nhà trẻ</th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
                <th rowspan="2" class="min-w-200">Kế toán, văn thư</th>
                <th rowspan="2" class="min-w-200">Y tế, thủ quỹ</th>
                <th rowspan="2" class="min-w-200">Nấu ăn</th>
                <th rowspan="2" class="min-w-200">Bảo vệ</th>
                <th colspan="2" class="min-w-200">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
                <th rowspan="2" class="min-w-200">
                    Tổng số nhân viên mẫu giáo, mầm non
                </th>
                <th rowspan="2" class="min-w-200">Nữ</th>
                <th rowspan="2" class="min-w-200">Dân tộc</th>
                <th rowspan="2" class="min-w-200">Nữ dân tộc</th>
                <th rowspan="2" class="min-w-200">Kế toán, văn thư</th>
                <th rowspan="2" class="min-w-200">Y tế, thủ quỹ</th>
                <th rowspan="2" class="min-w-200">Nấu ăn</th>
                <th rowspan="2" class="min-w-200">Bảo vệ</th>
                <th colspan="2" class="min-w-200">Viên chức</th>
                <th rowspan="2" class="min-w-200">Hợp đồng lao động</th>
            </tr>
            <tr v-if="active_tab === 5">
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
                <th rowspan="1" class="min-w-200">
                    HĐLV không xác định thời hạn
                </th>
                <th rowspan="1" class="min-w-200">HĐLV xác định thời hạn</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="active_tab === 1">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 2">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 3">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 4">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_4" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>

            <template v-if="active_tab === 5">
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="50">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_5" :key="td.index">
                        {{ item[2][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportTeachers',
    components: {
        CustomTable,

        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'CBQL mẫu giáo' },
                { number: 2, label: 'CBQL nhà trẻ' },
                { number: 3, label: 'Giáo viên' },
                { number: 4, label: 'Độ tuổi GV' },
                { number: 5, label: 'Nhân viên' }
            ],

            list_td_active_tab_1: [
                'E24',
                'E25',
                'E31',
                'E30',
                'E29',
                'E28',
                'E27',
                'E37',
                'J25',
                'K25',
                'L25',
                'F25',
                'G25',
                'H25',
                'E33',
                'E42',
                'E41',
                'E40',
                'E39',
                'E38',
                'E43',
                'J33',
                'K33',
                'L33',
                'F33',
                'G33',
                'H33'
            ],

            list_td_active_tab_2: [
                'E7',
                'E8',
                'E14',
                'E13',
                'E12',
                'E11',
                'E10',
                'E15',
                'J8',
                'K8',
                'L8',
                'F8',
                'G8',
                'H8',
                'E16',
                'E22',
                'E21',
                'E20',
                'E19',
                'E18',
                'E23',
                'J16',
                'K16',
                'L16',
                'F16',
                'G16',
                'H16'
            ],

            list_td_active_tab_3: [
                'E57',
                'E58',
                'E64',
                'E63',
                'E62',
                'E61',
                'E60',
                'E65',
                'J58',
                'K58',
                'L58',
                'F58',
                'G58',
                'H58',
                'E73',
                'E79',
                'E78',
                'E77',
                'E76',
                'E75',
                'E80',
                'J73',
                'K73',
                'L73',
                'F73',
                'G73',
                'H73'
            ],

            list_td_active_tab_4: [
                'E58',
                'E67',
                'E68',
                'E69',
                'E70',
                'E71',
                'E72',
                'E73',
                'E82',
                'E83',
                'E84',
                'E85',
                'E86',
                'E87'
            ],

            list_td_active_tab_5: [
                'E44',
                'E45',
                'F45',
                'G45',
                'H45',
                'E47',
                'E48',
                'E49',
                'E50',
                'J45',
                'K45',
                'L45',
                'E51',
                'F51',
                'K51',
                'H51',
                'E53',
                'E54',
                'E55',
                'E56',
                'J51',
                'K51',
                'L51'
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-200 {
    min-width: 200px;
}
</style>
