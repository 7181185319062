<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="notiVisible"
        :modal-title="'Ghi chú'"
        :close-function="closeModalNoti"
        :size="'xl'"
        :err-modal="errModal"
        :scrollable="true"
    >
        <template v-slot:content>
            <strong> Trường công lập </strong>
            <ul class="custom-list">
                <li v-for="(item, index) in listItems" :key="index">
                    {{ item }}
                </li>
            </ul>
            <strong> Trường tư thục </strong>
            <ul class="custom-list">
                <li>
                    Cán bộ quản lý: tính CBQL trong các trường Mầm non, Trường Mẫu
                    giáo, Nhà trẻ có hình thức hợp đồng là Viên chức HĐLV không
                    xác định thời hạn, Viên chức HĐLV xác định thời hạn, Hợp đồng
                    lao động thời hạn từ 1 năm trở lên, Hợp đồng lao động thời hạn
                    dưới 1 năm.
                </li>
                <li>
                    Giáo viên: Viên chức HĐLV không xác định thời hạn, HĐLV xác
                    định thời hạn, Hợp đồng lao động thời hạn từ 01 năm trở lên
                    (không tính hợp đồng thỉnh giảng, và hợp đồng dưới 1 năm, Hợp
                    đồng theo NĐ 68 giáo viên).
                </li>
                <li>
                    Tính số lượng nhân viên trong các Trường Mầm non, Trường Mẫu
                    giáo, Nhà trẻ, tính tất cả các loại hình thức hợp đồng (trừ
                    Hợp đồng thuê khoán)
                </li>
            </ul>
        </template>
    </CustomModal>
</template>

<script>
import CustomModal from '@/components/CustomModal.vue'

export default {
    name: 'ModalDissolveSchools',
    data() {
        return {
            listItems: [
                'Cán bộ quản lý: Viên chức HĐLV không xác định thời hạn, HĐLV xác định thời hạn, Hợp đồng lao động thời hạn từ 01 năm trở lên, Hợp đồng lao động thời hạn dưới 01 năm.',
                'Giáo viên: Viên chức HĐLV không xác định thời hạn, HĐLV xác định thời hạn, Hợp đồng lao động thời hạn từ 01 năm trở lên, Hợp đồng lao động thời hạn dưới 01 năm, Hợp đồng theo NĐ 68 (không tính hợp đồng thỉnh giảng).',
                'Nhân viên: Viên chức HĐLV không xác định thời hạn, HĐLV xác định thời hạn, Hợp đồng theo NĐ 68 (không tính nhân viên Hợp đồng thuê khoán, Hợp đồng lao động thời hạn từ 01 năm trở lên, Hợp đồng lao động thời hạn dưới 01 năm).'
            ],
            errModal: false
        }
    },
    props: {
        notiVisible: {
            type: Boolean,
            required: true
        },

        closeModalNoti: Function
    },

    components: { CustomModal },
    methods: {},
    mounted() {},
    computed: {}
}
</script>

<style scoped>
.custom-list {
    margin-top: 15px;
    list-style-type: none;
    counter-reset: list-counter;
    margin-left: 0;
    padding-left: 20px;
}

.custom-list li {
    position: relative;
    margin-bottom: 5px;
    padding-left: 30px; /* Space for the number */
    text-align: justify;
}

.custom-list li::before {
    content: counter(list-counter) '.';
    counter-increment: list-counter;
    position: absolute;
    left: 0;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
}
</style>
