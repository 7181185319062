<!-- Tab.vue -->
<template>
    <div :class="{ 'list-tab': true, 'border-hr': !hideHr }">
        <div
            v-for="tab in tabs"
            :key="tab.number"
            class="tab"
            @click="changeTab(tab.number)"
            :class="{ 'active-tab': activeTab === tab.number }"
        >
            {{ tab.label }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        initialTab: {
            type: Number,
            default: 1
        },
        tabs: {
            type: Array,
            required: true
        },
        hideHr: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            activeTab: this.initialTab
        }
    },
    methods: {
        changeTab(tabNumber) {
            this.activeTab = tabNumber
            this.$emit('tabChanged', tabNumber)
        }
    }
}
</script>

<style scoped>
.list-tab {
    display: flex;
}
.active-tab {
    background-color: #fff;
    border-top: 2px var(--blue-shared) solid !important;
    color: var(--blue-shared) !important;
    font-weight: bold !important;
}
.active-tab:hover,
.tab:hover {
    cursor: pointer;
    color: var(--blue-shared);
    padding: 10px 20px;
}
.tab {
    padding: 10px 20px;
    border-radius: 5px 5px 0 0;
    border-color: #dedede;
    color: black;
    font-size: 14px !important;
    font-size: 14px !important;
    cursor: pointer;
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}
.border-hr {
    border-bottom: 1px solid #dee2e6;
}
</style>
