<template>
    <CustomTable :column-form="2" :tab="true" :hidePagi="true">
        <template v-slot:btn-table>
            <CustomTab
                :initialTab="active_tab"
                :tabs="list_tab"
                @tabChanged="handleTabChanged"
            />
        </template>
        <template v-slot:header>
            <!-- 1. GDTX - THCS -->
            <tr v-if="active_tab === 1">
                <th rowspan="3" class="min-w-100">STT</th>
                <th rowspan="3" class="min-w-100">Địa phương</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Lớp 6</th>
                <th colspan="4">Lớp 7</th>
                <th colspan="4">Lớp 8</th>
                <th colspan="4">Lớp 9</th>
                <th colspan="12">Chia theo độ tuổi</th>
                <th colspan="4">Trong đó học viên khuyết tật</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th colspan="4">11-14 tuổi</th>
                <th colspan="4">15-18 tuổi</th>
                <th colspan="4">Trên 18 tuổi</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
            </tr>
            <tr v-if="active_tab === 1">
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
            </tr>

            <!-- 2. GDTX - THPT -->
            <tr v-if="active_tab === 2">
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-100">Địa phương</th>
                <th colspan="4">Tổng số</th>
                <th colspan="4">Lớp 10</th>
                <th colspan="4">Lớp 11</th>
                <th colspan="4">Lớp 12</th>
                <th colspan="12">Chia theo độ tuổi</th>
                <th colspan="4">
                    Học viên học văn hóa kết hợp với trung cấp nghề
                </th>
                <th colspan="4">Học viên khuyết tật</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th colspan="4">15-17 tuổi</th>
                <th colspan="4">18-21 tuổi</th>
                <th colspan="4">Trên 21 tuổi</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
            </tr>
            <tr v-if="active_tab === 2">
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
            </tr>

            <!-- 3. Xóa mù chữ -->
            <tr v-if="active_tab === 3">
                <th rowspan="4">STT</th>
                <th rowspan="4" class="min-w-100">Địa phương</th>
                <th colspan="4">Tổng số</th>
                <th colspan="24">
                    Xóa mù chữ và giáo dục tiếp tục sau khi biết chữ
                </th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="3" class="min-w-100">Tổng số</th>
                <th rowspan="3" class="min-w-100">Nữ</th>
                <th rowspan="3" class="min-w-100">Dân tộc</th>
                <th rowspan="3" class="min-w-100">Nữ DT</th>
                <th colspan="4">Học chương trình lớp 1 đến lớp 3</th>
                <th colspan="4">Học chương trình lớp 4 đến lớp 5</th>
                <th colspan="12">Trong đó: Chia theo độ tuổi</th>
                <th colspan="4">Trong đó học viên khuyết tật</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
                <th colspan="4">Từ 15 tuổi đến 25 tuổi</th>
                <th colspan="4">Từ 26 tuổi đến 35 tuổi</th>
                <th colspan="4">Từ 36 tuổi đến 60 tuổi</th>
                <th rowspan="2" class="min-w-100">Tổng số</th>
                <th rowspan="2" class="min-w-100">Nữ</th>
                <th rowspan="2" class="min-w-100">Dân tộc</th>
                <th rowspan="2" class="min-w-100">Nữ DT</th>
            </tr>
            <tr v-if="active_tab === 3">
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
            </tr>

            <!-- 4. Đào tạo bồi dưỡng -->
            <tr v-if="active_tab === 4">
                <th rowspan="3" class="min-w-100">STT</th>
                <th rowspan="3" class="min-w-100">Địa phương</th>
                <th colspan="24">Các chương trình đào tạo, bồi dưỡng</th>
            </tr>
            <tr v-if="active_tab === 4">
                <th colspan="4">Chương trình tin học</th>
                <th colspan="4">Chương trình ngoại ngữ</th>
                <th colspan="4">Chương trình hướng nghiệp, học nghề phổ thông</th>
                <th colspan="4">Chương trình học tiếng dân tộc</th>
                <th colspan="4">
                    Chương trình bồi dưỡng thường xuyên cho giáo viên mầm non và
                    phổ thông (theo Thông tư 26/2012/TT-BGDĐT)
                </th>
                <th colspan="4">
                    Chương trình GDTX đáp ứng yêu cầu của người học, cập nhật kiến
                    thức, kỹ năng, chuyển giao công nghệ
                </th>
            </tr>
            <tr v-if="active_tab === 4">
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
                <th rowspan="1" class="min-w-100">Tổng số</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ DT</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-if="active_tab === 1">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_1" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 2">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_2" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 3">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_3" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>
            <template v-if="active_tab === 4">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left min-width">
                        {{ item['H3'] }}
                    </td>

                    <td v-for="td in list_td_active_tab_4" :key="td.index">
                        {{ item[1][td] }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import CustomTab from '@/components/CustomTab.vue'

export default {
    name: 'ReportStudent',
    components: {
        CustomTable,
        CustomTab
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            active_tab: 1,
            list_tab: [
                { number: 1, label: 'GDTX - THCS' },
                { number: 2, label: 'GDTX - THPT' },
                { number: 3, label: 'Xóa mù chữ' },
                { number: 4, label: 'Đào tạo bồi dưỡng' }
            ],
            list_td_active_tab_1: [
                'E7',
                'F7',
                'G7',
                'H7',
                'E9',
                'F9',
                'G9',
                'H9',
                'E10',
                'F10',
                'G10',
                'H10',
                'E11',
                'F11',
                'G11',
                'H11',
                'E12',
                'F12',
                'G12',
                'H12',
                'E14',
                'F14',
                'G14',
                'H14',
                'E15',
                'F15',
                'G15',
                'H15',
                'E16',
                'F16',
                'G16',
                'H16',
                'E18',
                'F18',
                'G18',
                'H18'
            ],
            list_td_active_tab_2: [
                'E19',
                'F19',
                'G19',
                'H19',
                'E21',
                'F21',
                'G21',
                'H21',
                'E22',
                'F22',
                'G22',
                'H22',
                'E23',
                'F23',
                'G23',
                'H23',
                'E25',
                'F25',
                'G25',
                'H25',
                'E26',
                'F26',
                'G26',
                'H26',
                'E27',
                'F27',
                'G27',
                'H27',
                '',
                '',
                '',
                '',
                'E29',
                'F29',
                'G29',
                'H29'
            ],
            list_td_active_tab_3: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            list_td_active_tab_4: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]
        }
    },

    methods: {
        handleTabChanged(tabNumber) {
            this.active_tab = tabNumber
        }
    },
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-100 {
    min-width: 100px;
}
</style>
