<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-300">Đơn vị</th>
                <th rowspan="3" class="min-w-200">Tổng số (CBQL, GV, NV)</th>
                <th colspan="2">CBQL</th>
                <th colspan="2">Giáo viên</th>
                <th colspan="12">Nhân viên</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-150">Hiệu trưởng</th>
                <th rowspan="2" class="min-w-150">Phó Hiệu trưởng</th>
                <th rowspan="2" class="min-w-150">Tổng số</th>
                <th rowspan="2" class="min-w-200">GV chuyên trách Đoàn đội</th>

                <th rowspan="2" class="min-w-150">Nhân viên</th>
                <th rowspan="2" class="min-w-150">Văn thư</th>
                <th rowspan="2" class="min-w-150">Kế toán</th>
                <th rowspan="2" class="min-w-150">Y tế</th>
                <th rowspan="2" class="min-w-150">Thủ quỹ</th>
                <th rowspan="2" class="min-w-150">Thư viện</th>
                <th rowspan="2" class="min-w-150">Thiết bị, thí nghiệm</th>
                <th rowspan="2" class="min-w-150">CNTT</th>
                <th rowspan="2" class="min-w-150">Hỗ trợ khuyết tật</th>
                <th rowspan="2" class="min-w-150">Giáo vụ</th>
                <th rowspan="2" class="min-w-150">Bảo vệ</th>
                <th rowspan="2" class="min-w-150">Khác</th>
            </tr>
        </template>

        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left">{{ item.tenDonVi }}</td>
                <td>{{ item.tongSoCanBo }}</td>
                <td>{{ item.slHieuTruong }}</td>
                <td>{{ item.slHieuPho }}</td>
                <td>{{ item.slGiaoVien }}</td>
                <td>{{ item.slChuyenTrachDoan }}</td>
                <td>{{ item.slNhanVien }}</td>
                <td>{{ item.slNhanVienVanThu }}</td>
                <td>{{ item.slNhanVienKeToan }}</td>
                <td>{{ item.slNhanVienYTe }}</td>

                <td>{{ item.slNhanVienThuQuy }}</td>
                <td>{{ item.slNhanVienThuVien }}</td>
                <td>
                    {{ item.slNhanVienThietBi + item.slNhanVienThiNghiem }}
                </td>
                <td>{{ item.slNhanVienCntt }}</td>
                <td>{{ item.slNhanVienHoTroKhuyetTat }}</td>
                <td>{{ item.slNhanVienGiaoVu }}</td>

                <td>{{ item.slNhanVienBaoVe }}</td>
                <td>{{ item.slNhanVienKhac }}</td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'StatisticsOfTeachingStaff',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        },
        column_form: {
            type: Number,
            require: true
        }
    },

    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped></style>
