<template>
    <CustomTable
        :column-form="2"
        :tab="true"
        :hideBtnTable="true"
        :hidePagi="true"
    >
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-200">Đơn vị</th>
                <th colspan="13">Trung học</th>
                <th colspan="12">Tỷ lệ (%) trường Trung học theo tiêu chí</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-200">Tổng số trưởng Trung học</th>
                <th colspan="12">Trong đó</th>
                <th rowspan="2" class="min-w-200">
                    Trường có sử dụng máy tính cho dạy học
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có khai thác Intternet cho dạy học
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có tổ chức học trực tuyến
                </th>
                <th rowspan="2" class="min-w-200">Trường có điện (điện lưới)</th>
                <th rowspan="2" class="min-w-200">Trường có nguồn nước sạch</th>
                <th rowspan="2" class="min-w-200">Trường có nước uống</th>
                <th rowspan="2" class="min-w-200">
                    Trường có công trình vệ sinh
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có chương trình giáo dục vệ sinh đôi tay
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có chương trình giáo dục cơ bản về giới tính, phòng
                    chống bạo lực, xâm hại; cung cấp kiến thức về HIV.
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có cơ sở hạ tầng, tài liệu học tập, môi trường học tập
                    phù hợp và tiếp cận với học sinh khuyết tật
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có bộ phận làm công tác tư vấn học đường
                </th>
                <th rowspan="2" class="min-w-200">
                    Trường có kế hoạch giáo dục phòng, chống thiên tai và ứng phó
                    biến đổi khí hậu
                </th>
            </tr>
            <tr>
                <th rowspan="1" class="min-w-200">
                    Trường có sử dụng máy tính cho dạy học
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có khai thác Intternet cho dạy học
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có tổ chức học trực tuyến
                </th>
                <th rowspan="1" class="min-w-200">Trường có điện (điện lưới)</th>
                <th rowspan="1" class="min-w-200">Trường có nguồn nước sạch</th>
                <th rowspan="1" class="min-w-200">Trường có nước uống</th>
                <th rowspan="1" class="min-w-200">
                    Trường có công trình vệ sinh
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có chương trình giáo dục vệ sinh đôi tay
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có chương trình giáo dục cơ bản về giới tính, phòng
                    chống bạo lực, xâm hại; cung cấp kiến thức về HIV.
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có cơ sở hạ tầng, tài liệu học tập, môi trường học tập
                    phù hợp và tiếp cận với học sinh khuyết tật
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có bộ phận làm công tác tư vấn học đường
                </th>
                <th rowspan="1" class="min-w-200">
                    Trường có kế hoạch giáo dục phòng, chống thiên tai và ứng phó
                    biến đổi khí hậu
                </th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>

            <tr v-for="(item, index) in tableData" :key="index">
                <td>
                    {{ (start / limit) * limit + index + 1 }}
                </td>
                <td class="text-left min-width">
                    {{ item['H3'] }}
                </td>

                <td v-for="td in list_td" :key="td.index">
                    {{ item[0][td] }}
                </td>
            </tr>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ReportSchoolCcriteriaSDG',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            list_td: [
                'E10',
                'E27',
                'E28',
                '',
                'E29',
                'E30',
                '',
                'E31',
                '',
                'E33',
                'E34',
                'E35',
                'E36',
                'E37',
                'E52',
                'E53',
                '',
                'E54',
                'E55',
                '',
                'E56',
                '',
                'E58',
                'E59',
                'E60'
                // 'E61'
                // '',
                // '',
                // '',
                // '',
                // '',
                // '',
                // '',
                // '',
                // '',
                // '',
                // '',
                // ''
            ]
        }
    },

    methods: {},
    mounted() {},

    computed: {}
}
</script>

<style scoped>
.min-w-200 {
    min-width: 200px;
}
</style>
