<template>
    <span :class="`${statusClass} additionalClass`">{{ content }}</span>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
            required: true
        },
        status: {
            required: true
        }
    },

    computed: {
        statusClass() {
            const statusNumberMap = {
                1: 'active',
                0: 'inactive',
                '-1': 'deleted',
                2: 'dissolved',
                6: 'six'
            }
            const statusStringMap = {
                1: 'active',
                0: 'inactive',
                '-1': 'deleted',
                2: 'dissolved',
                6: 'six'
            }

            return typeof this.status === 'number'
                ? statusNumberMap[this.status] || ''
                : typeof this.status === 'string'
                ? statusStringMap[this.status] || ''
                : ''
        }
    }
}
</script>

<style scoped>
.active {
    background-color: #d4fcad;
}
.inactive {
    background-color: #e4e5e6;
}
.deleted {
    background-color: #f3cccc;
}
.dissolved {
    background-color: #faf1c7;
}
.six {
    background-color: #ffc8aa;
}
.additionalClass {
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    color: black !important;
}
</style>
