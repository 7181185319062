<template>
    <div>
        <div class="card-header">
            <div class="title"><slot name="title"></slot></div>
            <div>
                <slot name="btn-title"></slot>
            </div>
        </div>
        <div class="card-body">
            <slot name="body"></slot>
        </div>
    </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/index.css'

export default {
    name: 'CardItem',
    components: {},
    data() {
        return {}
    },
    props: {},
    methods: {}
}
</script>

<style scoped>
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #186ab2;
    height: 35px;
    color: white;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.card-header .title {
    padding: 10px;
    color: white;
    font-weight: 700;
    font-size: 15px;
}
.card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
</style>
