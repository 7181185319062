var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutCard',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Trạng thái ")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh","label":"","size":"medium"},on:{"click":_vm.handlerefresh_column_chart_status_student}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                _vm.column_chart_status_student
                            ),"xaxis_categories":_vm.xaxisCategories.column_chart_status_student}})]},proxy:true}])})],1),_c('div',{staticClass:"col-md-6 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Giới tính ")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh","label":"","size":"medium"},on:{"click":_vm.handlerefresh_column_chart_gender_student}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                _vm.column_chart_gender_student
                            ),"xaxis_categories":_vm.xaxisCategories.column_chart_gender_student}})]},proxy:true}])})],1),_c('div',{staticClass:"col-md-6 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Loại hình đào tạo")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh","label":"","size":"medium"},on:{"click":_vm.handlerefresh_column_chart_type_of_training}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                _vm.column_chart_type_of_training
                            ),"xaxis_categories":_vm.xaxisCategories.column_chart_type_of_training}})]},proxy:true}])})],1),_c('div',{staticClass:"col-md-6 mb-4"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Khu vực")]},proxy:true},{key:"btn-title",fn:function(){return [_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh","label":"","size":"medium"},on:{"click":_vm.handlerefresh_column_chart_area}})]},proxy:true},{key:"body",fn:function(){return [_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(_vm.column_chart_area),"xaxis_categories":_vm.xaxisCategories.column_chart_area}})]},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }