<template>
    <CustomTable :column-form="2" :hide-btn-table="true" :hide-pagi="true">
        <template v-slot:header>
            <tr>
                <th rowspan="3">STT</th>
                <th rowspan="3" class="min-w-200">&nbsp;</th>
                <th rowspan="3" class="min-w-100">Sĩ số</th>
                <th rowspan="3" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="6">Lớp 1</th>
                <th colspan="6">Lớp 2</th>
                <th colspan="6">Lớp 3</th>
                <th colspan="6">Lớp 4</th>
                <th colspan="6">Lớp 5</th>
            </tr>
            <tr>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
                <th rowspan="2" class="min-w-150">Tổng số HS có KQĐG</th>
                <th colspan="5">Trong tổng số</th>
            </tr>
            <tr>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
                <th rowspan="1" class="min-w-100">Nữ</th>
                <th rowspan="1" class="min-w-100">Dân tộc</th>
                <th rowspan="1" class="min-w-100">Nữ dân tộc</th>
                <th rowspan="1" class="min-w-100">Lớp ghép</th>
                <th rowspan="1" class="min-w-100">Khuyết tật</th>
            </tr>
        </template>
        <template v-slot:body>
            <template v-if="tableData.length === 0">
                <tr>
                    <td colspan="50">Không có bản ghi nào.</td>
                </tr>
            </template>
            <template v-for="(item, index) in tableData">
                <tr
                    v-for="rowItem in rowItems"
                    :key="'row_' + rowItem.index + '_' + index"
                >
                    <td>{{ rowItem.index }}</td>
                    <td :class="rowItem.class">{{ rowItem.label }}</td>
                    <td></td>
                    <td v-for="td in rowItem.list_td" :key="td.index">
                        {{ numberWithCommas(item[0][td]) }}
                    </td>
                </tr>
            </template>
        </template>
    </CustomTable>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import { numberWithCommas, generateListTdReportEqms } from '@/utils'
export default {
    name: 'QualityOfEducation',
    components: {
        CustomTable
    },
    props: {
        tableData: {
            type: Array,
            require: true
        },
        start: {
            type: Number,
            require: true
        },
        limit: {
            type: Number,
            require: true
        }
    },

    data() {
        return {
            rowItems: [
                {
                    index: 1,
                    label: 'I. Kết quả học tập',
                    class: 'bold-italic text-left',
                    list_td: generateListTdReportEqms(5)
                },
                {
                    index: 2,
                    label: '1. Tiếng việt',
                    class: 'bold-italic text-left',
                    list_td: generateListTdReportEqms(6)
                },
                {
                    index: 3,
                    label: 'Điểm 10',
                    class: 'text-left',
                    list_td: generateListTdReportEqms(7)
                },

                {
                    index: 4,
                    label: 'Điểm 9',
                    class: 'text-left',
                    list_td: generateListTdReportEqms(8)
                },

                {
                    index: 5,
                    label: 'Điểm 8',
                    class: 'text-left',
                    list_td: generateListTdReportEqms(9)
                },

                {
                    index: 6,
                    label: 'Điểm 7',
                    class: 'text-left',
                    list_td: generateListTdReportEqms(10)
                },

                {
                    index: 7,
                    label: 'Điểm 6',
                    class: 'text-left',
                    list_td: generateListTdReportEqms(11)
                },

                {
                    index: 8,
                    label: 'Điểm 5',
                    class: 'text-left',
                    list_td: generateListTdReportEqms(12)
                },

                {
                    index: 9,
                    label: 'Dưới điểm 5',
                    class: 'text-left',
                    list_td: generateListTdReportEqms(13)
                },

                {
                    index: 10,
                    label: '2. Toán',
                    class: 'bold-italic text-left ',
                    list_td: generateListTdReportEqms(14)
                },

                {
                    index: 11,
                    label: 'Điểm 10',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(15)
                },

                {
                    index: 12,
                    label: 'Điểm 9',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(16)
                },

                {
                    index: 13,
                    label: 'Điểm 8',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(17)
                },

                {
                    index: 14,
                    label: 'Điểm 7',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(18)
                },

                {
                    index: 15,
                    label: 'Điểm 6',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(19)
                },

                {
                    index: 16,
                    label: 'Điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(20)
                },

                {
                    index: 17,
                    label: 'Dưới điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(21)
                },

                {
                    index: 18,
                    label: '3. Khoa học',
                    class: 'bold-italic text-left ',
                    list_td: generateListTdReportEqms(22)
                },

                {
                    index: 19,
                    label: 'Điểm 10',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(23)
                },

                {
                    index: 20,
                    label: 'Điểm 9',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(24)
                },

                {
                    index: 21,
                    label: 'Điểm 8',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(25)
                },

                {
                    index: 22,
                    label: 'Điểm 7',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(26)
                },

                {
                    index: 23,
                    label: 'Điểm 6',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(27)
                },

                {
                    index: 24,
                    label: 'Điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(28)
                },

                {
                    index: 25,
                    label: 'Dưới điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(29)
                },

                {
                    index: 26,
                    label: '4. Lịch sử và Địa lý',
                    class: 'bold-italic text-left ',
                    list_td: generateListTdReportEqms(30)
                },

                {
                    index: 27,
                    label: 'Điểm 10',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(31)
                },

                {
                    index: 28,
                    label: 'Điểm 9',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(32)
                },

                {
                    index: 29,
                    label: 'Điểm 8',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(33)
                },

                {
                    index: 30,
                    label: 'Điểm 7',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(34)
                },

                {
                    index: 31,
                    label: 'Điểm 6',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(35)
                },

                {
                    index: 32,
                    label: 'Điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(36)
                },

                {
                    index: 33,
                    label: 'Dưới điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(37)
                },

                {
                    index: 34,
                    label: '5. Ngoại ngữ',
                    class: 'bold-italic text-left ',
                    list_td: generateListTdReportEqms(38)
                },

                {
                    index: 35,
                    label: 'Điểm 10',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(39)
                },

                {
                    index: 36,
                    label: 'Điểm 9',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(40)
                },

                {
                    index: 37,
                    label: 'Điểm 8',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(41)
                },

                {
                    index: 38,
                    label: 'Điểm 7',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(42)
                },

                {
                    index: 39,
                    label: 'Điểm 6',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(43)
                },

                {
                    index: 40,
                    label: 'Điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(44)
                },

                {
                    index: 41,
                    label: 'Dưới điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(45)
                },

                {
                    index: 42,
                    label: '6. TH-CN (Tin học)',
                    class: 'bold-italic text-left ',
                    list_td: generateListTdReportEqms(46)
                },

                {
                    index: 43,
                    label: 'Điểm 10',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(47)
                },

                {
                    index: 44,
                    label: 'Điểm 9',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(48)
                },

                {
                    index: 45,
                    label: 'Điểm 8',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(49)
                },

                {
                    index: 46,
                    label: 'Điểm 7',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(50)
                },

                {
                    index: 47,
                    label: 'Điểm 6',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(51)
                },

                {
                    index: 48,
                    label: 'Điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(52)
                },

                {
                    index: 49,
                    label: 'Dưới điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(53)
                },

                {
                    index: 50,
                    label: '7. Tiếng dân tộc',
                    class: 'bold-italic text-left ',
                    list_td: generateListTdReportEqms(54)
                },

                {
                    index: 51,
                    label: 'Điểm 10',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(55)
                },

                {
                    index: 52,
                    label: 'Điểm 9',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(56)
                },

                {
                    index: 53,
                    label: 'Điểm 8',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(57)
                },

                {
                    index: 54,
                    label: 'Điểm 7',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(58)
                },

                {
                    index: 55,
                    label: 'Điểm 6',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(59)
                },

                {
                    index: 56,
                    label: 'Điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(60)
                },

                {
                    index: 57,
                    label: 'Dưới điểm 5',
                    class: ' text-left ',
                    list_td: generateListTdReportEqms(61)
                }
            ]
        }
    },

    methods: { numberWithCommas, generateListTdReportEqms }
}
</script>

<style scoped>
.bold-italic {
    font-weight: bold;
    font-style: italic;
}
.min-w-200 {
    min-width: 200px;
}
.min-w-150 {
    min-width: 150px;
}
.min-w-100 {
    min-width: 100px;
}
</style>
